import React, { Component } from "react";
//import "./style.scss";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";

@observer
class ProvisionThanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorType: null,
        };
    }

    componentDidMount() {

    }

    render() {
        const { agent_guid, payment_url } = this.props;

        return (
            <div style={{ padding: 20, height: "100vh" }}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <p>
                        <img src="/assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
                    </p>

                    {agent_guid ? (
                        <>
                            <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>
                                You need to complete payment to access your dashboard.
                            </p>

                            <a href={payment_url + "?reference=agent_" + agent_guid} className="btn btn-lg btn-primary">Go to payment page &rarr;</a>
                        </>
                    ) : (
                        <>
                            <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>
                                Payment Complete
                            </p>

                            <Link to="/login" className="btn btn-primary">Go to your dashboard</Link>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const styles = {
    text: {
        fontSize: 16,
    },
}

export default ProvisionThanks;

import React, { Component } from "react";
import "./style.scss";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";

import { Redirect, Link } from "react-router-dom";

import { observer } from "mobx-react";

// TODO:
// Split CSS into SCSS bits like reset/base etc

@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      email: null,
      token: null
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get("e"); // Gets ?token=xxx
    const token = query.get("a"); // Gets ?token=xxx

    this.checkToken(email, token);
  }

  checkToken(email, token) {
    if (!email || !token) {
      this.setState({
        loading: false,
        error: "Invalid or expired SSO access token."
      });
      return;
    }

    api.accessToken(
      {
        email: email,
        token: token
      },
      response => {
        const api_key = response.api_key;
        const user = response.user;

        const cookies = new Cookies();
        cookies.set("bk_api_key", api_key, {
          path: "/",
          maxAge: 60 * 60 * 24
        });

        UserStore.updateData(response.user);
        AppStore.setAPIKey(api_key);

        AppStore.setAppData(response.app);

        this.setState({ tryLogin: true, loading: false });
      },
      error => {
        let err = error.response.data.message;
        this.setState({ loading: false, error: err });
      }
    );

    /*this.setState({
      email: email,
      token: token,
      loading: false,
      error: "Invalid or expired SSO access token."
    });*/
  }

  /*
  login(email, password, stack_id) {
    this.setState({ loading: true, error: null });

    api.login(
      {
        email: email,
        password: password,
        stack_id: stack_id
      },
      response => {
        //alert(JSON.stringify(response));

        if (response.apps !== undefined && response.apps.length) {
          this.setState({ apps: response.apps });
        } else {
          const api_key = response.api_key;
          const user = response.user;

          const cookies = new Cookies();
          cookies.set("bk_api_key", api_key, {
            path: "/",
            maxAge: 60 * 60 * 24
          });

          UserStore.updateData(response.user);
          AppStore.setAPIKey(api_key);

          AppStore.setAppData(response.app);

          this.setState({ tryLogin: true });
        }

        this.setState({ loading: false });
      },
      error => {
        let err = error.response.data.message;
        this.setState({ loading: false, error: err });
      }
    );
  }
*/

  ssoForm() {
    return (
      <div
        class="d-flex w-100 justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div class="container">
          <div class="row ">
            <div class="col-lg-8 mx-auto  mt-2">
              <div class="card py-3 m-b-30">
                <div class="card-body">
                  <h3 class="text-center">SSO</h3>

                  {this.state.error && (
                    <div class="card-body">
                      <div
                        class="alert alert-border-danger  alert-dismissible fade show"
                        role="alert"
                      >
                        <div class="d-flex">
                          <div class="icon">
                            <i class="icon mdi mdi-alert-octagram" />
                          </div>
                          <div class="content">
                            <strong>Error</strong>
                            <br />
                            {this.state.error}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <p class="text-center">
                    <Link to="/login" class="text-underline">
                      Try standard login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div class="login-content" key={1}>
        <p style={{ textAlign: "center" }}>Broker dashboard SSO</p>

        {this.state.error && (
          <div class="errorBox">
            <strong>Error</strong>
            <br />
            {this.state.error}
          </div>
        )}

        <p class="text-center">
          <a href="/login">Try standard login</a>
        </p>
      </div>
    );
  }

  content() {
    if (this.state.loading) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return this.ssoForm();
    }
  }

  render() {
    if (this.state.tryLogin) {
      return <Redirect to="/" />;
    }

    /*
    return (
      <div
        class="d-flex w-100 justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div class="container">
          <div class="row ">
            <div class="col-lg-8 mx-auto  mt-2">
              <div class="card py-3 m-b-30">
                <div class="card-body">
                  <h3 class="">API key</h3>

                  <div class="card-body">
                    <div
                      class="alert alert-border-danger  alert-dismissible fade show"
                      role="alert"
                    >
                      <div class="d-flex">
                        <div class="icon">
                          <i class="icon mdi mdi-alert-octagram" />
                        </div>
                        <div class="content">
                          <strong>Holy guacamole!</strong>
                          <br />
                          You should check in on some of those fields below.
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="text-center">
                    <Link to="/login" class="text-underline">
                      Try standard login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );*/

    return (
      <div class="login-container">
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {this.content()}
        </ReactCSSTransitionReplace>
      </div>
    );
  }
}

export default Login;

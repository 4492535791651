import React, { Component } from "react";
//import "./style.scss";
import "../../scss/atmos.scss";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";

import { Redirect } from "react-router-dom";

import { observer } from "mobx-react";

// TODO:
// Split CSS into SCSS bits like reset/base etc

@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
      passwordError: false,
      email: null,
      password: null,
      loading: false,
      error: null,
      apps: null,
      forgotScreen: false,
      forgotSent: false,
      tryOther: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForgot = this.handleSubmitForgot.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove("sidebar-pinnedn");
    document.body.classList.add("jumbo-page");
  }

  componentWillUnmount() {
    document.body.classList.add("sidebar-pinnedn");
    document.body.classList.remove("jumbo-page");
  }

  handleSubmitForgot(e) {
    e.preventDefault();
    const email = this.emailReset.value;

    let proceed = true;

    if (!email) {
      this.setState({ emailError: true });
      proceed = false;
    }

    this.setState({
      email: email
    });

    if (proceed) {
      this.forgot(email);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this.email.value;
    const password = this.password.value;

    let proceed = true;

    if (!email) {
      this.setState({ emailError: true });
      proceed = false;
    }
    if (!password || password.length < 6) {
      this.setState({ passwordError: true });
      proceed = false;
    }

    this.setState({
      email: email,
      password: password
    });

    if (proceed) {
      this.login(email, password);
    }
  }

  forgot(email, stack_id) {
    this.setState({ loading: true, error: null });

    api.forgot(
      {
        email: email,
        stack_id: stack_id
      },
      response => {
        this.setState({ loading: false, forgotSent: true });
      },
      error => {
        let err = error.response.data.message;
        this.setState({ loading: false, error: err });
      }
    );
  }

  login(email, password, stack_id) {
    this.setState({ loading: true, error: null, tryOther: false });

    api.login(
      {
        email: email,
        password: password,
        stack_id: stack_id
      },
      response => {
        //alert(JSON.stringify(response));
        const locstate = this.props.location.state;
        const loc = locstate?.from?.pathname;
        const no_redir = ['/logout', '/sso', '/login'];

        if (response.apps !== undefined && response.apps.length) {
          this.setState({ apps: response.apps });
        } else {
          const api_key = response.api_key;
          const user = response.user;

          const cookies = new Cookies();
          cookies.set("bk_api_key", api_key, {
            path: "/",
            maxAge: 60 * 60 * 24
          });

          UserStore.updateData(response.user);
          AppStore.setAPIKey(api_key);

          AppStore.setAppData(response.app);

          this.setState({ tryLogin: true });

          if (loc && !no_redir.includes(loc)) {
            window.location.pathname = loc;
          }
        }

        this.setState({ loading: false });
      },
      error => {
        let err = error.response.data.message;
        let tryval = false;
        if (error.response.data.check_other && error.response.data.check_other == true) {
          tryval = true;
        }

        if (error?.response?.data?.disabled == true) {
          err = "This account has been disabled. If you think this is error, please contact your account admin.";
        }

        this.setState({ loading: false, error: err, tryOther: tryval });
      }
    );
  }

  loginForm() {
    if (this.state.apps && !this.state.error) {
      return (
        <div class="login-content" key={2}>
          <p>Choose your app</p>

          {this.state.apps.map((item, index) => (
            <div
              key={index}
              class="app-list"
              onClick={() => {
                this.login(
                  this.state.email,
                  this.state.password,
                  item.stack_id
                );
              }}
            >
              <img src={item.icon} className="app-list-icon" />
              <span className="app-list-name">{item.name}</span>
            </div>
          ))}

          <p class="text-center">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({ error: null, apps: null });
              }}
            >
              Cancel
            </a>
          </p>
        </div>
      );
    }

    return (
      <div class="mx-auto col-md-8">
        <div class="p-b-20 text-center">
          <p>
            <img src="assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
          </p>
          <p class="admin-brand-content">Broker Dashboard</p>
        </div>

        <h3 class="text-center p-b-20 fw-400">Login</h3>

        {this.state.error && (
          <>
            {this.state.tryOther ? (<div class="alert alert-danger" role="alert">
              Invalid login. You may want to <a style={{ textDecoration: "underline" }} href="https://agent.homestack.com">check the agent dashboard</a> instead.
            </div>) : (<div class="alert alert-danger" role="alert">
              {this.state.error}
            </div>)}

          </>
        )}

        <form onSubmit={this.handleSubmit}>
          <div class="form-row">
            <div class="form-group floating-label col-md-12">
              <label>Email</label>
              <input
                type="email"
                name="email"
                id="email"
                class={
                  this.state.emailError
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Email"
                ref={input => (this.email = input)}
                onChange={text => {
                  this.setState({ emailError: false });
                }}
              />

              {this.state.emailError && (
                <div class="invalid-feedback">Please enter a valid email</div>
              )}
            </div>
            <div class="form-group floating-label col-md-12">
              <label>Password</label>
              <input
                type="password"
                id="password"
                ref={input => (this.password = input)}
                onChange={text => {
                  this.setState({ passwordError: false });
                }}
                class={
                  this.state.passwordError
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Password"
              />

              {this.state.passwordError && (
                <div class="invalid-feedback">
                  Please enter a password (minimum 6 characters)
                </div>
              )}
            </div>
          </div>

          <button type="submit" class="btn btn-primary btn-block btn-lg">
            Login
          </button>
        </form>
        <p class="text-right p-t-10">
          <a
            href={null}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ forgotScreen: true });
            }}
            class="text-underline"
          >
            Forgot Password?
          </a>
        </p>
      </div>
    );
  }

  loginFormOLD() {
    if (this.state.apps && !this.state.error) {
      return (
        <div class="login-content" key={2}>
          <p>Choose your app</p>

          {this.state.apps.map((item, index) => (
            <div
              key={index}
              class="app-list"
              onClick={() => {
                this.login(
                  this.state.email,
                  this.state.password,
                  item.stack_id
                );
              }}
            >
              <img src={item.icon} className="app-list-icon" />
              <span className="app-list-name">{item.name}</span>
            </div>
          ))}

          <p class="text-center">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({ error: null, apps: null });
              }}
            >
              Cancel
            </a>
          </p>
        </div>
      );
    }

    return (
      <div class="login-content" key={1}>
        <p>Sign in to your broker dashboard.</p>

        {this.state.error && (
          <div class="errorBox">
            <strong>Error</strong>
            <br />
            {this.state.error}
          </div>
        )}

        <form onSubmit={this.handleSubmit}>
          <p>
            <label for="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              ref={input => (this.email = input)}
              onChange={text => {
                this.setState({ emailError: false });
              }}
            />
          </p>
          {this.state.emailError && (
            <div class="error">
              <p>Please enter a valid email</p>
            </div>
          )}

          <p>
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              ref={input => (this.password = input)}
              onChange={text => {
                this.setState({ passwordError: false });
              }}
            />
          </p>
          {this.state.passwordError && (
            <div class="error">
              <p>Please enter a password (minimum 6 characters)</p>
            </div>
          )}

          <p>
            <button type="submit">
              {this.state.loading ? (
                <Spinner noFadeIn name="three-bounce" color="#fff" />
              ) : (
                "Sign in"
              )}
            </button>
          </p>
        </form>
        <p class="text-center">
          <a
            href={null}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ forgotScreen: true });
            }}
          >
            Forgot password?
          </a>
        </p>
      </div>
    );
  }

  chooseAppForm() { }

  forgotForm() {
    return (
      <div class="mx-auto col-md-8">
        <div class="p-b-20 text-center">
          <p>
            <img src="assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
          </p>
          <p class="admin-brand-content">Broker Dashboard</p>
        </div>

        <h3 class="text-center p-b-20 fw-400">Forgotten your password?</h3>

        {this.state.error && (
          <div class="alert alert-danger" role="alert">
            {this.state.error}
          </div>
        )}

        {this.state.forgotSent ? (
          <div class="alert alert-info" role="alert">
            <h4 class="alert-heading">Reset link sent!</h4>
            <p>Check your email for a reset link.</p>
          </div>
        ) : (
          <form onSubmit={this.handleSubmitForgot}>
            <div class="form-row">
              <div class="form-group floating-label col-md-12">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class={
                    this.state.emailError
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Email"
                  ref={input => (this.emailReset = input)}
                  onChange={text => {
                    this.setState({ emailError: false });
                  }}
                />

                {this.state.emailError && (
                  <div class="invalid-feedback">Please enter a valid email</div>
                )}
              </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block btn-lg">
              Send reset link
            </button>
          </form>
        )}

        <p class=" p-t-10">
          <a
            href={null}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                forgotScreen: false,
                forgotSent: false,
                error: null
              });
            }}
            class="text-underline"
          >
            &larr; Go back
          </a>
        </p>
      </div>
    );
  }

  forgotFormOLD() {
    return (
      <div class="login-content" key={1}>
        <p>Forgotten your password?</p>

        {this.state.error && (
          <div class="errorBox">
            <strong>Error</strong>
            <br />
            {this.state.error}
          </div>
        )}

        {this.state.forgotSent ? (
          <div class="successBox">
            <strong>Reset link sent!</strong>
            <br />
            Check your email for a reset link.
          </div>
        ) : (
          <form onSubmit={this.handleSubmitForgot}>
            <p>
              <label for="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                ref={input => (this.emailReset = input)}
                onChange={text => {
                  this.setState({ emailError: false });
                }}
              />
            </p>
            {this.state.emailError && (
              <div class="error">
                <p>Please enter a valid email</p>
              </div>
            )}

            <p>
              <button type="submit">
                {this.state.loading ? (
                  <Spinner noFadeIn name="three-bounce" color="#fff" />
                ) : (
                  "Send reset link"
                )}
              </button>
            </p>
          </form>
        )}
        <p class="text-center">
          <a
            href={null}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                forgotScreen: false,
                forgotSent: false,
                error: null
              });
            }}
          >
            &larr; Go back
          </a>
        </p>
      </div>
    );
  }

  switch() {
    if (this.state.forgotScreen) {
      return this.forgotForm();
    } else {
      return this.loginForm();
    }
  }

  render() {
    if (this.state.tryLogin) {
      return <Redirect to="/" />;
    }

    /*
    <div class="login-container">
        <img
          class="menu-logo"
          src="https://homestack.com/assets2/images/logo@2x.png"
        />

        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {this.switch()}
        </ReactCSSTransitionReplace>
      </div>*/

    return (
      <main class="admin-main  ">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-4  bg-white">
              <div class="row align-items-center m-h-100">
                <ReactCSSTransitionReplace
                  transitionName="cross-fade"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={500}
                >
                  {this.switch()}
                </ReactCSSTransitionReplace>
              </div>
            </div>
            <div
              class="col-lg-8 d-none d-md-block bg-cover"
              style={{
                backgroundImage: "url(assets/img/dash-login-bg.jpg)"
              }}
            />
          </div>
        </div>
      </main>
    );
  }
}

export default Login;

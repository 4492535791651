import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import QRCode from "qrcode.react";

@observer
class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = { logout: false };
  }

  copyToClipboard = (e) => {
    this.copyLink.select();
    document.execCommand("copy");
    //alert("Link copied to clipboard");
    window.showToastMessage("Success", "Link copied to clipboard");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    //e.target.focus();
    //this.setState({ copySuccess: 'Copied!' });
  };

  componentDidMount() {
    this.downloadQR();
  }

  downloadQR() {
    const canvas = document.querySelector(".qr > canvas");
    this.downloadRef.href = canvas.toDataURL();
    this.downloadRef.download = AppStore.app_data.short_name + "-qr.png";
  }

  render() {
    let aikLink = `https://bk.homestack.com/${
      AppStore.app_data.short_name
    }?aik=${UserStore.user.agent_data.invite_key}`;

    if (AppStore.app_data.toggles.websiteEnabled) {
      aikLink = `https://${AppStore.app_data.short_name}.hsidx.com/@${
        UserStore.user.agent_data.invite_key
      }`;
    }

    return (
      <Inner>
        <section class="admin-content">
          <div class="bg-dark bg-dots m-b-30">
            <div class="container">
              <div class="row p-b-60 p-t-60">
                <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                  <div class="m-b-10">
                    <div class="avatar avatar-lg ">
                      <div class="avatar-title bg-info rounded-circle fe fe-trending-up " />
                    </div>
                  </div>
                  <h3>Marketing </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="container pull-up">
            <div class="row">
              <div class="col-lg-6">
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Invite link</h5>
                    <p class="m-b-0 text-muted">
                      Users who register after installing from this link will
                      automatically suggest you as an agent.
                    </p>
                  </div>
                  <div class="card-body ">
                    <div class="form-group">
                      <label for="api_key">Your invite link</label>
                      <input
                        style={{ fontFamily: "monospace", fontSize: 16 }}
                        id="api_key"
                        type="text"
                        class="form-control"
                        ref={(input) => (this.copyLink = input)}
                        type="text"
                        value={aikLink}
                      />
                    </div>

                    <div class="text-center">
                      <button
                        onClick={this.copyToClipboard}
                        class="btn btn-info btn-cta"
                      >
                        Copy link
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">QR code</h5>
                  </div>
                  <div class="card-body ">
                    <div class="form-group" className="qr">
                      <QRCode
                        value={`https://bk.homestack.com/${
                          AppStore.app_data.short_name
                        }?aik=${UserStore.user.agent_data.invite_key}`}
                      />
                    </div>

                    <div class="text-center">
                      <a
                        class="btn btn-info btn-cta"
                        style={{ color: "#fff" }}
                        ref={(ref) => (this.downloadRef = ref)}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">App icon</h5>
                  </div>
                  <div class="card-body ">
                    <div class="text-center">
                      <p>
                        <img
                          src={AppStore.app_data.icon}
                          style={{ maxWidth: "100%", minWidth: 120 }}
                        />
                      </p>
                    </div>

                    {/*<div class="text-center">
                      <a
                        href={`https://agent.homestack.com/marketing/icon/${
                          AppStore.app_data.stack_id
                        }/?icon=${AppStore.app_data.icon}`}
                        class="btn btn-info btn-cta"
                      >
                        Download icon
                      </a>
                    </div>*/}
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                {/*<div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Enterprise App Webinar</h5>
                  </div>
                  <div class="card-body ">
                    <a
                      href="https://vimeo.com/366662894/e07be8ccb3"
                      className="text-underline"
                      target="_blank"
                    >
                      Watch the Enterprise App Webinar here.
                    </a>
                  </div>
                </div>*/}

                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">App store badges</h5>
                  </div>
                  <div class="card-body ">
                    <p>
                      <img
                        src="https://agent.homestack.com/assets/img/badges/all-badge.png"
                        class="img-responsive"
                      />
                    </p>

                    <div class="text-center">
                      <a
                        href="https://agent.homestack.com/assets/img/badges/badges.zip"
                        class="btn btn-info btn-cta"
                      >
                        Download badges
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Inner>
    );

    return (
      <Inner>
        <div class="content">
          <div class="row">
            <div class="col-2">
              {UserStore.isAgent() && (
                <div class="content-block">
                  <h2>Invite link</h2>
                  <p>
                    Users who register after installing from this link will
                    automatically suggest you as an agent.
                  </p>
                  <p>
                    <input
                      ref={(input) => (this.copyLink = input)}
                      type="text"
                      value={`https://bk.homestack.com/${
                        AppStore.app_data.short_name
                      }?aik=${UserStore.user.agent_data.invite_key}`}
                    />
                  </p>
                  <p>
                    <button onClick={this.copyToClipboard}>Copy link</button>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Inner>
    );
  }
}

export default Marketing;

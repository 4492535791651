import React, { useEffect, useState } from "react";
import Select from "react-select";
import Tag from "./Tag";
import UserStore from "../../../stores/UserStore";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const options = [
    //{ value: 'orange', label: 'Orange', bgcolor: 'orange', color: '#000'},
    { value: 'blue', label: 'Blue', bgcolor: '#AAD8EC', color: '#1E5270' },
    { value: 'sand', label: 'Sand', bgcolor: "#EECBB1", color: "#894D22" },
    { value: 'red', label: 'Red', bgcolor: "#FFAEAE", color: "#691712" },
    //{ value: 'blue', label: 'Blue', bgcolor: "#2075ea", color: "#fff" },
    { value: 'green', label: 'Green', bgcolor: "#B1DFDC", color: "#1F5A57" },
    { value: 'orange2', label: 'Orange', bgcolor: "#FAD574", color: "#98561A" },
    //{ value: 'lime', label: 'Lime', bgcolor: "#add945", color: "#000" },
    //{ value: 'purple', label: 'Purple', bgcolor: "#dc46ae", color: "#fff" },
    //{ value: 'darkred', label: 'Dark Red', bgcolor: "#ee3b3b", color: "#fff" }
];

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color;
        const bgcolor = data.bgcolor;
        return {
            ...styles,
            backgroundColor: bgcolor,
            color: color,
            fontWeight: "bold",
        };
    },
};

const CreateTag = (props) => {
    const { addTag, edit } = props;
    const [tagName, setTagName] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [superTag, setSuperTag] = useState(false);
    const [initialSuperTag, setInitialSuperTag] = useState(false);

    const checkTag = () => {
        if (!tagName || !tagColor) {
            alert("Tag name and color are required");
            return;
        }

        addTag({
            id: edit ? edit.id : null,
            name: tagName,
            color: tagColor,
            super_tag: superTag
        });
    }

    useEffect(() => {
        if (edit != null) {
            setTagName(edit.tag_long);
            setSuperTag(edit.super_tag == 1 ? true : false)
            setInitialSuperTag(edit.super_tag == 1 ? true : false)

            // Match hexes with color table...
            let found_color = false;
            options.map(opt => {
                if (edit.tag_color == opt.bgcolor && edit.text_color == opt.color) {
                    setTagColor(opt);
                    found_color = true;
                }
            });

            if (!found_color) {
                setTagColor("")
            }
        } else {
            setTagName("");
            setTagColor("");
            setSuperTag(false)
        }
    }, [edit]);

    return (
        <>
            <div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {edit ? (
                        <h3 style={{ marginBottom: 20 }}>Edit {edit.tag_long}</h3>
                    ) : (
                        <h3 style={{ marginBottom: 20 }}>Create tag</h3>
                    )}
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <label for="tag">Tag name</label>
                                <input
                                    type="text"
                                    name="tag"
                                    id="tag"
                                    className="form-control"
                                    placeholder="e.g. Hot Lead"
                                    onChange={e => {
                                        setTagName(e.target.value)
                                    }}
                                    value={tagName}
                                //defaultValue={tagName}
                                />
                            </p>
                        </div>

                        <div class="col-md-6">
                            <p>
                                <label>Tag color</label>
                                <Select
                                    //defaultValue={defColor}
                                    value={tagColor}
                                    options={options}
                                    styles={colourStyles}
                                    //onInputChange={(val) => { this.handleAgentInputChangeDelayed(val) }}
                                    onChange={(option) => {
                                        setTagColor(option)
                                        //this.setState({ selectedAgent: option });
                                    }}
                                    noOptionsMessage={() => { return <p>Start typing to list agents</p> }}
                                    placeholder="Select a color..."
                                />
                            </p>
                        </div>
                    </div>
                    {UserStore.isAdmin() && (
                        <div class="row">
                            <div class="col-md-12">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={superTag}
                                                onChange={() => {
                                                    setSuperTag(!superTag)
                                                }}
                                            />
                                        }
                                        label="Make available to all agents"
                                    />
                                </FormGroup>

                                {initialSuperTag != superTag && superTag == 0 && edit && (
                                    <div className="alert alert-warning">
                                        If you disable access to all agents, any agents that had this tag set will have it removed.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <hr />

                <button className="btn btn-primary" onClick={() => {
                    checkTag();
                }}>
                    {edit ? "Save tag" : "Create tag"}
                </button>

            </div>
        </>
    );
}

export default CreateTag;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import Button from "@material-ui/core/Button";
import api from "../../lib/api";

import { Formik } from "formik";
import Dropzone from "react-dropzone";
import { FiX, FiVideo } from "react-icons/fi";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";

let sid = 900120;

@observer
class CustomLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      success: false,
      saving: false,
      apps: [],
      editing: null,
      mpro: null,
      showNew: false,
      newTitle: "",
      editing: null,
      videoModal: false,

      loading2: true,
      error2: false,
      success2: false,
      saving2: false,
      apps2: [],
      editing2: null,
      showNew2: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getCustomSections(
      (response) => {
        this.setState({ loading: false, apps: response.data });
      },
      (error) => {
        this.setState({ error: true });
      }
    );

    api.getCustomSections(
      (response) => {
        this.setState({ loading2: false, apps2: response.data });
      },
      (error) => {
        this.setState({ error2: true });
      }, 1
    );
  }

  changeTitle(item, val) {
    const apps = this.state.apps;
    apps.map((app) => {
      if (app.id === item.id) {
        app.title = val;
      }
    });
    this.setState({ apps: apps });
  }

  changeTitle2(item, val) {
    const apps = this.state.apps2;
    apps.map((app) => {
      if (app.id === item.id) {
        app.title = val;
      }
    });
    this.setState({ apps2: apps });
  }

  changeContent(elem, sectionId, itemId, val) {
    const apps = this.state.apps;
    apps.map((app) => {
      if (app.id === sectionId) {
        app.contents.map((citem) => {
          if (citem.id === itemId) {
            citem[elem] = val;
          }
        });
      }
    });
    this.setState({ apps: apps });
  }

  changeContent2(elem, sectionId, itemId, val) {
    const apps = this.state.apps2;
    apps.map((app) => {
      if (app.id === sectionId) {
        app.contents.map((citem) => {
          if (citem.id === itemId) {
            citem[elem] = val;
          }
        });
      }
    });
    this.setState({ apps2: apps });
  }

  getHighId() {
    let high = 0;
    const apps = this.state.apps;
    apps.map((app) => {
      if (app.id > high) {
        high = app.id;
      }
    });

    return parseInt(high);
  }

  getHighId2() {
    let high = 0;
    const apps = this.state.apps2;
    apps.map((app) => {
      if (app.id > high) {
        high = app.id;
      }
    });

    return parseInt(high);
  }

  addSection() {
    const apps = this.state.apps;
    let high = this.getHighId() + 1;
    apps.push({ id: high, title: "", contents: [], sort_order: 0 });
    this.setState({ apps: apps });
  }

  addSubSection(idx) {
    const apps = this.state.apps;
    let high = this.getHighId() + 1;

    let app = apps[idx];
    if (app?.parent_id) {
      alert('Cannot add sub-section to a sub-section')
      return;
    }

    let sub_section = { id: high, title: "", contents: [], sort_order: 0, parent_id: apps[idx].id };
    //alert(JSON.stringify(apps[idx]));

    // Insert below the section at idx
    apps.splice(idx + 1, 0, sub_section);

    //    apps.push();

    this.setState({ apps: apps });
  }

  addSection2() {
    const apps = this.state.apps2;
    let high = this.getHighId2() + 1;
    apps.push({ id: high, title: "", contents: [], sort_order: 0 });
    this.setState({ apps2: apps });
  }

  newEntry(id) {
    const apps = this.state.apps;

    apps.map((app) => {
      if (app.id === id) {
        app.contents.push({
          id: sid,
          sort_order: 0,
          title: "",
          text: "",
          url: "",
        });
      }
    });

    sid++;

    this.setState({ apps: apps });
  }

  newEntry2(id) {
    const apps = this.state.apps2;

    apps.map((app) => {
      if (app.id === id) {
        app.contents.push({
          id: sid,
          sort_order: 0,
          title: "",
          text: "",
          url: "",
        });
      }
    });

    sid++;

    this.setState({ apps2: apps });
  }

  deleteEntry(sectionId, itemId) {
    const apps = this.state.apps;

    if (window.confirm("Remove this entry?")) {
      apps.map((app) => {
        if (app.id === sectionId) {
          app.contents.map((item, index) => {
            if (item.id === itemId) {
              app.contents.splice(index, 1);
            }
          });
        }
      });

      this.setState({ apps: apps });
    }
  }

  deleteEntry2(sectionId, itemId) {
    const apps = this.state.apps2;

    if (window.confirm("Remove this entry?")) {
      apps.map((app) => {
        if (app.id === sectionId) {
          app.contents.map((item, index) => {
            if (item.id === itemId) {
              app.contents.splice(index, 1);
            }
          });
        }
      });

      this.setState({ apps2: apps });
    }
  }

  deleteSection(item) {
    const apps = this.state.apps;

    if (
      window.confirm(
        "Remove this section? This will remove all its content too!"
      )
    ) {
      apps.map((app, index) => {
        if (app.id === item.id) {
          apps.splice(index, 1);
        }

        // Remove all associated sub-sections
        apps.map((app2, index2) => {
          if (app2.parent_id === item.id) {
            apps.splice(index2, 1);
          }
        });
      });

      this.setState({ apps: apps });
    }
  }

  deleteSection2(item) {
    const apps = this.state.apps2;

    if (
      window.confirm(
        "Remove this section? This will remove all its content too!"
      )
    ) {
      apps.map((app, index) => {
        if (app.id === item.id) {
          apps.splice(index, 1);
        }
      });

      this.setState({ apps2: apps });
    }
  }

  save() {
    api.saveCustomSections(
      this.state.apps,
      (response) => {
        this.setState({ success: true });
        window.showToastMessage("Success", "App Resources saved");
      },
      (error) => {
        this.setState({ error: true });
      }
    );
  }

  save2() {
    api.saveCustomSections(
      this.state.apps2,
      (response) => {
        this.setState({ success: true });
        window.showToastMessage("Success", "App Resources saved");
      },
      (error) => {
        this.setState({ error: true });
      }, 1
    );
  }

  moveSection(dir, idx) {
    const apps = this.state.apps;

    // Get the current section
    let section = apps[idx];
    let parent_id = section?.parent_id;

    // If it has a parent_id do not let it move above or below the parent
    if (parent_id) {
      let parent = apps.filter(x => x.id == parent_id)[0];
      let parent_idx = apps.indexOf(parent);

      let above_id = apps[idx - 1]?.id;
      let below_id = apps[idx + 1]?.id;
      let above_parent_id = apps[idx - 1]?.parent_id;
      let below_parent_id = apps[idx + 1]?.parent_id;

      // If the entry above the current section does not have an above_id or above_parent_id
      // that matches the current parent_id then do not allow it to move
      if (dir == "up" && above_id && above_parent_id != parent_id) {
        alert('Cannot move above parent section');
        return;
      }

      // If the entry below the current section does not have a below_id or below_parent_id
      // that matches the current parent_id then do not allow it to move
      if (dir == "down" && below_id && below_parent_id != parent_id) {
        alert('Cannot move below parent section');
        return;
      }

      //if (dir == "up" && idx == parent_idx + 1) {
      //  alert('Cannot move above parent section');
      //  return;
      //}

    }

    //
    let appsCopy = [...apps]; // Create a copy of the apps array
    let item = appsCopy[idx];
    let associatedItems = appsCopy.filter(app => app.parent_id === item.id);

    // Remove item and associated items from array
    appsCopy = appsCopy.filter(app => app.id !== item.id && app.parent_id !== item.id);

    if (dir == "up") {
      // If item is a sub-section, ensure it doesn't move above its parent section
      if (item.parent_id) {
        let parentIdx = appsCopy.findIndex(app => app.id === item.parent_id);
        if (idx - 1 <= parentIdx) return;
        // Move item one position up
        appsCopy.splice(idx - 1, 0, item);
      } else {
        // Find the previous item that doesn't have a parent_id
        let prevIdx = idx - 1;
        while (appsCopy[prevIdx] && appsCopy[prevIdx].parent_id) {
          prevIdx--;
        }

        // Insert item and associated items before the found item
        appsCopy.splice(prevIdx, 0, item, ...associatedItems);
      }
    } else {
      // If item is a sub-section, ensure it doesn't move below the next section
      if (item.parent_id) {
        let nextSectionIdx = appsCopy.findIndex((app, i) => i > idx && !app.parent_id);
        // if (idx + 1 >= nextSectionIdx) return;
        // Move item one position down
        appsCopy.splice(idx + 1, 0, item);
      } else {
        let nextIdx = idx;
        let checkIdx = idx + 2;
        if (appsCopy[checkIdx] && appsCopy[checkIdx].parent_id) {
          nextIdx = checkIdx;
        }

        // Insert item and associated items after the found item
        appsCopy.splice(nextIdx + 1, 0, item, ...associatedItems);
      }
    }

    this.setState({ apps: appsCopy });
    //
  }

  moveSection2(dir, idx) {
    const apps = this.state.apps2;

    if (dir == "up") {
      const item = apps[idx];
      apps[idx] = apps[idx - 1];
      apps[idx - 1] = item;
      this.setState({ apps2: apps });
    } else {
      const item = apps[idx];
      apps[idx] = apps[idx + 1];
      apps[idx + 1] = item;
      this.setState({ apps2: apps });
    }
  }

  moveItem(dir, idx) {
    const contents = this.state.editing.contents;

    if (dir == "up") {
      const item = contents[idx];
      contents[idx] = contents[idx - 1];
      contents[idx - 1] = item;
      this.setState({ editing: { ...this.state.editing, contents: contents } });
    } else {
      const item = contents[idx];
      contents[idx] = contents[idx + 1];
      contents[idx + 1] = item;
      this.setState({ editing: { ...this.state.editing, contents: contents } });
    }
  }

  moveItem2(dir, idx) {
    const contents = this.state.editing2.contents;

    if (dir == "up") {
      const item = contents[idx];
      contents[idx] = contents[idx - 1];
      contents[idx - 1] = item;
      this.setState({ editing2: { ...this.state.editing2, contents: contents } });
    } else {
      const item = contents[idx];
      contents[idx] = contents[idx + 1];
      contents[idx + 1] = item;
      this.setState({ editing2: { ...this.state.editing2, contents: contents } });
    }
  }

  agents() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadProfile();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (this.state.loading && 100 == 101) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const item = this.state.agent;

    return (
      <section class="admin-content">
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle fe fe-globe " />
                  </div>
                </div>
                <h3>App Resources &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                  this.setState({ videoModal: true })
                }} class="tag"><FiVideo /> HomeStack Pro Tips</span></h3>
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <Rodal
            width={"90%"}
            customStyles={{ maxWidth: 600 }}
            visible={this.state.videoModal}
            onClose={() => { this.setState({ videoModal: false }) }}
            // width={600}
            height={400}
          >
            <p><iframe src="https://player.vimeo.com/video/894606949" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
          </Rodal>

          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Global Sections</h5>
                  <p>These will appear to everyone using the app</p>
                </div>
                <div class="card-body ">
                  {this.state.apps.length == 0 ? (
                    <p>
                      <em>No custom sections set</em>
                    </p>
                  ) : (
                    <>
                      {this.state.apps.map((item, idx) => {
                        let downDisabled = null;
                        let upDisabled = null;

                        if (idx == 0) {
                          upDisabled = true;
                        }
                        if (idx == this.state.apps.length - 1) {
                          downDisabled = true;
                        }
                        // If parent_id and the one below does not have the same parent_id
                        if (item?.parent_id && this.state.apps[idx + 1]?.parent_id != item.parent_id) {
                          downDisabled = true;
                        }
                        // If parent_id and the one above does not have the same parent_id
                        if (item?.parent_id && this.state.apps[idx - 1]?.parent_id != item.parent_id) {
                          upDisabled = true;
                        }

                        let totalSectionsBelow = this.state.apps.slice(idx + 1).length;
                        let parentSections = this.state.apps.slice(idx + 1).filter(x => x.parent_id).length;
                        if (!item?.parent_id && (totalSectionsBelow == parentSections)) {
                          downDisabled = true;
                        }

                        return (
                          <div class="row" style={{ paddingBottom: 5 }}>
                            <div class="col-sm-6" style={{ paddingLeft: item?.parent_id ? 20 : 0 }}>
                              <input
                                type="text"
                                class="form-control"
                                value={item.title}
                                placeholder="Enter section title..."
                                onChange={(e) =>
                                  this.changeTitle(item, e.target.value)
                                }
                              />
                            </div>
                            <div class="col-sm-6">
                              <button
                                className="btn btn-info"
                                disabled={upDisabled}
                                //style={{ opacity: idx > 0 ? 1 : 0.5 }}
                                onClick={(e) => {
                                  if (idx == 0) return;
                                  this.moveSection('up', idx)
                                }}
                              >
                                ↑
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-info"
                                disabled={downDisabled}
                                onClick={(e) => {
                                  this.moveSection('down', idx)
                                }}
                              >
                                ↓
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-warning"
                                disabled={(item?.parent_id || item?.contents?.length > 0)}
                                onClick={(e) => {
                                  this.addSubSection(idx)
                                }}
                              >
                                +
                              </button>
                              &nbsp;

                              <button
                                className="btn btn-success"
                                disabled={
                                  // If a sub section has the parent_id of this item, disable edit
                                  this.state.apps.filter(x => x.parent_id == item.id).length > 0
                                }
                                onClick={(e) => {
                                  this.setState({ editing: item });
                                }}
                              >
                                Edit &rarr;
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                  this.deleteSection(item);
                                  this.setState({ editing: null });
                                }}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}

                  <hr />
                  <p>
                    <a
                      href="#"
                      className="text-underline"
                      onClick={(e) => {
                        e.preventDefault();
                        this.addSection();
                      }}
                    >
                      Add new section
                    </a>
                  </p>

                  <hr />

                  {this.state.apps.length >= 0 && (
                    <div class="form-group text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          /*api.updateAppMpro(
                      this.props.app.stack_id,
                      this.state.mpro,
                      response => {
                        //
                      },
                      error => {
                        alert("An error occurred");
                      }
                    );*/
                          this.save();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card m-b-30">
                {this.state.editing && (
                  <>
                    <div class="card-header">
                      <h5>
                        {this.state.editing.title ? (
                          this.state.editing.title
                        ) : (
                          <em>Untitled section</em>
                        )}
                      </h5>
                    </div>

                    <div class="card-body">
                      {this.state.editing.contents.map((item, idx) => (
                        <>
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label for="title">Heading</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="title"
                                    placeholder="Enter..."
                                    value={item.title}
                                    onChange={(e) =>
                                      this.changeContent(
                                        "title",
                                        this.state.editing.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="text">Text</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="text"
                                    placeholder="Enter..."
                                    value={item.text}
                                    onChange={(e) =>
                                      this.changeContent(
                                        "text",
                                        this.state.editing.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="form-group col-md-12">
                                  <label for="url">Link URL</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="url"
                                    placeholder="Enter..."
                                    value={item.url}
                                    onChange={(e) =>
                                      this.changeContent(
                                        "url",
                                        this.state.editing.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <p class="text-right">

                            <a
                              href="#"
                              className="hslink"
                              style={{ opacity: idx == 0 ? 0.5 : 1 }}
                              disabled={idx == 0}
                              onClick={(e) => {
                                e.preventDefault();
                                if (idx == 0) return;
                                this.moveItem("up", idx);
                              }}
                            >
                              ↑ Move up
                            </a> &nbsp; &nbsp;

                            <a
                              href="#"
                              className="hslink"
                              style={{ opacity: idx == this.state.editing.contents.length - 1 ? 0.5 : 1 }}
                              disabled={idx == this.state.editing.contents.length - 1}
                              onClick={(e) => {
                                e.preventDefault();
                                if (idx == this.state.editing.contents.length - 1) return;
                                this.moveItem("down", idx);
                              }}
                            >
                              ↓ Move down
                            </a> &nbsp; &nbsp;

                            <a
                              href="#"
                              className="hslink"
                              onClick={(e) => {
                                e.preventDefault();
                                this.deleteEntry(
                                  this.state.editing.id,
                                  item.id
                                );
                              }}
                            >
                              Remove entry
                            </a>
                          </p >
                          <hr />
                        </>
                      ))}

                      <p>
                        <a
                          href="#"
                          className="hslink"
                          onClick={(e) => {
                            e.preventDefault();
                            this.newEntry(this.state.editing.id);
                          }}
                        >
                          Add new entry
                        </a>
                      </p>

                      <hr />

                      {this.state.editing.contents.length > 0 && (
                        <div class="form-group text-right">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                              this.save();
                              this.setState({
                                editing: null,
                              });
                            }}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Agent Sections</h5>
                  <p>These will only appear to agents</p>
                </div>
                <div class="card-body ">
                  {this.state.apps2.length == 0 ? (
                    <p>
                      <em>No custom sections set</em>
                    </p>
                  ) : (
                    <>
                      {this.state.apps2.map((item, idx) => (
                        <div class="row" style={{ paddingBottom: 5 }}>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              value={item.title}
                              placeholder="Enter section title..."
                              onChange={(e) =>
                                this.changeTitle2(item, e.target.value)
                              }
                            />
                          </div>
                          <div class="col-sm-6">
                            <button
                              className="btn btn-info"
                              disabled={idx == 0}
                              //style={{ opacity: idx > 0 ? 1 : 0.5 }}
                              onClick={(e) => {
                                if (idx == 0) return;
                                this.moveSection2('up', idx)
                              }}
                            >
                              ↑
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-info"
                              disabled={idx == this.state.apps2.length - 1}
                              onClick={(e) => {
                                this.moveSection2('down', idx)
                              }}
                            >
                              ↓
                            </button>
                            &nbsp;

                            <button
                              className="btn btn-success"
                              onClick={(e) => {
                                this.setState({ editing2: item });
                              }}
                            >
                              Edit &rarr;
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                this.deleteSection2(item);
                                this.setState({ editing2: null });
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <hr />
                  <p>
                    <a
                      href="#"
                      className="text-underline"
                      onClick={(e) => {
                        e.preventDefault();
                        this.addSection2();
                      }}
                    >
                      Add new section
                    </a>
                  </p>

                  <hr />

                  {this.state.apps2.length >= 0 && (
                    <div class="form-group text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          /*api.updateAppMpro(
                      this.props.app.stack_id,
                      this.state.mpro,
                      response => {
                        //
                      },
                      error => {
                        alert("An error occurred");
                      }
                    );*/
                          this.save2();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card m-b-30">
                {this.state.editing2 && (
                  <>
                    <div class="card-header">
                      <h5>
                        {this.state.editing2.title ? (
                          this.state.editing2.title
                        ) : (
                          <em>Untitled section</em>
                        )}
                      </h5>
                    </div>

                    <div class="card-body">
                      {this.state.editing2.contents.map((item, idx) => (
                        <>
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label for="title">Heading</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="title"
                                    placeholder="Enter..."
                                    value={item.title}
                                    onChange={(e) =>
                                      this.changeContent2(
                                        "title",
                                        this.state.editing2.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="text">Text</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="text"
                                    placeholder="Enter..."
                                    value={item.text}
                                    onChange={(e) =>
                                      this.changeContent2(
                                        "text",
                                        this.state.editing2.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="form-group col-md-12">
                                  <label for="url">Link URL</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="url"
                                    placeholder="Enter..."
                                    value={item.url}
                                    onChange={(e) =>
                                      this.changeContent2(
                                        "url",
                                        this.state.editing2.id,
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <p class="text-right">

                            <a
                              href="#"
                              className="hslink"
                              style={{ opacity: idx == 0 ? 0.5 : 1 }}
                              disabled={idx == 0}
                              onClick={(e) => {
                                e.preventDefault();
                                if (idx == 0) return;
                                this.moveItem2("up", idx);
                              }}
                            >
                              ↑ Move up
                            </a> &nbsp; &nbsp;

                            <a
                              href="#"
                              className="hslink"
                              style={{ opacity: idx == this.state.editing2.contents.length - 1 ? 0.5 : 1 }}
                              disabled={idx == this.state.editing2.contents.length - 1}
                              onClick={(e) => {
                                e.preventDefault();
                                if (idx == this.state.editing2.contents.length - 1) return;
                                this.moveItem2("down", idx);
                              }}
                            >
                              ↓ Move down
                            </a> &nbsp; &nbsp;

                            <a
                              href="#"
                              className="hslink"
                              onClick={(e) => {
                                e.preventDefault();
                                this.deleteEntry2(
                                  this.state.editing2.id,
                                  item.id
                                );
                              }}
                            >
                              Remove entry
                            </a>
                          </p >
                          <hr />
                        </>
                      ))}

                      <p>
                        <a
                          href="#"
                          className="hslink"
                          onClick={(e) => {
                            e.preventDefault();
                            this.newEntry2(this.state.editing2.id);
                          }}
                        >
                          Add new entry
                        </a>
                      </p>

                      <hr />

                      {this.state.editing2.contents.length > 0 && (
                        <div class="form-group text-right">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                              this.save2();
                              this.setState({
                                editing2: null,
                              });
                            }}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

        </div>
      </section >
    );
  }


  render() {
    return (
      <Inner>
        <div class="content">{this.agents()}</div>
      </Inner>
    );
  }
}

const styles = {};

export default CustomLinks;

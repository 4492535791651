import React, { Component } from "react";
//import "../login/style.scss";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Inner from "../template/Inner";

@observer
class NoMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Inner>
        <div
          class="d-flex w-100 justify-content-center align-items-center flex-column"
          style={{ height: "100vh" }}
        >
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h3 class="">Not found</h3>
          <p class="text-muted">
            Sorry! The page you requested does not exist.
          </p>
          <p>
            <Link to="/" class="btn btn-primary">
              Back to dashboard
            </Link>
          </p>
        </div>
      </Inner>
    );
  }
}
/*
<div class="content">
          <div className="centerBox">
            <img src="https://bk.homestack.com/assets/img/not-found.svg" />
            <h2>Not found</h2>
            <p>Sorry! The page you requested does not exist.</p>
            <p>
              <Link to="/">
                <button>Back to dashboard</button>
              </Link>
            </p>
          </div>
        </div>
*/

export default NoMatch;

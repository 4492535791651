import React, { Component } from "react";
import "../../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AppStore from "../../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../../lib/api";

@observer
class DashMostViewed extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getStats(
      { type: "most_viewed" },
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ data: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  showData() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.load();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (!this.state.data) {
      return (
        <div className="loadingBox">
          <Spinner noFadeIn name="three-bounce" color="#016aaa" />
        </div>
      );
    }

    const item = this.state.data;

    return (
      <div>
        <h3>
          Most viewed listings
          <br />
          <small>Over past 7 days</small>
        </h3>

        {item.length > 1 ? (
          <div>
            {item.map(prop => (
              <div>
                <small>
                  {prop.data.FullStreetAddress}, {prop.data.City},{" "}
                  {prop.data.State}, {prop.data.ZipCode} (
                  <strong>{prop.data.frequency} times</strong>)
                </small>
              </div>
            ))}
          </div>
        ) : (
          <p>
            <em>No data</em>
          </p>
        )}
      </div>
    );
  }

  render() {
    return this.showData();
  }
}

const styles = {};

export default DashMostViewed;

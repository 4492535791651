import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import { FiX, FiVideo } from "react-icons/fi";
import helpers from "../../lib/helpers";
import { Tooltip } from "react-tippy";
import Rodal from "rodal";
import MapHelpers from "../../lib/map_helpers";
//import "rodal/lib/rodal.css";
import _debounce from "lodash/debounce";
import Select from "react-select";
import Autosuggest from "react-autosuggest";

const renderSuggestion = (suggestion) => (
    <span>
        <b>{suggestion.text}</b>
    </span>
);

@observer
class Featured extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            key: null,
            error: false,
            pockets: [],
            videoModal: false,
            loading: true,
            suggestions: [],
            newModal: false,
            alertListingKey: null,
            alertListingAddress: null,
            value: "",
            remarksModal: false,
            remarksListingID: null,
            remarks: ""
        };

    }

    componentDidMount() {
        this.loadFeatureds();
    }

    getSuggestionValue(suggestion) {
        this.setState({
            alertListingKey: suggestion.key,
            alertListingAddress: suggestion.text,
        });
        return suggestion.text;
    }

    onChange = (event, { newValue, method }) => {
        this.setState({ value: newValue });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        api.getListingSuggestions(
            value,
            (response) => {
                //
                console.log(response);

                let sug = [];
                const mls = response.mls;
                const address = response.address;

                address.map((item) => {
                    sug.push({
                        key: item.ListingKey,
                        text:
                            item.FullStreetAddress +
                            ", " +
                            item.City +
                            ", " +
                            item.State +
                            ", " +
                            item.ZipCode,
                    });
                });

                this.setState({
                    suggestions: sug,
                    alertListingKey: null,
                    alertListingAddress: null,
                });
            },
            (error) => {
                this.setState({
                    suggestions: [],
                    alertListingKey: null,
                    alertListingAddress: null,
                });
            }
        );
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    loadFeatureds() {
        if (
            !AppStore.app_data.toggles.statusMyListingsEnabled
        ) {
            return null;
        }

        api.getFeatureds(
            response => {
                this.setState({ pockets: response, loading: false });
            },
            error => {
                this.setState({ error: true, loading: false });
            }
        );
    }


    photo(photos) {
        return (
            <div
                style={{
                    backgroundColor: "#eee",
                    width: 40,
                    height: 40,
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img src={photos[0]} width={40} height={40} />
            </div>
        );
    }

    emptyPhoto() {
        return (
            <div
                style={{
                    backgroundColor: "#eee",
                    width: 40,
                    height: 40,
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <i
                    class="fe fe-image"
                    style={{ fontWeight: "normal", fontSize: 18, color: "#aaa" }}
                ></i>
            </div>
        );
    }

    featureds() {
        return this.normalTemplate(
            <>
                <div class="table-responsive">
                    <table class="table table-hover ">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Listing ID</th>
                                <th>Address</th>
                                <th>List price</th>
                                <th>Status</th>
                                <th>Views</th>
                                {UserStore.isAdmin() && (
                                    <th>Agent</th>
                                )}
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.loading && (
                                <tr>
                                    <td colSpan={9} className="text-center text-muted">
                                        Loading
                                    </td>
                                </tr>
                            )}
                            {this.state.pockets.length == 0 && !this.state.loading && (
                                <tr>
                                    <td colSpan={9} className="text-center text-muted">
                                        No featured listings
                                    </td>
                                </tr>
                            )}
                            {this.state.pockets.map(item => (
                                <tr>
                                    <td width={100} style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        {item.photos && item.photos.length > 0 && (
                                            <a href="#" class="text-underline" onClick={(e) => {
                                                e.preventDefault();
                                                AppStore.showListingModal(item.listing_key);
                                            }}>
                                                <img src={item.photos[0]} width="75" height="50" style={{ borderRadius: 6, objectFit: "cover" }} />
                                            </a>
                                        )}
                                    </td>
                                    <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        <a href="#" class="text-underline" onClick={(e) => {
                                            e.preventDefault();
                                            AppStore.showListingModal(item.listing_key);
                                        }}>
                                            {item.listing_id}
                                        </a>
                                    </td>
                                    <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        {item.manual == 1 ? (
                                            <Tooltip title="Listing adding manually">
                                                <span style={{ marginRight: 4 }} className="badge badge-info"><b>Manual</b></span>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Listing imported automatically">
                                                <span style={{ marginRight: 4 }} className="badge badge-warning"><b>Auto</b></span>
                                            </Tooltip>
                                        )}

                                        <a href="#" class="text-underline" onClick={(e) => {
                                            e.preventDefault();
                                            AppStore.showListingModal(item.listing_key);
                                        }}>
                                            {item.full_address}, {item.city}, {item.state}, {item.zip}
                                        </a>
                                    </td>
                                    <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        $
                                        {MapHelpers.shortPrice(
                                            item.price,
                                            true
                                        )}
                                    </td>
                                    <td style={{ textTransform: "capitalize", opacity: item.active == 0 ? 0.5 : 1 }}>
                                        {item.status.toLowerCase() == "active" && (<span className="badge badge-success"><strong>Active</strong></span>)}
                                        {item.status.toLowerCase() == "pending" && (<span className="badge badge-warning"><strong>Pending</strong></span>)}
                                        {item.status.toLowerCase() == "coming soon" && (<span className="badge badge-info"><strong>Coming Soon</strong></span>)}
                                    </td>
                                    <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        {item.meta.views}
                                    </td>
                                    {UserStore.isAdmin() && (
                                        <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                            {item.agents.map(ag => (
                                                <div style={{ marginRight: 10 }}>
                                                    <a href={"/agents/" + ag.id} className="text-underline">{ag.name}</a>
                                                </div>
                                            ))}
                                        </td>
                                    )}
                                    <td style={{ opacity: item.active == 0 ? 0.5 : 1 }}>
                                        <small>
                                            <a href="#" className="text-underline" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    remarksModal: true,
                                                    remarks: item.additional_remarks,
                                                    remarksListingID: item.listing_id,
                                                });
                                            }}>{item.additional_remarks ? "Edit" : "Add"} Additional Remarks</a>
                                        </small>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => {
                                            let set_active = item.active == 0 ? 1 : 0;
                                            api.hideFeatured({ key: item.listing_key, active: set_active }, response => {
                                                if (set_active == 1) {
                                                    window.showToastMessage("Success", "Listing hidden");
                                                } else {
                                                    window.showToastMessage("Success", "Listing unhidden");
                                                }
                                                this.loadFeatureds();
                                            }, error => {
                                                window.showToastMessage("Error", "Error updating", "danger", "fe-x");
                                            });
                                        }}>{item.active == 1 ? "Hide" : "Unhide"}</button>
                                    </td>
                                    <td>
                                        {item.manual == 1 && (
                                            <button className="btn btn-danger" onClick={() => {
                                                if (window.confirm("Are you sure you want to remove this listing?")) {
                                                    api.deleteFeatured({ key: item.listing_key }, response => {
                                                        window.showToastMessage("Success", "Listing removed");
                                                        this.loadFeatureds();
                                                    }, error => {
                                                        window.showToastMessage("Error", "Error removing listing", "danger", "fe-x");
                                                    });
                                                }
                                            }}>Remove</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    normalTemplate(contents) {
        return (
            <>
                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <div class="float-right">

                                    <button
                                        style={{ marginRight: 20 }}
                                        onClick={() => {
                                            this.setState({ newModal: true });
                                        }}
                                        class="btn btn-primary"
                                    >
                                        <i class=" fe fe-add " /> Add New Listing
                                    </button>


                                </div>

                                <h4 class="">
                                    {" "}
                                    <span class="btn btn-white-translucent">
                                        <i class="icon-placeholder fe fe-home " />
                                    </span>{" "}
                                    Featured Listings {AppStore.app_data.strings.filter_name_my != null && " (" + AppStore.app_data.strings.filter_name_my + ")"}
                                    <br />
                                    <small>These are listings from the MLS attached to your agent details.</small>
                                    {/*} &nbsp; &nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                                        this.setState({ videoModal: true })
                                    }} class="tag"><FiVideo /> HomeStack Pro Tips</span>*/}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container pull-up">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card m-b-30">
                                <div class="card-body">{contents}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    emptyTemplate(contents) {
        return (
            <>
                <div class="bg-dark bg-dots m-b-30">
                    <div class="container">
                        <div class="row p-b-60 p-t-60">
                            <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                                <div class="m-b-10">
                                    <div class="avatar avatar-lg ">
                                        <div class="avatar-title bg-info rounded-circle fe fe-home " />
                                    </div>
                                </div>
                                <h3>Exclusive listings </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="pull-up">
                    <div class="container">
                        <div class="row ">
                            <div class="col-lg-8 mx-auto  mt-2">
                                <div class="card py-3 m-b-30">
                                    <div class="card-body">{contents}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    render() {
        if (
            !AppStore.app_data.toggles.statusMyListingsEnabled
        ) {
            return <Redirect to="/" />;
        }

        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search listings by address...",
            value,
            onChange: this.onChange,
            className: "form-control",
        };

        //return <Redirect to="/" />;

        return <Inner>
            <Rodal
                width={"90%"}
                customStyles={{ maxWidth: 600, maxHeight: 300, overflowY: "scroll", zsIndex: 2000 }}
                visible={this.state.remarksModal}
                onClose={() => { this.setState({ remarksModal: false }) }}
                //width={500}
                height={"90%"}
            >
                <h2>Additional Remarks</h2>
                <textarea className="form-control" rows="6" value={this.state.remarks} onChange={e => {
                    this.setState({ remarks: e.target.value });
                }} />
                <hr />
                <button className="btn btn-primary" onClick={() => {
                    api.updateFeatured({
                        listing_id: this.state.remarksListingID,
                        remarks: this.state.remarks
                    }, response => {
                        window.showToastMessage("Success", "Remarks updated");
                        this.setState({ remarksModal: false });
                        this.loadFeatureds();
                    }, error => {
                        window.showToastMessage("Error", "Error updating remarks", "danger", "fe-x");
                    });
                }}>Save</button>
            </Rodal>

            <Rodal
                width={"90%"}
                customStyles={{ maxWidth: 600, maxHeight: 300, overflowY: "scroll", zsIndex: 2000 }}
                visible={this.state.newModal}
                onClose={() => { this.setState({ newModal: false }) }}
                //width={500}
                height={"90%"}
            >
                <h2>Add New Listing</h2>
                <p>
                    <label for="listing">Choose listing:</label>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={(val) =>
                            this.getSuggestionValue(val)
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </p>
                {this.state.alertListingKey != null && (
                    <>
                        <hr />
                        <button class="btn btn-primary" onClick={() => {

                            const key = this.state.alertListingKey;

                            api.addFeatured({ key: key }, response => {
                                window.showToastMessage(
                                    "Success",
                                    "Listing added"
                                );

                                this.setState({ newModal: false })
                                this.loadFeatureds();
                            }, error => {
                                window.showToastMessage(
                                    "Error",
                                    "Error adding listing",
                                    "danger",
                                    "fe-x"
                                );
                            });

                        }}>Add Listing</button>
                    </>
                )}
            </Rodal>

            {this.featureds()}
        </Inner>;
    }
}

export default Featured;

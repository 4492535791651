import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import api from "../../lib/api";

const toggles = [
  { title: "User is online (only available for push)", name: "UserLogin" },
  { title: "New user sign ups", name: "UserNew" },
  { title: "User favorites", name: "UserFav" },
  { title: "User notes", name: "UserNote" },
  { title: "User saved searches", name: "UserSavedSearch" },
];

@observer
class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      logout: false,
      toggles: {
        emailUserNew: false,
        pushUserNew: false,
        emailUserFav: false,
        pushUserFav: false,
        emailUserNote: false,
        pushUserNote: false,
        emailUserSavedSearch: false,
        pushUserSavedSearch: false,
        pushUserLogin: false
      }
    };
  }

  componentDidMount() {
    this.loadPrefs();
  }

  savePrefs() {
    api.savePrefs(
      {
        emailUserNew: this.state.toggles.emailUserNew ? 1 : 0,
        pushUserNew: this.state.toggles.pushUserNew ? 1 : 0,
        emailUserFav: this.state.toggles.emailUserFav ? 1 : 0,
        pushUserFav: this.state.toggles.pushUserFav ? 1 : 0,
        emailUserNote: this.state.toggles.emailUserNote ? 1 : 0,
        pushUserNote: this.state.toggles.pushUserNote ? 1 : 0,
        emailUserSavedSearch: this.state.toggles.emailUserSavedSearch ? 1 : 0,
        pushUserSavedSearch: this.state.toggles.pushUserSavedSearch ? 1 : 0,
        pushUserLogin: this.state.toggles.pushUserLogin ? 1 : 0
      },
      response => {
        alert("Preferences saved");
      },
      error => {
        alert("An error occurred");
      }
    );
  }

  loadPrefs() {
    api.getPrefs(
      response => {
        this.setState({ loading: false });
        if (response) {
          this.setState({
            toggles: {
              emailUserNew: response.emailUserNew == 1 ? true : false,
              pushUserNew: response.pushUserNew == 1 ? true : false,
              emailUserFav: response.emailUserFav == 1 ? true : false,
              pushUserFav: response.pushUserFav == 1 ? true : false,
              emailUserNote: response.emailUserNote == 1 ? true : false,
              pushUserNote: response.pushUserNote == 1 ? true : false,
              emailUserSavedSearch:
                response.emailUserSavedSearch == 1 ? true : false,
              pushUserSavedSearch:
                response.pushUserSavedSearch == 1 ? true : false,
              pushUserLogin: response.pushUserLogin == 1 ? true : false
            }
          });
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <Inner>
          <div class="content">
            <div className="centerBox">
              <img src="https://bk.homestack.com/assets/img/not-found.svg" />
              <h2>Unable to load data</h2>
              <p>
                <button
                  onClick={() => {
                    this.setState({ error: false });
                    this.loadPrefs();
                  }}
                >
                  Try again
                </button>
              </p>
            </div>
          </div>
        </Inner>
      );
    }

    if (this.state.loading) {
      return (
        <Inner>
          <div
            class="d-flex w-100 justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </Inner>
      );
    }

    return (
      <Inner>
        <section class="admin-content">
          <div class="bg-dark bg-dots m-b-30">
            <div class="container">
              <div class="row p-b-60 p-t-60">
                <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                  <div class="m-b-10">
                    <div class="avatar avatar-lg ">
                      <div class="avatar-title bg-info rounded-circle fe fe-zap " />
                    </div>
                  </div>
                  <h3>Preferences </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="container pull-up">
          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Alerts Preferences</h5>
                </div>
                <div class="card-body ">
                  {toggles.map(item => (
                    <div style={styles.alertRow}>
                      <p>{item.title}</p>

                      <div style={styles.toggleRow}>
                        <div>
                          {item.name != "UserLogin" && (
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={
                                      this.state.toggles["email" + item.name]
                                    }
                                    onChange={() => {
                                      const key = "email" + item.name;
                                      const tog = this.state.toggles;
                                      tog["email" + item.name] = !this.state
                                        .toggles["email" + item.name];

                                      this.setState({
                                        toggles: tog
                                      });
                                    }}
                                    value="1"
                                  />
                                }
                                label="Email"
                              />
                            </FormGroup>
                          )}
                        </div>
                        <div>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={
                                    this.state.toggles["push" + item.name]
                                  }
                                  onChange={() => {
                                    const key = "push" + item.name;
                                    const tog = this.state.toggles;
                                    tog["push" + item.name] = !this.state
                                      .toggles["push" + item.name];

                                    this.setState({
                                      toggles: tog
                                    });
                                  }}
                                  value="1"
                                />
                              }
                              label="Push"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  ))}

                  {!AppStore.readOnly && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onClick={() => {
                        this.savePrefs();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Inner>
    );
  }
}

const styles = {
  alertRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  toggleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  }
};

export default Preferences;

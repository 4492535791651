import React, { Component } from "react";
import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import AppStore from "../../stores/AppStore";
import UserStore from "../../stores/UserStore";
import Modal from "@material-ui/core/Modal";
import api from "../../lib/api";
import {
  FiTarget,
  FiSettings,
  FiUsers,
  FiBriefcase,
  FiTrendingUp,
  FiUser,
  FiLogOut,
  FiX,
  FiMenu,
  FiZap,
  FiHelpCircle,
  FiMail,
} from "react-icons/fi";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import ListingModal from "./ListingModal";

let loaded_embed = false;

@observer
class Inner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      goHome: false,
      showMenu: true,
      supportModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    if (!loaded_embed) {
      /*const script = document.createElement('script');
      let url = '/assets/ww.js'
      script.src = url;   //(This is external js url)
      script.async = true;
      document.body.appendChild(script);*/
      loaded_embed = true;
    }
  }

  resize() {
    this.setState({ showMenu: window.innerWidth >= 991 });
  }

  supportEmailLink() {
    if (UserStore.isAdmin()) {
      return (
        <li class="menu-item ">
          <a href="mailto:prosupport@homestack.com" class="menu-link">
            <span class="menu-label">
              <span class="menu-name">Email support</span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder fe fe-mail " />
            </span>
          </a>
        </li>
      );
    } else {
      return (
        <li class="menu-item ">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ supportModal: true });
            }}
            className="menu-link"
          >
            <span class="menu-label">
              <span class="menu-name">Email support</span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder fe fe-mail " />
            </span>
          </a>
        </li>
      );
    }
  }

  handleCloseSupportModal = () => {
    this.setState({ supportModal: false });
  };

  featuredMenu() {
    /*if (AppStore.app_data.stack_id == 2481787 || AppStore.app_data.stack_id == 1) {

    } else {
      return null;
    }*/

    const currentLocation = window.location.pathname
      .replace(/\//, "")
      .replace(/\/.*/, "");

    if (!UserStore.isAdmin() && !UserStore.isAgent()) {
      return null;
    }

    if (AppStore.app_data.template == "windermere") {
      return null;
    }

    if (!AppStore.app_data.toggles.statusMyListingsEnabled) {
      return null;
    }

    let title = "Featured Listings";
    let official_title = AppStore.app_data.strings.filter_name_my;
    if (official_title) {
      //title = official_title;
    }

    return (
      <li
        class={currentLocation == "featured" ? "menu-item active" : "menu-item"}
        style={{
          //opacity: !AppStore.app_data.toggles.pocketListingsEnabled ? 0.7 : 1,
        }}
      >
        <Link to="/featured" class="menu-link">
          <span class="menu-label">
            <span class="menu-name">{title}</span>
          </span>
          <span class="menu-icon">
            <i class="icon-placeholder fe fe-home " />
          </span>
        </Link>
      </li>
    );

    //{AppStore.app_data.toggles.mortgageProEditingEnabled && (UserStore.isAdmin() || AppStore.app_data.mpro_agent == true) && (
  }

  pocketMenu() {
    const currentLocation = window.location.pathname
      .replace(/\//, "")
      .replace(/\/.*/, "");

    if (!AppStore.app_data.toggles.pocketListingsEnabled) {
      return null;
    }

    if (!UserStore.isAgent() && !UserStore.isAdmin()) {
      return null;
    }

    if (
      (UserStore.isAdmin() ||
        AppStore.app_data.toggles.pocketListingsAllowAgents)
      && AppStore.app_data.template != "windermere"
    ) {
      return (
        <li
          class={currentLocation == "pocket" ? "menu-item active" : "menu-item"}
          style={{
            opacity: !AppStore.app_data.toggles.pocketListingsEnabled ? 0.7 : 1,
          }}
        >
          <Link to="/pocket" class="menu-link">
            <span class="menu-label">
              <span class="menu-name">Exclusive listings</span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder fe fe-home " />
            </span>
          </Link>
        </li>
      );
    }

    return null;
  }

  customLinksMenu() {
    const currentLocation = window.location.pathname
      .replace(/\//, "")
      .replace(/\/.*/, "");

    if (!AppStore.app_data.toggles.customLinksEnabled) {
      return null;
    }

    if (UserStore.isManager()) {
      return null;
    }

    if (UserStore.isAdmin() && AppStore.app_data.toggles.customLinksEnabled) {
      return (
        <li
          class={
            currentLocation == "app-resources" ? "menu-item active" : "menu-item"
          }
          style={{
            opacity: !AppStore.app_data.toggles.pocketListingsEnabled ? 0.7 : 1,
          }}
        >
          <Link to="/app-resources" class="menu-link">
            <span class="menu-label">
              <span class="menu-name">App Resources</span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder fe fe-globe " />
            </span>
          </Link>
        </li>
      );
    }

    return null;
  }

  render() {
    if (this.state.logout) {
      const cookies = new Cookies();
      cookies.remove("bk_api_key");
      cookies.remove('bk_api_key', { path: '/' });
      cookies.remove('bk_ro');
      return <Redirect to="/login" />;
    }

    if (this.state.goHome) {
      this.setState({ goHome: false });
      return <Redirect to={"/"} />;
    }

    const user = UserStore.user;
    const currentLocation = window.location.pathname
      .replace(/\//, "")
      .replace(/\/.*/, "");

    return (
      <div className="">
        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          visible={AppStore.listingModal}
          onClose={() => { AppStore.hideListingModal() }}
          //width={600}
          height={400}
          //customStyles={{ zIndex: 3900 }}
          customMaskStyles={{ zIndex: 3900 }}
        >
          <ListingModal listing={AppStore.listingData} onClose={() => {
            AppStore.hideListingModal()
          }} />
        </Rodal>

        <aside class="admin-sidebar">
          <div class="admin-sidebar-brand">
            <img
              src={AppStore.app_data.icon}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                marginRight: 15,
              }}
            />

            <div class="ml-auto">
              <a
                href="#"
                class="admin-pin-sidebar btn-ghost btn btn-rounded-circle"
              />

              <a href="#" class="admin-close-sidebar" />
            </div>
          </div>
          <div class="admin-sidebar-wrapper js-scrollbar">
            <ul class="menu">
              <li
                class={currentLocation == "" ? "menu-item active" : "menu-item"}
              >
                <Link to="/" class="menu-link">
                  <span class="menu-label">
                    <span class="menu-name">Dashboard</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-activity " />
                  </span>
                </Link>
              </li>

              {(UserStore.isAdmin() || UserStore.isManager()) && (
                <li
                  class={
                    currentLocation == "agents"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/agents" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Agents</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-briefcase " />
                    </span>
                  </Link>
                </li>
              )}

              <li
                class={
                  currentLocation == "users" ? "menu-item active" : "menu-item"
                }
              >
                <Link to="/users" class="menu-link">
                  <span class="menu-label">
                    <span class="menu-name">Users</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-users " />
                  </span>
                </Link>
              </li>

              {AppStore.app_data.toggles.chatEnabled && (UserStore.isAgent() || UserStore.isAdmin()) &&
                (AppStore.app_data.template != "windermere" || (AppStore.app_data.template == "windermere" && !UserStore.isAdmin())) &&
                (
                  <li
                    class={
                      currentLocation == "chat" ? "menu-item active" : "menu-item"
                    }
                  >
                    <Link to="/chat" class="menu-link">
                      <span class="menu-label">
                        <span class="menu-name">Chat</span>
                      </span>
                      <span class="menu-icon">
                        <i class="icon-placeholder fe fe-message-circle " />
                      </span>
                    </Link>
                  </li>
                )}

              {AppStore.app_data.toggles.pushNotificationsEnabled &&
                AppStore.app_data.toggles.manualPushEnabled &&
                (UserStore.isAdmin() ||
                  (AppStore.app_data.toggles.manualPushEnabledAll && UserStore.isAgent())) && (
                  <li
                    class={
                      currentLocation == "alerts"
                        ? "menu-item active"
                        : "menu-item"
                    }
                  >
                    <Link to="/alerts" class="menu-link">
                      <span class="menu-label">
                        <span class="menu-name">Push notifications</span>
                      </span>
                      <span class="menu-icon">
                        <i class="icon-placeholder fe fe-bell " />
                      </span>
                    </Link>
                  </li>
                )}


              {(UserStore.isAgent() || UserStore.isAdmin()) && AppStore.app_data.toggles.agentSavedSearchEnabled && (
                <li
                  class={
                    currentLocation == "searchlinks"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/searchlinks" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Search Templates</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-search " />
                    </span>
                  </Link>
                </li>
              )}


              {this.featuredMenu()}
              {this.pocketMenu()}
              {this.customLinksMenu()}

              {(UserStore.isAdmin() || UserStore.isAgent()) && (
                <li
                  class={
                    currentLocation == "marketing"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/marketing" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Marketing</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-trending-up " />
                    </span>
                  </Link>
                </li>
              )}

              {UserStore.isAgent() && (
                <li
                  class={
                    currentLocation == "preferences"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/preferences" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Preferences</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-zap " />
                    </span>
                  </Link>
                </li>
              )}

              {/*UserStore.isAgent() && AppStore.app_data.template != "windermere" && (*/}
              {UserStore.isAgent() && (
                <li
                  class={
                    currentLocation == "profile"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/profile" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Profile</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-user " />
                    </span>
                  </Link>
                </li>
              )}


              {(UserStore.isAdmin() || UserStore.isAgent()) && (
                <li
                  class={
                    currentLocation == "tags"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/tags" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Tags</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-tag " />
                    </span>
                  </Link>
                </li>
              )}

              {AppStore.app_data.toggles.mortgageProEditingEnabled && (UserStore.isAdmin() || (AppStore.app_data.mpro_agent == true && UserStore.isAgent())) && (
                <li
                  class={
                    currentLocation == "mortgagepro"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/mortgagepro" class="menu-link">
                    <span class="menu-label">
                      <span class="menu-name">Mortgage Pro</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-briefcase " />
                    </span>
                  </Link>
                </li>
              )}


              {UserStore.isAdmin() ? (
                <>
                  <li
                    class={
                      currentLocation == "settings"
                        ? "menu-item active"
                        : "menu-item"
                    }
                  >
                    <Link to="/settings" class="menu-link">
                      <span class="menu-label">
                        <span class="menu-name">Settings</span>
                      </span>
                      <span class="menu-icon">
                        <i class="icon-placeholder fe fe-settings " />
                      </span>
                    </Link>
                  </li>

                  <li
                    class={
                      currentLocation == "integrations"
                        ? "menu-item active"
                        : "menu-item"
                    }
                  >
                    <Link to="/integrations" class="menu-link">
                      <span class="menu-label">
                        <span class="menu-name">Integrations</span>
                      </span>
                      <span class="menu-icon">
                        <i class="icon-placeholder fe fe-repeat " />
                      </span>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  {UserStore.isAgent() && (
                    <li
                      class={
                        currentLocation == "settings"
                          ? "menu-item active"
                          : "menu-item"
                      }
                    >
                      <Link to="/user_settings" class="menu-link">
                        <span class="menu-label">
                          <span class="menu-name">Settings &amp; Integrations</span>
                        </span>
                        <span class="menu-icon">
                          <i class="icon-placeholder fe fe-settings " />
                        </span>
                      </Link>
                    </li>
                  )}
                </>
              )}

              {UserStore.isPayments() && !AppStore.readOnly && (
                <li
                  class={
                    currentLocation == "billing"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <a
                    href={UserStore.user.payments_link}
                    target="_blank"
                    class="menu-link"
                  >
                    <span class="menu-label">
                      <span class="menu-name">Billing</span>
                    </span>
                    <span class="menu-icon">
                      <i class="icon-placeholder fe fe-dollar-sign " />
                    </span>
                  </a>
                </li>
              )}

              {AppStore.app_data.toggles.selfProvisionedAgentsEnabled && UserStore.user.agent_payments_link && !AppStore.readOnly &&
                UserStore?.user?.agent_data?.subscription_status != "canceled" &&
                (
                  <li
                    class={
                      currentLocation == "billing"
                        ? "menu-item active"
                        : "menu-item"
                    }
                  >
                    <a
                      href={UserStore.user.agent_payments_link}
                      target="_blank"
                      class="menu-link"
                    >
                      <span class="menu-label">
                        <span class="menu-name">Billing</span>
                      </span>
                      <span class="menu-icon">
                        <i class="icon-placeholder fe fe-dollar-sign " />
                      </span>
                    </a>
                  </li>
                )}

              <hr />

              <li
                class={
                  currentLocation == "training"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <a href="https://www.homestack.com/resources" target="_blank" class="menu-link">
                  <span class="menu-label">
                    <span class="menu-name">Training</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-thumbs-up " />
                  </span>
                </a>
              </li>

              <li
                class={
                  currentLocation == "webinar"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <a href="https://homestack.ewebinar.com/webinar/app-kickstart-training-15871" target="_blank" class="menu-link">
                  <span class="menu-label">
                    <span class="menu-name">App Kickstart Webinar</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-award " />
                  </span>
                </a>
              </li>

              <hr />

              <li class="menu-item ">
                <a
                  href="https://homestack.zendesk.com/hc/en-us"
                  class="menu-link"
                  target="_blank"
                >
                  <span class="menu-label">
                    <span class="menu-name">Help Center</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-help-circle " />
                  </span>
                </a>
              </li>

              {this.supportEmailLink()}

              <li class="menu-item ">
                <a
                  href="/logout"
                  onClick={() => {
                    this.setState({ logout: true });
                  }}
                  class="menu-link"
                >
                  <span class="menu-label">
                    <span class="menu-name">Log out</span>
                  </span>
                  <span class="menu-icon">
                    <i class="icon-placeholder fe fe-log-out " />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </aside>

        <main class="admin-main">
          <Modal
            open={this.state.supportModal}
            onClose={this.handleCloseSupportModal}
          >
            <div style={styles.modal}>
              <div style={styles.modalTitle}>
                <h2>Support contacts</h2>
                <div
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    this.setState({ supportModal: false });
                  }}
                >
                  <FiX />
                </div>
              </div>

              <hr />

              {AppStore.app_data.super_admins.map((item) => (
                <p>
                  <a href={"mailto:" + item.email} class="text-underline">
                    <strong>{item.name}</strong> - {item.email}
                  </a>
                </p>
              ))}
            </div>
          </Modal>

          {!AppStore.isEmbedded ? (
            <header class="admin-header" style={{ borderTop: AppStore.readOnly ? "3px solid orange" : null }}>
              <a
                href="#"
                class="sidebar-toggle"
                data-toggleclass="sidebar-open"
                data-target="body"
              >
                {" "}
              </a>

              <nav class=" mr-auto my-auto">
                <ul class="nav align-items-center">
                  <li class="nav-item">
                    <strong style={{ paddingLeft: 20 }}>
                      {AppStore.app_data.name} Dashboard {AppStore.readOnly ? <span class="badge badge-warning" style={{ color: "#000", fontWeight: "bold" }}>Read Only</span> : null}
                    </strong>
                  </li>
                </ul>
              </nav>

              {/*<nav class=" mr-auto my-auto">
              <ul class="nav align-items-center">
                <li class="nav-item">
                  <a
                    class="nav-link  "
                    data-target="#siteSearchModal"
                    data-toggle="modal"
                    href="#"
                  >
                    <i class=" mdi mdi-magnify mdi-24px align-middle" />
                  </a>
                </li>
              </ul>
            </nav>*/}
              <nav class=" ml-auto">
                <ul class="nav align-items-center">
                  {/*<li class="nav-item">
                  <div class="dropdown">
                    <a
                      href="#"
                      class="nav-link"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      <i class="mdi mdi-24px mdi-bell-outline" />{" "}
                    </a>

                    <div class="dropdown-menu notification-container dropdown-menu-right">
                      <h6 class="dropdown-header text-center">
                        <span class="mdi mdi-bell-ring" /> You have 4 New
                        Messages
                      </h6>

                      <div class="notification-events">
                        <div class="list-group list-group-flush">
                          <a
                            href="#"
                            class="list-group-item list-group-item-action  text-info text-truncate"
                          >
                            <i class="mdi mdi-alert text-info" />5 new members
                            joined today
                          </a>
                        </div>
                      </div>
                      <a class="dropdown-item text-center" href="#">
                        View All
                      </a>
                    </div>
                  </div>
                </li>*/}
                  <li class="nav-item dropdown ">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id=""
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div class="avatar avatar-sm avatar-online">
                        {UserStore.user.photo ? (
                          <img
                            src={UserStore.user.photo}
                            class="rounded-circle"
                          />
                        ) : (
                          <span class="avatar-title rounded-circle ">
                            {UserStore.user.display_name
                              .split(" ")
                              .map((n, i, a) =>
                                i === 0 || i + 1 === a.length ? n[0] : null
                              )
                              .join("")}
                          </span>
                        )}
                        {/*
                      <span class="avatar-title rounded-circle bg-accent">
                        V
                      </span>*/}
                      </div>
                    </a>
                    <div
                      class="dropdown-menu  dropdown-menu-right"
                      aria-labelledby=""
                    >
                      <a
                        class="dropdown-item"
                        href="/logout"
                        onClick={() => {
                          this.setState({ logout: true });
                        }}
                      >
                        {" "}
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
            </header>
          ) : (
            <div style={{ marginTop: -65, backgroundColor: "salmon" }}></div>
          )}

          <section class="admin-content">
            <div class="ocontainer">
              {this.props.children}
              {/*<div class="jumbotron">
                <div class="row">
                  <div class="col-lg-6 col-xlg-4">
                    <h3 class="">Hi John, Welcome Back</h3>
                    <p class="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </p>
                  </div>
                </div>

                <h1>Content page</h1>
                <p>Content</p>
              </div>*/}
            </div>
          </section>
        </main>

        <div
          class="modal slide-left  fade"
          id="siteSearchModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="siteSearchModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-all-0" id="site-search">
                <button
                  type="button"
                  class="close light"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="form-dark bg-accent text-white p-t-60 p-b-20 bg-dots">
                  <h3 class="text-uppercase    text-center  fw-300 ">
                    {" "}
                    Search
                  </h3>

                  <div class="container-fluid">
                    <div class="col-md-10 p-t-10 m-auto">
                      <input
                        type="search"
                        placeholder="Search Something"
                        class=" search form-control form-control-lg"
                      />
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="bg-accent text-muted container-fluid p-b-10 text-center text-overline">
                    results
                  </div>
                  <ul class="list-group list  ">
                    <div class="list-group-item d-flex  align-items-center">
                      <div class="m-r-20">
                        <div class="avatar avatar-sm ">
                          <img
                            class="avatar-img rounded-circle"
                            src="assets/img/users/user-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="">
                        <div class="name">Eric Chen</div>
                        <div class="text-muted">Developer</div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div class="page dashboard">
        {this.state.showMenu && (
          <div class="menu">
            <div class="menu-content">
              {window.innerWidth <= 991 && (
                <div
                  id="menu-close"
                  style={{
                    cursor: "pointer",
                    padding: 20,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    this.setState({ showMenu: false });
                  }}
                >
                  <FiX style={{ marginRight: 10 }} /> Close Menu
                </div>
              )}

              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flex: 1 }}>
                  <div
                    onClick={() => {
                      this.setState({ goHome: true });
                    }}
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 20,
                    }}
                  >
                    <img
                      src={AppStore.app_data.icon}
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        marginRight: 15,
                      }}
                    />
                    {AppStore.app_data.name}
                  </div>

                  <div style={styles.menuItem} className="menuItem">
                    <FiTarget style={styles.menuIcon} />
                    <Link to="/" style={styles.menuLink}>
                      Dashboard
                    </Link>
                  </div>

                  {UserStore.isAdmin() && (
                    <div style={styles.menuItem} className="menuItem">
                      <FiBriefcase style={styles.menuIcon} />
                      <Link to="/agents" style={styles.menuLink}>
                        Agents
                      </Link>
                    </div>
                  )}

                  <div style={styles.menuItem} className="menuItem">
                    <FiUsers style={styles.menuIcon} />
                    <Link to="/users" style={styles.menuLink}>
                      Users
                    </Link>
                  </div>

                  <div style={styles.menuItem} className="menuItem">
                    <FiTrendingUp style={styles.menuIcon} />
                    <Link to="/marketing" style={styles.menuLink}>
                      Marketing
                    </Link>
                  </div>

                  {UserStore.isAgent() && (
                    <div style={styles.menuItem} className="menuItem">
                      <FiZap style={styles.menuIcon} />
                      <Link to="/preferences" style={styles.menuLink}>
                        Preferences
                      </Link>
                    </div>
                  )}

                  <div style={styles.menuItem} className="menuItem">
                    <FiUser style={styles.menuIcon} />
                    <Link to="/profile" style={styles.menuLink}>
                      Profile
                    </Link>
                  </div>

                  {UserStore.isAdmin() && (
                    <div style={styles.menuItem} className="menuItem">
                      <FiSettings style={styles.menuIcon} />
                      <Link to="/settings" style={styles.menuLink}>
                        App settings
                      </Link>
                    </div>
                  )}

                  <div style={styles.menuItemLogout} className="menuItem">
                    <FiHelpCircle style={styles.menuIcon} />
                    <a
                      href="https://homestack.zendesk.com/hc/en-us"
                      style={styles.menuLink}
                      target="_blank"
                    >
                      Support
                    </a>
                  </div>

                  <div style={styles.menuItem} className="menuItem">
                    <FiMail style={styles.menuIcon} />
                    <a
                      href="mailto:support@homestack.com"
                      style={styles.menuLink}
                    >
                      Email support
                    </a>
                  </div>

                  <div style={styles.menuItemLogout} className="menuItem">
                    <FiLogOut style={styles.menuIcon} />
                    <a
                      href="/logout"
                      style={styles.menuLink}
                      onClick={() => {
                        this.setState({ logout: true });
                      }}
                    >
                      Log out
                    </a>
                  </div>
                </div>

                <div style={{ padding: 20 }}>
                  <img
                    src="https://bk.homestack.com/assets/img/hslogo@2x.png"
                    style={{ height: 30 }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div class="main-content">
          <div class="header">
            <div class="header-nav-left">
              <div
                class="menu-button"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({ showMenu: !this.state.showMenu });
                }}
              >
                <FiMenu />
              </div>
              {AppStore.app_data.name} Dashboard
            </div>

            <div class="header-nav-right">
              <div class="header-photo">
                <Link to="/profile">
                  {UserStore.user.photo ? (
                    <img src={UserStore.user.photo} />
                  ) : (
                    <FiUser />
                  )}
                </Link>
              </div>
            </div>
          </div>

          {this.props.children}

          <div class="footer">
            <img src="https://homestack.com/assets2/images/logo@2x.png" />
            &copy; 2019 HomeStack
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  menuItemLogout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //marginTop: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid rgba(255,255,255,0.1)",
  },
  menuIcon: {
    marginRight: 10,
    opacity: 0.5,
  },
  menuLink: {
    color: "#fff",
    textDecoration: "none",
    display: "block",
    flex: 1,
  },
  modal: {
    backgroundColor: "#fff",
    padding: 20,
    maxWidth: 500,
    width: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
};

export default Inner;

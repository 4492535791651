import { observable, action } from "mobx";
import api from "../lib/api";

class AppStore {
  @observable
  stack_id = null;

  @observable
  api_key = null;

  @observable
  modals = {
    alerts: { visible: false, data: null },
    schedule: { visible: false, data: null },
    filters: { visible: false, data: null },
    share: { visible: false, data: null },
    save: { visible: false, data: null },
    notes: { visible: false, data: null },
    gallery: { visible: false, data: null },
    calc: { visible: false, data: null, callback: null }
  };

  @observable
  app_data = {};

  @observable
  agentCardsChanged = false;

  @observable
  newOrder = [];

  @observable
  readOnly = false;

  @observable
  refreshMap = false;

  @observable
  refreshData = null;

  @observable
  listingModal = false;

  @observable
  listingData = null;

  @observable
  isEmbedded = false;

  @observable
  embedUser = null;

  @action
  showListingModal(listing_key) {
    this.listingData = null;

    api.listing(listing_key, response => {
      this.listingModal = true;
      this.listingData = response;
    }, error => {
      alert("There was an error loading the listing. It may have been removed since.")
    });
  }

  @action
  setEmbedded() {
    this.isEmbedded = true;
  }

  @action
  setEmbedUser(user) {
    this.embedUser = user;
  }

  @action
  hideListingModal() {
    this.listingModal = false;
  }

  @action
  setReadOnly() {
    this.readOnly = true;
  }

  @action
  setStack(stack_id) {
    this.stack_id = stack_id;
  }

  @action
  setAPIKey(key) {
    this.api_key = key;
  }

  @action logo() {
    if (this.app_data.theme) {
      return this.app_data.theme.logo;
    }
    return null;
  }

  @action setAppData(data) {
    this.app_data = data;
  }

  @action setRefreshData(val) {
    this.refreshData = val;
    this.refreshMap = true;
  }

  @action clearRefreshData(val) {
    this.refreshData = null;
  }

  @action
  showModal(menu) {
    this.hideAllMenu();
    this.modals[menu].visible = true;
  }

  @action
  hideModal(menu) {
    this.modals[menu].visible = false;
    this.modals[menu].data = null;
  }

  @action
  setModalData(menu, data) {
    this.modals[menu].data = data;
  }

  @action
  setModalCallback(menu, data) {
    this.modals[menu].callback = data;
  }

  @action getAppData(stack_id, _callback) {
    api.getApp(
      stack_id,
      response => {
        this.app_data = response;
        _callback(true);
      },
      error => {
        _callback(false);
        // Do something drastic here!
      }
    );
  }
}

export default new AppStore();

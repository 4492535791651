import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import moment from "moment";

const allowed_items = ["view", "open", "share", "fav", "note"];

@observer
class UserHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, prevSubtype: null };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    if (this.props.subtype != this.state.prevSubtype) {
      this.setState({ prevSubtype: this.props.subtype });
      this.load();
    }
  }

  load() {
    api.getStats(
      {
        type: "history",
        subtype: this.props.subtype,
        user_id: this.props.userId,
      },
      (response) => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ data: response, error: false });
          }
        }
      },
      (error) => {
        this.setState({ error: true });
      }
    );
  }

  eventType(type) {
    if (type == "view") {
      return <span class="badge badge-success">Viewed</span>;
    }
    if (type == "open") {
      return <span class="badge badge-info">Open</span>;
    }
    if (type == "share") {
      return <span class="badge badge-secondary">Shared</span>;
    }
    if (type == "fav") {
      return <span class="badge badge-warning">Favorited</span>;
    }
    if (type == "note") {
      return <span class="badge badge-soft-warning">Note</span>;
    }
  }

  showData() {
    if (this.state.error) {
      if (!AppStore.app_data.toggles.agentInsightsEnabled) {
        return (
          <div class="card m-b-30">
            <div class="card-body ">
              <div
                class="alert alert-border-warning  alert-dismissible fade show"
                role="alert"
              >
                <div class="d-flex">
                  <div class="icon">
                    <i class="icon mdi mdi-alert-circle-outline" />
                  </div>
                  <div class="content">
                    <strong>Upgrade required</strong>
                    <br />
                    Please contact HomeStack support to enable all dashboard
                    features.
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div class="card m-b-30">
          <div class="card-body ">
            <p>No data</p>
          </div>
        </div>
      );
    }

    if (!this.state.data) {
      return (
        <div class="card m-b-30">
          <div class="card-body" style={{ alignSelf: "center" }}>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const item = this.state.data;
    const subtype = this.props.subtype;

    if (!AppStore.app_data.toggles.agentInsightsEnabled) {
      return (
        <div class="card m-b-30">
          <div class="card-body ">
            <div
              class="alert alert-border-warning  alert-dismissible fade show"
              role="alert"
            >
              <div class="d-flex">
                <div class="icon">
                  <i class="icon mdi mdi-alert-circle-outline" />
                </div>
                <div class="content">
                  <strong>Upgrade required</strong>
                  <br />
                  Please contact HomeStack support to enable all dashboard
                  features.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div class="row">
        <div class="col-md-12 my-auto ">
          {item.length == 0 && <p class="text-muted">No results</p>}

          {Object.entries(item).length !== 0 && item.constructor === Object && (
            <div class="timeline">
              {Object.keys(item)
                .reverse()
                .map((keyName, i) => (
                  <div>
                    {allowed_items.includes(item[keyName].type) && (
                      <div class="timeline-item" style={{ cursor: "pointer" }} onClick={() => {
                        AppStore.showListingModal(item[keyName].listing_key);
                      }}>
                        <div class="timeline-wrapper">
                          <div class="">
                            <div class="avatar avatar-sm">
                              <img
                                class="avatar-img rounded"
                                src={item[keyName].photo}
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class=" d-md-none text-muted">
                              {moment.unix(item[keyName].event_time).fromNow()}
                              {" "}
                              ({moment
                                .unix(item[keyName].event_time)
                                .format("MMM D YYYY")})
                            </div>
                            <p>
                              <strong>{item[keyName].user_name}</strong>
                              <br />

                              {this.eventType(item[keyName].type)}{" "}
                              {item[keyName].address}
                              <br />
                              <pre style={{ backgroundColor: "#eee" }}>
                                {item[keyName].note}
                              </pre>
                            </p>
                          </div>
                          <div class="d-none d-md-block ml-auto col-auto text-muted">
                            {moment.unix(item[keyName].event_time).fromNow()}
                            <br />
                            {moment
                              .unix(item[keyName].event_time)
                              .format("MMM D YYYY")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return this.showData();
  }
}

export default UserHistory;

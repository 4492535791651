import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import Help from "../help/Help";
import Select from "react-select";

const crmFields = [
  { name: null, fields: [], parsing: false },
  { name: "fub", fields: ['api_key'], parsing: false },
  { name: "propertybase", fields: ['api_key', 'api_url'], parsing: false },

  // Below are for screenshotting, not live atm! Remove being goign live!!
  { name: "boomtown", fields: ['api_key'], parsing: false },
  { name: "kvcore", fields: ['api_key'], parsing: false },
  { name: "chime", fields: ['api_key'], parsing: false },
  { name: "sierra", fields: ['api_key'], parsing: false },
  { name: "deltanet", fields: ['api_key'], parsing: false },
  { name: "moxiworks", fields: [], parsing: false },
  { name: "cinc", fields: ['api_key'], parsing: false },
  { name: "request", fields: [], parsing: false },
];

/*
Boomtown!, kvCORE, Chime, Sierra Interactive, DeltaNet CRM, MoxiWorks, CINC
Request an integration...
*/

@observer
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      key: null,
      error: false,
      brokerage_name: "",
      dmca_link: "",
      integration: null,
      my_type: "app"
    };
  }

  allowedField = (name, field) => {
    let allowed = false;

    crmFields.map(item => {
      if (item.name == name) {
        const f = item.fields;
        if (f.includes(field)) {
          allowed = true;
        }
      }
    });

    return allowed;
  }

  crmOptions = () => {
    return [
      { value: null, label: "None" },
      { value: "boomtown", label: "Boomtown!" },
      { value: "chime", label: "Chime" },
      { value: "cinc", label: "CINC" },
      { value: "deltanet", label: "DeltaNet CRM" },
      { value: "fub", label: "Follow Up Boss" },
      { value: "kvore", label: "kvCORE" },
      { value: "moxiworks", label: "MoxiWorks" },
      { value: "propertybase", label: "Propertybase" },
      { value: "sierra", label: "Sierra Interactive" },
      { value: "request", label: "Request an integration..." }
    ];
  };

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({
      brokerage_name: AppStore.app_data.brokerage_name,
      dmca_link: AppStore.app_data.links.dmca,
      my_type: AppStore.app_data.settings.my_type
    });

    api.getAPIKey(
      response => {
        this.setState({ key: response.key });
      },
      error => {
        this.setState({ error: true });
      }
    );

    if (AppStore.app_data.toggles.integrationsEnabled) {
      api.getIntegrationInfo(
        response => {
          this.setState({ integration: response.info })
        },
        error => {
          this.setState({ error: true });
        }
      );
    }
  }

  copyToClipboard = e => {
    this.copyLink.select();
    document.execCommand("copy");
    window.showToastMessage("Success", "URL copied to clipboard");
    //alert("Copied to clipboard");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    //e.target.focus();
    //this.setState({ copySuccess: 'Copied!' });
  };

  updateIntegration() {
    // TODO: Check the required fields to make sure they are present
    let failed = false;
    let int = this.state.integration;
    let name = this.state.integration?.name;
    let required = [];
    crmFields.map(item => {
      if (item.name == name) {
        required = item.fields;
      }
    });

    //alert(JSON.stringify(required))

    let pre_int = {
      name: null,
      api_url: null,
      api_key: null,
      username: null,
      password: null
    };

    if (!name || required.length == 0) {
      int = pre_int;
    } else {
      pre_int.name = int.name;
      required.map(item => {
        if (!int[item]) {
          failed = true;
        } else {
          pre_int[item] = int[item];
        }
      });
      int = pre_int;
    }

    if (failed) {
      alert("All fields are required");
      return;
    }

    api.updateIntegration(
      int,
      response => {
        window.showToastMessage("Success", "Integration updated");
      },
      error => {
        alert("An error occured");
      }
    );
  }

  updateBrokerageName() {
    api.updateApp(
      {
        brokerage_name: this.state.brokerage_name
      },
      response => {
        AppStore.app_data.brokerage_name = this.state.brokerage_name;
        window.showToastMessage("Success", "Name updated");
      },
      error => {
        alert("An error occured");
      }
    );
  }

  updateMyListings() {
    api.updateApp(
      {
        my_type: this.state.my_type
      },
      response => {
        //AppStore.app_data.brokerage_name = this.state.brokerage_name;
        window.showToastMessage("Success", "Featured Listings updated");
      },
      error => {
        alert("An error occured");
      }
    );
  }

  updateDMCA() {
    api.updateApp(
      {
        dmca_link: this.state.dmca_link
      },
      response => {
        AppStore.app_data.links.dmca = this.state.dmca_link;
        window.showToastMessage("Success", "DMCA link updated");
      },
      error => {
        alert("An error occured");
      }
    );
  }

  render() {
    if (!UserStore.isAdmin()) {
      return <Redirect to="/" />;
    }

    if (this.state.error) {
      return (
        <Inner>
          <div class="content">
            <div className="centerBox">
              <img src="https://bk.homestack.com/assets/img/not-found.svg" />
              <h2>Unable to load data</h2>
              <p>
                <button
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </button>
              </p>
            </div>
          </div>
        </Inner>
      );
    }

    if (!this.state.key) {
      return (
        <Inner>
          <div
            class="d-flex w-100 justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </Inner>
      );
    }

    return (
      <Inner>
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            {/*<Help section="settings" />*/}
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle mdi mdi-settings " />
                  </div>
                </div>
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <div class="row ">
              <div class="col-lg-6">
                <div class="card py-3 m-b-30">
                  <div class="card-body">
                    <h3 class="">Brokerage / company name</h3>

                    <p>
                      If you enter something here it will show underneath each
                      agents name in the app (only for build versions 500+).
                    </p>

                    <div class="form-group">
                      <label for="api_key">Brokerage / company name</label>
                      <input
                        style={{ fontSize: 16 }}
                        id="api_key"
                        type="text"
                        class="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            brokerage_name: e.target.value
                          });
                        }}
                        value={this.state.brokerage_name}
                      />
                    </div>

                    <div class="text-right">
                      {!AppStore.readOnly && (
                        <button
                          onClick={() => {
                            this.updateBrokerageName();
                          }}
                          class="btn btn-primary btn-cta"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {UserStore.isAdmin() && AppStore.app_data.toggles.statusMyListingsEnabled && (
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h3 class="">Featured Listings</h3>

                      <p>Choose how you want associated agent listings to be displayed in app.</p>

                      <div class="card-body">
                        <div class="">
                          <div class="option-box">
                            <input id="radio-new1" name="bigradios" type="radio" value="app" checked={this.state.my_type == "app"} onChange={() => {
                              this.setState({ my_type: "app" })
                            }} />
                            <label for="radio-new1">
                              <span class="radio-content">
                                <span class="h6 d-block">App-wide</span>
                                <span class="text-muted d-block m-b-10">
                                  All listings for active agents in the app will be grouped together.
                                </span>
                              </span>
                            </label>
                          </div>
                          {AppStore.app_data.toggles.officesEnabled && (
                            <div class="option-box">
                              <input id="radio-new3" name="bigradios" type="radio" value="office" checked={this.state.my_type == "office"} onChange={() => {
                                this.setState({ my_type: "office" })
                              }} />
                              <label for="radio-new3">
                                <span class="radio-content">
                                  <span class="h6 d-block">Per-office</span>

                                  <span class="text-muted d-block m-b-10">
                                    Listings will be shown for the office level of the selected agent (or hidden if no agent currently selected).
                                  </span>
                                </span>
                              </label>
                            </div>
                          )}
                          <div class="option-box">
                            <input id="radio-new2" name="bigradios" type="radio" value="agent" checked={this.state.my_type == "agent"} onChange={() => {
                              this.setState({ my_type: "agent" })
                            }} />
                            <label for="radio-new2">
                              <span class="radio-content">
                                <span class="h6 d-block">Per-agent</span>

                                <span class="text-muted d-block m-b-10">
                                  Listings will only be shown for the selected agent (or hidden if no agent currently selected).
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="text-right">
                        {!AppStore.readOnly && (
                          <button
                            onClick={() => {
                              this.updateMyListings();
                            }}
                            class="btn btn-primary btn-cta"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              </div>

              <div class="col-lg-6">

                <div class="card py-3 m-b-30">
                  <div class="card-body">
                    <h3 class="">DMCA link</h3>

                    <p>
                      If you have a DMCA page, then enter here and it will be
                      linked under the listing disclaimer.
                    </p>

                    <div class="form-group">
                      <label for="dmca_link">DMCA link URL</label>
                      <input
                        style={{ fontSize: 16 }}
                        id="dmca_link"
                        type="text"
                        class="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            dmca_link: e.target.value
                          });
                        }}
                        value={this.state.dmca_link}
                      />
                    </div>

                    <div class="text-right">
                      {!AppStore.readOnly && (
                        <button
                          onClick={() => {
                            this.updateDMCA();
                          }}
                          class="btn btn-primary btn-cta"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {AppStore.app_data.toggles.selfProvisionedAgentsEnabled && AppStore.app_data?.self_prov_join_link && (
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h3 class="">Agent sign-up link</h3>

                      <p>
                        Using this link, agents can sign up and pay to join your app.
                      </p>

                      <div class="form-group">
                        <input
                          style={{ fontFamily: "monospace", fontSize: 16 }}
                          id="agent_link"
                          type="text"
                          class="form-control"
                          ref={input => (this.copyLink = input)}
                          value={AppStore.app_data?.self_prov_join_link}
                        />
                      </div>

                      <button
                        onClick={this.copyToClipboard}
                        class="btn btn-primary"
                      >
                        Copy link
                      </button>
                    </div>
                  </div>
                )}


              </div>
            </div>
          </div>
        </section>
      </Inner>
    );

    return (
      <Inner>
        <div class="content">
          <div class="row">
            <div class="col-2">
              {UserStore.isAgent() && (
                <div class="content-block">
                  <h2>API key</h2>
                  <p>Your unique app API key. Keep this safe!</p>
                  <p>
                    <input
                      ref={input => (this.copyLink = input)}
                      type="text"
                      value={this.state.key}
                    />
                  </p>
                  <div class="row">
                    <p>
                      <button onClick={this.copyToClipboard}>
                        Copy API key
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/*<div class="col-2">
              <div class="content-block">
                <h2>Regenerate API key</h2>
                <p>
                  You can generate a new API key here, but anything relying on
                  the old one will no longer work.
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <p>
                      <button
                        style={{ backgroundColor: "#a90000" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to generate a new API key? Anything using the old key will no longer work until updated."
                            )
                          ) {
                            alert("rolling....");
                          }
                        }}
                      >
                        Regenerate API key
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </Inner>
    );
  }
}

export default Settings;

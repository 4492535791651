import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  Switch,
} from "react-router-dom";
import Spinner from "react-spinkit";
import PrivateRoute from "./lib/PrivateRoute";
import Login from "./pages/login/Login";
import SSO from "./pages/login/SSO";
import FubError from "./pages/login/FubError";
import Dashboard from "./pages/dashboard/Dashboard";
import Agents from "./pages/dashboard/Agents";
import Agent from "./pages/dashboard/Agent";
import MortgagePro from "./pages/dashboard/MortgagePro";
import Users from "./pages/dashboard/Users";
import User from "./pages/dashboard/User";
import AgentSavedSearch from "./pages/dashboard/AgentSavedSearch";
import SearchLinks from "./pages/dashboard/SearchLinks";
import Alerts from "./pages/dashboard/Alerts";
import Tags from "./pages/dashboard/Tags";
import Marketing from "./pages/dashboard/Marketing";
import Settings from "./pages/dashboard/Settings";
import Integrations from "./pages/dashboard/Integrations";
import Chat from "./pages/dashboard/Chat";
import UserSettings from "./pages/dashboard/UserSettings";
import SettingsSSO from "./pages/dashboard/SettingsSSO";
import Provision from "pages/provision/Provision";
import ProvisionThanks from "pages/provision/ProvisionThanks";
import Preferences from "./pages/dashboard/Preferences";
import Profile from "./pages/dashboard/Profile";
import Pocket from "./pages/dashboard/Pocket";
import PocketEdit from "./pages/dashboard/PocketEdit";
import Featured from "./pages/dashboard/Featured";
import CustomLinks from "./pages/dashboard/CustomLinks";
import NoMatch from "./pages/dashboard/NoMatch";
import Training from "./pages/dashboard/Training";
import { Cookies } from "react-cookie";
import crypto from 'crypto';

import api from "./lib/api";
import UserStore from "./stores/UserStore";
import { observer } from "mobx-react";
import AppStore from "./stores/AppStore";

import "react-tippy/dist/tippy.css";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#016aaa",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },

    // error: will use the default color
  },
});

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on: true,
      isChecking: true,
      fubEmbedSignatureError: false,
      fubOK: false,
      fubContext: null,
      doneFUB: false,
      redir: null,
    };

    //const cookies = new Cookies();
    //cookies.set("api_key", "12dada", { path: "/", maxAge: 60 * 60 * 24 });
  }

  componentDidMount() {
    const cookies = new Cookies();
    let api_key = cookies.get("bk_api_key");
    let q_ro = null;

    //if (!api_key) {
    // If we set an API key in URL then use this instead
    const query = new URLSearchParams(window.location.search);
    let q_api_key = query.get("k");
    q_ro = query.get('ro'); // read only flag

    if (q_ro == 1) {
      cookies.set("bk_ro", "1", {
        path: "/",
        maxAge: 60 * 60 * 24
      });
    }

    let bk_ro_pre = cookies.get("bk_ro");
    if (bk_ro_pre == "1") {
      q_ro = 1;
    }

    if (q_api_key) {
      cookies.set("bk_api_key", q_api_key, {
        path: "/",
        maxAge: 60 * 60 * 24
      });

      let dash_url = "https://broker.homestack.com"; // use  dash_url = "/" imstead but make sure opensesame still works

      window.location.replace(dash_url);
    }
    //}

    if (api_key && !UserStore.hasUser()) {
      AppStore.setAPIKey(api_key);
      // Call the API and get the user info
      this.setState({ isChecking: true });

      api.key(
        {
          api_key: api_key,
          ro: q_ro
        },
        (response) => {
          //alert(JSON.stringify(response));
          UserStore.updateData(response.user);
          //AppStore.getAppData(response.user.stack_id);
          //this.setState({ isChecking: false });

          let bk_ro = cookies.get("bk_ro");
          if (bk_ro == "1") {
            AppStore.setReadOnly();
          }

          AppStore.setAppData(response.app);
          this.setState({ isChecking: false });
        },
        (error) => {
          // Do nothing... it will just go to login then
          this.setState({ isChecking: false });
        }
      );
    } else {
      this.setState({ isChecking: false });
    }

    // Check if we are loading in via an embed (FUB), e.g. ?fub_embed=1
    //const urlParams = new URLSearchParams(window.location.search);
    //if (urlParams.has('fub_embed')) {
    this.loadFUBscript();
    //}
  }

  loadFUBscript() {
    const urlParams = new URLSearchParams(window.location.search);
    //const script = document.createElement("script");
    //script.src = "https://eia.followupboss.com/embeddedApps-v1.0.0.js";
    //script.async = true;
    //document.body.appendChild(script);

    const fub_context = urlParams.get('context');
    const fub_signature = urlParams.get('signature');

    if (fub_context && fub_signature) {
      this.setState({ isChecking: true });
    }

    if (fub_context && fub_signature && this.isFromFollowUpBoss(fub_context, fub_signature)) {
      // Signature is good
      //this.setState({ fubOK: true, fubContext: fub_context });

      //
      try {
        const FUB = JSON.parse(atob(fub_context));

        const data = {
          fub_id: FUB.account.id,
          fub_domain: FUB.account.domain,
          fub_agent_email: FUB.user.email,
          fub_user_email: FUB.person?.emails[0]?.value,
          debug: FUB?.debugState
        }

        // FUB debug error checks
        if (FUB?.debugState == "account_not_found") {
          let dash_url = "/fub?err=account";
          window.location.replace(dash_url);
        } else if (FUB?.debugState == "person_not_found") {
          let dash_url = "/fub?err=person";
          window.location.replace(dash_url);
        } else if (FUB?.debugState == "user_not_found") {
          let dash_url = "/fub?err=user";
          window.location.replace(dash_url);
        } else if (FUB?.debugState == "unauthorized") {
          let dash_url = "/fub?err=auth";
          window.location.replace(dash_url);
        } else if (FUB?.debugState == "working") {
          // TODO Temp - remove!
          data.debug = "working";
          api.fubAuth(data, response => {
            //let dash_url = "/fub?err=" + JSON.stringify(response);
            //window.location.replace(dash_url);

            if (response.user_id_redirect == null) {
              let dash_url = "/fub?err=user";
              //let dash_url = "/fub?err=" + JSON.stringify(response); // TODO
              window.location.replace(dash_url);
            } else {
              const api_key = response.api_key;
              const user = response.user;

              const cookies = new Cookies();
              cookies.set("bk_api_key", api_key, {
                path: "/",
                maxAge: 60 * 60 * 24
              });

              UserStore.updateData(response.user);
              AppStore.setAPIKey(api_key);

              AppStore.setAppData(response.app);

              AppStore.setEmbedded();
              //AppStore.setEmbedUser(response?.user_id_redirect);

              this.setState({ isChecking: false }); //, redir: "/users/" + response?.user_id_redirect });

              let curr_path = window.location.pathname;

              if (!curr_path.includes("/users")) {
                const loc = "/users/" + response?.user_id_redirect;
                window.location.pathname = loc;
              }
            }
          }, error => {
            //let dash_url = "/fub?err=general";
            let dash_url = "/fub?err=" + JSON.stringify(error); // TODO
            window.location.replace(dash_url);
          });
        } else {
          api.fubAuth(data, response => {
            //let dash_url = "/fub?err=" + JSON.stringify(response);
            //window.location.replace(dash_url);

            if (response.user_id_redirect == null) {
              let dash_url = "/fub?err=user";
              //let dash_url = "/fub?err=" + JSON.stringify(response); // TODO
              window.location.replace(dash_url);
            } else {
              const api_key = response.api_key;
              const user = response.user;

              const cookies = new Cookies();
              cookies.set("bk_api_key", api_key, {
                path: "/",
                maxAge: 60 * 60 * 24
              });

              UserStore.updateData(response.user);
              AppStore.setAPIKey(api_key);

              AppStore.setAppData(response.app);

              AppStore.setEmbedded();
              //AppStore.setEmbedUser(response?.user_id_redirect);

              this.setState({ isChecking: false }); //, redir: "/users/" + response?.user_id_redirect });

              let curr_path = window.location.pathname;

              if (!curr_path.includes("/users")) {
                const loc = "/users/" + response?.user_id_redirect;
                window.location.pathname = loc;
              }
            }
          }, error => {
            //let dash_url = "/fub?err=general";
            let dash_url = "/fub?err=" + JSON.stringify(error); // TODO
            window.location.replace(dash_url);
          });
        }
      } catch (e) {
        let dash_url = "/fub?err=auth";
        window.location.replace(dash_url);
      }
      //

    } else if (fub_context && fub_signature) {
      // No signature etc, or invalid
      this.setState({ fubEmbedSignatureError: true })
    }
  }

  isFromFollowUpBoss(context, signature) {
    const calculated = crypto
      .createHmac('sha256', "bdfa47a4cd6e83de0f9b0eda2dbf41b2")
      .update(context)
      .digest('hex');
    return calculated === signature;
  }

  render() {
    if (this.state.fubEmbedSignatureError) {
      ///return <Redirect to="/fub?err=sig" />;

      let dash_url = "/fub?err=sig";
      window.location.replace(dash_url);
    }

    if (this.state.isChecking) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    if (AppStore?.app_data?.toggles?.selfProvisionedAgentsEnabled && UserStore?.user?.agent_data?.self_provisioned) {
      const sub_status = UserStore?.user?.agent_data?.subscription_status;
      if (!sub_status) {
        // They need to make the first payment

        let agent_guid = UserStore?.user?.agent_data?.guid;
        let payment_url = AppStore.app_data?.self_prov_link;

        return (
          <MuiThemeProvider theme={theme}>
            <div className="app">
              <ProvisionThanks agent_guid={agent_guid} payment_url={payment_url} />
            </div>
          </MuiThemeProvider>
        );
      } else if (sub_status != "active") {
        // It's cancelled or past_sue etc
        // What do?
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className="app">
          <Router>
            <Switch>
              {/*<Route path="/" component={Dashboard} />*/}

              <Route path="/login" component={Login} />
              <Route path="/sso" component={SSO} />
              <Route path="/sso/" component={SSO} />
              <Route path="/fub" component={FubError} />
              <Route path="/provision/complete" component={ProvisionThanks} />
              <Route path="/provision/:uri/:provisionId" component={Provision} />
              <PrivateRoute exact path="/" component={Dashboard} />

              <PrivateRoute path="/agents/:agentId" component={Agent} />
              <PrivateRoute path="/agents" component={Agents} />

              <PrivateRoute path="/mortgagepro" component={MortgagePro} />

              <PrivateRoute path="/users/:userId/agentsavedsearch/:searchId" component={AgentSavedSearch} />
              <PrivateRoute path="/users/:userId/agentsavedsearch" component={AgentSavedSearch} />
              <PrivateRoute path="/users/:userId" component={User} />
              <PrivateRoute path="/users" component={Users} />

              <PrivateRoute path="/searchlinks/new" component={AgentSavedSearch} />
              <PrivateRoute path="/searchlinks/:searchId" component={AgentSavedSearch} />
              <PrivateRoute path="/searchlinks" component={SearchLinks} />

              <PrivateRoute path="/alerts" component={Alerts} />
              <PrivateRoute path="/tags" component={Tags} />

              <PrivateRoute path="/marketing" component={Marketing} />
              <PrivateRoute path="/preferences" component={Preferences} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute
                exact
                path="/user_settings"
                component={UserSettings}
              />
              <PrivateRoute exact path="/settings" component={Settings} />
              <PrivateRoute exact path="/integrations" component={Integrations} />

              <PrivateRoute exact path="/chat" component={Chat} />
              <PrivateRoute exact path="/training" component={Training} />
              <PrivateRoute path="/settings/sso" component={SettingsSSO} />
              <PrivateRoute
                exact
                path="/custom-links"
                component={CustomLinks}
              />
              <PrivateRoute
                exact
                path="/app-resources"
                component={CustomLinks}
              />
              <PrivateRoute exact path="/pocket" component={Pocket} />
              <PrivateRoute exact path="/pocket/new" component={PocketEdit} />
              <PrivateRoute path="/pocket/:listingId" component={PocketEdit} />
              <PrivateRoute exact path="/featured" component={Featured} />
              <PrivateRoute component={NoMatch} />
            </Switch>

            {/*<Redirect
          to={{
            pathname: "/login",
            state: {}
          }}
        />

        <Route path="/login" component={Login} />*/}
          </Router>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { FiX, FiPlusCircle, FiVideo } from "react-icons/fi";
import { Formik } from "formik";
import moment from "moment";
import Select from "react-select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _debounce from "lodash/debounce";
import Tag from "./components/Tag";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import ManageUserTags from "./components/ManageUserTags";

@observer
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      error: false,
      createModal: false,
      sendInviteEmail: true,
      sendInviteSMS: false,
      resultLimit: 10,
      resultOffset: 0,
      resultsFrom: 0,
      resultsTo: 0,
      resultsTotal: 0,
      resultSort: "",
      resultQ: "",
      queryQ: "",
      agentQ: "",
      tagQ: "",
      currentTags: [],
      currentTags2: [],
      allTags: [],
      loading: true,
      tagsModal: false,
      agents: [{ value: -1, label: "None set" }],
      selectedAgent: null,
      selectedAgent2: null,
      videoModal: false,
      importModal: false,
      selectedModal: false,
      selectedFile: null,
      csvUsers: [],
      csvUsersBad: [],
      selectedUsers: []
    };

    this.handleAgentInputChangeDelayed = _debounce(this.handleAgentInputChange, 500);
  }

  selectUsers(user, checked) {
    let selected = this.state.selectedUsers;

    if (checked) {
      if (user) {
        // only if not alreqdy in the array
        if (!selected.includes(user)) {
          selected.push(user);
        }
      } else {
        this.state.users.map(user => {
          if (!selected.includes(user.id)) {
            selected.push(user.id);
          }
        });
      }
    } else {
      if (user) {
        // Remove the user from the array
        let index = selected.indexOf(user);
        if (index > -1) {
          selected.splice(index, 1);
        }
      } else {
        selected = [];
      }
    }

    this.setState({ selectedUsers: selected });
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const limitQ = query.get("limit");
    const offsetQ = query.get("offset");
    const sortQ = query.get("sort");
    const queryQ = query.get("q");
    const agentQ = query.get('agent');
    const tagQ = query.get('tag');

    if (limitQ && offsetQ) {
      this.setState({
        resultLimit: parseInt(limitQ),
        resultOffset: parseInt(offsetQ),
      });
    }

    if (sortQ) {
      this.setState({
        resultSort: sortQ,
      });
    }

    if (queryQ) {
      this.setState({
        resultQ: queryQ,
      });
    }

    if (agentQ) {
      this.setState({
        agentQ: agentQ
      })
    }

    if (tagQ) {
      this.setState({
        tagQ: tagQ
      })
    }

    setTimeout(() => {
      this.loadUsers();
      this.loadAllTags();
    }, 0);
  }

  handleCloseImport = () => {
    this.setState({ importModal: false });
  };

  handleCloseSelected = () => {
    this.setState({ selectedModal: false });
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    if (!this.state.selectedFile) {
      alert("Please select a CSV file.");
      return null;
    }

    // Details of the uploaded file
    //console.log(this.state.selectedFile);

    // axios.post("api/uploadfile", formData);
    api.uploadUsers(
      this.state.selectedFile,
      (response) => {
        //alert(JSON.stringify(response));
        if (response) {
          this.setState({
            csvUsers: response.data.ok_rows,
            csvUsersBad: response.data.error_rows,
            selectedFile: null,
          });
        } else {
          alert("Error processing file");
        }
      },
      (error) => {
        alert(error.response.data.message);
      }
    );
  };

  sendUsers() {
    api.sendUsers(
      this.state.csvUsers,
      (response) => {
        if (response) {
          this.setState({
            csvUsers: [],
            csvUsersBad: [],
            selectedFile: null,
            importModal: false,
            selectedModal: false
          });

          window.showToastMessage(
            "Success",
            "Users imported successfully."
          );

          this.loadUsers();
        } else {
          alert("Error processing file");
        }
      },
      (error) => {
        alert(error.response.data.message);
      }
    );
  }

  clearAll() {
    this.setState({
      selectedFile: null,
      csvUsers: [],
      csvUsersBad: [],
    });
  }

  selectedModal() {
    return (
      <Modal open={this.state.selectedModal} onClose={this.handleCloseSelected}>
        <div style={styles.modal}>
          <div style={styles.modalTitle}>
            <h2>Update selected ({this.state.selectedUsers.length})</h2>
            <div
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                this.setState({ selectedModal: false });
              }}
            >
              <FiX style={styles.menuIcon} />
            </div>
          </div>


          <strong>Change agent</strong><br />
          <Select
            styles={selectstyles}
            value={this.state.selectedAgent2?.value}
            options={this.state.agents}
            onInputChange={(val) => { this.handleAgentInputChangeDelayed(val) }}
            onChange={(option) => {
              this.setState({ selectedAgent2: option.value });
            }}
            placeholder="Agent name..."
          />

          {this.state.selectedAgent2 && (
            <button className="btn btn-primary" style={{ marginTop: 10 }} onClick={() => {
              if (window.confirm("Are you sure you want to update the selected agent for " + this.state.selectedUsers.length + " users?")) {

                api.changeAgents(JSON.stringify(this.state.selectedUsers), this.state.selectedAgent2, (response) => {
                  window.showToastMessage(
                    "Success",
                    "Agents updated"
                  );
                  this.setState({ selectedModal: false });

                  setTimeout(() => {
                    this.setState({ selectedUsers: [] });
                    this.updateHistory();
                    this.loadUsers();
                  }, 0);
                }, (error) => {
                  window.showToastMessage(
                    "Error",
                    "Error updating agents",
                    "danger",
                    "fe-x"
                  );
                });
              }
            }}>Update agents</button>
          )}

          <hr />

          <h5>Assign Tags</h5>
          <ManageUserTags
            plainPicker={true}
            allTags={this.state.allTags}
            user={null}
            currentTags={this.state.currentTags2}
            onAdd={(tag) => {
              //alert('add! ' + JSON.stringify(tag))
              this.addTag2(tag);
            }}
            onRemove={(tag) => {
              this.removeTag2(tag);
              //alert('remove! ' + JSON.stringify(tag))
            }}
            onCreate={(data) => {
              //alert('create should be didabled via prop')
            }}
          />

          {this.state.currentTags2.length > 0 && (
            <button className="btn btn-primary" style={{ marginTop: 10 }} onClick={() => {
              if (window.confirm("Are you sure you want to update the tags for " + this.state.selectedUsers.length + " users?")) {
                api.addUserTags(
                  {
                    user_ids: JSON.stringify(this.state.selectedUsers),
                    tag_ids: JSON.stringify(this.state.currentTags2)
                  }, (response) => {
                    window.showToastMessage(
                      "Success",
                      "Tags updated"
                    );
                    this.setState({ selectedModal: false });

                    setTimeout(() => {
                      this.setState({ selectedUsers: [], currentTags2: [] });
                      this.updateHistory();
                      this.loadUsers();
                    }, 0);
                  }, (error) => {
                    window.showToastMessage(
                      "Error",
                      "Error updating tags",
                      "danger",
                      "fe-x"
                    );
                  });
              }
            }}>Update agents</button>
          )}

        </div>
      </Modal>
    );
  }

  importModal() {
    // TODO: Show screen for apperoval of agents (just basics of name/email)
    return (
      <Modal open={this.state.importModal} onClose={this.handleCloseImport}>
        <div style={styles.modal}>
          <div style={styles.modalTitle}>
            <h2>Import users CSV</h2>
            <div
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                this.setState({ importModal: false });
              }}
            >
              <FiX style={styles.menuIcon} />
            </div>
          </div>

          {this.state.csvUsersBad.length > 0 && (
            <>
              <p>
                <strong>The following users cannot be imported:</strong>
                <ul>
                  {this.state.csvUsersBad.map((item) => (
                    <li>
                      [{item.reason}] {item.row.name} ({item.row.email})
                    </li>
                  ))}
                </ul>
              </p>
            </>
          )}

          {this.state.csvUsers.length ? (
            <>
              <p>
                <strong>The following users are ready to import:</strong>
              </p>
              <ul>
                {this.state.csvUsers.map((item) => (
                  <li>
                    {item.name} ({item.email})
                  </li>
                ))}
              </ul>
              <p style={{ paddingTop: 10 }}>
                <button
                  class="btn btn-primary"
                  onClick={() => {
                    this.sendUsers();
                  }}
                >
                  Import users
                </button>

                <button
                  style={{ marginLeft: 20 }}
                  class="btn btn-danger"
                  onClick={() => {
                    this.clearAll();
                  }}
                >
                  Cancel
                </button>
              </p>
            </>
          ) : (
            <>
              <p>
                You can{" "}
                <a
                  href="https://homestack.com/hs_user_import.csv"
                  download
                  //href="https://docs.google.com/spreadsheets/d/18bH-IIxqnbJPOSdU2G0NWIFzitfk8brBQmHhFyOHgbs/edit?usp=sharing"
                  class="text-underline"
                  target="_blank"
                >
                  download the CSV template to fill in here
                </a>
                . You can also use a the <a
                  //href="https://homestack.com/hs_user_import.csv"
                  href="https://docs.google.com/spreadsheets/d/18bH-IIxqnbJPOSdU2G0NWIFzitfk8brBQmHhFyOHgbs/edit?usp=sharing"
                  class="text-underline"
                  target="_blank"
                >google sheet template link here</a>. Make sure to follow the template otherwise the import will
                fail. <strong>If you are using Safari, right click and choose to download this file from the link</strong>.
              </p>

              <div>
                <input
                  type="file"
                  name="file"
                  class="form-control-file"
                  onChange={this.onFileChange}
                />
                <p style={{ paddingTop: 10 }}>
                  <button class="btn btn-primary" onClick={this.onFileUpload}>
                    Upload CSV file
                  </button>
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }

  checkActiveTags() {
    if (this.state.allTags && this.state.tagQ) {
      let all_tags = this.state.allTags;
      let active_tag_names = this.state.tagQ.split(",");
      let set_current = [];

      for (let x = 0; x < active_tag_names.length; x++) {
        all_tags.map(tag => {
          if (tag.tag === active_tag_names[x]) {
            set_current.push(tag);
          }
        });
      }

      this.setState({ currentTags: set_current })
    }
  }

  loadAllTags() {
    api.loadAllUserTags(
      (response) => {
        this.setState({ allTags: response.tags })
        this.checkActiveTags();
        //this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  removeTag(tag) {
    let tags = this.state.tagQ;
    let current_tags = this.state.currentTags;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    }

    // Check if we already have some
    if (tags) {
      // Get how many
      let all_tags = tags.split(',');
      let new_tags = [];

      if (all_tags.length) {
        for (let x = 0; x < all_tags.length; x++) {
          if (all_tags[x] != tag.tag) {
            new_tags.push(all_tags[x]);
          }
        }
      }

      f_tags = new_tags.join(',');
    }

    this.setState({ tagQ: f_tags, currentTags: new_current_tags });
    this.updateTag()
  }

  removeTag2(tag) {
    let current_tags = this.state.currentTags2;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    }

    this.setState({ currentTags2: new_current_tags });
  }

  addTag(tag) {
    let tags = this.state.tagQ;
    let current_tags = this.state.currentTags;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      new_current_tags.push(tag);
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    } else {
      new_current_tags.push(tag);
    }


    // Check if we already have some
    if (tags) {
      // Get how many
      let all_tags = tags.split(',');
      let new_tags = [];

      if (all_tags.length) {
        for (let x = 0; x < all_tags.length; x++) {
          if (all_tags[x] != tag.tag) {
            new_tags.push(all_tags[x]);
          }
        }
      }

      new_tags.push(tag.tag);

      f_tags = new_tags.join(',');
    }

    this.setState({ tagQ: f_tags, currentTags: new_current_tags });
    this.updateTag()
  }

  addTag2(tag) {
    let current_tags = this.state.currentTags2;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      new_current_tags.push(tag);
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    } else {
      new_current_tags.push(tag);
    }

    this.setState({ currentTags2: new_current_tags });
  }

  loadAgents(q, callback) {
    api.getAgents(
      null,
      null,
      null,
      (response) => {
        let options = [];

        if (response.agents.length > 0) {
          //options.push({ value: null, label: "No agent" });
        }

        response.agents.map((item) => {
          options.push({ value: item.id, label: item.display_name });
        });

        options.push([{ value: -1, label: "None set" }]);

        this.setState({ agents: options });
      },
      (error) => {
        this.setState({ agents: [{ value: -1, label: "None set" }] });
      },
      null,
      q
    );
  }

  handleAgentInputChange = value => {
    let q = value;

    if (q.length < 3) {
      this.setState({ agents: [] });
    } else {
      this.loadAgents(q);
    }
  };

  csv() {
    api.getCSV(
      (response) => {
        /*var file = new Blob([response], { type: "application/csv" });
        var fileURL = URL.createObjectURL(file);

        // Open new windows and show PDF
        window.open(fileURL);*/

        //IE11 support
        if (window.navigator && window.navigator.msSaveOrOpenBlob && false) {
          let blob = new Blob([response], { type: "text/csv" });
          window.navigator.msSaveOrOpenBlob(blob, "users.csv");
        } else {
          // other browsers
          let file = new File([response], "users.csv", {
            type: "text/csv",
          });

          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari) {
            let blob = new Blob([response], { type: "text/csv" });
            let exportUrl = URL.createObjectURL(blob);
            var e = document.createElement("a");
            e.href = exportUrl;
            e.target = "_self";
            e.setAttribute("download", "download");
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
          } else {
            let exportUrl = URL.createObjectURL(file);
            window.location.assign(exportUrl);
            URL.revokeObjectURL(exportUrl);
          }
        }
      },
      (error) => {
        //
      }
    );
  }

  loadUsers() {
    this.setState({ loading: true });

    api.getUsers(
      this.state.resultLimit,
      this.state.resultOffset,
      this.state.resultSort,
      (response) => {
        this.setState({
          users: response.users,
          resultsTotal: response.total,
          resultsFrom: response.from,
          resultsTo: response.to,
          loading: false,
        });
      },
      (error) => {
        this.setState({ error: true, loading: false });
      },
      null,
      this.state.resultQ,
      this.state.agentQ,
      this.state.tagQ
    );
  }

  updateHistory() {
    this.props.history.push(
      `${window.location.pathname}?offset=${parseInt(
        this.state.resultOffset
      )}&limit=${parseInt(this.state.resultLimit)}&sort=${this.state.resultSort
      }&q=${this.state.resultQ}&agent=${this.state.agentQ}&tag=${this.state.tagQ}`
    );
  }

  updateQuery(q) {
    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
    }, 0);
  }

  updateTag(q) {
    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
    }, 0);
  }

  updateAgent() {
    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
    }, 0);
  }

  updateLimit(limit) {
    this.setState({
      resultLimit: limit,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
    }, 0);
  }

  updateSort(sort) {
    this.setState({
      resultSort: sort,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
    }, 0);
  }

  removeAllFilters() {
    this.setState({
      resultQ: "",
      queryQ: "",
      agentQ: "",
      tagQ: "",
      currentTags: []
    });
    setTimeout(() => {
      this.updateHistory();
      this.loadUsers();
    }, 0);
  }

  page(type) {
    if (type == "next") {
      this.setState({
        resultOffset: parseInt(this.state.resultOffset) + parseInt(this.state.resultLimit),
      });
    } else if (type == "prev") {
      this.setState({
        resultOffset: parseInt(this.state.resultOffset) - parseInt(this.state.resultLimit),
      });
    } else if (type == "last") {
      this.setState({
        resultOffset: parseInt(this.state.resultsTotal) - parseInt(this.state.resultLimit),
      });
    } else if (type == "first") {
      this.setState({
        resultOffset: 0,
      });
    }

    setTimeout(() => {
      this.updateHistory();

      this.loadUsers();
      window.scrollTo(0, 0);
    }, 0);
  }

  pagination() {
    const tags = this.state.currentTags;

    // TODO:
    // Need to add a button for modal to add filter tags as otherwise can only
    // use those showing on the screen from user list

    return (
      <>

        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 30 }}>

          <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <a href="#" onClick={(e) => {
              e.preventDefault();
              this.setState({ tagsModal: true })
            }} className="text-underline" style={{ marginBottom: 4, marginRight: 20 }}><FiPlusCircle /> Filter by tags</a>

            <div>
              {tags.map(tag => (
                <Tag
                  asFilter={true}
                  tag={tag}
                  onRemove={(selected) => {
                    this.removeTag(selected);
                  }}
                />
              ))}
            </div>
          </div>


          {this.state.agentQ || this.state.resultQ || this.state.queryQ || this.state.tagQ ? (
            <div style={{}}>
              <a href="#" className="text-underline" onClick={(e) => {
                e.preventDefault();
                this.removeAllFilters();
              }}>Remove all filters</a>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 5,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              class="fddorm-group"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                class="form-control"
                name="q"
                value={this.state.resultQ}
                placeholder="Search name, email..."
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.updateQuery();
                  }
                }}
                onChange={(e) => {
                  this.setState({ resultQ: e.target.value });
                  if (!e.target.value) {
                    this.updateQuery();
                  }
                }}
              />
              {this.state.resultQ ? (
                <i
                  onClick={() => {
                    this.setState({ resultQ: "" });
                    this.updateQuery();
                  }}
                  class="fe fe-x"
                  style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    cursor: "pointer",
                    fontSize: 20,
                    color: "red",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <div class="fddorm-group">
              <select
                value={this.state.resultLimit}
                class="form-control"
                onChange={(e) => {
                  this.updateLimit(e.target.value);
                }}
              >
                <option value="10">Show 10</option>
                <option value="25">Show 25</option>
                <option value="50">Show 50</option>
              </select>
            </div>
            <div style={{ marginLeft: 10 }}>
              <strong>
                {this.state.resultsFrom} - {this.state.resultsTo} of{" "}
                {this.state.resultsTotal}
              </strong>
            </div>
            <div style={{ marginLeft: 30 }}>
              <strong>Order</strong>
            </div>
            <div class="fddorm-group" style={{ marginLeft: 10 }}>
              <select
                value={this.state.resultSort}
                class="form-control"
                onChange={(e) => {
                  this.updateSort(e.target.value);
                }}
              >
                <option value="">Last login (recent)</option>
                <option value="l">Last login (old)</option>
                <option value="j">Joined date</option>
                <option value="a">A-Z</option>
                <option value="z">Z-A</option>
              </select>
            </div>
            {UserStore.isAdmin() && (
              <>
                <div style={{ marginLeft: 30 }}>
                  <strong>Agent</strong>
                </div>
                <div class="fddorm-group" style={{ marginLeft: 10 }}>
                  <Select
                    styles={selectstyles}
                    value={this.state.selectedAgent}
                    options={this.state.agents}
                    onInputChange={(val) => { this.handleAgentInputChangeDelayed(val) }}
                    onChange={(option) => {
                      this.setState({ selectedAgent: option.value, agentQ: option.value });
                      this.updateAgent(option.value);
                    }}
                    placeholder="Agent name..."
                  />
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {this.state.resultsFrom > 1 && (
              <>
                <button
                  onClick={() => {
                    this.page("first");
                  }}
                  class="btn btn-sm btn-secondary"
                >
                  First
                </button>
                <div style={{ width: 10 }} />
                <button
                  onClick={() => {
                    this.page("prev");
                  }}
                  class="btn btn-sm btn-primary"
                >
                  &larr; Previous
                </button>
              </>
            )}
            <div style={{ width: 10 }} />
            {this.state.resultsTo < this.state.resultsTotal && (
              <>
                <button
                  onClick={() => {
                    this.page("next");
                  }}
                  class="btn btn-sm btn-primary"
                >
                  Next &rarr;
                </button>
                <div style={{ width: 10 }} />
                <button
                  onClick={() => {
                    this.page("last");
                  }}
                  class="btn btn-sm btn-secondary"
                >
                  Last
                </button>
              </>
            )}
          </div>
        </div>

        {this.state.selectedUsers.length > 0 && (
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <button class="btn btn-primary" onClick={() => {
              this.setState({ selectedModal: true });
            }}>Update selected users</button>
          </div>
        )}

      </>
    );
  }

  users() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadUsers();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (this.state.users.loading) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <section class="admin-content">
        <Modal open={this.state.createModal} onClose={this.handleClose}>
          <div style={styles.modal}>
            <div style={styles.modalTitle}>
              <h2>Create new user</h2>
              <div
                style={{
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.setState({ createModal: false });
                }}
              >
                <FiX style={styles.menuIcon} />
              </div>
            </div>

            <Formik
              initialValues={{
                display_name: null,
                email: null,
                password: null,
                phone: null,
              }}
              validate={(values) => {
                let errors = {};

                if (!values.display_name) {
                  errors.display_name = "Required";
                }

                if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length < 6) {
                  errors.password = "Password must be at least 6 characters";
                }

                if (!values.phone) {
                  errors.phone = "Required";
                } else if (values.phone.length < 6) {
                  errors.phone = "Enter a valid phone number";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  values.send_email = this.state.sendInviteEmail;
                  values.send_sms = this.state.sendInviteSMS;
                  //alert(JSON.stringify(values, null, 2));

                  api.createUser(
                    values,
                    (response) => {
                      //alert(JSON.stringify(response));
                      this.setState({
                        createModal: false,
                      });

                      window.showToastMessage("Success", "New user created");
                      this.loadUsers();
                      //this.setState({ toAgent: response.id });
                    },
                    (error) => {
                      alert(error.response.data.message);
                    }
                  );

                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <p>
                    <label for="display_name">Name</label>
                    <input
                      type="text"
                      name="display_name"
                      class="form-control"
                      id="display_name"
                      placeholder="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.display_name}
                    />
                    <span className="form-error">
                      {errors.display_name &&
                        touched.display_name &&
                        errors.display_name}
                    </span>
                  </p>

                  <p>
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      id="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="form-error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </p>

                  <p>
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      class="form-control"
                      id="phone"
                      placeholder="Phone number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    <span className="form-error">
                      {errors.phone && touched.phone && errors.phone}
                    </span>
                  </p>

                  <p>
                    <label for="password">Password</label>
                    <input
                      type="text"
                      name="password"
                      class="form-control"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span className="form-error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </p>

                  <p>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.sendInviteEmail}
                            onChange={() => {
                              const tog = !this.state.sendInviteEmail;
                              this.setState({
                                sendInviteEmail: tog,
                              });
                            }}
                            value="1"
                          />
                        }
                        label="Send email invite"
                      />
                    </FormGroup>
                  </p>

                  <p>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.sendInviteSMS}
                            onChange={() => {
                              const tog = !this.state.sendInviteSMS;
                              this.setState({
                                sendInviteSMS: tog,
                              });
                            }}
                            value="1"
                          />
                        }
                        label="Send SMS invite"
                      />
                    </FormGroup>
                  </p>

                  <p>
                    <button
                      class="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create user{" "}
                      {(this.state.sendInviteEmail || this.state.sendInviteSMS) ? "& send invite" : null}
                    </button>
                  </p>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        <div class="bg-dark">
          <div class="container  m-b-30">
            <div class="row">
              <div class="col-12 text-white p-t-40 p-b-90">
                <div class="float-right">
                  {AppStore.app_data.toggles.userExportEnabled && !UserStore.isManager() && (
                    <button
                      style={{ marginRight: 20 }}
                      onClick={() => {
                        this.csv();
                      }}
                      class="btn btn-primary"
                    >
                      <i class=" fe fe-download " /> Download CSV
                    </button>
                  )}

                  {!UserStore.isManager() && (
                    <button
                      style={{ marginRight: 20 }}
                      onClick={() => {
                        this.setState({ importModal: true });
                      }}
                      class="btn btn-primary"
                    >
                      <i class=" fe fe-upload " /> Upload CSV
                    </button>
                  )}

                  {!AppStore.readOnly && !UserStore.isManager() && (
                    <button
                      onClick={() => {
                        this.setState({ createModal: true });
                      }}
                      class="btn btn-success"
                    >
                      New user
                    </button>
                  )}
                </div>
                <h4 class="">
                  {" "}
                  <span class="btn btn-white-translucent">
                    <i class="icon-placeholder fe fe-users " />
                  </span>{" "}
                  Users &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                    this.setState({ videoModal: true })
                  }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                </h4>

                {/*<p class="opacity-75 ">
                  We have inherited from Bootstrap 4, with some tweaks we have
                  <br /> created table styles that blend in our design.
                </p>*/}
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-md-12">
              <div class="card m-b-30">
                <div class="card-body">
                  {this.pagination()}
                  <div class="table-responsive">
                    <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>
                            <input value="all" type="checkbox" className="oform-control" onChange={e => {
                              if (e.target.checked) {
                                // checked
                                this.selectUsers(null, true);
                              } else {
                                // unchecked
                                this.selectUsers(null, false);
                              }
                            }} />
                          </th>
                          <th style={{ width: 50 }}>&nbsp;</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Joined</th>
                          <th>Logins</th>
                          <th>Saved searches</th>
                          {(UserStore.isAdmin() || UserStore.isManager()) && <th>Agent</th>}
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.length == 0 && (
                          <tr>
                            <td colspan="8">
                              <p className="text-muted text-center">No users found. <a className="text-underline" href="#" onClick={(e) => {
                                e.preventDefault();
                                this.removeAllFilters();
                              }}>Remove all filters</a></p>
                            </td>
                          </tr>
                        )}
                        {this.state.users.map((item) => (
                          <tr>
                            <td>
                              <input type="checkbox" checked={this.state.selectedUsers.includes(item.id)} className="form-control" onChange={e => {
                                this.selectUsers(item.id, e.target.checked);
                              }} />
                            </td>
                            <td style={{ width: 40 }}>
                              <Link
                                to={`/users/${item.id}`}
                              >
                                {item.photo ? (
                                  <div class="avatar avatar-sm">
                                    <img
                                      src={item.photo}
                                      class="avatar-img rounded-circle"
                                    />
                                  </div>
                                ) : (
                                  <div class="avatar avatar-sm">
                                    <span class="avatar-title rounded-circle ">
                                      {item.display_name
                                        .split(" ")
                                        .map((n, i, a) =>
                                          i === 0 || i + 1 === a.length
                                            ? n[0]
                                            : null
                                        )
                                        .join("")}
                                    </span>
                                  </div>
                                )}
                              </Link>
                            </td>
                            <td style={{ maxWidth: 300 }}>
                              <Link
                                to={`/users/${item.id}`}
                                class="text-underline"
                              >
                                {/*<Link to={`/users/${item.id}`}>*/}
                                {item.display_name}
                              </Link>
                              <br />

                              {UserStore.isAdmin() && item.type == "agent" && (
                                <Tag
                                  tag={{ tag: "agent", tag_long: "Agent", tag_color: "#AAD8EC", text_color: "#1E5270" }}
                                  onClick={(selected) => {
                                    this.addTag(selected);
                                  }}
                                />
                                /*<span style={{ marginLeft: 5, marginBottom: 5 }} class="badge badge-warning">Agent</span>*/
                              )}
                              <>
                                {item.tags.map(tag => (
                                  <Tag
                                    tag={tag}
                                    onClick={(selected) => {
                                      this.addTag(selected);
                                    }}
                                  />
                                ))}
                              </>


                              {/*</Link>*/}
                            </td>
                            <td>
                              <a href={`mailto:${item.email}`}>{item.email}</a>
                            </td>
                            <td>
                              <a href={`tel:${item.phone}`}>{item.phone}</a>
                            </td>
                            <td>
                              <small>
                                {moment.unix(item.created_epoch).fromNow()}
                              </small>
                            </td>
                            <td>
                              {item.login_count}
                              <br />
                              <small>
                                Last was{" "}
                                {moment.unix(item.last_login_epoch).fromNow()}
                              </small>
                            </td>
                            <td>
                              {item.saved}<br />
                              {AppStore.app_data.toggles.agentSavedSearchEnabled && !UserStore.isManager() && (
                                <Link className="btn btn-sm btn-primary" to={{
                                  pathname: "/users/" + item.id + "/agentsavedsearch",
                                  //state: { user: item }
                                }}>Create&nbsp;Search</Link>
                              )}
                            </td>

                            {(UserStore.isAdmin() || UserStore.isManager()) && (
                              <td>
                                {item.agent_id ? (
                                  <Link
                                    to={`/agents/${item.agent_id}`}
                                    class="text-underline"
                                  >
                                    {item.agent_name}
                                  </Link>
                                ) : (
                                  <em>None set</em>
                                )}
                              </td>
                            )}

                            <td>
                              <Link
                                to={`/users/${item.id}`}
                                class="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>

                            {/*<td>
                      <Button
                        color="primary"
                        component={Link}
                        to={`/users/${item.id}`}
                      >
                        View
                      </Button>
                    </td>*/}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {this.pagination()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <Inner>
        <div class="content">{this.users()}</div>

        {this.importModal()}
        {this.selectedModal()}

        <Rodal
          visible={this.state.videoModal}
          onClose={() => { this.setState({ videoModal: false }) }}
          //width={600}
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          height={400}
        >
          <p><iframe src="https://player.vimeo.com/video/694470737" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
        </Rodal>

        <Rodal
          visible={this.state.tagsModal}
          onClose={() => { this.setState({ tagsModal: false }) }}
          width={"90%"}
          customStyles={{ maxWidth: 600, overflow: "auto" }}
          //width={600}
          height={400}
        >
          <ManageUserTags
            allTags={this.state.allTags}
            user={null}
            currentTags={this.state.currentTags}
            onAdd={(tag) => {
              //alert('add! ' + JSON.stringify(tag))
              this.addTag(tag);
            }}
            onRemove={(tag) => {
              this.removeTag(tag);
              //alert('remove! ' + JSON.stringify(tag))
            }}
            onCreate={(data) => {
              //alert('create should be didabled via prop')
            }}
          />
        </Rodal>
      </Inner>
    );
  }
}

const selectstyles = {
  container: base => ({
    ...base,
    flex: 1,
    minWidth: 180
  })
};

const styles = {
  modal: {
    backgroundColor: "#fff",
    padding: 20,
    maxWidth: 500,
    maxHeight: 600,
    minHeight: 500,
    width: "90%",
    top: "50%",
    left: "50%",
    overflow: "scroll",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
};

export default Users;

import { observable, action } from "mobx";

// TODO: Change this to what's needed!

class UserStore {
  @observable
  user = {};

  @action
  updateData(data) {
    this.user = data;
  }

  @action
  updateItem(key, data) {
    this.user[key] = data;
  }

  @action
  clearData(data) {
    this.user = {};
  }

  @action
  hasUser() {
    return Object.keys(this.user).length === 0 ? false : true;
  }

  @action
  isAgent() {
    if (this.hasUser()) {
      if (this.user.type == "agent") {
        return true;
      }
    }

    return false;
  }

  @action
  isManager() {
    if (this.hasUser()) {
      /*if (this.user.type == "manager") {
        return true;
      }*/

      if (
        (this.user.type == "agent" && this.user.subType == "agentManager") ||
        this.user.type == "manager"
      ) {
        return true;
      }
    }

    return false;
  }

  @action
  isAdmin() {
    if (this.hasUser()) {
      if (
        (this.user.type == "agent" && this.user.subType == "agentAdmin") ||
        this.user.type == "admin"
      ) {
        return true;
      }
    }

    return false;
  }

  @action
  isPayments() {
    if (this.hasUser()) {
      if (this.user.payments == true && this.user.payments_link) {
        return true;
      }
    }

    return false;
  }
}

export default new UserStore();

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { FiX, FiVideo } from "react-icons/fi";
import { Formik } from "formik";
import Select from "react-select";
//import moment from "moment";
import Autosuggest from "react-autosuggest";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ManageUserTags from "./components/ManageUserTags";
import Rodal from "rodal";
import _debounce from "lodash/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
//import "rodal/lib/rodal.css";

let alertTypes = [
  { value: "url", label: "URL" },
  { value: "listing", label: "Listing" },
  { value: "text", label: "Text" }
];

let scheduleTypes = [
  { value: "now", label: "Now" },
  { value: "schedule", label: "Schedule a time" }
];

const renderSuggestion = (suggestion) => (
  <span>
    <b>{suggestion.text}</b>
  </span>
);

@observer
class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      offices: [],
      office: [{ value: null, label: "No office" }],
      scheduled_alerts: [],
      loading: true,
      error: false,
      createModal: false,
      sendInviteEmail: true,
      resultLimit: 10,
      resultOffset: 0,
      resultsFrom: 0,
      resultsTo: 0,
      resultsTotal: 0,
      resultSort: "",
      scheduleType: { value: "now", label: "Now" },
      alertType: null, // listing
      alertRecipient: null,
      alertRecipientTag: false,
      alertListingKey: null,
      alertListingAddress: null,
      alertUsers: [{ value: "all", label: "All users" }],
      value: "",
      suggestions: [],
      currentTags: [],
      allTags: [],
      tagTotalUsers: 0,
      loadingTags: false,
      users: [],
      userModal: false,
      videoModal: false,
      showSchedule: false,
      schedule_date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)),
      schedule_hour: 9,
      schedule_minute: 0
    };

    this.handleAgentInputChangeDelayed = _debounce(this.handleAgentInputChange, 500);
  }

  loadOffices() {
    if (AppStore.app_data.toggles.officesEnabled) {
      api.getOffices(
        (response) => {
          let data = response.offices
          //this.setState({ offices: response.offices });

          function buildTree(items, parentId = "0") {
            return items
              .filter(item => item.parent_id === parentId)
              .map(item => ({ ...item, children: buildTree(items, item.id) }));
          }

          function flattenTree(items, depth = 0) {
            let flat = [];
            items.forEach(item => {
              flat.push({ ...item, depth, padding: `${depth * 10}` }); // Add item with depth and padding
              if (item.children) {
                flat = [...flat, ...flattenTree(item.children, depth + 1)]; // Flatten children with incremented depth
              }
            });
            return flat.map(({ children, ...item }) => item); // Return flat array without children property
          }

          const tree = buildTree(data); // Build hierarchical tree structure
          const orderedFlatArray = flattenTree(tree); // Flatten the tree back into an array with order preserved

          let us = [];

          us.push({ value: null, label: "No office" });

          // Turn orderedFlatArray into value/label
          orderedFlatArray.map((item) => {
            let the_name = item.name;

            let spacer = "";
            if (item.padding > 0) {
              spacer = "-".repeat(parseInt(item.padding) / 10);
              spacer += " ";
            }
            the_name = spacer + the_name;

            item.value = item.id;
            item.label = the_name;
            us.push(item);
          });

          this.setState({
            offices: us,
          });
        },
        (error) => {
          //
        }
      );
    }
  }

  getUserTags() {
    this.setState({
      loadingTags: true,
      tagTotalUsers: 0
    });

    const office_id = this.state?.office?.value ?? null;

    setTimeout(() => {
      let use_tags = [];
      let curr_tags = this.state.currentTags;

      curr_tags.map(item => {
        use_tags.push(item.tag);
      });

      api.getUserTags({ tags: use_tags }, response => {
        let api_users = response.users;

        this.setState({
          loadingTags: false,
          tagTotalUsers: api_users.length,
          users: api_users
        });
      }, error => {
        this.setState({
          loadingTags: false,
          tagTotalUsers: 0
        });
      }, office_id);
    }, 1000);
  }

  getSuggestionValue(suggestion) {
    this.setState({
      alertListingKey: suggestion.key,
      alertListingAddress: suggestion.text,
    });
    return suggestion.text;
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const limitQ = query.get("limit");
    const offsetQ = query.get("offset");
    const sortQ = query.get("sort");

    if (limitQ && offsetQ) {
      this.setState({
        resultLimit: parseInt(limitQ),
        resultOffset: parseInt(offsetQ),
      });
    }

    if (sortQ) {
      this.setState({
        resultSort: sortQ,
      });
    }

    setTimeout(() => {
      this.loadAlerts();
      this.loadUsers();
      this.loadAllTags();
      this.loadOffices();
    }, 0);
  }

  checkActiveTags() {
    if (this.state.allTags && this.state.tagQ) {
      let all_tags = this.state.allTags;
      let active_tag_names = this.state.tagQ.split(",");
      let set_current = [];

      for (let x = 0; x < active_tag_names.length; x++) {
        all_tags.map(tag => {
          if (tag.tag === active_tag_names[x]) {
            set_current.push(tag);
          }
        });
      }

      this.setState({ currentTags: set_current })
    }
  }

  loadAllTags() {
    api.loadAllUserTags(
      (response) => {
        this.setState({ allTags: response.tags })
        this.checkActiveTags();
        //this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  removeTag(tag) {
    let tags = this.state.tagQ;
    let current_tags = this.state.currentTags;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    }

    // Check if we already have some
    if (tags) {
      // Get how many
      let all_tags = tags.split(',');
      let new_tags = [];

      if (all_tags.length) {
        for (let x = 0; x < all_tags.length; x++) {
          if (all_tags[x] != tag.tag) {
            new_tags.push(all_tags[x]);
          }
        }
      }

      f_tags = new_tags.join(',');
    }

    this.setState({ tagQ: f_tags, currentTags: new_current_tags });

    this.getUserTags();
  }

  addTag(tag) {
    let tags = this.state.tagQ;
    let current_tags = this.state.currentTags;
    let f_tags = tag.tag;

    // TODO also add to currentTags array if not there...
    let new_current_tags = [];
    if (current_tags.length > 0) {
      new_current_tags.push(tag);
      for (let x = 0; x < current_tags.length; x++) {
        if (current_tags[x].tag != tag.tag) {
          new_current_tags.push(current_tags[x]);
        }
      }
    } else {
      new_current_tags.push(tag);
    }


    // Check if we already have some
    if (tags) {
      // Get how many
      let all_tags = tags.split(',');
      let new_tags = [];

      if (all_tags.length) {
        for (let x = 0; x < all_tags.length; x++) {
          if (all_tags[x] != tag.tag) {
            new_tags.push(all_tags[x]);
          }
        }
      }

      new_tags.push(tag.tag);

      f_tags = new_tags.join(',');
    }

    this.setState({ tagQ: f_tags, currentTags: new_current_tags });

    this.getUserTags();
  }

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    api.getListingSuggestions(
      value,
      (response) => {
        //
        console.log(response);

        let sug = [];
        const mls = response.mls;
        const address = response.address;

        address.map((item) => {
          sug.push({
            key: item.ListingKey,
            text:
              item.FullStreetAddress +
              ", " +
              item.City +
              ", " +
              item.State +
              ", " +
              item.ZipCode,
          });
        });

        this.setState({
          suggestions: sug,
          alertListingKey: null,
          alertListingAddress: null,
        });
      },
      (error) => {
        this.setState({
          suggestions: [],
          alertListingKey: null,
          alertListingAddress: null,
        });
      }
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  handleAgentInputChange = value => {
    let q = value;

    if (q.length < 3) {
      this.setState({ alertUsers: [{ value: "all", label: "All users" }] });
    } else {
      this.loadUsers(q);
    }
  };

  // (limit, offset, sort, _success, _error, id, query, agent_id, tag)
  loadUsers(q) {
    if (!q) {
      return;
    }

    const office_id = this.state?.office?.value ?? null;

    api.getUsers(
      2000,
      0,
      "a",
      (response) => {
        let users = response.users;

        //let us = this.state.alertUsers;

        let us = [];

        us.push({ value: "all", label: "All users" });

        users.map((item) => {
          us.push({ value: item.id, label: item.display_name });
        });

        this.setState({ alertUsers: us });
      },
      (error) => {
        //
      },
      null,
      q, // id, query, agent_id, tag, office_id
      null,
      null,
      office_id
    );
  }

  deleteScheduledAlert(id) {
    api.deleteScheduledAlert(
      id,
      (response) => {
        this.loadAlerts();
      },
      (error) => {
        //
      }
    );
  }

  loadAlerts() {
    api.getAlerts(
      //this.state.resultLimit,
      //this.state.resultOffset,
      //this.state.resultSort,
      (response) => {
        this.setState({
          alerts: response.alerts,
          loading: false,
          //resultsTotal: response.total,
          //resultsFrom: response.from,
          //resultsTo: response.to,
        });
      },
      (error) => {
        this.setState({ error: true, loading: false });
      }
    );

    api.getScheduledAlerts(
      //this.state.resultLimit,
      //this.state.resultOffset,
      //this.state.resultSort,
      (response) => {
        this.setState({
          scheduled_alerts: response.alerts,
          loading: false,
          //resultsTotal: response.total,
          //resultsFrom: response.from,
          //resultsTo: response.to,
        });
      },
      (error) => {
        this.setState({ error: true, loading: false });
      }
    );
  }

  updateHistory() {
    this.props.history.push(
      `${window.location.pathname}?offset=${parseInt(
        this.state.resultOffset
      )}&limit=${parseInt(this.state.resultLimit)}&sort=${this.state.resultSort
      }`
    );
  }

  updateLimit(limit) {
    this.setState({
      resultLimit: limit,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadAlerts();
    }, 0);
  }

  updateSort(sort) {
    this.setState({
      resultSort: sort,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadAlerts();
    }, 0);
  }

  page(type) {
    if (type == "next") {
      this.setState({
        resultOffset: this.state.resultOffset + this.state.resultLimit,
      });
    } else if (type == "prev") {
      this.setState({
        resultOffset: this.state.resultOffset - this.state.resultLimit,
      });
    } else if (type == "last") {
      this.setState({
        resultOffset: this.state.resultsTotal - this.state.resultLimit,
      });
    } else if (type == "first") {
      this.setState({
        resultOffset: 0,
      });
    }

    setTimeout(() => {
      this.updateHistory();

      this.loadAlerts();
      window.scrollTo(0, 0);
    }, 0);
  }

  showAlertType(item) {
    if (item.meta_type == "url") {
      return (<span className="badge badge-warning">URL</span>);
    }
    if (item.meta_type == "listing") {
      return (<span className="badge badge-success">
        Listing
      </span>);
    }
    if (item.meta_type == "text") {
      return (<span className="badge badge-info">Text</span>);
    }

    return (<span className="badge badge-primary">{item.meta_type}</span>);
  }

  showIntendedRecipients() {
    //userModal
    //{this.state.alertRecipientTag && !this.state.loadingTags && ("(" + this.state.tagTotalUsers + " users)")}

    if (this.state.alertRecipientTag && !this.state.loadingTags) {
      if (this.state.tagTotalUsers == 0) {
        return (<>({this.state.tagTotalUsers} users)</>);
      } else {
        return (<>(<a href="#" className="text-underline" onClick={() => {
          this.setState({ userModal: true });
        }}>{this.state.tagTotalUsers} users</a>)</>);
      }
    }

    return null;
  }

  alerts() {
    let sa = this.state.scheduled_alerts;

    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadUsers();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    /*if (!this.state.alerts.length) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <p>No alert</p>
        </div>
      );
    }*/

    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search listings by address...",
      value,
      onChange: this.onChange,
      className: "form-control",
    };

    return (
      <section class="admin-content">
        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          visible={this.state.videoModal}
          onClose={() => { this.setState({ videoModal: false }) }}
          //width={600}
          height={400}
        >
          <p><iframe src="https://player.vimeo.com/video/499641244" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
        </Rodal>

        <Modal open={this.state.createModal} onClose={this.handleClose}>
          <div style={styles.modal}>
            <Rodal
              width={"90%"}
              customStyles={{ maxWidth: 600, zIndex: 2000 }}
              visible={this.state.userModal}
              onClose={() => { this.setState({ userModal: false }) }}
              //width={500}
              height={300}
            >
              <>
                <h3>Push will be sent to the following users</h3>
                <div style={{ overflow: "auto", height: "80%" }}>
                  <ul>
                    {this.state.users.map(item => (
                      <li>{item.display_name}</li>
                    ))}
                  </ul>
                </div>
              </>
            </Rodal>
            <div style={styles.modalTitle}>
              <h2>Send push notification</h2>
              <div
                style={{
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.setState({ createModal: false });
                }}
              >
                <FiX style={styles.menuIcon} />
              </div>
            </div>

            <Formik
              initialValues={{
                title: null,
                subtitle: null,
                //password: null,
                //phone: null,
              }}
              validate={(values) => {
                let errors = {};

                if (!values.title) {
                  errors.title = "Required";
                }

                if (values.title && values.title.length > 140) {
                  errors.title = "Max length is 140 characters";
                }

                if (values.title && values.title.length < 10) {
                  errors.title = "Min length is 10 characters";
                }

                if (!values.subtitle) {
                  errors.subtitle = "Required";
                }

                if (values.subtitle && values.subtitle.length > 140) {
                  errors.title = "Max length is 140 characters";
                }

                if (values.subtitle && values.subtitle.length < 10) {
                  errors.subtitle = "Min length is 10 characters";
                }

                //if (values.subtitle.length > 140) {
                //  errors.subtitle = "Max length is 140 characters";
                //}

                /*if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length < 6) {
                  errors.password = "Password must be at least 6 characters";
                }

                if (!values.phone) {
                  errors.phone = "Required";
                } else if (values.phone.length < 6) {
                  errors.phone = "Enter a valid phone number";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                */

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (this.state.alertRecipientTag) {
                  const ctags = this.state.currentTags;
                  if (ctags.length == 0) {
                    alert('Please choose some tags');
                    setSubmitting(false);
                    return;
                  }
                } else {
                  if (!this.state.alertRecipient) {
                    alert("Alert recipient is required");
                    setSubmitting(false);
                    return;
                  }
                }

                if (!this.state.alertType) {
                  alert("Alert type is required");
                  setSubmitting(false);
                  return;
                }

                if (
                  this.state.alertType &&
                  this.state.alertType.value == "text"
                ) {
                  if (values.text == "") {
                    alert("Please enter some text");
                    return;
                  }
                }

                if (
                  this.state.alertType &&
                  this.state.alertType.value == "url"
                ) {
                  /*if (typeof values.url != "undefined" && !values.url) {
                    alert("Please enter a valid URL");
                    return;
                  }

                  if (
                    typeof values.url != "undefined" &&
                    values.url.indexOf("http") == 0
                  ) {
                    alert("Please enter a valid URL");
                    return;
                  }*/
                }

                if (
                  this.state.alertType &&
                  this.state.alertType.value == "listing"
                ) {
                  let key = this.state.alertListingKey;
                  let addr = this.state.alertListingAddress;

                  values.key = key;
                  values.address = addr;
                }

                if (!this.state.alertRecipientTag) {
                  values.recipient = this.state.alertRecipient.value;
                }

                values.type = this.state.alertType.value;

                if (this.state.alertRecipientTag) {
                  let use_tags = [];
                  let curr_tags = this.state.currentTags;

                  curr_tags.map(item => {
                    use_tags.push(item.tag);
                  });

                  values.tags = use_tags;
                }

                // Scheduling stuff
                let d = this.state.schedule_date;
                let new_date = [
                  d.getFullYear(),
                  ('0' + (d.getMonth() + 1)).slice(-2),
                  ('0' + d.getDate()).slice(-2)
                ].join('-');

                let sh = this.state.schedule_hour;
                let sm = this.state.schedule_minute;

                String(sh).padStart(2, '0');
                String(sm).padStart(2, '0');

                let stime = sh + ':' + sm + ':00';

                new_date += ' ' + stime;

                if (this.state.showSchedule) {
                  values.timing = "schedule";
                  values.send_date_time = new_date;
                }
                // End of scheduling

                const office_id = this.state?.office?.value ?? null;
                values.office_id = office_id;

                setTimeout(() => {
                  api.sendAlert(
                    values,
                    (response) => {
                      this.setState({
                        createModal: false,
                      });
                      window.showToastMessage("Success", "Alert sent");
                      this.loadAlerts();
                    },
                    (error) => {
                      alert(error.response.data.message);
                    }
                  );

                  //values.send_email = this.state.sendInviteEmail;

                  /*api.createUser(
                    values,
                    (response) => {
                      //alert(JSON.stringify(response));
                      this.setState({
                        createModal: false,
                      });

                      window.showToastMessage("Success", "New user created");
                      this.loadUsers();
                      //this.setState({ toAgent: response.id });
                    },
                    (error) => {
                      alert(error.response.data.message);
                    }
                  );*/

                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  {AppStore.app_data.toggles.officesEnabled && (
                    <p>
                      <label>
                        Office - {JSON.stringify(this.state.office.value)}
                      </label>
                      <Select
                        placeholder="Start typing to select office..."
                        value={this.state.office}
                        options={this.state.offices}
                        onChange={(option) => {
                          this.setState({ office: option });
                        }}
                      />
                    </p>
                  )}
                  <p>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <label>
                        Recipient{" "}
                        {this.state.alertRecipientTag && this.state.loadingTags && (
                          <div class="spinner-border text-warning spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.showIntendedRecipients()}
                      </label>
                      <div>
                        <a href="#" className="text-underline" onClick={(e) => {
                          e.preventDefault();
                          this.setState({ alertRecipientTag: !this.state.alertRecipientTag })
                        }}>
                          {this.state.alertRecipientTag ? "Select user(s)" : "Select tags"}
                        </a>
                      </div>
                    </div>

                    {this.state.alertRecipientTag ? (
                      <>
                        <ManageUserTags
                          plainPicker={true}
                          showAgentTag={true}
                          allTags={this.state.allTags}
                          user={null}
                          currentTags={this.state.currentTags}
                          onAdd={(tag) => {
                            this.addTag(tag);
                          }}
                          onRemove={(tag) => {
                            this.removeTag(tag);

                          }}
                          onCreate={(data) => {
                            //alert('create should be didabled via prop')
                          }}
                        />
                      </>
                    ) : (
                      <Select
                        placeholder="Start typing to select user..."
                        value={this.state.alertRecipient}
                        options={this.state.alertUsers}
                        onInputChange={(val) => { this.handleAgentInputChangeDelayed(val) }}
                        onChange={(option) => {
                          this.setState({ alertRecipient: option });
                        }}
                      />
                    )}

                  </p>

                  <p>
                    <label for="title">Title* (max 140 characters)</label>
                    <input
                      type="text"
                      name="title"
                      class="form-control"
                      id="title"
                      placeholder="Enter your title..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    <span className="form-error" style={{ color: "red", fontWeight: "bold" }}>
                      {errors.title && touched.title && errors.title}
                    </span>
                  </p>

                  <p>
                    <label for="subtitle">Subtitle* (max 140 characters)</label>
                    <input
                      type="text"
                      name="subtitle"
                      class="form-control"
                      id="subtitle"
                      placeholder="Enter your subtitle..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subtitle}
                    />
                    <span className="form-error" style={{ color: "red", fontWeight: "bold" }}>
                      {errors.subtitle && touched.subtitle && errors.subtitle}
                    </span>
                  </p>

                  <p>
                    <label>Alert type</label>
                    <Select
                      value={this.state.alertType}
                      options={alertTypes}
                      onChange={(option) => {
                        this.setState({
                          alertType: option,
                          alertListingKey: null,
                          alertListingAddress: null,
                          value: "",
                        });
                      }}
                    />
                  </p>

                  {this.state.alertType && this.state.alertType.value == "text" && (
                    <p>
                      <label for="text">Text</label>
                      <textarea
                        rows="4"
                        type="text"
                        name="text"
                        class="form-control"
                        id="text"
                        placeholder="Enter text..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.text}
                      />
                      <span className="form-error">
                        {errors.text && touched.text && errors.text}
                      </span>
                    </p>
                  )}

                  {this.state.alertType && this.state.alertType.value == "url" && (
                    <p>
                      <label for="url">URL</label>
                      <input
                        type="text"
                        name="url"
                        class="form-control"
                        id="url"
                        placeholder="Enter URL..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.url}
                      />
                      <span className="form-error">
                        {errors.url && touched.url && errors.url}
                      </span>
                    </p>
                  )}

                  {this.state.alertType &&
                    this.state.alertType.value == "listing" && (
                      <p>
                        <label for="listing">Choose listing</label>
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={(val) =>
                            this.getSuggestionValue(val)
                          }
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                      </p>
                    )}

                  <p>
                    <label>Send</label>
                    <Select
                      value={this.state.scheduleType}
                      options={scheduleTypes}
                      onChange={(option) => {
                        let showSchedule = false;
                        if (option.value == "schedule") {
                          showSchedule = true;
                        }

                        this.setState({
                          scheduleType: option,
                          showSchedule: showSchedule
                        });
                      }}
                    />
                  </p>

                  {this.state.showSchedule && (
                    <div class="row">
                      <div class="col-md-6">
                        <p>
                          <label>Date</label>
                          <DatePicker
                            className="form-control"
                            showIcon
                            minDate={new Date()}
                            selected={this.state.schedule_date}
                            onChange={(date) => {
                              this.setState({ schedule_date: date });
                            }}
                          />
                        </p>
                      </div>

                      <div class="col-md-6">
                        <p>
                          <label>Time</label>

                          <div class="row">
                            <div class="col-md-6">
                              <select className="form-control" value={this.state.schedule_hour} onChange={(e) => {
                                this.setState({ schedule_hour: e.target.value });
                              }}>
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                              </select>
                            </div>
                            <div class="col-md-6">
                              <select className="form-control" value={this.state.schedule_minute} onChange={(e) => {
                                this.setState({ schedule_minute: e.target.value });
                              }}>
                                <option value="0">00</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                              </select>
                            </div>
                          </div>
                        </p>
                      </div>

                      <div class="col-md-12">
                        <p><small>Time now is {moment().tz(AppStore.app_data.tz).format("HH:mm z")}</small></p>
                      </div>
                    </div>
                  )}



                  {/*<p>
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      id="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="form-error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </p>

                  <p>
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      class="form-control"
                      id="phone"
                      placeholder="Phone number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    <span className="form-error">
                      {errors.phone && touched.phone && errors.phone}
                    </span>
                  </p>

                  <p>
                    <label for="password">Password</label>
                    <input
                      type="text"
                      name="password"
                      class="form-control"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span className="form-error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </p>

                  <p>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.sendInviteEmail}
                            onChange={() => {
                              const tog = !this.state.sendInviteEmail;
                              this.setState({
                                sendInviteEmail: tog,
                              });
                            }}
                            value="1"
                          />
                        }
                        label="Send email invite"
                      />
                    </FormGroup>
                    </p>*/}

                  <p>
                    <button
                      class="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {this.state.showSchedule ? "Schedule notification" : "Send notification"}
                    </button>
                  </p>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        <div class="bg-dark">
          <div class="container  m-b-30">
            <div class="row">
              <div class="col-12 text-white p-t-40 p-b-90">
                <div class="float-right">
                  {!AppStore.readOnly && (
                    <button
                      onClick={() => {
                        this.setState({ createModal: true });
                      }}
                      class="btn btn-success"
                    >
                      Send notification
                    </button>
                  )}
                </div>
                <h4 class="">
                  {" "}
                  <span class="btn btn-white-translucent">
                    <i class="icon-placeholder fe fe-bell " />
                  </span>{" "}
                  Push notifications &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                    this.setState({ videoModal: true })
                  }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                </h4>
                {/*<p class="opacity-75 ">
                  We have inherited from Bootstrap 4, with some tweaks we have
                  <br /> created table styles that blend in our design.
                </p>*/}
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-md-12">

              {sa.length > 0 && (
                <div class="card m-b-30">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover ">
                        <thead>
                          <tr>
                            <th>To</th>
                            <th>Alert</th>
                            <th>Type</th>
                            <th>Content</th>
                            <th>Scheduled</th>
                            {UserStore.isAdmin() && <th>Sender</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.scheduled_alerts.map((item) => (
                            <tr>
                              <td>{item.recipient}</td>
                              <td>
                                {item.title}
                                <br />
                                <span class="text-muted">{item.subtitle}</span>
                              </td>
                              <td>
                                {this.showAlertType(item)}
                              </td>
                              <td style={{ maxWidth: 400 }}>{item.meta_value}</td>
                              <td>
                                <span className="badge badge-info" style={{ backgroundColor: "purple", fontWeight: "bold" }}>
                                  {moment(item.schedule_date).format("MMM D, YYYY @ HH:mm")}
                                </span>
                              </td>
                              {UserStore.isAdmin() && (
                                <td>{item.display_name}</td>
                              )}
                              <td>
                                <button className="btn btn-danger" onClick={() => {
                                  if (window.confirm('Remove this scheduled notification?')) {
                                    this.deleteScheduledAlert(item.id);
                                  }
                                }}>Delete</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              <div class="card m-b-30">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>To</th>
                          <th>Alert</th>
                          <th>Type</th>
                          <th>Content</th>
                          <th>Sent</th>
                          {UserStore.isAdmin() && <th>Sender</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.alerts.map((item) => (
                          <tr>
                            <td>{item.recipient}</td>
                            <td>
                              {item.title}
                              <br />
                              <span class="text-muted">{item.subtitle}</span>
                            </td>
                            <td>
                              {this.showAlertType(item)}
                            </td>
                            <td style={{ maxWidth: 400 }}>{item.meta_value}</td>
                            <td>
                              <small>{moment.unix(item.when).fromNow()}</small>
                            </td>
                            {UserStore.isAdmin() && (
                              <td>{item.display_name}</td>
                            )}

                            {/*<td>
                      <Button
                        color="primary"
                        component={Link}
                        to={`/users/${item.id}`}
                      >
                        View
                      </Button>
                    </td>*/}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }

  render() {
    let sa = this.state.scheduled_alerts;

    if (
      AppStore.app_data.toggles.pushNotificationsEnabled &&
      AppStore.app_data.toggles.manualPushEnabled &&
      (UserStore.isAdmin() || AppStore.app_data.toggles.manualPushEnabledAll)
    ) {
      // ok!
    } else {
      return <Redirect to="/" />;
    }

    return (
      <Inner>
        <div class="content">
          {this.alerts()}
        </div>
      </Inner>
    );
  }
}

const styles = {
  modal: {
    backgroundColor: "#fff",
    padding: 20,
    maxWidth: 500,
    width: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
};

export default Alerts;

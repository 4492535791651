import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import { FiX, FiVideo } from "react-icons/fi";
import helpers from "../../lib/helpers";
import { Tooltip } from "react-tippy";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";

@observer
class Pocket extends Component {
  constructor(props) {
    super(props);
    this.state = { logout: false, key: null, error: false, pockets: [], videoModal: false };
  }

  componentDidMount() {
    this.loadPockets();
  }

  loadPockets() {
    if (
      !AppStore.app_data.toggles.pocketListingsEnabled ||
      AppStore.app_data.toggles.pocketListingsAutomated
    ) {
      return null;
    }

    api.getPockets(
      response => {
        this.setState({ pockets: response });
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  checkStatus() {
    if (AppStore.app_data.toggles.pocketListingsEnabled) {
      if (AppStore.app_data.toggles.pocketListingsAutomated) {
        return this.automated();
      } else {
        return this.enabled();
      }
    } else {
      return this.disabled();
    }
  }

  automated() {
    return this.emptyTemplate(
      <>
        <h3 class="">Automated</h3>
        <p>
          Exclusive listings are automated. Please contact{" "}
          <a href="mailto:support@homestack.com" class="text-underline">
            support@homestack.com
          </a>{" "}
          if you have any issues.
        </p>
      </>
    );
  }

  disabled() {
    return this.emptyTemplate(
      <>
        <h3 class="">Not enabled</h3>
        <p>
          Exclusive listings are not enabled for this account. Please contact{" "}
          <a href="mailto:support@homestack.com" class="text-underline">
            support@homestack.com
          </a>{" "}
          to discuss enabling.
        </p>
      </>
    );
  }

  photo(photos) {
    return (
      <div
        style={{
          backgroundColor: "#eee",
          width: 40,
          height: 40,
          borderRadius: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src={photos[0]} width={40} height={40} />
      </div>
    );
  }

  emptyPhoto() {
    return (
      <div
        style={{
          backgroundColor: "#eee",
          width: 40,
          height: 40,
          borderRadius: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <i
          class="fe fe-image"
          style={{ fontWeight: "normal", fontSize: 18, color: "#aaa" }}
        ></i>
      </div>
    );
  }

  enabled() {
    return this.normalTemplate(
      <>
        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          visible={this.state.videoModal}
          onClose={() => { this.setState({ videoModal: false }) }}
          //width={600}
          height={400}
        >
          <p><iframe src="https://player.vimeo.com/video/499645621" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
        </Rodal>
        <div class="table-responsive">
          <table class="table table-hover ">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>Address</th>
                <th>Beds</th>
                <th>Baths</th>
                <th>Type</th>
                <th>Status</th>
                <th>Active</th>
                <th>Added by</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.state.pockets.length == 0 && (
                <tr>
                  <td colSpan={7} className="text-center text-muted">
                    No exclusive listings
                  </td>
                </tr>
              )}
              {this.state.pockets.map(item => (
                <tr>
                  <td>
                    {item.available_globally == 1 && (
                      <Tooltip title="Available globally">
                        <i
                          class="fe fe-globe text-info"
                          style={{ fontWeight: "bold" }}
                        ></i>
                      </Tooltip>
                    )}
                  </td>
                  <td>
                    {item.photos.length > 0
                      ? this.photo(item.photos)
                      : this.emptyPhoto()}
                  </td>
                  <td>{item.full_address}</td>
                  <td>{item.beds}</td>
                  <td>{Math.abs(item.baths)}</td>
                  <td>{helpers.propertyType(item.property_type)}</td>
                  <td>{helpers.propertyStatus(item.status)}</td>
                  <td>
                    {item.active == 1 ? (
                      <i
                        class="fe fe-check text-success"
                        style={{ fontWeight: "bold" }}
                      ></i>
                    ) : (
                      <i
                        class="fe fe-x text-danger"
                        style={{ fontWeight: "bold" }}
                      ></i>
                    )}
                  </td>
                  {item.tp_id ? (<>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      <span class="badge badge-lg badge-info">Automated Listing</span>
                    </td>
                  </>) : (
                    <>
                      <td>{item.display_name}</td>
                      <td>

                        <Link
                          to={`/pocket/${item.listing_key}`}
                          class="btn btn-primary"
                        >
                          Edit
                        </Link>

                      </td>
                      <td>

                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            if (window.confirm("Delete this listing?")) {
                              api.deletePocket(
                                item.listing_key,
                                response => {
                                  window.showToastMessage(
                                    "Success",
                                    "Listing deleted"
                                  );
                                  this.loadPockets();
                                  // Refrsh listings here
                                },
                                error => {
                                  alert(
                                    "An error occurred. Please try again later."
                                  );
                                }
                              );
                            }
                          }}
                        >
                          Delete
                        </button>

                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  normalTemplate(contents) {
    return (
      <>
        <div class="bg-dark">
          <div class="container  m-b-30">
            <div class="row">
              <div class="col-12 text-white p-t-40 p-b-90">
                <div class="float-right">

                  <Link to="/pocket/new" class="btn btn-success">
                    New listing
                  </Link>

                </div>

                <h4 class="">
                  {" "}
                  <span class="btn btn-white-translucent">
                    <i class="icon-placeholder fe fe-home " />
                  </span>{" "}
                  Exclusive listings &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                    this.setState({ videoModal: true })
                  }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-md-12">
              <div class="card m-b-30">
                <div class="card-body">{contents}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  emptyTemplate(contents) {
    return (
      <>
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle fe fe-home " />
                  </div>
                </div>
                <h3>Exclusive listings </h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <div class="row ">
              <div class="col-lg-8 mx-auto  mt-2">
                <div class="card py-3 m-b-30">
                  <div class="card-body">{contents}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  render() {
    if (
      !UserStore.isAdmin() &&
      !AppStore.app_data.toggles.pocketListingsAllowAgents
    ) {
      return <Redirect to="/" />;
    }

    //return <Redirect to="/" />;

    return <Inner>{this.checkStatus()}</Inner>;
  }
}

export default Pocket;

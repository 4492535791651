import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../lib/api";

@observer
class UserMostViewed extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getStats(
      { type: "most_viewed", user_id: this.props.userId },
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ data: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  showData() {
    if (this.state.error) {
      if (!AppStore.app_data.toggles.agentInsightsEnabled) {
        return (
          <div class="card m-b-30">
            <div class="card-header">
              <h5 class="m-0">Most viewed listings in past week</h5>
            </div>
            <div class="card-body ">
              <div
                class="alert alert-border-warning  alert-dismissible fade show"
                role="alert"
              >
                <div class="d-flex">
                  <div class="icon">
                    <i class="icon mdi mdi-alert-circle-outline" />
                  </div>
                  <div class="content">
                    <strong>Upgrade required</strong>
                    <br />
                    Please contact HomeStack support to enable all dashboard
                    features.
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-0">Most viewed listings in past week</h5>
          </div>
          <div class="card-body ">
            <p>No data</p>
          </div>
        </div>
      );
    }

    if (!this.state.data) {
      return (
        <div class="card m-b-30">
          <div class="card-body" style={{ alignSelf: "center" }}>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const item = this.state.data;

    if (!AppStore.app_data.toggles.agentInsightsEnabled) {
      if (item.length > 3) {
        let item_new = [];
        for (let x = 0; x < 4; x++) {
          item_new.push(item[x]);
        }

        return (
          <div class="card m-b-30">
            <div class="card-header">
              <h5 class="m-0">Most viewed listings in past week</h5>
            </div>
            <div
              class="card-body"
              style={{ position: "relative", overflow: "hidden" }}
            >
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 100
                }}
              >
                <div>
                  <div
                    class="alert alert-border-warning  alert-dismissible fade show"
                    role="alert"
                    style={{ zIndex: 100 }}
                  >
                    <div class="d-flex">
                      <div class="icon">
                        <i class="icon mdi mdi-alert-circle-outline" />
                      </div>
                      <div class="content">
                        <strong>Upgrade required</strong>
                        <br />
                        Please contact HomeStack support to enable all dashboard
                        features.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-hover "
                  style={{ filter: "blur(4px)" }}
                >
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Address</th>
                      <th>Views</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item_new.map(listing => (
                      <tr style={{cursor:"pointer"}} onClick={() => {
                        AppStore.showListingModal(listing.data.PropertyID);
                      }}>
                        <td>
                          {listing.photo && (
                            <div class="avatar avatar-sm">
                              <img
                                src={listing.photo}
                                class="avatar-img rounded"
                              />
                            </div>
                          )}
                        </td>
                        <td>
                          {listing.data.FullStreetAddress}
                          <br />
                          {listing.data.City}, {listing.data.State},{" "}
                          {listing.data.ZipCode}
                        </td>
                        <td>{listing.data.frequency}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-0">Most viewed listings in past week</h5>
          </div>
          <div class="card-body ">
            <div
              class="alert alert-border-warning  alert-dismissible fade show"
              role="alert"
            >
              <div class="d-flex">
                <div class="icon">
                  <i class="icon mdi mdi-alert-circle-outline" />
                </div>
                <div class="content">
                  <strong>Upgrade required</strong>
                  <br />
                  Please contact HomeStack support to enable all dashboard
                  features.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div class="card m-b-30">
        <div class="card-header">
          <h5 class="m-0">Most viewed listings in past week</h5>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="col-md-12 my-auto ">
              {item.length == 0 && <p class="text-muted">No results</p>}

              {item.length > 0 && (
                <div class="table-responsive">
                  <table class="table table-hover ">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Address</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map(listing => (
                        <tr style={{cursor:"pointer"}} onClick={() => {
                          AppStore.showListingModal(listing.data.PropertyID);
                        }}>
                          <td>
                            {listing.photo && (
                              <div class="avatar avatar-sm">
                                <img
                                  src={listing.photo}
                                  class="avatar-img rounded"
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {listing.data.FullStreetAddress}
                            <br />
                            {listing.data.City}, {listing.data.State},{" "}
                            {listing.data.ZipCode}
                          </td>
                          <td>{listing.data.frequency}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.showData();
  }
}

export default UserMostViewed;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import Help from "../help/Help";

@observer
class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    window.location.href = "https://info.homestack.com/education";
    return null;

    return (
      <Inner>
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle fe fe-thumbs-up " />
                  </div>
                </div>
                <h3>Training</h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <Help section="training" />
          </div>
        </section>
      </Inner>
    );
  }
}

export default Training;

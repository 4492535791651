import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import Help from "../help/Help";
import moment from "moment";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";

@observer
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bot_new: "",
      chat_list: [],
      current_transcript: null,
      chatModal: false
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getBotMessage(
      (response) => {
        this.setState({ loading: false, bot_new: response.message });
      },
      (error) => {
        //alert("Error:" + JSON.stringify(error.response.data.message));
        alert("An error occurred");
      }
    );

    api.getChatList(
      (response) => {
        this.setState({ chat_list: response.chats });
      },
      (error) => {
        //alert("Error:" + JSON.stringify(error.response.data.message));
        //alert("An error occurred");
      }
    );
  }

  getTranscript(room_id) {
    api.getChatTranscript(
      { room_id: room_id },
      response => {
        this.setState({ current_transcript: response.transcript })
      },
      error => {
        alert("Unable to load transcript");
      }
    );
  }

  updateMessage() {
    api.updateBotMessage(
      this.state.bot_new,
      (response) => {
        window.showToastMessage("Success", "Bot message was saved");
      },
      (error) => {
        window.showToastMessage(
          "Error",
          "Error saving bot message",
          "danger",
          "fe-x"
        );
      }
    );
  }

  downloadFile({ data, fileName, fileType }) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  exportFile(data) {
    let rando = Math.ceil((Math.random() * 10000) + 1);
    this.downloadFile({
      data: data,
      fileName: 'chat_transcript_' + rando + '.txt',
      fileType: 'text/plain',
    })
  }

  render() {
    if (!AppStore.app_data.toggles.chatEnabled) {
      return <Redirect to="/" />;
    }

    const { chat_list } = this.state;

    if (this.state.error) {
      return (
        <Inner>
          <div class="content">
            <div className="centerBox">
              <img src="https://bk.homestack.com/assets/img/not-found.svg" />
              <h2>Unable to load data</h2>
              <p>
                <button
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </button>
              </p>
            </div>
          </div>
        </Inner>
      );
    }

    return (
      <Inner>
        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          visible={this.state.chatModal}
          onClose={() => { this.setState({ chatModal: false, current_transcript: null }) }}
          //width={600}
          height={400}
        >
          <h4>Chat Transcript</h4>
          <textarea style={{ fontFamily: "monospace", fontSize: 12 }} className="form-control" rows="12" value={this.state.current_transcript} />
          {this.state.current_transcript != null && (
            <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.exportFile(this.state.current_transcript)
                }}
              >
                Download transcript
              </button>
            </div>
          )}
        </Rodal>

        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <Help section="chat" />
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle fe fe-message-circle " />
                  </div>
                </div>
                <h3>Chat</h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <div class="row ">
              <div class="col-lg-6">

                {(AppStore.app_data.template != "windermere" || (AppStore.app_data.template == "windermere" && !UserStore.isAdmin())) && (
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h4>New user bot message</h4>
                      <p>
                        When a new user signs up in the app, you can automatically
                        send them a welcome message. Enter the message below to
                        enable.
                      </p>
                      <p>
                        <label for="bot_new">Bot message</label>
                        <textarea
                          id="bot_new"
                          className="form-control"
                          rows={5}
                          placeholder="Welcome to my app..."
                          value={this.state.bot_new}
                          onChange={(e) => {
                            this.setState({ bot_new: e.target.value });
                          }}
                        />
                      </p>
                      <hr />
                      {!AppStore.readOnly && (
                        <p>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.updateMessage();
                            }}
                          >
                            Update bot message
                          </button>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div class="col-lg-6">

                <div class="card py-3 m-b-30">
                  <div class="card-body">
                    <h4>Chat transcripts</h4>
                    {chat_list.length == 0 && (
                      <p className="text-muted">No chats</p>
                    )}

                    <table class="table table-responsive">
                      <thead>
                        <th>Chat</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {chat_list.length == 0 && (
                          <td colspan="2">No chats</td>
                        )}
                        {chat_list.map(item => (
                          <>
                            {item.users.length > 0 && (
                              <tr>
                                <td>
                                  <strong>{item.users.join(", ")}</strong> <small>({item.total_messages} message{item.total_messages > 1 ? "s" : null})</small><br />
                                  <small>Last message sent {moment.unix(item.last_message).format("MMM D YYYY")}</small>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary btn-small"
                                    onClick={() => {
                                      this.getTranscript(item.room_id);
                                      this.setState({ chatModal: true })
                                    }}
                                  >
                                    Get transcript
                                  </button>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </Inner>
    );
  }
}

export default Chat;

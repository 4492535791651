import React, { Component } from "react";
import "../../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AppStore from "../../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../../lib/api";

@observer
class DashStat extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getStats(
      { type: this.props.type },
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ data: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  showData() {
    if (this.state.error) {
      return (
        <div class="card m-b-30">
          <div class="card-body ">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fw-600">Unable to load data</h6>
              </div>
              <div class="col-md-12 my-auto ">
                <a
                  href="#"
                  class="text-primary"
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </a>
              </div>
            </div>
          </div>
        </div>
      );

      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.load();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (!this.state.data) {
      return (
        <div class="card m-b-30">
          <div class="card-body" style={{ alignSelf: "center" }}>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const item = this.state.data;

    let avatar_class = "avatar-title bg-info rounded-circle fe ";
    if (this.props.color) {
      avatar_class =
        "avatar-title bg-" + this.props.color + " rounded-circle fe  ";
    }
    if (this.props.icon) {
      avatar_class += "fe-" + this.props.icon;
    } else {
      avatar_class += "fe-zap";
    }

    return (
      <div class="card m-b-30">
        <div class="card-body">
          <div class="row p-t-20 p-b-20">
            <div class="my-auto col-md-12">
              <div
                class="avatar avatar-sm float-left"
                style={{ marginRight: 10 }}
              >
                <div class={avatar_class} />
              </div>
              <h1
                class={
                  this.props.color ? "text-" + this.props.color : "text-primary"
                }
              >
                {item}
              </h1>
              <p class="m-0 text-muted">{this.props.label}</p>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div class="card m-b-30">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-12">
              <h6 class="fw-600">{this.props.label}</h6>
            </div>
            <div class="col-md-12 my-auto ">
              <h4 class={this.props.color ? this.props.color : "text-primary"}>
                {item}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );

    /*return (
      <h3>
        {item} {this.props.label}
      </h3>
    );*/
  }

  render() {
    return this.showData();
  }
}

const styles = {};

export default DashStat;

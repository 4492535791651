import React, { Component } from "react";
//import "./style.scss";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";

@observer
class Provision extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            app: null,
            loading: true,
            agent_loading: false,
            account_setup: false,
            agent_id: null,

            uri: null,
            provision_id: null,

            display_name: "",
            email: "",
            phone: "",
            password: "",
        };
    }

    componentDidMount() {
        let url = window.location.href; // Get current URL
        let segments = url.split('/'); // Split the URL by '/'
        let lastSegment = segments.pop() || segments.pop(); // Get the last segment
        // Get the 2nd last segment
        let secondLastSegment = segments.pop() || segments.pop(); // Get the last segment

        const query = new URLSearchParams(window.location.search);
        const prov_id = query.get("prov_id");

        let uri = secondLastSegment;// "hsrb"; //lastSegment;
        let provision_id = lastSegment;//prov_id;

        this.setState({ uri, provision_id });

        api.getProvApp(uri, provision_id, response => {
            this.setState({ app: response, loading: false });
        }, error => {
            this.setState({ error: error?.response?.data?.message ?? "Unknown error", loading: false });
        });
    }

    saveAgent() {
        const { display_name, email, phone, password, uri, provision_id } = this.state;

        if (display_name.length < 3) {
            alert("Please enter a valid name");
            return;
        }
        if (email.length < 3) {
            alert("Please enter a valid email");
            return;
        }
        if (phone.length < 3) {
            alert("Please enter a valid phone number");
            return;
        }
        if (password.length < 6) {
            alert("Please enter a valid password");
            return;
        }

        this.setState({ agent_loading: true });

        api.agentProv(uri, {
            provision_id: provision_id,
            display_name: display_name,
            email: email,
            phone: phone,
            password: password,
        }, response => {
            this.setState({ agent_loading: false, account_setup: true, agent_id: response.guid });
        }, error => {
            alert(error?.response?.data?.message);
            this.setState({ agent_loading: false });
        });
    }

    render() {
        const { app } = this.state;

        if (this.state.loading) {
            return (
                <div
                    class="d-flex w-100 justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        if (this.state.error) {
            return (
                <div style={{ padding: 20, height: "100vh" }}>
                    <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <p>
                            <img src="/assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
                        </p>
                        <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>
                            Error: {this.state.error}
                        </p>
                    </div>
                </div>
            );
        }

        if (!app.enabled) {
            return (
                <div style={{ padding: 20, height: "100vh" }}>
                    <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <p>
                            <img src="/assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
                        </p>
                        <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>
                            Self-provisioning is not enabled for this app.
                        </p>
                    </div>
                </div>
            );
        }

        if (!app.payment_url) {
            return (
                <div style={{ padding: 20, height: "100vh" }}>
                    <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <p>
                            <img src="/assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
                        </p>
                        <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>
                            Setup is not complete for this app.
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div style={{ height: "100vh" }} className="row">
                <div className="col-md-4 d-none d-lg-block" style={{ backgroundColor: "#fff" }}>
                    <div style={{
                        display: "flex",
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <p>
                            <img src={app.theme.logo} width="160" alt="" style={{ borderRadius: 16 }} />
                        </p>
                        <p class="admin-bggrand-content" style={{ fontWeight: "bold", fontSize: 18, marginLeft: 0, padding: 0 }}>
                            {app.name}
                        </p>
                    </div>
                </div>

                <div className="col-sm-12 col-md-8">
                    <div style={{
                        display: "flex",
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        padding: 20
                    }}>
                        <div className="d-block d-lg-none" style={{ width: "100%", marginBottom: 20 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ marginRight: 12 }}>
                                    <img src={app.theme.logo} width="40" alt="" style={{ borderRadius: 8 }} />
                                </div>
                                <div>
                                    <span class="admin-bggrand-content" style={{ fontWeight: "bold", marginLeft: 0, padding: 0 }}>
                                        {app.name}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <h1>Join {app.name} as an agent</h1>
                        <p>Fill in your details below and you will then be taken to a payment page to gain access to the app and your agent dashboard.</p>


                        <div style={{ width: "80%", maxWidth: 400, marginTop: 20 }}>
                            {!this.state.account_setup && !this.state.agent_loading && (
                                <>
                                    <p>
                                        <label for="display_name"><strong>Full name</strong></label>
                                        <input type="text" className="form-control" id="display_name" placeholder="Enter your full name"
                                            value={this.state.display_name}
                                            onChange={e => {
                                                this.setState({ display_name: e.target.value });
                                            }} />
                                    </p>

                                    <p>
                                        <label for="email"><strong>Email</strong></label>
                                        <input type="email" className="form-control" id="email" placeholder="you@example.com"
                                            value={this.state.email}
                                            onChange={e => {
                                                this.setState({ email: e.target.value });
                                            }} />
                                    </p>

                                    <p>
                                        <label for="phone"><strong>Phone number</strong></label>
                                        <input type="tel" className="form-control" id="phone" placeholder="555 123 456"
                                            value={this.state.phone}
                                            onChange={e => {
                                                this.setState({ phone: e.target.value });
                                            }} />
                                    </p>

                                    <p>
                                        <label for="password"><strong>Choose a password (min 6 character)</strong></label>
                                        <input type="password" className="form-control" id="password" placeholder="Password..."
                                            value={this.state.password}
                                            onChange={e => {
                                                this.setState({ password: e.target.value });
                                            }} />
                                    </p>

                                    <div style={{ marginTop: 40 }}>
                                        <button className="btn btn-lg btn-primary" onClick={() => {
                                            this.saveAgent();
                                        }}>Save agent details</button>
                                    </div>
                                </>
                            )}

                            {this.state.agent_loading && (
                                <div
                                    class=""
                                //style={{ height: "100vh" }}
                                >
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}

                            {this.state.account_setup && this.state.agent_id && (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ backgroundColor: "#8be036", width: 40, height: 40, borderRadius: 20, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 12 }}>
                                            <i class="icon-placeholder fe fe-check " style={{ color: "#fff", fontSize: 28 }} />
                                        </div>

                                        <h3>Agent details saved</h3>
                                    </div>

                                    <div style={{ marginTop: 40 }}>
                                        <a href={app.payment_url + "?reference=agent_" + this.state.agent_id} className="btn btn-lg btn-primary">Go to payment page &rarr;</a>
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    text: {
        fontSize: 16,
    },
}

export default Provision;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import AppStore from "../../stores/AppStore";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import Dropzone from "react-dropzone";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

@observer
class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: null,
      error: false,
      toAgents: false,
      agent_password: "",
      offices: []
    };
    //alert(JSON.stringify(props));
  }

  componentDidMount() {
    this.loadAgents(this.props.match.params.agentId);
    this.loadOffices();
  }

  loadOffices() {
    if (!AppStore.app_data.toggles.officesEnabled) {
      return;
    }

    api.getOffices(response => {
      let data = response.offices
      //this.setState({ offices: response.offices });

      function buildTree(items, parentId = "0") {
        return items
          .filter(item => item.parent_id === parentId)
          .map(item => ({ ...item, children: buildTree(items, item.id) }));
      }

      function flattenTree(items, depth = 0) {
        let flat = [];
        items.forEach(item => {
          flat.push({ ...item, depth, padding: `${depth * 10}` }); // Add item with depth and padding
          if (item.children) {
            flat = [...flat, ...flattenTree(item.children, depth + 1)]; // Flatten children with incremented depth
          }
        });
        return flat.map(({ children, ...item }) => item); // Return flat array without children property
      }

      const tree = buildTree(data); // Build hierarchical tree structure
      const orderedFlatArray = flattenTree(tree); // Flatten the tree back into an array with order preserved

      this.setState({
        offices: orderedFlatArray,
      });
    }, error => {
      //
    });
  }

  loadAgents(agentId) {
    api.getAgents(
      null,
      null,
      null,
      (response) => {
        if (!response) {
          this.setState({ error: true });
        } else {
          this.setState({ agent: response.agents });
        }
      },
      (error) => {
        this.setState({ error: true });
      },
      agentId
    );
  }

  agents() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadAgents(this.props.match.params.agentId);
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (!this.state.agent) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const item = this.state.agent;

    return (
      <section class="admin-content">
        <div class="bg-dark">
          <div class="container  m-b-30">
            <div class="row">
              <div class="col-12 text-white p-t-40 p-b-90">
                <h4 class="">
                  {" "}
                  <span class="btn btn-white-translucent">
                    <i class="icon-placeholder fe fe-briefcase " />
                  </span>{" "}
                  Edit {item.display_name}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-body">
                  <Formik
                    initialValues={{
                      display_name: item.display_name,
                      email: item.email,
                      office_id: item.office?.id,
                      crm_email: item.crm_email,
                      phone: item.phone,
                      web: item.web,
                      bio: item.bio,
                      licenseDisplay: item.license.licenseDisplay,
                      licenseNumber: item.license.licenseNumber,
                      licensePrefix: item.license.licensePrefix,
                      active: item.active,
                    }}
                    validate={(values) => {
                      let errors = {};

                      if (!values.display_name) {
                        errors.display_name = "Required";
                      }

                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));

                        api.updateProfile(
                          values,
                          (response) => {
                            this.forceUpdate();
                            //alert("Profile saved");
                            window.showToastMessage(
                              "Success",
                              "Profile was saved"
                            );
                          },
                          (error) => {
                            //alert("Error saving profile");
                            window.showToastMessage(
                              "Error",
                              "Error saving profile",
                              "danger",
                              "fe-x"
                            );
                          },
                          item.id
                        );

                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <p>
                          <label for="display_name">Name</label>
                          <input
                            type="text"
                            name="display_name"
                            class="form-control"
                            id="display_name"
                            placeholder="Agent name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.display_name}
                          />
                          <span className="form-error">
                            {errors.display_name &&
                              touched.display_name &&
                              errors.display_name}
                          </span>
                        </p>

                        <p>
                          <label for="email">Email</label>
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            id="email"
                            placeholder="Agent email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="form-error">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </p>

                        <p>
                          <label for="phone">Phone</label>
                          <input
                            type="text"
                            name="phone"
                            class="form-control"
                            id="phone"
                            placeholder="Agent phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                          <span className="form-error">
                            {errors.phone && touched.phone && errors.phone}
                          </span>
                        </p>

                        <p>
                          <label for="web">Website</label>
                          <input
                            type="text"
                            name="web"
                            class="form-control"
                            id="web"
                            placeholder="Agent website"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.web}
                          />
                          <span className="form-error">
                            {errors.web && touched.web && errors.web}
                          </span>
                        </p>

                        <p>
                          <label for="bio">Biography</label>
                          {/*<input
                            type="text"
                            name="bio"
                            class="form-control"
                            id="bio"
                            placeholder="Agent bio"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bio}
                            maxLength={200}
                            />*/}
                          <textarea
                            type="text"
                            name="bio"
                            class="form-control"
                            id="bio"
                            placeholder="Agent bio"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bio}
                            maxLength={2000}
                            rows={3}
                          />
                          <span className="form-error">
                            {errors.bio && touched.bio && errors.bio}
                          </span>
                        </p>

                        {AppStore.app_data.toggles.officesEnabled && (
                          <>
                            <hr />
                            <div class="form-group">
                              <label for="office_id">Office</label>
                              <select name="office_id" id="office_id" class="form-control" onChange={e => {
                                this.setState({ office_id: e.target.value });
                                values.office_id = e.target.value;
                              }}>
                                <option value="0">None</option>
                                {this.state.offices.map(office => {
                                  let spacer = "";
                                  if (office.padding > 0) {
                                    spacer = "-".repeat(parseInt(office.padding) / 10);
                                    spacer += " ";
                                  }

                                  let sel = false;

                                  if (values.office_id == office.id) {
                                    sel = true;
                                  }

                                  return (
                                    <option value={office.id} selected={sel}>
                                      {spacer}{office.name}
                                    </option>
                                  );

                                })}
                              </select>
                            </div>
                          </>
                        )}

                        {AppStore.app_data.toggles.crmEmailEnabled && (
                          <>
                            <hr />

                            <p>
                              <label for="crm_email">CRM Email</label>
                              <input
                                type="email"
                                name="crm_email"
                                class="form-control"
                                id="crm_email"
                                placeholder="CRM email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.crm_email}
                              />
                              <span className="form-error">
                                {errors.crm_email &&
                                  touched.crm_email &&
                                  errors.crm_email}
                              </span>
                            </p>

                            <p>
                              <small>
                                For use when sending new user agents directly to
                                CRM by email.
                              </small>
                            </p>
                          </>
                        )}

                        <hr />

                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={values.licenseDisplay}
                                onChange={() => {
                                  //form.setFieldValue("email", "wwooo");
                                  values.licenseDisplay = !values.licenseDisplay;
                                  this.forceUpdate();
                                }}
                              //onChange={handleChange}
                              //value="1"
                              />
                            }
                            label="Display license details"
                          />
                        </FormGroup>

                        {values.licenseDisplay && (
                          <div>
                            <p>
                              <label for="licensePrefix">
                                License Prefix (if required)
                              </label>
                              <input
                                type="text"
                                name="licensePrefix"
                                class="form-control"
                                id="licensePrefix"
                                placeholder="Agent license prefix"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.licensePrefix}
                              />
                              <span className="form-error">
                                {errors.licensePrefix &&
                                  touched.licensePrefix &&
                                  errors.licensePrefix}
                              </span>
                            </p>

                            <p>
                              <label for="licenseNumber">License #</label>
                              <input
                                type="text"
                                name="licenseNumber"
                                class="form-control"
                                id="licenseNumber"
                                placeholder="Agent license #"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.licenseNumber}
                              />
                              <span className="form-error">
                                {errors.licenseNumber &&
                                  touched.licenseNumber &&
                                  errors.licenseNumber}
                              </span>
                            </p>
                          </div>
                        )}

                        <hr />

                        {item.self_provisioned ? (
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled
                                  color="primary"
                                  checked={values.active}
                                  onChange={() => {

                                  }}
                                //onChange={handleChange}
                                //value="1"
                                />
                              }
                              label="Active (will show in app if active)"
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={values.active}
                                  onChange={() => {
                                    //form.setFieldValue("email", "wwooo");
                                    values.active = !values.active;
                                    this.forceUpdate();
                                  }}
                                //onChange={handleChange}
                                //value="1"
                                />
                              }
                              label="Active (will show in app if active)"
                            />
                          </FormGroup>
                        )}

                        <p>
                          <small>
                            If an agent has users tied to them, making them
                            inactive will no longer show them for their users
                            and they will be prompted to choose another agent.
                          </small>
                        </p>

                        <hr />

                        {AppStore.app_data.template != "windermere" && (
                          <p>
                            <button
                              class="btn btn-primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </p>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Photo</h5>
                </div>
                <div class="card-body">
                  <Dropzone
                    multiple={false}
                    accept={"image/jpeg, image/png"}
                    onDrop={(acceptedFiles) => {
                      const reader = new FileReader();

                      reader.onabort = () => alert("file reading was aborted");
                      reader.onerror = () => alert("file reading has failed");
                      reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader.result;

                        api.uploadAvatar(
                          item.id,
                          binaryStr,
                          (response) => {
                            //this.setState({ uploading: false, photo: response.photo });
                            //alert("ok! " + JSON.stringify(response));
                            this.loadAgents(this.props.match.params.agentId);
                            //saveUserData(response.user);
                          },
                          (error) => {
                            //this.setState({ uploading: false });
                            // alert("An error occurred");
                            window.showToastMessage(
                              "Error",
                              "An error occurred",
                              "danger",
                              "fe-x"
                            );
                          }
                        );
                      };

                      acceptedFiles.forEach((file) =>
                        reader.readAsArrayBuffer(file)
                      );
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {item.photo ? (
                            <div
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: 5,
                                padding: 10,
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.photo}
                                style={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 60,
                                }}
                              />
                              <p style={{ marginTop: 10, color: "#aaa" }}>
                                Click to upload or drag new file.
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px dotted #ddd",
                                borderRadius: 5,
                                height: 200,
                                padding: 10,
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ marginTop: 10, color: "#aaa" }}>
                                Drag &amp; drop a photo here, or click to
                                select.
                              </p>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>

                  {item.photo && (
                    <div>
                      <p style={{ marginTop: 10 }}>
                        {AppStore.app_data.template != "windermere" && (
                          <button
                            class="btn btn-danger"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this photo?"
                                )
                              ) {
                                api.deleteAvatar(
                                  item.id,
                                  (response) => {
                                    //this.setState({ uploading: false, photo: response.photo });
                                    //alert("ok! " + JSON.stringify(response));
                                    this.loadAgents(
                                      this.props.match.params.agentId
                                    );
                                    //saveUserData(response.user);
                                  },
                                  (error) => {
                                    //this.setState({ uploading: false });
                                    //alert("An error occurred");
                                    window.showToastMessage(
                                      "Error",
                                      "An error occurred",
                                      "danger",
                                      "fe-x"
                                    );
                                  }
                                );
                              }
                            }}
                          >
                            Remove photo
                          </button>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {AppStore.app_data.template != "windermere" && !UserStore.isManager() && !item.self_provisioned && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Delete agent</h5>
                    <p class="text-muted">
                      This cannot be reversed. All associated users will be
                      prompted to select new agents.
                    </p>
                  </div>
                  <div class="card-body">
                    <p>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this agent? You cannot undo this!"
                            )
                          ) {
                            api.deleteAgent(
                              this.props.match.params.agentId,
                              (response) => {
                                this.setState({ toAgents: true });
                              },
                              (error) => {
                                window.showToastMessage(
                                  "Error",
                                  "An error occurred",
                                  "danger",
                                  "fe-x"
                                );
                              }
                            );
                          }
                        }}
                        class="btn btn-danger"
                      >
                        Delete agent
                      </button>
                    </p>
                  </div>
                </div>
              )}

              {AppStore.app_data.template != "windermere" && !UserStore.isManager() && !item.self_provisioned && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Change agent password</h5>
                  </div>
                  <div class="card-body">
                    <p>
                      <label for="agent_password">New password</label>
                      <input type="text" name="agent_password" value={this.state.agent_password} className="form-control" placeholder="Minimum 6 characters" onChange={(e) => {
                        this.setState({ agent_password: e.target.value });
                      }} />
                    </p>
                    <hr />
                    <p>
                      <button
                        class="btn btn-primary"
                        type="submit"
                        onClick={() => {
                          let pwd = this.state.agent_password
                          if (pwd.length < 6) {
                            alert('Passwords must be at least 6 characters');
                          } else {
                            api.updatePassword(
                              { agent_password: pwd },
                              (response) => {
                                this.forceUpdate();
                                window.showToastMessage(
                                  "Success",
                                  "Password was saved"
                                );
                              },
                              (error) => {
                                //alert("Error saving profile");
                                window.showToastMessage(
                                  "Error",
                                  "Error saving password",
                                  "danger",
                                  "fe-x"
                                );
                              },
                              item.id
                            );
                          }
                        }}
                      >
                        Update
                      </button>
                    </p>
                  </div>
                </div>
              )}

              {AppStore.app_data.template != "windermere" && !UserStore.isManager() && !item.self_provisioned && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Resend agent creation email</h5>
                  </div>
                  <div class="card-body">
                    <p>This will resend the initial email that was sent on creation of the new agent.</p>
                    <p>
                      <button className="btn btn-primary" onClick={() => {
                        api.resendAgentEmail(
                          (response) => {
                            this.forceUpdate();
                            window.showToastMessage(
                              "Success",
                              "Email was sent"
                            );
                          },
                          (error) => {
                            //alert("Error saving profile");
                            window.showToastMessage(
                              "Error",
                              "Error sending email",
                              "danger",
                              "fe-x"
                            );
                          },
                          item.id
                        );
                      }}>Re-send email</button>
                    </p>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    if (!UserStore.isAdmin() && !UserStore.isManager()) {
      return <Redirect to="/" />;
    }

    if (this.state.toAgents) {
      return <Redirect to={"/agents"} />;
    }

    const user = UserStore.user;

    return <Inner>{this.agents()}</Inner>;
  }
}

export default withRouter(Agent);

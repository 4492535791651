import React, { Component } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Inner from "../template/Inner";
import api from "../../lib/api";
import moment from "moment";
import { FiX, FiVideo } from "react-icons/fi";
import Tag from "./components/Tag";
import CreateTag from "./components/CreateTag";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";

@observer
class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            tags: [],
            editModal: false,
            edit: null,
            videoModal: false
        };
    }

    componentDidMount() {
        this.loadTags();
    }

    loadTags() {
        api.loadAllUserTags(
            (response) => {
                this.setState({ tags: response.tags, loading: false })
                //this.loadUser(this.props.match.params.userId);
            },
            (error) => {
                this.setState({ tags: [], loading: false, error: true })
                //alert("Error loading tags");
            },
            true
        );
    }

    createTag(data) {
        let col = null;
        if (data.color && data.color.value) {
            col = data.color.value;
        }

        api.createUserTag(
            {
                tag_id: data.id,
                user_id: this.props.match.params.userId,
                name: data.name,
                color: col,
                super_tag: data.super_tag
            },
            (response) => {
                //this.setState({ allTags: response.tags })
                //this.loadUser(this.props.match.params.userId);
                this.loadTags();
            },
            (error) => {
                //alert("Error loading tags");
            }
        );
    }

    info(tag) {
        return (
            <p>
                {tag.system_tag == 1 && (
                    <small>
                        <strong>System tag</strong><br />
                    </small>
                )}
                {tag.super_tag == 1 && !UserStore.isAdmin() && (
                    <small>
                        <strong>Created by account admin</strong><br />
                    </small>
                )}
                {tag.super_tag == 1 && UserStore.isAdmin() && (
                    <small>
                        <strong>Available to all agents</strong><br />
                    </small>
                )}
                {tag.tag_desc}
            </p>
        );
    }

    editButton(tag) {
        if (tag.system_tag == 1) {
            return null;
        }

        if (tag.super_tag == 1 && !UserStore.isAdmin()) {
            return null;
        }

        return (
            <button
                className="btn btn-primary"
                onClick={() => {
                    this.setState({
                        edit: tag,
                        editModal: true
                    })
                }}
            >
                Edit
            </button>
        );
    }

    deleteButton(tag) {
        if (tag.system_tag == 1) {
            return null;
        }

        if (tag.super_tag == 1 && !UserStore.isAdmin()) {
            return null;
        }

        return (
            <button
                className="btn btn-danger"
                onClick={() => {
                    let extra = "";
                    if (tag.use_count > 0) {
                        extra = " This tag is currently associated with " + tag.use_count + " user(s)";
                        if (UserStore.isAdmin() && (tag.use_count_all - tag.use_count) > 0) {
                            extra += " and " + (tag.use_count_all - tag.use_count) + " user(s) from other agents.";
                        }
                    } else {
                        if (UserStore.isAdmin() && tag.use_count_all > 0) {
                            extra = " This tag is currently associated with " + tag.use_count_all + " user(s) from other agents.";
                        }
                    }
                    if (window.confirm("Delete this tag?" + extra)) {
                        api.deleteUserTag(
                            {
                                tag_id: tag.id,
                            },
                            (response) => {
                                //this.setState({ allTags: response.tags })
                                //this.loadUser(this.props.match.params.userId);
                                this.loadTags();
                            },
                            (error) => {
                                //alert("Error loading tags");
                            }
                        );
                    }
                }}
            >
                Delete
            </button>
        );
    }

    tags() {
        if (this.state.error) {
            return (
                <div className="centerBox">
                    <img src="https://bk.homestack.com/assets/img/not-found.svg" />
                    <h2>Unable to load data</h2>
                    <p>
                        <button
                            onClick={() => {
                                this.setState({ error: false });
                                this.loadTags();
                            }}
                        >
                            Try again
                        </button>
                    </p>
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div
                    class="d-flex w-100 justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <section class="admin-content">
                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <div class="float-right">
                                    {!AppStore.readOnly && (
                                        <button
                                            onClick={() => {
                                                this.setState({ editModal: true });
                                            }}
                                            class="btn btn-success"
                                        >
                                            Create tag
                                        </button>
                                    )}
                                </div>
                                <h4 class="">
                                    {" "}
                                    <span class="btn btn-white-translucent">
                                        <i class="icon-placeholder fe fe-tag " />
                                    </span>{" "}
                                    Tags &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                                        this.setState({ videoModal: true })
                                    }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container pull-up">
                    <Rodal
                        width={"90%"}
                        customStyles={{ maxWidth: 600 }}
                        visible={this.state.videoModal}
                        onClose={() => { this.setState({ videoModal: false }) }}
                        //width={600}
                        height={400}
                    >
                        <p><iframe src="https://player.vimeo.com/video/703329721" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
                    </Rodal>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card m-b-30">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover ">
                                            <thead>
                                                <tr>
                                                    <th>Tag</th>
                                                    <th>Users</th>
                                                    <th>Info</th>
                                                    <th></th>
                                                    <th></th>
                                                    {/*UserStore.isAdmin() && <th>Sender</th>*/}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.tags.map((item) => (
                                                    <tr>
                                                        <td>
                                                            <Link to={"users?tag=" + item.tag}>
                                                                <Tag
                                                                    displayOnly={true}
                                                                    tag={item}

                                                                />
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            {item.use_count}
                                                            {UserStore.isAdmin() && item.super_tag == 1 && item.use_count != item.use_count_all && (
                                                                <>
                                                                    <br />
                                                                    <small>(All agents: {item.use_count_all})</small>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {this.info(item)}
                                                        </td>
                                                        <td>
                                                            {!AppStore.readOnly && this.editButton(item)}
                                                        </td>
                                                        <td>
                                                            {!AppStore.readOnly && this.deleteButton(item)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    render() {
        if (AppStore.app_data.stack_id != 1) {
            //return null;
        }

        return (
            <Inner>
                <div class="content">{this.tags()}</div>

                <Rodal
                    width={"90%"}
                    customStyles={{ maxWidth: 600 }}
                    visible={this.state.editModal}
                    onClose={() => { this.setState({ editModal: false, edit: null }) }}
                    // width={600}
                    height={400}
                >
                    <CreateTag
                        edit={this.state.edit}
                        addTag={(tag) => {
                            this.setState({ editModal: false })
                            this.createTag(tag);
                        }}
                    />
                </Rodal>
            </Inner>
        );
    }
}

const styles = {
    modal: {
        backgroundColor: "#fff",
        padding: 20,
        maxWidth: 500,
        width: "90%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
    },
    modalTitle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
};

export default Tags;

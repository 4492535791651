import React, { Component } from "react";
import "./style.scss";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";

import { Redirect, Link } from "react-router-dom";

import { observer } from "mobx-react";

// TODO:
// Split CSS into SCSS bits like reset/base etc

@observer
class FubError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorType: null,
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const error = query.get("err"); // Gets ?token=xxx
        if (error) {
            this.setState({ errorType: error });
        }
    }

    error(errorType) {
        if (errorType == "person") {
            return (
                <div>
                    <p style={styles.text}>
                        <strong>Person not found</strong>
                    </p>
                    <p style={styles.text}>
                        Unable to find this person in HomeStack.
                    </p>
                </div>
            );
        }

        if (errorType == "user") {
            return (
                <div>
                    <p style={styles.text}>
                        <strong>User not found</strong>
                    </p>
                    <p style={styles.text}>
                        Unable to find a user for this account in HomeStack.
                    </p>
                </div>
            );
        }

        if (errorType == "account") {
            return (
                <div>
                    <p style={styles.text}>
                        <strong>No Account</strong>
                    </p>
                    <p style={styles.text}>
                        Sign up with HomeStack custom mobile app integrations. <a href="https://www.homestack.com">Visit HomeStack to learn more</a>.
                    </p>
                </div>
            );
        }

        if (errorType == "auth") {
            return (
                <div>
                    <p style={styles.text}>
                        <strong>Unauthorized</strong>
                    </p>
                    <p style={styles.text}>
                        This account is not authorized for HomeStack embed. Please contact support if you feel this is in error.
                    </p>
                </div>
            );
        }

        return (
            <div>
                <p style={styles.text}>
                    <strong>Not found</strong>
                </p>
                <p style={styles.text}>Unable to load this page or user.</p>
                <p style={styles.text}>
                    For common reasons this might occur, <a href="https://homestack.zendesk.com/hc/en-us/articles/10395663819667-Follow-Up-Boss" target="_blank">please check our FAQ</a>.
                </p>
            </div>
        );
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <div>
                    <p>
                        <img src="assets/img/logo@2x.png" width="40" alt="" style={{ borderRadius: 8 }} />
                    </p>
                    <p class="admin-brand-content" style={{ marginLeft: 0, padding: 0 }}>Homestack</p>

                    {this.error(this.state.errorType)}
                </div>
            </div>
        );
    }
}

const styles = {
    text: {
        fontSize: 16,
    },
}

export default FubError;

import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import moment from "moment";

@observer
class UserRegistrations extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getStats(
      { type: "app_recent" },
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ data: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  showData() {
    if (this.state.error) {
      return (
        <div class="card m-b-30">
          <div class="card-body ">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fw-600">Unable to load data</h6>
              </div>
              <div class="col-md-12 my-auto ">
                <a
                  href="#"
                  class="text-primary"
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.data) {
      return (
        <div class="card m-b-30">
          <div class="card-body" style={{ alignSelf: "center" }}>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const item = this.state.data;

    return (
      <div class="card m-b-30">
        <div class="card-header">
          <h5 class="m-0">Recent users joined</h5>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="col-md-12 my-auto ">
              {item.length == 0 && <p class="text-muted">No results</p>}

              {item.length > 0 && (
                <div class="table-responsive">
                  <table class="table table-hover ">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Name</th>
                        <th>Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map(user => (
                        <tr>
                          <td>
                            {user.photo ? (
                              <div class="avatar avatar-sm">
                                <img
                                  src={user.photo}
                                  class="avatar-img rounded-circle"
                                />
                              </div>
                            ) : (
                              <div class="avatar avatar-sm">
                                <span class="avatar-title rounded-circle ">
                                  {user.display_name
                                    .split(" ")
                                    .map((n, i, a) =>
                                      i === 0 || i + 1 === a.length
                                        ? n[0]
                                        : null
                                    )
                                    .join("")}
                                </span>
                              </div>
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/users/${user.id}`}
                              className="text-underline"
                            >
                              {user.display_name}
                            </Link>
                          </td>
                          <td>
                            <small>
                              {moment.unix(user.created_epoch).fromNow()}
                            </small>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.showData();
  }
}

export default UserRegistrations;

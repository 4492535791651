import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../stores/AppStore";
import FilterStore from "../../stores/FilterStore";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import moment from "moment";
//import Button from "../components/Button";
//import Slider from "rc-slider";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import MapHelpers from "../../lib/map_helpers";
import { reaction } from "mobx";
import { Check } from "react-feather";

const config = {
    filters: {
        houseMax: 5000000,
        leaseMax: 10000,
        houseStep: 25000,
        leaseStep: 500
    }
};

//const Range = Slider.Range;

@observer
class SavedSearchFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldFilters: null,
            scrollEnabled: true,
            domValue: null,
            sortValue: null,
            multiSteps: config.filters.houseStep,
            multiMax: config.filters.houseMax,
            multiSliderValue: [0, config.filters.houseMax],
            yearSteps: 1,
            yearMax: moment().year(),
            yearMin: 1900,
            yearValue: [1900, moment().year()],
            lotSteps: 100,
            lotMax: 10000,
            lotMin: 0,
            lotValue: [0, 10000],
            sqftSteps: 100,
            sqftMax: 10000,
            sqftMin: 0,
            sqftValue: [0, 10000],
            priceEntryManual: false,
            priceEntryManualMin: "$",
            priceEntryManualMax: "$",
            sqftEntryManual: false,
            sqftEntryManualMin: "",
            sqftEntryManualMax: ""
        };

        // Make sure we don't look for open if it's disabled
        if (!AppStore.app_data.toggles.statusOpenEnabled) {
            FilterStore.updateFilterObject("status", "open", false);
        }

        this.sliderChangePrice = this.sliderChangePrice.bind(this);
        this.sliderChangeSqft = this.sliderChangeSqft.bind(this);
        this.sliderChangeLot = this.sliderChangeLot.bind(this);
        this.sliderChangeYear = this.sliderChangeYear.bind(this);
    }

    componentDidMount() {
        this.setState({
            oldFilters: FilterStore.getFilters(),
            multiSliderValue: [
                FilterStore.filters.price.from
                    ? FilterStore.filters.price.from
                    : 0,
                FilterStore.filters.price.to
                    ? FilterStore.filters.price.to
                    : FilterStore.filters.status.lease
                        ? config.filters.leaseMax
                        : config.filters.houseMax
            ],
            priceEntryManualMin: FilterStore.filters.price.from
                ? "$" + FilterStore.filters.price.from.toString()
                : "$0",
            priceEntryManualMax: FilterStore.filters.price.to
                ? "$" + FilterStore.filters.price.to.toString()
                : FilterStore.filters.status.lease
                    ? "$" + config.filters.leaseMax.toString()
                    : "$" + config.filters.houseMax.toString(),
            sqftEntryManualMin: FilterStore.filters.sqft.from
                ? FilterStore.filters.sqft.from.toString()
                : "0",
            sqftEntryManualMax: FilterStore.filters.sqft.to
                ? FilterStore.filters.sqft.to.toString()
                : this.state.sqftMax.toString(),
            multiSteps: FilterStore.filters.status.lease
                ? config.filters.leaseStep
                : config.filters.houseStep,
            multiMax: FilterStore.filters.status.lease
                ? config.filters.leaseMax
                : config.filters.houseMax,
            yearValue: [
                FilterStore.filters.year_built.from
                    ? FilterStore.filters.year_built.from
                    : 1900,
                FilterStore.filters.year_built.to
                    ? FilterStore.filters.year_built.to
                    : moment().year()
            ],
            lotValue: [
                FilterStore.filters.lot_size.from
                    ? FilterStore.filters.lot_size.from
                    : 0,
                FilterStore.filters.lot_size.to
                    ? FilterStore.filters.lot_size.to
                    : 10000
            ],
            sqftValue: [
                FilterStore.filters.sqft.from
                    ? FilterStore.filters.sqft.from
                    : 0,
                FilterStore.filters.sqft.to
                    ? FilterStore.filters.sqft.to
                    : 10000
            ],
            domValue: FilterStore.filters.dom.to,
            sortValue: FilterStore.filters.sort
        });

        reaction(
            () => AppStore.modals.filters.visible,
            () => {
                if (AppStore.modals.filters.visible) {
                    this.setState({
                        oldFilters: FilterStore.getFilters(),
                        multiSliderValue: [
                            FilterStore.filters.price.from
                                ? FilterStore.filters.price.from
                                : 0,
                            FilterStore.filters.price.to
                                ? FilterStore.filters.price.to
                                : FilterStore.filters.status.lease
                                    ? config.filters.leaseMax
                                    : config.filters.houseMax
                        ],
                        priceEntryManualMin: FilterStore.filters.price.from
                            ? "$" + FilterStore.filters.price.from.toString()
                            : "$0",
                        priceEntryManualMax: FilterStore.filters.price.to
                            ? "$" + FilterStore.filters.price.to.toString()
                            : FilterStore.filters.status.lease
                                ? "$" + config.filters.leaseMax.toString()
                                : "$" + config.filters.houseMax.toString(),
                        sqftEntryManualMin: FilterStore.filters.sqft.from
                            ? FilterStore.filters.sqft.from.toString()
                            : "0",
                        sqftEntryManualMax: FilterStore.filters.sqft.to
                            ? FilterStore.filters.sqft.to.toString()
                            : this.state.sqftMax.toString(),
                        multiSteps: FilterStore.filters.status.lease
                            ? config.filters.leaseStep
                            : config.filters.houseStep,
                        multiMax: FilterStore.filters.status.lease
                            ? config.filters.leaseMax
                            : config.filters.houseMax,
                        yearValue: [
                            FilterStore.filters.year_built.from
                                ? FilterStore.filters.year_built.from
                                : 1900,
                            FilterStore.filters.year_built.to
                                ? FilterStore.filters.year_built.to
                                : moment().year()
                        ],
                        lotValue: [
                            FilterStore.filters.lot_size.from
                                ? FilterStore.filters.lot_size.from
                                : 0,
                            FilterStore.filters.lot_size.to
                                ? FilterStore.filters.lot_size.to
                                : 10000
                        ],
                        sqftValue: [
                            FilterStore.filters.sqft.from
                                ? FilterStore.filters.sqft.from
                                : 0,
                            FilterStore.filters.sqft.to
                                ? FilterStore.filters.sqft.to
                                : 10000
                        ],
                        domValue: FilterStore.filters.dom.to,
                        sortValue: FilterStore.filters.sort
                    });
                }
            }
        );
    }

    switchPriceEntry() {
        this.setState({
            priceEntryManual: !this.state.priceEntryManual
        });
    }

    switchSqftEntry() {
        this.setState({
            sqftEntryManual: !this.state.sqftEntryManual
        });
    }

    resetOthers() {
        // Reset price slider
        this.setState({
            multiSteps: config.filters.houseStep,
            multiMax: config.filters.houseMax,
            multiSliderValue: [0, config.filters.houseMax],
            yearValue: [1900, moment().year()],
            lotValue: [0, 10000],
            sqftValue: [0, 10000],
            domValue: null,
            //domValue: FilterStore.filters.dom.to,
            sortValue: null,
            priceEntryManualMin: FilterStore.filters.price.from
                ? "$" + FilterStore.filters.price.from.toString()
                : "$0",
            priceEntryManualMax: FilterStore.filters.price.to
                ? "$" + FilterStore.filters.price.to.toString()
                : FilterStore.filters.status.lease
                    ? "$" + config.filters.leaseMax.toString()
                    : "$" + config.filters.houseMax.toString(),
            sqftEntryManualMin: FilterStore.filters.sqft.from
                ? FilterStore.filters.sqft.from.toString()
                : "0",
            sqftEntryManualMax: FilterStore.filters.sqft.to
                ? FilterStore.filters.sqft.to.toString()
                : this.state.sqftMax.toString()
        });

        // Make sure we don't look for open if it's disabled
        if (!AppStore.app_data.toggles.statusOpenEnabled) {
            FilterStore.updateFilterObject("status", "open", false);
        }
    }

    none() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <img
                    src="/assets/img/listing-error.svg"
                    style={{ marginBottom: 20 }}
                />
                <h5>Unable to load data</h5>
                <p style={{ color: "#777" }}>
                    An error occurred. Please try another link.
                </p>
            </div>
        );
    }

    statusBlock(type) {
        let color = "#777";
        let border = "#b4b4b4";
        let label = "Active";
        let active_box = false;

        if (type == "pocket") {
            if (FilterStore.filters.status.pocket) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = AppStore.app_data.custom.pocket.filter_name;
        } else if (type == "active") {
            if (FilterStore.filters.status.active) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = "Active";
        } else if (type == "open") {
            if (FilterStore.filters.status.open) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = "Open House";
        } else if (type == "pending") {
            if (FilterStore.filters.status.pending) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = "Pending";
        } else if (type == "closed") {
            if (FilterStore.filters.status.closed) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = "Sold";
        } else if (type == "lease") {
            if (FilterStore.filters.status.lease) {
                color = AppStore.app_data.theme.primary;
                border = AppStore.app_data.theme.primary;
                active_box = true;
            }
            label = "Lease";
        }

        return (
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer" }} onClick={() => {
                FilterStore.updateFilterObject(
                    "status",
                    type,
                    !FilterStore.filters.status[type]
                );

                if (type == "lease") {
                    if (FilterStore.filters.status.lease) {
                        this.setState({
                            multiSteps: config.filters.leaseStep,
                            multiMax: config.filters.leaseMax,
                            multiSliderValue: [0, config.filters.leaseMax]
                        });
                        FilterStore.updateFilterObject(
                            "price",
                            "from",
                            null
                        );
                        FilterStore.updateFilterObject("price", "to", null);
                    } else {
                        this.setState({
                            multiSteps: config.filters.houseStep,
                            multiMax: config.filters.houseMax,
                            multiSliderValue: [0, config.filters.houseMax]
                        });
                        FilterStore.updateFilterObject(
                            "price",
                            "from",
                            null
                        );
                        FilterStore.updateFilterObject("price", "to", null);
                    }
                } else {
                    if (this.state.multiSteps != config.filters.houseStep) {
                        this.setState({
                            multiSteps: config.filters.houseStep,
                            multiMax: config.filters.houseMax,
                            multiSliderValue: [0, config.filters.houseMax]
                        });
                        FilterStore.updateFilterObject(
                            "price",
                            "from",
                            null
                        );
                        FilterStore.updateFilterObject("price", "to", null);
                    }
                }
            }}>
                <div
                    style={{
                        width: 22,
                        height: 22,
                        borderRadius: 3,
                        border: "1px solid #b4b4b4",
                        marginRight: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Check
                        size={16}
                        color={
                            active_box == true
                                ? AppStore.app_data.theme.primary
                                : "#fff"
                        }
                    />
                </div>
                <span>{label}</span>
            </div>
        );

        return (
            <div
                style={{
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    border: "1px solid " + border,
                    color: color,
                    fontWeight: "600",
                    fontSize: 16,
                    borderRadius: 6,
                    marginRight: 10,
                    padding: 15,
                    marginBottom: 5
                }}
                onClick={() => {
                    FilterStore.updateFilterObject(
                        "status",
                        type,
                        !FilterStore.filters.status[type]
                    );

                    if (type == "lease") {
                        if (FilterStore.filters.status.lease) {
                            this.setState({
                                multiSteps: config.filters.leaseStep,
                                multiMax: config.filters.leaseMax,
                                multiSliderValue: [0, config.filters.leaseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        } else {
                            this.setState({
                                multiSteps: config.filters.houseStep,
                                multiMax: config.filters.houseMax,
                                multiSliderValue: [0, config.filters.houseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        }
                    } else {
                        if (this.state.multiSteps != config.filters.houseStep) {
                            this.setState({
                                multiSteps: config.filters.houseStep,
                                multiMax: config.filters.houseMax,
                                multiSliderValue: [0, config.filters.houseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        }
                    }
                }}
            >
                {label}
            </div>
        );
    }

    statusBlock2(type) {
        let color = "#777";
        let label = "Active";
        let border = "#b4b4b4";
        let check = false;

        if (type == "pocket") {
            if (FilterStore.filters.status.pocket) {
                color = AppStore.app_data.custom.pocket.pin_color;
                border = AppStore.app_data.custom.pocket.pin_color;
                check = true;
            }
            label = AppStore.app_data.custom.pocket.filter_name;
        } else if (type == "active") {
            if (FilterStore.filters.status.active) {
                color = "#2ab717";
                border = "#2ab717";
                check = true;
            }
            label = "Active";
        } else if (type == "open") {
            if (FilterStore.filters.status.open) {
                color = "#18cced";
                border = "#18cced";
                check = true;
            }
            label = "Open House";
        } else if (type == "pending") {
            if (FilterStore.filters.status.pending) {
                color = "#ffb758";
                border = "#ffb758";
                check = true;
            }
            label = "Pending";
        } else if (type == "closed") {
            if (FilterStore.filters.status.closed) {
                color = "#ed5922";
                border = "#ed5922";
                check = true;
            }
            label = "Sold";
        } else if (type == "lease") {
            if (FilterStore.filters.status.lease) {
                color = "#ad2cea";
                border = "#ad2cea";
                check = true;
            }
            label = "Lease";
        }

        return (
            <div
                style={{
                    cursor: "pointer",
                    display: "inline-block",
                    border: "1px solid #b4b4b4", // + border,
                    fontSize: 16,
                    marginRight: 10,
                    marginBottom: 10,
                    fontWeight: "600",
                    borderRadius: 6,
                    color: color,
                    padding: "15px 15px"
                }}
                onClick={() => {
                    FilterStore.updateFilterObject(
                        "status",
                        type,
                        !FilterStore.filters.status[type]
                    );

                    if (type == "lease") {
                        if (FilterStore.filters.status.lease) {
                            this.setState({
                                multiSteps: config.filters.leaseStep,
                                multiMax: config.filters.leaseMax,
                                multiSliderValue: [0, config.filters.leaseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        } else {
                            this.setState({
                                multiSteps: config.filters.houseStep,
                                multiMax: config.filters.houseMax,
                                multiSliderValue: [0, config.filters.houseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        }
                    } else {
                        if (this.state.multiSteps != config.filters.houseStep) {
                            this.setState({
                                multiSteps: config.filters.houseStep,
                                multiMax: config.filters.houseMax,
                                multiSliderValue: [0, config.filters.houseMax]
                            });
                            FilterStore.updateFilterObject(
                                "price",
                                "from",
                                null
                            );
                            FilterStore.updateFilterObject("price", "to", null);
                        }
                    }
                }}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                        style={{
                            width: 22,
                            height: 22,
                            borderRadius: 3,
                            border: "1px solid #b4b4b4",
                            marginRight: 9,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Check
                            size={16}
                            color={
                                check
                                    ? AppStore.app_data.theme.primary
                                    : "#fff"
                            }
                        />
                    </div>
                    <span>{label}</span>
                </div>
            </div>
        );
    }

    sliderChangePrice(values) {
        if (values[0] == 0) {
            FilterStore.updateFilterObject("price", "from", null);
            this.setState({
                priceEntryManualMin: "$0"
            });
        } else if (values[0] > this.state.multiMax) {
            this.setState({
                priceEntryManualMin: "$" + this.state.multiMax.toString()
            });
        } else {
            FilterStore.updateFilterObject("price", "from", values[0]);
            this.setState({
                priceEntryManualMin: "$" + values[0].toString()
            });
        }

        if (values[1] == this.state.multiMax) {
            FilterStore.updateFilterObject("price", "to", null);
            this.setState({
                priceEntryManualMax: "$" + this.state.multiMax.toString()
            });
        } else if (values[1] == 0) {
            this.setState({
                priceEntryManualMax: "$0"
            });
        } else {
            FilterStore.updateFilterObject("price", "to", values[1]);
            this.setState({
                priceEntryManualMax: "$" + values[1].toString()
            });
        }

        this.setState({
            multiSliderValue: values
        });
    }

    sliderChangeSqft(values) {
        if (values[0] == this.state.sqftMin) {
            FilterStore.updateFilterObject("sqft", "from", null);
            this.setState({
                sqftEntryManualMin: "0"
            });
        } else {
            FilterStore.updateFilterObject("sqft", "from", values[0]);
            this.setState({
                sqftEntryManualMin: values[0].toString()
            });
        }

        if (values[1] == this.state.sqftMax) {
            FilterStore.updateFilterObject("sqft", "to", null);
            this.setState({
                sqftEntryManualMax: this.state.sqftMax.toString()
            });
        } else {
            FilterStore.updateFilterObject("sqft", "to", values[1]);
            this.setState({
                sqftEntryManualMax: values[1].toString()
            });
        }

        this.setState({
            sqftValue: values
        });
    }

    sliderChangeLot(values) {
        if (values[0] == this.state.lotMin) {
            FilterStore.updateFilterObject("lot_size", "from", null);
        } else {
            FilterStore.updateFilterObject("lot_size", "from", values[0]);
        }

        if (values[1] == this.state.lotMax) {
            FilterStore.updateFilterObject("lot_size", "to", null);
        } else {
            FilterStore.updateFilterObject("lot_size", "to", values[1]);
        }

        this.setState({
            lotValue: values
        });
    }

    sliderChangeYear(values) {
        if (values[0] == this.state.yearMin) {
            FilterStore.updateFilterObject("year_built", "from", null);
        } else {
            FilterStore.updateFilterObject("year_built", "from", values[0]);
        }

        if (values[1] == this.state.yearMax) {
            FilterStore.updateFilterObject("year_built", "to", null);
        } else {
            FilterStore.updateFilterObject("year_built", "to", values[1]);
        }

        this.setState({
            yearValue: values
        });
    }

    pricePicker() {
        if (this.state.priceEntryManual) {
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ fontSize: 16, color: "#777" }}>
                            Price from
                            <input
                                value={this.state.priceEntryManualMin}
                                type="text"
                                className="form-control"
                                onChange={e => {
                                    const text = e.target.value;
                                    const val = parseInt(
                                        text.replace(/[^0-9.]/g, "")
                                    );
                                    const currentValues = this.state
                                        .multiSliderValue;

                                    if (val == 0 || !val) {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "from",
                                            null
                                        );
                                        this.setState({
                                            priceEntryManualMin: "$",
                                            multiSliderValue: [
                                                0,
                                                currentValues[1]
                                            ]
                                        });
                                    } else if (val > this.state.multiMax && 1 == 9) {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "from",
                                            this.state.multiMax
                                        );
                                        this.setState({
                                            priceEntryManualMin:
                                                "$" +
                                                this.state.multiMax.toString(),
                                            multiSliderValue: [
                                                this.state.multiMax,
                                                currentValues[1]
                                            ]
                                        });
                                    } else {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "from",
                                            val
                                        );
                                        this.setState({
                                            priceEntryManualMin:
                                                "$" + val.toString(),
                                            multiSliderValue: [
                                                val,
                                                currentValues[1]
                                            ]
                                        });
                                    }
                                }}
                            />
                        </div>

                        <div style={{ fontSize: 16, color: "#777" }}>
                            Price to
                            <input
                                value={this.state.priceEntryManualMax}
                                type="text"
                                className="form-control"
                                onChange={e => {
                                    const text = e.target.value;
                                    const val = parseInt(
                                        text.replace(/[^0-9.]/g, "")
                                    );
                                    const currentValues = this.state
                                        .multiSliderValue;

                                    if (val == 0 || !val) {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "to",
                                            null
                                        );
                                        this.setState({
                                            priceEntryManualMax: "$",
                                            multiSliderValue: [
                                                currentValues[0],
                                                0
                                            ]
                                        });
                                    } else if (val > this.state.multiMax && 1 == 9) {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "to",
                                            this.state.multiMax
                                        );
                                        this.setState({
                                            priceEntryManualMax:
                                                "$" +
                                                this.state.multiMax.toString(),
                                            multiSliderValue: [
                                                currentValues[0],
                                                this.state.multiMax
                                            ]
                                        });
                                    } else {
                                        FilterStore.updateFilterObject(
                                            "price",
                                            "to",
                                            val
                                        );
                                        this.setState({
                                            priceEntryManualMax:
                                                "$" + val.toString(),
                                            multiSliderValue: [
                                                currentValues[0],
                                                val
                                            ]
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <div style={{ fontSize: 16, color: "#777" }}>
                        $
                        {MapHelpers.shortPrice(
                            this.state.multiSliderValue[0],
                            true
                        )}
                    </div>
                    <div style={{ fontSize: 16, color: "#777" }}>
                        $
                        {MapHelpers.shortPrice(
                            this.state.multiSliderValue[1],
                            true
                        )}
                        {this.state.multiSliderValue[1] >= this.state.multiMax
                            ? "+"
                            : null}
                    </div>
                </div>

                <Slider
                    range
                    allowCross={false}
                    defaultValue={[
                        this.state.multiSliderValue[0],
                        this.state.multiSliderValue[1]
                    ]}
                    step={this.state.multiSteps}
                    min={0}
                    max={this.state.multiMax}
                    onChange={this.sliderChangePrice}
                    value={this.state.multiSliderValue}
                />
            </>
        );
    }

    sqftPicker() {
        if (this.state.sqftEntryManual) {
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ fontSize: 16, color: "#777" }}>
                            Sqft from
                            <input
                                value={this.state.sqftEntryManualMin}
                                type="text"
                                className="form-control"
                                onChange={e => {
                                    const text = e.target.value;
                                    const val = parseInt(
                                        text.replace(/[^0-9.]/g, "")
                                    );
                                    const currentValues = this.state.sqftValue;

                                    if (val == 0 || !val) {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "from",
                                            null
                                        );
                                        this.setState({
                                            sqftEntryManualMin: "",
                                            sqftValue: [0, currentValues[1]]
                                        });
                                    } else if (val > this.state.sqftMax) {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "from",
                                            this.state.sqftMax
                                        );
                                        this.setState({
                                            sqftEntryManualMin: this.state.sqftMax.toString(),
                                            sqftValue: [
                                                this.state.sqftMax,
                                                currentValues[1]
                                            ]
                                        });
                                    } else {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "from",
                                            val
                                        );
                                        this.setState({
                                            sqftEntryManualMin: val.toString(),
                                            sqftValue: [val, currentValues[1]]
                                        });
                                    }
                                }}
                            />
                        </div>

                        <div style={{ fontSize: 16, color: "#777" }}>
                            Sqft to
                            <input
                                value={this.state.sqftEntryManualMax}
                                type="text"
                                className="form-control"
                                onChange={e => {
                                    const text = e.target.value;
                                    const val = parseInt(
                                        text.replace(/[^0-9.]/g, "")
                                    );
                                    const currentValues = this.state.sqftValue;

                                    if (val == 0 || !val) {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "to",
                                            null
                                        );
                                        this.setState({
                                            sqftEntryManualMax: "",
                                            sqftValue: [currentValues[0], 0]
                                        });
                                    } else if (val > this.state.sqftMax) {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "to",
                                            this.state.sqftMax
                                        );
                                        this.setState({
                                            sqftEntryManualMax: this.state.sqftMax.toString(),
                                            sqftValue: [
                                                currentValues[0],
                                                this.state.sqftMax
                                            ]
                                        });
                                    } else {
                                        FilterStore.updateFilterObject(
                                            "sqft",
                                            "to",
                                            val
                                        );
                                        this.setState({
                                            sqftEntryManualMax: val.toString(),
                                            sqftValue: [currentValues[0], val]
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <div style={{ fontSize: 16, color: "#777" }}>
                        {this.state.sqftValue[0]} sqft
                    </div>
                    <div style={{ fontSize: 16, color: "#777" }}>
                        {this.state.sqftValue[1]}
                        {this.state.sqftValue[1] >= this.state.sqftMax
                            ? "+"
                            : null}
                        {" sqft"}
                    </div>
                </div>

                <Slider
                    range
                    allowCross={false}
                    defaultValue={[
                        this.state.sqftValue[0],
                        this.state.sqftValue[1]
                    ]}
                    step={this.state.sqftSteps}
                    min={0}
                    max={this.state.sqftMax}
                    onChange={this.sliderChangeSqft}
                    value={this.state.sqftValue}
                />
            </>
        );
    }

    propType(type) {
        let color = "#777";
        let border = "#b4b4b4";
        let label = "House";
        let active_box = false;

        if (type == "house") {
            label = "House";
        } else if (type == "condo") {
            label = "Condo / Townhouse";
        } else if (type == "mfamily") {
            label = "Multi-family";
        } else if (type == "mobile") {
            label = "Mobile / Manufactured";
        } else if (type == "land") {
            label = "Land";
        } else if (type == "other") {
            label = "Other";
        }

        if (FilterStore.filters.type.includes(type)) {
            color = AppStore.app_data.theme.primary;
            border = AppStore.app_data.theme.primary;
            active_box = true;
        }

        return (
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer" }} onClick={() => {
                FilterStore.toggleFilterType(type);
            }}>
                <div
                    style={{
                        width: 22,
                        height: 22,
                        borderRadius: 3,
                        border: "1px solid #b4b4b4",
                        marginRight: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Check
                        size={16}
                        color={
                            active_box == true
                                ? AppStore.app_data.theme.primary
                                : "#fff"
                        }
                    />
                </div>
                <span>{label}</span>
            </div>
        );

        return (
            <div
                style={{
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    border: "1px solid " + border,
                    color: color,
                    fontWeight: "600",
                    fontSize: 16,
                    borderRadius: 6,
                    marginRight: 10,
                    padding: 15,
                    marginBottom: 5
                }}
                onClick={() => {
                    FilterStore.toggleFilterType(type);
                }}
            >
                {label}
            </div>
        );
    }

    filters() {
        const data = AppStore.modals.filters.data;

        return (
            <div className="container" style={{
                //padding: "40px 30px"
                flex: 1
            }}>
                <div className="row">
                    <div class="col">


                        <div style={{ marginTop: 20 }}>
                            <h5>Listing status</h5>

                            <div style={{ marginTop: 10 }}>
                                {AppStore.app_data.toggles
                                    .pocketListingsEnabled && (
                                        <>{this.statusBlock("pocket")}</>
                                    )}
                                {this.statusBlock("active")}
                                {AppStore.app_data.toggles
                                    .statusOpenEnabled && (
                                        <>{this.statusBlock("open")}</>
                                    )}
                                {AppStore.app_data.toggles
                                    .statusPendingEnabled && (
                                        <>{this.statusBlock("pending")}</>
                                    )}
                                {AppStore.app_data.toggles
                                    .statusClosedEnabled && (
                                        <>{this.statusBlock("closed")}</>
                                    )}
                                {AppStore.app_data.toggles
                                    .statusLeaseEnabled && (
                                        <>{this.statusBlock("lease")}</>
                                    )}
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <h5>Beds &amp; baths</h5>

                            <div
                                style={{
                                    marginTop: 10,
                                    borderRadius: 6,
                                    border: "1px solid #b4b4b4",
                                    padding: "18px 20px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src="/assets/img/icon-beds.svg"
                                        style={{ width: 16, height: 16 }}
                                    />
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: "#333",
                                            marginLeft: 15
                                        }}
                                    >
                                        Beds
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                AppStore.app_data.theme
                                                    .primary,
                                            fontSize: 22,
                                            border:
                                                "1px solid " +
                                                AppStore.app_data.theme
                                                    .primary,
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            if (
                                                parseInt(
                                                    FilterStore.filters.beds
                                                ) > 1
                                            ) {
                                                FilterStore.updateFilter(
                                                    "beds",
                                                    parseInt(
                                                        FilterStore.filters.beds
                                                    ) - 1
                                                );
                                            } else {
                                                FilterStore.updateFilter(
                                                    "beds",
                                                    null
                                                );
                                            }
                                        }}
                                    >
                                        -
                                    </div>
                                    <div
                                        style={{
                                            width: 30,
                                            color: "#777",
                                            fontSize: 16,
                                            fontWeight: "600",
                                            marginLeft: 5,
                                            marginRight: 5,
                                            textAlign: "center"
                                        }}
                                    >
                                        {FilterStore.filters.beds
                                            ? FilterStore.filters.beds.toString() +
                                            "+"
                                            : "Any"}
                                    </div>
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                AppStore.app_data.theme
                                                    .primary,
                                            fontSize: 22,
                                            border:
                                                "1px solid " +
                                                AppStore.app_data.theme
                                                    .primary,
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            if (
                                                parseInt(
                                                    FilterStore.filters.beds
                                                ) > 0
                                            ) {
                                                FilterStore.updateFilter(
                                                    "beds",
                                                    parseInt(
                                                        FilterStore.filters.beds
                                                    ) + 1
                                                );
                                            } else {
                                                FilterStore.updateFilter(
                                                    "beds",
                                                    1
                                                );
                                            }
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    borderRadius: 6,
                                    border: "1px solid #b4b4b4",
                                    padding: "18px 20px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src="/assets/img/icon-baths.svg"
                                        style={{ width: 16, height: 16 }}
                                    />
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: "#333",
                                            marginLeft: 15
                                        }}
                                    >
                                        Baths
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                AppStore.app_data.theme
                                                    .primary,
                                            fontSize: 22,
                                            border:
                                                "1px solid " +
                                                AppStore.app_data.theme
                                                    .primary,
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            if (
                                                parseInt(
                                                    FilterStore.filters.baths
                                                ) > 1
                                            ) {
                                                FilterStore.updateFilter(
                                                    "baths",
                                                    parseInt(
                                                        FilterStore.filters
                                                            .baths
                                                    ) - 1
                                                );
                                            } else {
                                                FilterStore.updateFilter(
                                                    "baths",
                                                    null
                                                );
                                            }
                                        }}
                                    >
                                        -
                                    </div>
                                    <div
                                        style={{
                                            width: 30,
                                            color: "#777",
                                            fontSize: 16,
                                            fontWeight: "600",
                                            marginLeft: 5,
                                            marginRight: 5,
                                            textAlign: "center"
                                        }}
                                    >
                                        {FilterStore.filters.baths
                                            ? FilterStore.filters.baths.toString() +
                                            "+"
                                            : "Any"}
                                    </div>
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                AppStore.app_data.theme
                                                    .primary,
                                            fontSize: 22,
                                            border:
                                                "1px solid " +
                                                AppStore.app_data.theme
                                                    .primary,
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            if (
                                                parseInt(
                                                    FilterStore.filters.baths
                                                ) > 0
                                            ) {
                                                FilterStore.updateFilter(
                                                    "baths",
                                                    parseInt(
                                                        FilterStore.filters
                                                            .baths
                                                    ) + 1
                                                );
                                            } else {
                                                FilterStore.updateFilter(
                                                    "baths",
                                                    1
                                                );
                                            }
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <h5>Price range</h5>
                                <a
                                    href="#"
                                    style={{
                                        color:
                                            AppStore.app_data.theme.primary,
                                        fontSize: 16
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({
                                            priceEntryManual: !this.state
                                                .priceEntryManual
                                        });
                                    }}
                                >
                                    {!this.state.priceEntryManual
                                        ? "Enter manually"
                                        : "Use slider"}
                                </a>
                            </div>

                            <div style={{ marginTop: 15 }}>
                                {this.pricePicker()}
                            </div>
                        </div>

                        <div style={{ marginTop: 40 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <h5>Square footage</h5>
                                <a
                                    href="#"
                                    style={{
                                        color:
                                            AppStore.app_data.theme.primary,
                                        fontSize: 16
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({
                                            sqftEntryManual: !this.state
                                                .sqftEntryManual
                                        });
                                    }}
                                >
                                    {!this.state.sqftEntryManual
                                        ? "Enter manually"
                                        : "Use slider"}
                                </a>
                            </div>

                            <div style={{ marginTop: 15 }}>
                                {this.sqftPicker()}
                            </div>
                        </div>

                        <div style={{ marginTop: 40 }}>
                            <h5>Property type</h5>

                            <div
                                style={{
                                    marginTop: 15,
                                    paddingBottom: 20,
                                    //display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    //overflowX: "scroll"
                                }}
                            >
                                {this.propType("house")}
                                {this.propType("condo")}
                                {this.propType("mfamily")}
                                {this.propType("mobile")}
                                {this.propType("land")}
                                {this.propType("other")}
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <h5>Days on market</h5>

                            <select
                                value={this.state.domValue}
                                className="form-control"
                                style={{
                                    border: "1px solid #b4b4b4",
                                    fontSize: 16
                                }}
                                onChange={e => {
                                    this.setState({
                                        domValue: e.target.value
                                    });

                                    FilterStore.updateFilterObject(
                                        "dom",
                                        "to",
                                        e.target.value
                                    );
                                }}
                            >
                                <option value={null}>Any</option>
                                <option
                                    value={1}
                                    selected={
                                        this.state.domValue == 1 ? true : false
                                    }
                                >
                                    Less than a day
                                </option>
                                <option
                                    value={3}
                                    selected={
                                        this.state.domValue == 3 ? true : false
                                    }
                                >
                                    Less than 3 days
                                </option>
                                <option
                                    value={7}
                                    selected={
                                        this.state.domValue == 7 ? true : false
                                    }
                                >
                                    Less than 7 days
                                </option>
                                <option
                                    value={30}
                                    selected={
                                        this.state.domValue == 30 ? true : false
                                    }
                                >
                                    Less than a month
                                </option>
                                <option
                                    value={90}
                                    selected={
                                        this.state.domValue == 90 ? true : false
                                    }
                                >
                                    Less than 3 months
                                </option>
                                <option
                                    value={180}
                                    selected={
                                        this.state.domValue == 180
                                            ? true
                                            : false
                                    }
                                >
                                    Less than 6 months
                                </option>
                                <option
                                    value={365}
                                    selected={
                                        this.state.domValue == 365
                                            ? true
                                            : false
                                    }
                                >
                                    Less than a year
                                </option>
                            </select>
                        </div>

                        <div style={{ marginTop: 40 }}>
                            <h5>Lot size</h5>

                            <div style={{ marginTop: 15 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{ fontSize: 16, color: "#777" }}
                                    >
                                        {this.state.lotValue[0]} sqft
                                    </div>
                                    <div
                                        style={{ fontSize: 16, color: "#777" }}
                                    >
                                        {this.state.lotValue[1]}
                                        {this.state.lotValue[1] >=
                                            this.state.lotMax
                                            ? "+"
                                            : null}
                                        {" sqft"}
                                    </div>
                                </div>

                                <Slider
                                    range
                                    allowCross={false}
                                    defaultValue={[
                                        this.state.lotValue[0],
                                        this.state.lotValue[1]
                                    ]}
                                    step={this.state.lotSteps}
                                    min={this.state.lotMin}
                                    max={this.state.lotMax}
                                    onChange={this.sliderChangeLot}
                                    value={this.state.lotValue}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 40 }}>
                            <h5>Year built</h5>

                            <div style={{ marginTop: 15 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{ fontSize: 16, color: "#777" }}
                                    >
                                        {this.state.yearValue[0]}
                                        {this.state.yearValue[0] <=
                                            this.state.yearMin
                                            ? " & earlier"
                                            : null}
                                    </div>
                                    <div
                                        style={{ fontSize: 16, color: "#777" }}
                                    >
                                        {this.state.yearValue[1]}
                                    </div>
                                </div>

                                <Slider
                                    range
                                    allowCross={false}
                                    defaultValue={[
                                        this.state.yearValue[0],
                                        this.state.yearValue[1]
                                    ]}
                                    step={this.state.yearSteps}
                                    min={this.state.yearMin}
                                    max={this.state.yearMax}
                                    onChange={this.sliderChangeYear}
                                    value={this.state.yearValue}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 40 }}>
                            <h5>Features</h5>
                            <div style={{ marginTop: 0 }}>
                                {AppStore?.app_data?.custom_filters &&
                                    AppStore.app_data.custom_filters.length > 0 && (
                                        <>
                                            {AppStore.app_data.custom_filters.map((item) => {
                                                if (item.type == 'bool') {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer" }} onClick={() => {
                                                            FilterStore.updateFilterObject(
                                                                "custom",
                                                                item.name,
                                                                !FilterStore.filters.custom[item.name]
                                                            );
                                                        }}>
                                                            <div
                                                                style={{
                                                                    width: 22,
                                                                    height: 22,
                                                                    borderRadius: 3,
                                                                    border: "1px solid #b4b4b4",
                                                                    marginRight: 9,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <Check
                                                                    size={16}
                                                                    color={
                                                                        FilterStore.filters.custom[item.name]
                                                                            ? AppStore.app_data.theme.primary
                                                                            : "#fff"
                                                                    }
                                                                />
                                                            </div>
                                                            <span>{item.label}</span>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    content() {
        return this.none();
    }

    render() {
        return (

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    overflowY: "scroll",
                    flexDirection: "column"
                }}
            >
                <div
                    style={{ display: "flex", flex: 1, paddingBottom: 80 }}
                >
                    {this.filters()}
                </div>

                <div
                    style={{
                        //position: "absolute",
                        //width: "100%",
                        backgroundColor: "#fff",
                        bottom: 0,
                        paddingTop: 15,
                        //paddingLeft: 30,
                        //paddingRight: 30,
                        paddingBottom: 15,
                        borderTop: "1px solid #eee",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <div style={{ flex: 0.5 }}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                FilterStore.resetFilters();
                                this.resetOthers();
                            }}
                            style={{ fontSize: 16, color: "#777" }}
                        >
                            Reset
                        </a>
                    </div>
                    {/*<button
                        style={{ flex: 0.5 }}
                        className="btn btn-primary"
                        onClick={() => {
                            //AppStore.refreshMap = true;

                            //this.props.onApply();
                        }}
                    >
                        Apply filters
                    </button>*/}
                </div>
            </div>

        );
    }
}

const styles = {
    alert: {
        marginBottom: 15,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer"
    },
    alertPhoto: {
        width: 70,
        height: 70,
        backgroundColor: "#fff",
        marginRight: 15,
        borderRadius: 3,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    }
};

export default SavedSearchFilters;

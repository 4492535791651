import React, { useState } from "react";
import Tag from "./Tag";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import CreateTag from "./CreateTag";

const ManageUserTags = (props) => {
    const { allTags, user, currentTags, onAdd, onRemove, onCreate, plainPicker, showAgentTag } = props;
    const [newModal, setNewModal] = useState(false)

    if (!allTags || (!user && !currentTags)) {
        return null;
    }

    let userTags = [];

    if (currentTags) {
        userTags = currentTags;
    } else {
        userTags = user.tags;
    }

    let used_tags = [];
    userTags.map(tag => {
        used_tags.push(tag.id);
    });

    let allTagsNew = [];
    allTags.map(tag => {
        if (used_tags.includes(tag.id)) {

        } else {
            allTagsNew.push(tag);
        }
    });
    const showAllTags = allTagsNew;

    return (
        <>
            <div>
                {!plainPicker && (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h3 style={{ marginBottom: 20 }}>
                            {currentTags ? "Filter by tags" : "Manage user tags"}
                        </h3>
                        {!currentTags ? (
                            <div style={{ marginRight: 30 }}>
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    setNewModal(true);
                                }}>Create tag</button>
                            </div>
                        ) : null}
                    </div>
                )}

                {plainPicker ? null : (<h4>Current tags</h4>)}
                {userTags.length == 0 && (
                    <p className="text-muted">None current set</p>
                )}

                {userTags.map(tag => (
                    <Tag
                        asFilter={true}
                        disableSystemTag={tag.system_tag == 1 && !currentTags ? true : false}
                        tag={tag}
                        largeMode={plainPicker ? false : true}
                        onRemove={() => {
                            if (tag.system_tag == 1 && !currentTags && 1 == 9) {
                                alert('You cannot remove a system tag');
                            } else {
                                onRemove(tag);
                            }
                        }}
                    />
                ))}

                <hr />

                {plainPicker ? (<h5>Available tags</h5>) : (<h4>Available tags</h4>)}
                <p>
                    <>
                        {(!plainPicker || showAgentTag) && (
                            <Tag
                                largeMode={plainPicker ? false : true}
                                tag={{ tag: "agent", tag_long: "Agent", tag_color: "#016aaa", text_color: "#fff" }}
                                onClick={(tag) => {
                                    onAdd(tag);
                                }}
                            />
                        )}
                    </>
                    {showAllTags.map(tag => {
                        if (tag.system_tag == 0 || currentTags) {
                            return (
                                <Tag
                                    largeMode={plainPicker ? false : true}
                                    tag={tag}
                                    onClick={() => {
                                        onAdd(tag);
                                    }}
                                />
                            );
                        }
                    })}
                </p>

            </div>

            <Rodal
                visible={newModal}
                onClose={() => { setNewModal(false); }}
                //width={600}
                width={"90%"}
                customStyles={{ maxWidth: 600 }}
                height={400}
            >
                <CreateTag
                    addTag={(tag) => {
                        setNewModal(false);
                        onCreate(tag)
                    }}
                />
            </Rodal>
        </>
    );
}

export default ManageUserTags;

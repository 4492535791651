import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import Select from "react-select";
import Help from "../help/Help";
import { Formik } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  FiSettings,
} from "react-icons/fi";
import moment from "moment";

const crmFields = [
  { name: null, fields: [], parsing: false },
  {
    name: "fub",
    fields: ['api_key'],
    parsing: false,
    link: {
      url: "https://app.followupboss.com/2/api", //"https://help.followupboss.com/hc/en-us/articles/360014289393-API-Keys",
      label: "Follow Up Boss API Instructions"
    },
    links: [
      {
        url: "https://app.followupboss.com/2/api",
        label: "Step 1: Add Follow Up Boss API key above (get my key)"
      },
      {
        url: "https://homestack.followupboss.com/2/integrations",
        label: "Step 2: Enable HomeStack in your FUB Integrations Dashboard"
      }
    ]
  },
  { name: "idc", fields: ['username', 'password'], parsing: false },
  { name: "propertybase", fields: ['api_key', 'api_url'], parsing: false },
  // Below are for screenshotting, not live atm! Remove being goign live!!
  //{ name: "boomtown", fields: ['api_key'], parsing: false },
  //{ name: "kvcore", fields: ['api_key'], parsing: false },
  { name: "kvcore", fields: ['api_key'], parsing: false },
  //{ name: "chime", fields: ['api_key'], parsing: false },
  //{ name: "sierra", fields: ['api_key'], parsing: false },
  {
    name: "sierra",
    fields: ['api_key'],
    parsing: false,
    link: {
      url: "https://client.sierrainteractivedev.com/integrations.aspx",
      label: "API key can be found here. Select the 'Sierra Interactive' option and copy provided API key."
    }
  },
  //{ name: "deltanet", fields: [], parsing: true },
  //{ name: "moxiworks", fields: [], parsing: false },
  //{ name: "cinc", fields: ['api_key'], parsing: false },
  { name: "wise", fields: ['api_key'], parsing: false },
  { name: "request", fields: [], parsing: false },
];

@observer
class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      key: null,
      error: false,
      brokerage_name: "",
      dmca_link: "",
      agent: null,
      integration: null,
      read_integration: null,
    };
  }

  allowedField = (name, field) => {
    let allowed = false;

    crmFields.map(item => {
      if (item.name == name) {
        const f = item.fields;
        if (f.includes(field)) {
          allowed = true;
        }
      }
    });

    return allowed;
  }

  crmOptions = () => {
    return [
      { value: null, label: "None" },
      //{ value: "boomtown", label: "Boomtown!" },
      //{ value: "chime", label: "Chime" },
      //{ value: "cinc", label: "CINC" },
      //{ value: "deltanet", label: "DeltaNet CRM" },
      { value: "fub", label: "Follow Up Boss" },
      { value: "kvcore", label: "kvCORE" },
      //{ value: "kvore", label: "kvCORE" },
      //{ value: "moxiworks", label: "MoxiWorks" },
      { value: "propertybase", label: "Propertybase" },
      { value: "idc", label: "IDC" },
      { value: "sierra", label: "Sierra Interactive" },
      { value: "wise", label: "Wise Agent" },
      { value: "request", label: "Request an integration..." }
    ];
  };

  componentDidMount() {
    this.loadProfile();
    this.load();
  }

  load() {
    if (AppStore.app_data.toggles.integrationsEnabled && AppStore.app_data.toggles.integrationsAgentEnabled) {
      api.getIntegrationInfo(
        response => {
          this.setState({ integration: response.info, read_integration: JSON.parse(JSON.stringify(response.info)) })
        },
        error => {
          this.setState({ error: true });
        },
        true
      );
    }
  }

  updateIntegration() {
    // TODO: Check the required fields to make sure they are present
    let failed = false;
    let int = this.state.integration;
    let name = this.state.integration?.name;
    let required = [];
    crmFields.map(item => {
      if (item.name == name) {
        required = item.fields;
      }
    });

    //alert(JSON.stringify(required))

    let pre_int = {
      name: null,
      api_url: null,
      api_key: null,
      username: null,
      password: null
    };

    if (!name || required.length == 0) {
      int = pre_int;
    } else {
      pre_int.name = int.name;
      required.map(item => {
        if (!int[item]) {
          failed = true;
        } else {
          pre_int[item] = int[item];
        }
      });
      int = pre_int;
    }

    if (failed) {
      alert("All fields are required");
      return;
    }

    api.updateIntegration(
      int,
      response => {
        window.showToastMessage("Success", "Integration updated");
      },
      error => {
        alert("An error occured");
      },
      true
    );
  }

  loadProfile() {
    api.getProfile(
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ agent: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  copyToClipboard = e => {
    this.copyLink.select();
    document.execCommand("copy");
    window.showToastMessage("Success", "API key copied to clipboard");
  };

  render() {
    if (UserStore.isAdmin()) {
      return <Redirect to="/settings" />;
    }

    if (this.state.error) {
      return (
        <Inner>
          <div class="content">
            <div className="centerBox">
              <img src="https://bk.homestack.com/assets/img/not-found.svg" />
              <h2>Unable to load data</h2>
              <p>
                <button
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </button>
              </p>
            </div>
          </div>
        </Inner>
      );
    }

    if (!this.state.agent) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const item = this.state.agent;

    if (!UserStore.user.agent_data.api_key) {
      return (
        <Inner>
          <div class="bg-dark bg-dots m-b-30">
            <div class="container">
              <div class="row p-b-60 p-t-60">
                <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                  <div class="m-b-10">
                    <div class="avatar avatar-lg ">
                      <div class="avatar-title bg-info rounded-circle mdi mdi-settings " />
                    </div>
                  </div>
                  <h3>Settings &amp; Integrations</h3>
                </div>
              </div>
            </div>
          </div>

          <section class="pull-up">
            <div class="container">
              <div class="row ">
                <div class="col-lg-6">
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h3 class="">API key not available</h3>

                      <p>
                        There is no API key for this account. If you believe
                        this is in error, please contact support.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Inner>
      );
    }

    let help_link = crmFields.find((op) => {
      return op.name == this.state.integration?.name;
    })?.link;

    let help_links = crmFields.find((op) => {
      return op.name == this.state.integration?.name;
    })?.links;

    const integration = this.state.integration;
    const read_integration = this.state?.read_integration;

    return (
      <Inner>
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            {/*<Help section="settings" />*/}
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle mdi mdi-settings " />
                  </div>
                </div>
                <h3>Settings &amp; Integrations</h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <div class="row ">
              <div class="col-lg-6">
                <div class="card py-3 m-b-30">
                  <div class="card-body">
                    <h3 class="">API key</h3>

                    <div class="form-group">
                      <label for="api_key">Your API key</label>
                      <input
                        style={{ fontFamily: "monospace", fontSize: 16 }}
                        id="api_key"
                        type="text"
                        class="form-control"
                        ref={input => (this.copyLink = input)}
                        type="text"
                        value={UserStore.user.agent_data.api_key}
                      />
                    </div>

                    <div class="text-center">
                      <button
                        onClick={this.copyToClipboard}
                        class="btn btn-info btn-cta"
                      >
                        Copy API key
                      </button>
                    </div>
                  </div>
                </div>

                {AppStore.app_data.toggles.crmEmailEnabled && AppStore.app_data.toggles.crmEmailProfileEnabled && (
                  <div class="card m-b-30">
                    <div class="card-body ">
                      <h3 class="">CRM Email Parsing</h3>
                      <Formik
                        initialValues={{
                          crm_email: item.crm_email
                        }}
                        validate={values => {
                          let errors = {};

                          if (values.crm_email && (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              values.crm_email
                            ))
                          ) {
                            errors.crm_email = "Invalid email address";
                          }

                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            //alert(JSON.stringify(values, null, 2));

                            api.updateProfileCRM(
                              values,
                              response => {
                                this.forceUpdate();

                                window.showToastMessage(
                                  "Success",
                                  "CRM parsing email has been saved"
                                );
                              },
                              error => {
                                window.showToastMessage(
                                  "Error",
                                  "Error saving parsing email",
                                  "danger",
                                  "fe-x"
                                );
                              },
                              item.id
                            );

                            setSubmitting(false);
                          }, 400);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit}>

                            <p>
                              <label for="crm_email">CRM parsing email</label>
                              <input
                                type="text"
                                class={
                                  errors.bio
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="crm_email"
                                id="crm_email"
                                placeholder="CRM email..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.crm_email}
                                maxLength={200}
                              />
                              <div class="invalid-feedback">
                                {errors.crm_email && touched.crm_email && errors.crm_email}
                              </div>
                            </p>
                            <p>This email is used to capture leads in your CRM system if you have email parsing set up. <strong>It is not the contact email</strong>.</p>

                            <hr />

                            <p>
                              <button
                                class="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Update
                              </button>
                            </p>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                )}
              </div>

              <div class="col-lg-6">
                {UserStore?.user?.agent_data?.self_provisioned &&
                  UserStore?.user?.agent_data?.subscription_status == "canceled" &&
                  (
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="alert alert-border-danger  alert-dismissible fade show"
                          role="alert"
                          style={{
                            backgroundColor: "#fff",
                            /*boxShadow: "1px 2px 10px rgba(0,0,0,0.3)"*/
                          }}
                        >
                          <h4 className="text-danger">Subscription canceled</h4>
                          <p>Your subsription was cancelled and you have until
                            {" "}<strong>{moment.unix(UserStore?.user?.agent_data?.subscription_cancel_at).format("MMMM D, YYYY")}</strong>{" "}
                            after which your account will be deactivated. <a href="/users" className="text-underline">Download your users now</a> if you need to access this information later.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {AppStore.app_data.toggles.zapierEnabled && (
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h3 class="">
                        Zapier <span class="badge badge-warning">beta</span>
                      </h3>

                      <div class="card-body">
                        <p>
                          You can get access to the HomeStack Zapier beta from:{" "}
                          <a
                            href="https://zapier.com/developer/public-invite/28937/ce9d7c0cead1b4ca460d46d7fd1c9b7b/"
                            target="_blank"
                            class="text-underline"
                          >
                            https://zapier.com/developer/public-invite/28937/ce9d7c0cead1b4ca460d46d7fd1c9b7b
                          </a>
                        </p>

                        <hr />

                        <p>
                          Read the{" "}
                          <a
                            class="text-underline"
                            href="/assets/docs/homestack-zapier-beta-guide.pdf"
                            target="_blank"
                          >
                            Zapier integration guide
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {AppStore.app_data.toggles.integrationsEnabled && AppStore.app_data.toggles.integrationsAgentEnabled && (
                  <div class="card py-3 m-b-30">
                    <div class="card-body">
                      <h3 class="">CRM Integration</h3>
                      <p>
                        Select your CRM from the supported list below and enter the required credentials.
                      </p>

                      <div style={{ marginBottom: 12 }}>
                        {read_integration?.active && read_integration?.sync_checked && read_integration?.name && !read_integration.error && (
                          <div style={{
                            display: "inline-flex",
                            padding: "3px 6px",
                            borderRadius: 6,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 16,
                            fontWeight: "bold",
                            backgroundColor: "#00cc99",
                            color: "#fff"
                          }}>
                            <FiSettings className="rotator" style={{ marginRight: 6 }} />
                            Running...
                          </div>
                        )}

                        {read_integration?.active && !read_integration.sync_checked && read_integration?.name && !read_integration.error && (
                          <div style={{
                            display: "inline-flex",
                            padding: "3px 6px",
                            borderRadius: 6,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 16,
                            fontWeight: "bold",
                            backgroundColor: "#f7bc08",
                            color: "#fff"
                          }}>
                            <FiSettings className="rotator" style={{ marginRight: 6 }} />
                            Checking...
                          </div>
                        )}

                        {read_integration?.error && read_integration?.name && (
                          <div style={{
                            display: "inline-flex",
                            padding: "3px 6px",
                            borderRadius: 6,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 16,
                            fontWeight: "bold",
                            backgroundColor: "#f2545b",
                            color: "#fff"
                          }}>
                            <FiSettings style={{ marginRight: 6 }} />
                            Error. Please check credentials.
                          </div>
                        )}
                      </div>

                      <>
                        <p>
                          <label>
                            CRM provider
                          </label>
                          <Select
                            value={this.state.integration?.name}
                            options={this.crmOptions()}
                            value={this.crmOptions().find((op) => {
                              return op.value == this.state.integration?.name;
                            })}
                            onChange={(option) => {
                              const int = this.state.integration;
                              int.name = option.value;
                              this.setState({ integration: int });
                            }}
                          />
                        </p>

                        {this.state.integration?.name == "request" && (
                          <p>
                            Please email <a className="text-underline" href="mailto:support@homestack.com">support@homestack.com</a> with the
                            details of the integration you'd like us to work with. <strong>Note: If your CRM supports email parsing you can fill in the field below to start using this now.</strong>
                          </p>
                        )}

                        {crmFields.find((op) => {
                          return op.name == this.state.integration?.name;
                        }).parsing && (
                            <div class="card-body">
                              <div
                                class="alert alert-border-warning  alert-dismissible fade show"
                                role="alert"
                              >
                                <div class="d-flex">
                                  <div class="icon">
                                    <i class="icon mdi mdi-alert-circle-outline" />
                                  </div>
                                  <div class="content">
                                    <strong>Available through email parsing</strong>
                                    <br />
                                    To receive new leads for {this.crmOptions().find((op) => {
                                      return op.value == this.state.integration?.name;
                                    }).label}, fill in the CRM email parsing section.
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}



                        {this.allowedField(this.state.integration?.name, 'api_url') && (
                          <div class="form-group">
                            <label for="crm_api_url">API URL</label>
                            <input
                              style={{ fontSize: 16 }}
                              id="crm_api_url"
                              type="text"
                              class="form-control"
                              type="text"
                              onChange={e => {
                                const int = this.state.integration;
                                int.api_url = e.target.value;
                                this.setState({ integration: int });
                              }}
                              value={this.state.integration?.api_url}
                            />
                          </div>
                        )}

                        {this.allowedField(this.state.integration?.name, 'api_key') && (
                          <div class="form-group">
                            <label for="crm_api_key">API Key</label>
                            <input
                              style={{ fontSize: 16 }}
                              id="crm_api_key"
                              type="text"
                              class="form-control"
                              type="text"
                              onChange={e => {
                                const int = this.state.integration;
                                int.api_key = e.target.value;
                                this.setState({ integration: int });
                              }}
                              value={this.state.integration?.api_key}
                            />
                          </div>
                        )}

                        {this.allowedField(this.state.integration?.name, 'username') && (
                          <div class="form-group">
                            <label for="crm_username">Username</label>
                            <input
                              style={{ fontSize: 16 }}
                              id="crm_username"
                              type="text"
                              class="form-control"
                              onChange={e => {
                                const int = this.state.integration;
                                int.username = e.target.value;
                                this.setState({ integration: int });
                              }}
                              value={this.state.integration?.username}
                            />
                          </div>
                        )}

                        {this.allowedField(this.state.integration?.name, 'password') && (
                          <div class="form-group">
                            <label for="crm_password">Password</label>
                            <input
                              style={{ fontSize: 16 }}
                              id="crm_password"
                              type="text"
                              class="form-control"
                              onChange={e => {
                                const int = this.state.integration;
                                int.password = e.target.value;
                                this.setState({ integration: int });
                              }}
                              value={this.state.integration?.password}
                            />
                          </div>
                        )}

                        {help_links && (
                          <>
                            {help_links.map(hlink => (
                              <p><a target="_blank" href={hlink.url} className="text-underline">{hlink.label}</a></p>
                            ))}
                          </>
                        )}

                        {help_link && !help_links && (
                          <p><a target="_blank" href={help_link.url} className="text-underline">{help_link.label}</a></p>
                        )}

                        {!crmFields.find((op) => {
                          return op.name == this.state.integration?.name;
                        }).parsing && this.state.integration?.name != "request" && (
                            <div class="text-right">
                              {!AppStore.readOnly && (
                                <button
                                  onClick={() => {
                                    this.updateIntegration();
                                  }}
                                  class="btn btn-primary btn-cta"
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          )}
                      </>

                    </div>
                  </div>
                )}

                {UserStore?.user?.agent_data?.self_provisioned &&
                  UserStore?.user?.agent_data?.subscription_status != "canceled" &&
                  (
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="alert alert-border-danger  alert-dismissible fade show"
                          role="alert"
                          style={{
                            backgroundColor: "#fff",
                            /*boxShadow: "1px 2px 10px rgba(0,0,0,0.3)"*/
                          }}
                        >
                          <h4 className="text-danger">Cancel Subscription</h4>
                          <p>If you cancel, you will still be able to access the dashboard and app until
                            {" "}<strong>{moment.unix(UserStore?.user?.agent_data?.subscription_next_date).format("MMMM D, YYYY")}</strong>.
                          </p>
                          <p>
                            <button onClick={() => {
                              if (window.confirm("Are you sure you want to cancel your subscription? You will lose access to the dashboard, app and your users when your current billing period ends?")) {
                                // reload window

                                api.cancelSubscription(
                                  response => {
                                    window.showToastMessage("Success", "Subscription canceled");
                                    window.location.reload();
                                  },
                                  error => {
                                    window.showToastMessage("Error", "Error canceling subscription", "danger", "fe-x");
                                  }
                                );
                              }
                            }} className="btn btn-danger">Cancel Subscription</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </section>
      </Inner>
    );

    return (
      <Inner>
        <div class="content">
          <div class="row">
            <div class="col-2">
              {UserStore.isAgent() && (
                <div class="content-block">
                  <h2>API key</h2>

                  <p>Your unique app API key. Keep this safe!</p>
                  <p>
                    <input
                      ref={input => (this.copyLink = input)}
                      type="text"
                      value={this.state.key}
                    />
                  </p>
                  <div class="row">
                    <p>
                      <button onClick={this.copyToClipboard}>
                        Copy API key
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Inner>
    );
  }
}

export default UserSettings;

import React, { Component } from "react";
import { observer } from "mobx-react";
import { Cookies } from "react-cookie";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import moment from "moment";

const cookies = new Cookies();

@observer
class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: true,
      help: [],
      empty: false,
      loaded: false,
    };
  }

  componentDidMount() {
    const hide_help = cookies.get("hide_help");
    if (hide_help) {
      this.setState({ showHelp: false });
    }
  }

  showHelp() {
    this.setState({ showHelp: true });
    cookies.remove("hide_help");
  }

  hideHelp() {
    this.setState({ showHelp: false });
    cookies.set("hide_help", "1", {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
  }

  content(item) {
    if (item.type == "video") {
      if (item.content.includes("youtube")) {
        // https://www.youtube.com/embed/Qgtk2Myl6TI
        let vid =
          "https://www.youtube.com/embed/" + item.content.replace(/.*v=/, "");
        return (
          <iframe
            width="100%"
            height="100%"
            src={vid}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        );
      }

      if (item.content.includes("vimeo")) {
        // https://player.vimeo.com/video/259411563
        let vid =
          "https://player.vimeo.com/video/" +
          item.content.replace(/.*com\//, "");
        return (
          <iframe
            src={vid}
            width="100%"
            height="100%"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          />
        );
      }

      return null;
    }

    return (
      <div
        class="help-content"
        dangerouslySetInnerHTML={{ __html: item.content }}
      />
    );
  }

  load(section) {
    if (this.state.loaded) {
      //return false;
    }
    if (!section) {
      return null;
    }

    if (!this.state.help.length && !this.state.empty && !this.state.loaded) {
      api.getHelp(
        (response) => {
          this.setState({
            help: response,
            empty: false,
            loaded: true,
          });

          if (section == "training") {
            this.setState({ showHelp: true });
          }
        },
        (error) => {
          this.setState({ empty: true, loaded: true });
        },
        section
      );
    }

    if (this.state.help.length == 0) {
      return null;
    }

    if (!this.state.showHelp && section != "training") {
      return (
        <div class="row" style={{ paddingTop: 10 }}>
          <div class="col-md-12 text-right">
            <i
              class="fe fe-help-circle"
              style={{
                paddingRight: 5,
                //fontSize: 20,
                color: "#fff",
              }}
            />
            <a
              href="#"
              style={{ color: "#fff", textDecoration: "underline" }}
              onClick={(e) => {
                e.preventDefault();
                this.showHelp();
              }}
            >
              Show help
            </a>
          </div>
        </div>
      );
    }

    return (
      <div class="row" style={{ paddingTop: 20 }}>
        {this.state.help.map((item) => (
          <div class="col-lg-4 col-md-6">
            <div class="card m-b-30">
              <div class="card-body">
                <p>
                  <strong>{item.title}</strong>
                </p>
                {this.content(item)}
              </div>
            </div>
          </div>
        ))}

        {section != "training" && (
          <div class="col-md-12">
            <p class="text-right" style={{ marginTop: 0, paddingTop: 0 }}>
              <i
                class="fe fe-arrow-up"
                style={{
                  paddingRight: 5,
                  //fontSize: 20,
                  color: "#fff",
                }}
              />
              <a
                href="#"
                style={{ color: "#fff", textDecoration: "underline" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.hideHelp();
                }}
              >
                Hide help
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.load(this.props.section);
  }
}

export default Help;

import React, { useState, useCallback } from "react";
import AgentCard from "./AgentCard";
import update from "immutability-helper";
import AppStore from "../../../stores/AppStore";
const style = {
  width: 400
};

let initialOrder = [];
let populateInitial = true;

const Container = props => {
  {
    let agentList = [];

    if (initialOrder.length == props.agents.length) {
      populateInitial = false;
    }

    props.agents.map((agent, index) => {
      agentList.push({
        id: index,
        text: JSON.stringify(agent)
      });

      if (populateInitial && !initialOrder.includes(agent.id)) {
        initialOrder.push(agent.id);
      }
    });

    const initialCards = agentList;
    const [cards, setCards] = useState(agentList);
    const dropCard = useCallback((dragIndex, hoverIndex) => {
      let newOrder = [];
      cards.map(item => {
        let agent = JSON.parse(item.text);
        newOrder.push(agent.id);
      });

      AppStore.newOrder = newOrder;

      if (JSON.stringify(initialOrder) != JSON.stringify(newOrder)) {
        AppStore.agentCardsChanged = true;
      } else {
        //AppStore.agentCardsChanged = false;
      }
    });
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        /*let newOrder = [];
        cards.map(item => {
          let agent = JSON.parse(item.text);
          newOrder.push(agent.id);
        });*/

        //AppStore.newOrder = newOrder;

        if (props.agents.length != props.resultsTotal || props.resultSort) {
          return;
        }

        const dragCard = cards[dragIndex];
        setCards(
          update(cards, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
          })
        );
      },
      [cards]
    );
    const renderCard = (card, index) => {
      if (props.agents.length != props.resultsTotal || props.resultSort) {
        return (
          <AgentCard
            disabled={true}
            key={card.id}
            index={index}
            id={card.id}
            text={card.text}
            moveCard={moveCard}
            dropCard={dropCard}
          />
        );
      }
      return (
        <AgentCard
          disabled={false}
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
          dropCard={dropCard}
        />
      );
    };
    return (
      <>
        <tbody>{cards.map((card, i) => renderCard(card, i))}</tbody>
      </>
    );
  }
};
export default Container;

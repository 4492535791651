// Map helpers

//import haversine from "haversine";

const MapHelpers = {
    getInitials(string) {
        var names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    },

    getMapSize(region) {
        // Get the coords of the map corners

        const bounds = this.getMapBounds(region);

        // Work out the diagonal size of the map in miles
        const map_size = this.coordDistMiles(
            bounds.northWest.lat,
            bounds.northWest.lng,
            bounds.southEast.lat,
            bounds.southEast.lng
        );

        return map_size;
    },

    // We are ok to reload the map if we move it half the screen
    // distance away or more.
    /*canReloadMap: function (old_region, new_region) {
        if (!old_region) {
            return false;
        }

        // Get the coords of the map corners
        const bounds = this.getMapBounds(old_region);

        // Work out the diagonal size of the map in miles
        const map_size = this.coordDistMiles(
            bounds.northWest.lat,
            bounds.northWest.lng,
            bounds.southEast.lat,
            bounds.southEast.lng
        );

        // How far we moved on the map in miles
        const moved = haversine(old_region, new_region, { unit: "mile" }) || 0;

        if (moved > map_size / 3) {
            return true;
        }

        return false;
    },*/

    getMapBounds: function (region) {
        var b = {};
        b.northWest = region.nw;
        b.northEast = region.ne;
        b.southWest = region.sw;
        b.southEast = region.se;

        return b;
    },

    getMapBounds2: function (region) {
        var b = {};
        b.northWest = {};
        b.northEast = {};
        b.southWest = {};
        b.southEast = {};

        b.northWest.lat =
            parseFloat(region.latitude) +
            parseFloat(region.latitudeDelta) / 2.0;
        b.northWest.lng =
            parseFloat(region.longitude) -
            parseFloat(region.longitudeDelta) / 2.0;

        b.southWest.lat =
            parseFloat(region.latitude) -
            parseFloat(region.latitudeDelta) / 2.0;
        b.southWest.lng =
            parseFloat(region.longitude) -
            parseFloat(region.longitudeDelta) / 2.0;

        b.northEast.lat =
            parseFloat(region.latitude) +
            parseFloat(region.latitudeDelta) / 2.0;
        b.northEast.lng =
            parseFloat(region.longitude) +
            parseFloat(region.longitudeDelta) / 2.0;

        b.southEast.lat =
            parseFloat(region.latitude) -
            parseFloat(region.latitudeDelta) / 2.0;
        b.southEast.lng =
            parseFloat(region.longitude) +
            parseFloat(region.longitudeDelta) / 2.0;

        return b;
    },

    coordDistMiles: function (cLat, cLon, lt, ln) {
        var distance =
            3959 *
            Math.acos(
                Math.cos((cLat * Math.PI) / 180) *
                Math.cos((lt * Math.PI) / 180) *
                Math.cos((ln * Math.PI) / 180 - (cLon * Math.PI) / 180) +
                Math.sin((cLat * Math.PI) / 180) *
                Math.sin((lt * Math.PI) / 180)
            );
        distance = Math.round(distance * 100) / 100;
        return distance;
    },

    deltaToZoom: function (longitudeDelta) {
        return Math.round(Math.log(360 / longitudeDelta) / Math.LN2);
    },

    zoomToDelta: function (zoom) {
        return Math.exp(Math.log(360) - zoom * Math.LN2);
    },

    timeAgo: function (from, expandedFormat) {
        let use_time = "?";
        const now = Math.round(new Date().getTime() / 1000);
        const time_diff = now - from;

        const minutes = Math.round(time_diff / 60);
        const hours = Math.round(time_diff / 60 / 60);
        const days = Math.floor(time_diff / 60 / 60 / 24);
        const weeks = Math.round(time_diff / 60 / 60 / 24 / 7);

        if (expandedFormat) {
            if (weeks > 400) {
                use_time = "";
            } else if (days > 30) {
                use_time = weeks + (weeks == 1 ? " week" : " weeks");
            } else if (hours > 23) {
                use_time = days + (days == 1 ? " day" : " days");
            } else if (minutes > 59) {
                use_time = hours + (hours == 1 ? " hour" : " hours");
            } else {
                use_time = minutes + (minutes == 1 ? " min" : " mins");
            }
        } else {
            if (days > 30) {
                use_time = weeks + "w";
            } else if (hours > 23) {
                use_time = days + "d";
            } else if (minutes > 59) {
                use_time = hours + "h";
            } else {
                use_time = minutes + "m";
            }
        }

        return use_time;
    },

    shortState: function (state) {
        let stateFull = state.toLowerCase();

        const states = {
            al: { full: "alabama", other: ["ala"] },
            ak: { full: "alaska", other: ["alas"] },
            az: { full: "arizona", other: ["ariz"] },
            ar: { full: "arkansas", other: ["ark"] },
            ca: { full: "california", other: ["calif", "cal"] },
            co: { full: "colorado", other: ["colo", "col"] },
            ct: { full: "connecticut", other: ["conn"] },
            de: { full: "delaware", other: ["del"] },
            dc: {
                full: "district of columbia",
                other: ["washington dc", "wash dc"]
            },
            fl: { full: "florida", other: ["fla", "flor"] },
            ga: { full: "georgia", other: [] },
            hi: { full: "hawaii", other: ["hawaii"] },
            id: { full: "idaho", other: ["ida"] },
            il: { full: "illinois", other: ["ill", "ills"] },
            in: { full: "indiana", other: ["ind"] },
            ia: { full: "iowa", other: ["ioa"] },
            ks: { full: "kansas", other: ["kans", "kan", "ka"] },
            ky: { full: "kentucky", other: ["ken", "kent"] },
            la: { full: "louisiana", other: [] },
            me: { full: "maine", other: [] },
            md: { full: "maryland", other: [] },
            ma: { full: "massachusetts", other: ["mass"] },
            mi: { full: "michigan", other: ["mich"] },
            mn: { full: "minnesota", other: ["minn"] },
            ms: { full: "mississippi", other: ["miss"] },
            mo: { full: "missouri", other: [] },
            mt: { full: "montana", other: ["mont"] },
            ne: { full: "nebraska", other: ["nebr", "neb"] },
            nv: { full: "nevada", other: ["nev"] },
            nh: { full: "new hampshire", other: [] },
            nj: { full: "new jersey", other: [] },
            nm: { full: "new mexico", other: ["n mex", "new m"] },
            ny: { full: "new york", other: ["n york"] },
            nc: { full: "north carolina", other: ["n carolina", "n car"] },
            nd: {
                full: "north dakota",
                other: ["n dakota", "n dak", "nodak", "no dak"]
            },
            oh: { full: "ohio", other: ["o"] },
            ok: { full: "oklahoma", other: ["okla"] },
            or: { full: "oregon", other: ["oreg", "ore"] },
            pa: { full: "pennsylvania", other: ["penn", "penna"] },
            ri: { full: "rhode island", other: ["ri pp", "r isl"] },
            sc: { full: "south carolina", other: ["s carolina", "s car"] },
            sd: {
                full: "south dakota",
                other: ["s dakota", "s dak", "sodak", "so dak"]
            },
            tn: { full: "tennessee", other: ["tenn"] },
            tx: { full: "texas", other: ["tex"] },
            ut: { full: "utah", other: [] },
            vt: { full: "vermont", other: [] },
            va: { full: "virginia", other: ["virg"] },
            wa: { full: "washington", other: ["wash", "wn"] },
            wv: {
                full: "west virginia",
                other: ["w virginia", "w va", "wva", "w virg"]
            },
            wi: { full: "wisconsin", other: ["wis", "wisc"] },
            wy: { full: "wyoming", other: ["wyo"] }
        };

        let found = null;

        Object.keys(states).forEach(function (key) {
            if (stateFull == states[key].full) {
                found = key.toUpperCase();
            }
        });
        if (found) {
            return found;
        }

        return state;
    },

    prettyPrice: function (num, round) {
        if (round) {
            num = Math.round(num);
        }
        if (!num) {
            num = 0;
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    shortPrice: function (number, k) {
        const SI_SYMBOL = ["", "k", "m", "G", "T", "P", "E"];

        // what tier? (determines SI symbol)
        let tier = (Math.log10(number) / 3) | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        let suffix = SI_SYMBOL[tier];
        let scale = Math.pow(10, tier * 3);

        // scale the number
        let scaled = number / scale;

        if (scaled === Math.round(scaled)) {
            scaled = Math.abs(scaled);
        } else {
            if (scaled.toFixed(1) == Math.round(scaled * 100) / 100) {
                scaled = scaled.toFixed(1);
            } else {
                scaled = scaled.toFixed(2);
            }
        }

        if (suffix === "k") {
            if (!k) {
                scaled = Math.round(scaled);
            }

            // Avoid 1000k
            if (scaled === 1000) {
                scaled = 1;
                suffix = "m";
            }
        }

        // format number and add suffix
        return scaled + suffix;
    }
};

export default MapHelpers;

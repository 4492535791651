import React from "react";
import {
    FiX
} from "react-icons/fi";

const Tag = (props) => {
    const { tag, onClick, onRemove, asFilter, displayOnly, largeMode, disableSystemTag } = props;

    if (displayOnly) {
        return (
            <>
                <span
                    className="tag tag-filter"
                    style={{ color: tag.text_color ? tag.text_color : null, backgroundColor: tag.tag_color ? tag.tag_color : null }}
                >
                    {tag.tag_long}
                </span>
            </>
        );
    }

    if (asFilter) {
        return (
            <>
                <a href="#" onClick={(e) => {
                    onRemove(tag)
                    e.preventDefault()
                }}>
                    <span
                        className="tag tag-filter"
                        style={{ color: tag.text_color ? tag.text_color : null, backgroundColor: tag.tag_color ? tag.tag_color : null }}
                    >
                        {tag.tag_long}

                        {disableSystemTag ? null : (
                            <FiX style={{ marginLeft: 5 }} />
                        )}
                    </span>
                </a>
            </>
        );
    }

    return (
        <>
            <a href="#" onClick={(e) => {
                onClick(tag)
                e.preventDefault()
            }}>
                <span
                    className={largeMode ? "tag tag-filter" : "tag"}
                    style={{ color: tag.text_color ? tag.text_color : null, backgroundColor: tag.tag_color ? tag.tag_color : null }}
                >
                    {tag.tag_long}
                </span>
            </a>
        </>
    );
}

export default Tag;

import { observable, action } from "mobx";

const def_filters = {
    status: {
        active: true,
        open: true,
        pocket: true,
        pending: false,
        closed: false,
        lease: false
    },
    type: [], // [ 'sfamily', 'condo' ... ]
    beds: null,
    baths: null,
    price: {
        from: null,
        to: null
    },
    dom: {
        from: null,
        to: null
    },
    year_built: {
        from: null,
        to: null
    },
    sqft: {
        from: null,
        to: null
    },
    lot_size: {
        from: null,
        to: null
    },
    sort: null,
    reductions: false,
    custom: {},
};

class FilterStore {
    @observable
    filters = def_filters;

    // Check if we have active filters, i.e. not the defaults
    @action
    hasActiveFilters() {
        if (JSON.stringify(def_filters) === JSON.stringify(this.filters)) {
            return false;
        }

        return true;
    }

    /*@computed get total() {
    return this.price * this.amount;
  }*/

    // Get all filters
    @action
    getFilters() {
        return JSON.parse(JSON.stringify(this.filters));
    }

    // Replace everything
    @action
    changeFilters(data) {
        this.filters = data;
    }

    // Reset to defaults
    @action
    resetFilters() {
        this.changeFilters(def_filters);
    }

    // This will overwite anything set im data but leave the others
    @action
    updateFilters(data) {
        this.filters = { ...this.filters, ...data };
    }

    // Update a single item
    @action
    updateFilter(key, val) {
        this.filters[key] = val;
    }

    @action
    updateFilterObject(key1, key2, val) {
        // for open, active etc.. vs lease
        if (key1 == "status") {
            if (key2 == "lease" && val) {
                this.filters.status.active = false;
                this.filters.status.open = false;
                this.filters.status.pending = false;
                this.filters.status.closed = false;
                this.filters.status.pocket = false;
            } else {
                this.filters.status.lease = false;
            }
        }

        if (key1 == "custom") {
            if (Array.isArray(this.filters[key1])) {
                this.filters[key1] = {}
            }

            if (!this.filters[key1][key2]) {
                this.filters[key1][key2] = true;
            }
        }

        this.filters[key1][key2] = val;
    }

    @action
    toggleFilterType(val) {
        // If val in array, remove it, else add it

        if (this.filters.type.includes(val)) {
            let index = this.filters.type.indexOf(val);
            if (index !== -1) this.filters.type.splice(index, 1);
        } else {
            this.filters.type.push(val);
        }
    }
}

export default new FilterStore();

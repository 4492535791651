import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import moment from 'moment';

@observer
class ListingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  render() {
    const {listing} = this.props;

    if (!listing) {
        return null;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });

      let given = moment.unix(listing.listing_date);
      let current = moment().startOf('day');
      let dom = current.diff(given, 'days');

    return (
        <div style={{paddingTop:30}}>
          <h4>{listing.full_address}, {listing.city}, {listing.state}, {listing.zip}</h4>
            <h5>{formatter.format(listing.price)}</h5>

            <div style={{height:290,overflow:"scroll"}}>
          <div style={{
              overflowX:"scroll",
              display:"flex"
          }}>
              {listing.photos.map(item => (
                <img
                    src={item}
                    //class="avatar-img rounded"
                    style={{objectFit:"cover",minWidth:150,height:100,borderRadius:4,marginRight:5}}
                />
                ))}
          </div>

          <div style={{display:"flex",justifyContent:"space-between",marginTop:16}}>
              <span>{listing.beds} beds</span>
              <span>{listing.baths} baths</span>
              <span>{listing.lot_size} sqft</span>
              <span>{dom} days on app</span>
          </div>
          
          <div style={{display:"flex",marginTop:16,flexWrap: 'wrap'}}>
                {Object.keys(listing.details_list).map((key, index) => (
                    <div style={{flex: "1 1 160px", marginBottom:6}}>
                        <strong>{key}</strong><br/>
                        {listing.details_list[key]}
                    </div>
                ))}
          </div>

          <div style={{display:"flex",marginTop:16,flexWrap: 'wrap'}}>
              <strong>Description</strong>
              {listing.description}
          </div>

          <div style={{display:"flex",marginTop:16,flexWrap: 'wrap',color:"#777",fontSize:12}}>
              <strong>Disclaimer</strong>
              {listing.mls.mls_disclaimer}
          </div>

          <div style={{display:"flex",justifyContent:"flex-end",marginTop:16,paddingBottom:80}}>
              <button className="btn btn-primary" onClick={() => {
                  this.props.onClose();
              }}>Close</button>
          </div>
          </div>
          
          </div>
    );
  }
}

export default ListingModal;

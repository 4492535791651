import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import GoogleMapReact from "google-map-react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import api from "../../lib/api";
import Autosuggest from "react-autosuggest";
import SavedSearchFilters from "./SavedSearchFilters";
import FilterStore from "stores/FilterStore";
import { Check } from "react-feather";
import { FiX, FiPlusCircle, FiVideo } from "react-icons/fi";
import moment from "moment";
import Rodal from "rodal";

let drawingManager = null;
let gMap = null;
let forceRefresh = false;
let overlays = [];
let the_mode = null;
let moogle = null;

const renderSuggestion = (suggestion) => (
    <span>
        <b>{suggestion.text}</b>
    </span>
);

@observer
class AgentSavedSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_type: "saved",
            id: null,
            loading: true,
            error: false,
            lat: 34.1466,
            lng: -118.8074,
            zoom: 14,
            user: null,
            search: "",
            value: "",
            email: true,
            push: true,
            suggestions: [],
            alertListingKey: null,
            alertListingAddress: null,
            name: "",
            region: {
                zoom: 14,
                lat: 34.1466,
                lng: -118.8074,
                bounds: null
            },
            listings: [],
            loadingListings: true,
            listingPage: null,
            mobileMap: false,
            hoverListing: null,
            quickPreview: null,
            firstLoad: true,
            mapType: "roadmap",
            polygon: null,
            drawMode: false,
            showSaveSearch: false,
            edit: false,
            editing: null,
            set_pol: null,
            set_reg: null,
            redir: null,
            loadingPreview: true,
            videoModal: false,
            links: []
        };
    }

    componentDidMount() {
        const currentLocation = window.location.pathname
            .replace(/\//, "")
            .replace(/\/.*/, "");

        const link_state = this.props.location.state;

        if (currentLocation == "searchlinks") {
            this.setState({ current_type: "link", loading: false })
        } else {

            if (link_state?.user) {
                this.setState({ loading: false, user: link_state.user });
            } else {
                const pn = window.location.pathname.split("/");
                let user_id = null;

                if (pn.length > 1) {
                    user_id = pn[2];

                    api.getUsers(
                        null,
                        null,
                        null,
                        (response) => {
                            if (!response) {
                                this.setState({ error: true });
                            } else {
                                if (response.length == 0) {
                                    this.setState({ error: true });
                                } else {
                                    //alert(JSON.stringify(response.users));
                                    this.setState({ loading: false, user: response.users });
                                }
                            }
                        },
                        (error) => {
                            this.setState({ error: true });
                        },
                        user_id
                    );
                } else {
                    this.setState({ error: true, loading: false });
                }
            }

        }

        FilterStore.resetFilters();

        if (link_state?.edit) {
            let curr = link_state.edit;
            //alert(JSON.stringify(curr));
            let reg = this.state.region;
            reg.lat = curr.lat;
            reg.lng = curr.lng;

            if (currentLocation != "searchlinks") {
                FilterStore.updateFilters(JSON.parse(curr.filters));
            } else {
                FilterStore.updateFilters(curr.filters);
                //alert(curr.filters);
            }

            let pol = null;
            if (curr.polygon) {
                //pol = JSON.parse(curr.polygon);

                let old_pol = JSON.parse(curr.polygon);
                let new_pol = [];
                for (let x = 0; x < old_pol.length; x++) {
                    new_pol.push([old_pol[x].longitude, old_pol[x].latitude]);
                }
                pol = new_pol;
            }

            this.setState({
                editing: curr,
                edit: true,
                id: curr.id,
                name: curr.search_name ? curr.search_name : curr?.name ? curr.name : null,
                region: reg,
                email: curr.notify_email == 1 ? true : false,
                push: curr.notify_push == 1 ? true : false,
                //polygon: pol
            });

            if (pol) {
                setTimeout(() => {
                    this.setState({
                        set_pol: pol,
                        set_reg: reg
                    });
                }, 2000);
            }
        }

        this.loadLinks();

        const appdata = AppStore.app_data;

        if (appdata.settings.default_region.lat && appdata.settings.default_region.lng) {
            let reg = {
                lat: parseFloat(appdata.settings.default_region.lat),
                lng: parseFloat(appdata.settings.default_region.lng),
                zoom: parseInt(appdata.settings.default_region.zoom),
                bounds: null
            };
            this.setState({ region: reg })
        }
    }

    loadLinks() {
        api.loadSearchLinks(
            (response) => {
                this.setState({
                    links: response.links,
                });
            },
            (error) => {
            }
        );
    }

    componentDidUpdate() {
        if (this.state.set_pol) {

            this.setState({
                polygon: this.state.set_pol
            });

            forceRefresh = true;
            //FilterStore.updateFilters(item.filters);

            AppStore.setRefreshData({
                lat: this.state.set_reg.lat,
                lng: this.state.set_reg.lng,
                polygon: this.state.set_pol
            });

            this.setState({ set_pol: null })
        }

        if (forceRefresh) {
            if (AppStore.refreshData) {
                const reg = this.state.region;

                const has_entries = AppStore?.refreshData?.polygon ?? [];

                reg.lat = AppStore.refreshData.lat;
                reg.lng = AppStore.refreshData.lng;
                if (AppStore.refreshData.polygon && has_entries.length) {
                    this.setState({ polygon: AppStore.refreshData.polygon });

                    let new_coords = [];
                    let coords = AppStore.refreshData.polygon;

                    coords.map(item => {
                        new_coords.push({
                            lat: parseFloat(item[1]),
                            lng: parseFloat(item[0])
                        });
                    });

                    let myCounty = new moogle.maps.Polygon({
                        paths: new_coords,
                        strokeColor: "#FD7E02",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "rgba(253,126,2,0.5)",
                        fillOpacity: 0.35,
                        map: gMap,
                        editable: true
                    });
                    overlays.push(myCounty);
                    myCounty.setMap(gMap);

                    let self = this;

                    moogle.maps.event.addListener(
                        myCounty.getPath(),
                        "set_at",
                        function () {
                            const coords2 = myCounty
                                .getPath()
                                .getArray()
                                .map(coord => {
                                    return {
                                        lat: coord.lat(),
                                        lng: coord.lng()
                                    };
                                });

                            let new_coords = [];
                            coords2.map(item => {
                                new_coords.push({
                                    longitude: item.lng,
                                    latitude: item.lat
                                });
                            });

                            self.setState({
                                polygon: new_coords,
                                drawMode: false
                            });
                            //self.loadListings(self.state.region);
                        }
                    );

                    var bounds = new moogle.maps.LatLngBounds();
                    for (var i = 0; i < new_coords.length; i++) {
                        const latLng = new moogle.maps.LatLng(
                            new_coords[i].lat,
                            new_coords[i].lng
                        );
                        bounds.extend(latLng);
                    }
                    gMap.fitBounds(bounds);
                } else {
                    this.setState({
                        polygon: null
                    });
                }
                AppStore.refreshData = null;

                this.setState({ region: reg, showSaveSearch: true });
            }
            forceRefresh = false;
        }
    }

    handleGoogleMapApi = (google) => {
        drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            //drawingMode: null,
            drawingControl: false,
            polygonOptions: {
                draggable: true,
                editable: true,
                strokeColor: "#FD7E02",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "rgba(253,126,2,0.5)",
                fillOpacity: 0.35
            }
        });

        moogle = google;

        if (this.state.drawMode) {
            drawingManager.setDrawingMode(
                google.maps.drawing.OverlayType.POLYGON
            );
        } else {
            drawingManager.setDrawingMode(null);
        }

        the_mode = google.maps.drawing.OverlayType.POLYGON;

        let self = this;
        gMap = google.map;

        // Polygon complete
        google.maps.event.addListener(
            drawingManager,
            "polygoncomplete",
            function (polygon) {
                const coords = polygon
                    .getPath()
                    .getArray()
                    .map(coord => {
                        return {
                            lat: coord.lat(),
                            lng: coord.lng()
                        };
                    });

                console.log(JSON.stringify(coords, null, 1));

                // Polygon updated
                google.maps.event.addListener(
                    polygon.getPath(),
                    "set_at",
                    function () {
                        const coords2 = polygon
                            .getPath()
                            .getArray()
                            .map(coord => {
                                return {
                                    lat: coord.lat(),
                                    lng: coord.lng()
                                };
                            });

                        let new_coords = [];
                        coords2.map(item => {
                            new_coords.push({
                                longitude: item.lng,
                                latitude: item.lat
                            });
                        });

                        self.setState({ polygon: new_coords, drawMode: false });
                        // TODO: got polygon here
                    }
                );

                overlays.push(polygon);

                let new_coords = [];
                coords.map(item => {
                    new_coords.push({
                        longitude: item.lng,
                        latitude: item.lat
                    });
                });

                drawingManager.setDrawingMode(null);
                self.setState({ polygon: new_coords, drawMode: false });
                console.log("self", self.state.region);
                //self.loadListings(self.state.region);

                // TODO SAVE COORDINATES HERE
            }
        );

        drawingManager.setMap(google.map);

        /*google.maps.event.addListener(gMap, "dragend", function (event) {
            const lat = this.position.lat();
            const lng = this.position.lng();
            self.setState({
                lat: lat,
                lng: lng,
            });
            console.log('smog')
        });*/

        google.maps.event.addListener(gMap, "dragend", function () {
            var center = this.getCenter();
            var latitude = center.lat();
            var longitude = center.lng();

            let reg = self.state.region;
            reg.lat = latitude;
            reg.lng = longitude;

            self.setState({ region: reg });
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        api.getListingSuggestions(
            value,
            (response) => {
                //
                console.log(response);

                let sug = [];
                const mls = response.mls;
                const area = response.area;
                const city = response.city;
                const zip = response.zip;

                city.map((item) => {
                    sug.push({
                        lat: item.lat,
                        lng: item.lng,
                        polygon: item.polygon,
                        text:
                            item.value
                    });
                });

                zip.map((item) => {
                    sug.push({
                        lat: item.lat,
                        lng: item.lng,
                        polygon: item.polygon,
                        text:
                            item.value
                    });
                });

                this.setState({
                    suggestions: sug,
                    alertListingKey: null,
                    alertListingAddress: null,
                });
            },
            (error) => {
                this.setState({
                    suggestions: [],
                    alertListingKey: null,
                    alertListingAddress: null,
                });
            }
        );
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onChange = (event, { newValue, method }) => {
        this.setState({ value: newValue });
    };

    getSuggestionValue(suggestion) {
        let reg = {
            lat: suggestion.lat,
            lng: suggestion.lng,
            zoom: this.state.region.zoom,
            bounds: null
        };

        this.setState({
            //alertListingKey: suggestion.key,
            //lat: suggestion.lat,
            //lng: suggestion.lng,
            polygon: JSON.parse(suggestion.polygon),
            region: reg,
            alertListingAddress: suggestion.text,
        });

        forceRefresh = true;
        //FilterStore.updateFilters(item.filters);

        AppStore.setRefreshData({
            lat: suggestion.lat,
            lng: suggestion.lng,
            polygon: JSON.parse(suggestion.polygon)
        });

        return suggestion.text;
    }

    loadListings(_callback) {
        this.setState({ loadingPreview: true, listings: [] });

        const reg = this.state.region;

        api.listings(
            {
                lat: reg.lat,
                lng: reg.lng,
                miles: 5,
                //type: this.state.searchType,
                //value: this.state.searchValue,
                filters: JSON.stringify(FilterStore.filters),
                polygon: this.state.polygon,
                since: moment().subtract('weeks', 1).unix()
            },
            response => {
                this.setState({ loadingPreview: false, listings: response.properties });
                _callback();
            }, error => {
                this.setState({ loadingPreview: false, listings: [] });
                _callback();
            });
    }

    sideModal() {
        const { user, editing } = this.state;
        const listings = this.state.listings;

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return (
            <div
                class="modal fade modal-slide-right"
                id="slideRightModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="slideRightModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="slideRightModalLabel">
                                Preview Saved Search
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div
                            class="modal-body"
                            style={{ maxHeight: "100vh", overflow: "scroll" }}
                        >
                            {this.state.loadingPreview && (
                                <div
                                    class="d-flex w-100 justify-content-center align-items-center"
                                    style={{ height: "100vh" }}
                                >
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}

                            {listings.length == 0 && (
                                <p>No results found within the last week for these filters</p>
                            )}

                            {listings.length > 0 && (
                                <p>
                                    <strong>{listings.length} listings found within the last week</strong>
                                </p>
                            )}

                            {listings.map((item, index) => (
                                <div class="listing-preview" style={{ display: "flex", flexDirection: "row", marginBottom: 4, cursor: "pointer" }} onClick={(e) => {
                                    e.preventDefault();
                                    //const closing = document.getElementById('close_this_modal');
                                    //closing.click();
                                    AppStore.showListingModal(item.listing_key);
                                }}>
                                    <div style={{ marginRight: 6 }}>
                                        <img src={item.photos[0]} style={{ borderRadius: 3, width: 90, height: 60, objectFit: "cover" }} />
                                    </div>
                                    <div>
                                        <strong>{item.full_address}, {item.city}, {item.state}, {item.zip}</strong><br />
                                        <strong>{formatter.format(item.price)}</strong>, {item.beds} beds, {item.baths} baths, {item.building_size} sqft
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div class="modal-footer">
                            <button
                                style={{ display: "none" }}
                                id="modal_link_close"
                                type="button"
                                class="btn btn-success"
                                data-dismiss="modal"
                            >Close</button>

                            <button
                                type="button"
                                class="btn btn-info"
                                //data-dismiss="modal"
                                onClick={() => {
                                    var link = document.getElementById('modal_link_close');
                                    link.click();

                                    this.setState({
                                        redir: '/users/' + user.user_id
                                    });
                                }}
                            >
                                Close
                            </button>

                            &nbsp;&nbsp;&nbsp;

                            <button
                                type="button"
                                class="btn btn-success"
                                //data-dismiss="modal"
                                onClick={() => {
                                    var link = document.getElementById('modal_link_close');
                                    link.click();

                                    window.showToastMessage(
                                        "Sending",
                                        "Search will be sent shortly..."
                                    );

                                    this.setState({
                                        redir: '/users/' + user.user_id
                                    });
                                }}
                            >
                                Send Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.redir) {
            FilterStore.resetFilters();

            return <Redirect to={this.state.redir} />;
        }

        if (this.state.error) {
            return (
                <Inner>
                    <div class="content">
                        <div className="centerBox">
                            <img src="https://bk.homestack.com/assets/img/not-found.svg" />
                            <h2>Unable to load data</h2>
                            <p>
                                <button
                                    onClick={() => {
                                        this.setState({ error: false });
                                        this.loadPrefs();
                                    }}
                                >
                                    Try again
                                </button>
                            </p>
                        </div>
                    </div>
                </Inner>
            );
        }

        if (this.state.loading) {
            return (
                <Inner>
                    <div
                        class="d-flex w-100 justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </Inner>
            );
        }

        const { user, editing, links } = this.state;

        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "e.g. Los Angeles",
            value,
            onChange: this.onChange,
            className: "form-control",
        };

        return (
            <Inner>
                <Rodal
                    width={"90%"}
                    customStyles={{ maxWidth: 600 }}
                    visible={this.state.videoModal}
                    onClose={() => { this.setState({ videoModal: false }) }}
                    //width={600}
                    height={400}
                >
                    <p><iframe src="https://player.vimeo.com/video/742780639" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
                </Rodal>

                <section class="admin-content">
                    {this.sideModal()}

                    <div class="bg-dark">
                        <div class="container  m-b-30">
                            <div class="row">
                                <div class="col-12 text-white p-t-40 p-b-90">
                                    <div class="float-right">

                                        {this.state.edit && (
                                            <button style={{ flex: 0.4, marginLeft: 10 }} className="btn btn-danger" onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this search?')) {
                                                    if (this.state.id) {
                                                        let id = this.state.id;

                                                        if (this.state.current_type == "link") {
                                                            api.saveSearchLink({
                                                                id: id,
                                                                delete: 1,
                                                                //user_id: user.user_id
                                                            }, response => {
                                                                // success
                                                                window.showToastMessage(
                                                                    "Success",
                                                                    "Search was deleted!"
                                                                );

                                                                this.setState({
                                                                    redir: '/searchlinks'
                                                                });
                                                            }, error => {
                                                                alert("Error: " + error)
                                                            });
                                                        } else {
                                                            api.saveSearch({
                                                                id: id,
                                                                delete: 1,
                                                                user_id: user.user_id
                                                            }, response => {
                                                                // success
                                                                window.showToastMessage(
                                                                    "Success",
                                                                    "Search was deleted!"
                                                                );

                                                                this.setState({
                                                                    redir: '/users/' + user.user_id
                                                                });
                                                            }, error => {
                                                                alert("Error: " + error)
                                                            });
                                                        }
                                                    }
                                                }
                                            }}>Delete</button>
                                        )}


                                    </div>
                                    <h4 class="">
                                        {" "}
                                        <span class="btn btn-white-translucent">
                                            <i class="icon-placeholder fe fe-search " />
                                        </span>{" "}
                                        {this.state.edit ? "Edit " : "Create "}
                                        {this.state.current_type == "saved" ? "Agent Saved Search for " + user?.display_name : "Search Template"}

                                        {this.state.current_type != "link" && (
                                            <>
                                                &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                                                    this.setState({ videoModal: true })
                                                }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                                            </>
                                        )}
                                    </h4>

                                    {AppStore.isEmbedded ? (
                                        <div style={{ textAlign: "center" }}>
                                            <a style={{ fontWeight: "bold", padding: "8px 12px", borderRadius: 8, backgroundColor: "#ffffff30" }}
                                                href="#"
                                                onClick={() => {
                                                    if (this.state.current_type == "saved") {
                                                        this.setState({
                                                            redir: "/users/" + user.user_id
                                                        })
                                                    } else {
                                                        this.setState({
                                                            redir: "/searchlinks"
                                                        })
                                                    }
                                                }}
                                                target="_blank">
                                                Back to {this.state.current_type == "saved" ? "user profile" : "search templates"}
                                            </a>
                                        </div>
                                    ) : (
                                        <p>
                                            <a href="#" onClick={() => {
                                                if (this.state.current_type == "saved") {
                                                    this.setState({
                                                        redir: "/users/" + user.user_id
                                                    })
                                                } else {
                                                    this.setState({
                                                        redir: "/searchlinks"
                                                    })
                                                }
                                            }} style={{ color: "#fff" }}>
                                                Back to {this.state.current_type == "saved" ? "user profile" : "search templates"}
                                            </a>
                                        </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container pull-up">
                        <div class="row">
                            <div class="col-lg-6">

                                {/*
                                <div class="card m-b-30">
                                    <div class="card-body ">
                                        <h5>Search Type</h5>

                                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                                            <a
                                                href="#"
                                                style={{
                                                    border: this.state.current_type == "saved" ? "1px solid #2d68a5" : "1px solid #ddd",
                                                    backgroundColor: this.state.current_type == "saved" ? "#e1eefa" : "#eee",
                                                    color: this.state.current_type == "saved" ? "#2d68a5" : "#999",
                                                    fontWeight: "bold",
                                                    padding: "8px 12px",
                                                    borderRadius: 6,
                                                    marginRight: 10
                                                }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ current_type: "saved" })
                                                }}>
                                                Saved Search
                                            </a>

                                            {!this.state.edit && (
                                                <a
                                                    href="#"
                                                    style={{
                                                        border: this.state.current_type == "link" ? "1px solid #2d68a5" : "1px solid #ddd",
                                                        backgroundColor: this.state.current_type == "link" ? "#e1eefa" : "#eee",
                                                        color: this.state.current_type == "link" ? "#2d68a5" : "#999",
                                                        fontWeight: "bold",
                                                        padding: "8px 12px",
                                                        borderRadius: 6,
                                                        marginRight: 10
                                                    }}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({ current_type: "link" })
                                                    }}>
                                                    Search Template
                                                </a>
                                            )}
                                        </div>

                                        {!this.state.edit && (
                                            <p><small>A Saved Search will notify the user of future listings matching the chosen filters. A Search Template will open a current search with the matching filters and the user can choose to save or not.</small></p>
                                        )}
                                    </div>
                                </div>
                                */}

                                {this.state.current_type == "saved" && links.length >= 0 && (
                                    <div class="card m-b-30">
                                        <div class="card-body ">
                                            <p>
                                                <label for="name">
                                                    Create Search
                                                </label>

                                                <select
                                                    value={this.state.domValue}
                                                    className="form-control"
                                                    style={{
                                                        border: "1px solid #b4b4b4",
                                                        fontSize: 16
                                                    }}
                                                    onChange={e => {
                                                        if (e.target.value != 0) {
                                                            if (window.confirm('Are you sure you want to replace all filters with those of the chosen search template?')) {

                                                                for (let x = 0; x < links.length; x++) {
                                                                    if (links[x].id == e.target.value) {
                                                                        const clink = links[x];
                                                                        //alert(JSON.stringify(links[x]));

                                                                        for (
                                                                            let i = 0;
                                                                            i < overlays.length;
                                                                            i++
                                                                        ) {
                                                                            overlays[i].setMap(null);
                                                                        }

                                                                        this.setState({
                                                                            name: clink.name,
                                                                            drawMode: false,
                                                                            polygon: null
                                                                        });

                                                                        let reg = this.state.region;
                                                                        reg.lat = clink.lat;
                                                                        reg.lng = clink.lng;


                                                                        FilterStore.updateFilters(clink.filters);

                                                                        let pol = null;
                                                                        if (clink.polygon) {
                                                                            //pol = JSON.parse(curr.polygon);

                                                                            let old_pol = JSON.parse(clink.polygon);
                                                                            let new_pol = [];
                                                                            for (let x = 0; x < old_pol.length; x++) {
                                                                                new_pol.push([old_pol[x].longitude, old_pol[x].latitude]);
                                                                            }
                                                                            pol = new_pol;
                                                                        }

                                                                        if (pol) {
                                                                            setTimeout(() => {
                                                                                this.setState({
                                                                                    set_pol: pol,
                                                                                    set_reg: reg
                                                                                });
                                                                            }, 2000);
                                                                        }
                                                                    }
                                                                }

                                                            }
                                                        }
                                                    }}
                                                >
                                                    <option value={0}>Create New Search</option>
                                                    {links.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </p>
                                        </div>
                                    </div>
                                )}


                                {this.state.current_type == "saved" && (
                                    <div class="card m-b-30">

                                        <div class="card-body ">

                                            <p style={{ display: "none" }}>
                                                <a
                                                    href="#"
                                                    id="modal_link"
                                                    onClick={() => {
                                                        // ...
                                                        //
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#slideRightModal"
                                                    className="text-underline"
                                                >
                                                    Preview
                                                </a>
                                            </p>

                                            <p>
                                                <label for="name">
                                                    Saved Search Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    class="form-control"
                                                    id="name"
                                                    placeholder="e.g. Los Angeles $1m+"
                                                    onChange={(e) => {
                                                        this.setState({ name: e.target.value });
                                                    }}
                                                    value={this.state.name}
                                                />
                                            </p>


                                            <strong>Notification preferences</strong>



                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer" }} onClick={() => {
                                                this.setState({ email: !this.state.email })
                                            }}>
                                                <div
                                                    style={{
                                                        width: 22,
                                                        height: 22,
                                                        borderRadius: 3,
                                                        border: "1px solid #b4b4b4",
                                                        marginRight: 9,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <Check
                                                        size={16}
                                                        color={
                                                            this.state.email
                                                                ? AppStore.app_data.theme.primary
                                                                : "#fff"
                                                        }
                                                    />
                                                </div>
                                                <span>Email</span>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer" }} onClick={() => {
                                                this.setState({ push: !this.state.push })
                                            }}>
                                                <div
                                                    style={{
                                                        width: 22,
                                                        height: 22,
                                                        borderRadius: 3,
                                                        border: "1px solid #b4b4b4",
                                                        marginRight: 9,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <Check
                                                        size={16}
                                                        color={
                                                            this.state.push
                                                                ? AppStore.app_data.theme.primary
                                                                : "#fff"
                                                        }
                                                    />
                                                </div>
                                                <span>Push notifications</span>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {this.state.current_type == "link" && (
                                    <div class="card m-b-30">

                                        <div class="card-body ">

                                            <p style={{ display: "none" }}>
                                                <a
                                                    href="#"
                                                    id="modal_link"
                                                    onClick={() => {
                                                        // ...
                                                        //
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#slideRightModal"
                                                    className="text-underline"
                                                >
                                                    Preview
                                                </a>
                                            </p>

                                            <p>
                                                <label for="name">
                                                    Search Template Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    class="form-control"
                                                    id="name"
                                                    placeholder="e.g. Los Angeles $1m+"
                                                    onChange={(e) => {
                                                        this.setState({ name: e.target.value });
                                                    }}
                                                    value={this.state.name}
                                                />
                                            </p>




                                        </div>
                                    </div>
                                )}

                                <div class="card m-b-30">

                                    <div class="card-body ">
                                        <p>
                                            <label for="listing">Search city, zip, or draw on map... <span className="text-danger">*</span></label>
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={
                                                    this.onSuggestionsFetchRequested
                                                }
                                                onSuggestionsClearRequested={
                                                    this.onSuggestionsClearRequested
                                                }
                                                getSuggestionValue={(val) =>
                                                    this.getSuggestionValue(val)
                                                }
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                            />
                                        </p>



                                        <div style={{
                                            height: 300,
                                            marginBottom: 10,
                                            position: "relative",
                                        }}>
                                            {!this.state.polygon && !this.state.drawMode ? (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        bottom: 20,
                                                        left: 20,
                                                        width: 50,
                                                        height: 50,
                                                        borderRadius: 25,
                                                        backgroundColor: "#fff",
                                                        zIndex: 60,
                                                        boxShadow:
                                                            "0px 0px 10px 0px rgba(0,0,0,0.15)",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={() => {
                                                        //tgg
                                                        drawingManager.setDrawingMode(
                                                            the_mode
                                                        );

                                                        for (
                                                            let i = 0;
                                                            i < overlays.length;
                                                            i++
                                                        ) {
                                                            overlays[i].setMap(null);
                                                        }

                                                        // Stop at one polygon!
                                                        this.setState({
                                                            drawMode: !this.state.drawMode
                                                        });
                                                    }}
                                                >
                                                    <img
                                                        src="/assets/img/draw-on-map.svg"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        bottom: 20,
                                                        left: 20,
                                                        width: 90,
                                                        height: 50,
                                                        borderRadius: 25,
                                                        backgroundColor: "#fff",
                                                        zIndex: 60,
                                                        boxShadow:
                                                            "0px 0px 10px 0px rgba(0,0,0,0.15)",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: 18,
                                                        color:
                                                            AppStore.app_data.theme.primary,
                                                        fontWeight: "600"
                                                    }}
                                                    onClick={() => {
                                                        // Stop at one polygon!

                                                        for (
                                                            let i = 0;
                                                            i < overlays.length;
                                                            i++
                                                        ) {
                                                            overlays[i].setMap(null);
                                                        }

                                                        drawingManager.setDrawingMode(null);
                                                        this.setState({
                                                            drawMode: false,
                                                            polygon: null
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            )}

                                            <GoogleMapReact
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={(google2) =>
                                                    this.handleGoogleMapApi(google2)
                                                }
                                                options={(maps) => {
                                                    return {
                                                        zoomControl: true,
                                                        fullscreenControl: false,
                                                        mapTypeId: "roadmap", //this.state.mapType
                                                    };
                                                }}
                                                // onChange={_debounce(this.mapMoved, 1500)}
                                                bootstrapURLKeys={{
                                                    libraries: "drawing",
                                                    key: "AIzaSyCKUKIYX3gchVw_hWarG8X4Mb9IdaLrclo",
                                                }}
                                                center={{
                                                    lat: parseFloat(this.state.region.lat),
                                                    lng: parseFloat(this.state.region.lng),
                                                }}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                defaultZoom={this.state.region.zoom}
                                            />

                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="col-lg-6" style={{}}>
                                <div class="card m-b-30">
                                    <div class="card-body ">

                                        <SavedSearchFilters />

                                    </div>

                                </div>

                                <div class=" m-b-30">

                                    <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "transparent" }}>
                                        <button
                                            style={{ flex: 1 }}
                                            className="btn btn-primary"
                                            onClick={() => {
                                                let id = this.state.id;
                                                let name = this.state.name;
                                                let lat = this.state.region.lat;
                                                let lng = this.state.region.lng;
                                                let polygon = this.state.polygon;
                                                let filters = FilterStore.filters;
                                                let email = this.state.email;
                                                let push = this.state.push;
                                                let fixed_polygon = 1;

                                                if (!name) {
                                                    alert("You must choose enter a name");
                                                    return;
                                                }

                                                if (this.state.edit && this.state.id) {

                                                    if (this.state.current_type == "link") {

                                                        api.saveSearchLink({
                                                            id: id,
                                                            search_name: name,
                                                            lat: lat,
                                                            lng: lng,
                                                            filters: filters,
                                                            polygon: polygon,
                                                            //notify_email: email,
                                                            //notify_push: push,
                                                            //user_id: this.state.current_type == "search" ? user.user_id : null
                                                        }, response => {
                                                            // success
                                                            window.showToastMessage(
                                                                "Success",
                                                                "Search Template was updated!"
                                                            );

                                                            this.setState({
                                                                edit: true,
                                                                id: response.id
                                                            });

                                                            this.setState({
                                                                redir: '/searchlinks/'
                                                            });
                                                        }, error => {
                                                            alert("Error: " + error)
                                                        });

                                                    } else {

                                                        api.saveSearch({
                                                            id: id,
                                                            search_name: name,
                                                            lat: lat,
                                                            lng: lng,
                                                            filters: filters,
                                                            polygon: polygon,
                                                            notify_email: email,
                                                            notify_push: push,
                                                            user_id: this.state.current_type == "saved" ? user.user_id : null
                                                        }, response => {
                                                            // success
                                                            window.showToastMessage(
                                                                "Success",
                                                                "Search was updated!"
                                                            );

                                                            this.setState({
                                                                edit: true,
                                                                id: response.id
                                                            });

                                                            this.setState({
                                                                redir: '/users/' + user.user_id
                                                            });
                                                        }, error => {
                                                            alert("Error: " + error)
                                                        });

                                                    }
                                                } else {
                                                    // Brand new, so we show the modal first

                                                    // Save always but if we have listings then
                                                    // show the sidebar, else just go back

                                                    if (1 == 1) {
                                                        if (this.state.current_type == "link") {

                                                            // TODO after crerate redir to the search Template page!

                                                            api.saveSearchLink({
                                                                search_name: name,
                                                                lat: lat,
                                                                lng: lng,
                                                                filters: filters,
                                                                polygon: polygon,
                                                                notify_email: email,
                                                                notify_push: push,
                                                                //user_id: user.user_id,
                                                                user_id: null,
                                                                //user_id: this.state.current_type == "search" ? user.user_id : null,
                                                                send_now: 1
                                                            }, response => {

                                                            }, error => {
                                                                alert("Error: " + error)
                                                            });

                                                            window.showToastMessage(
                                                                "Success",
                                                                "Search Template was sent!"
                                                            );

                                                            this.setState({
                                                                redir: '/searchlinks/'
                                                            });

                                                            return;
                                                        }

                                                        api.saveSearch({
                                                            search_name: name,
                                                            lat: lat,
                                                            lng: lng,
                                                            filters: filters,
                                                            polygon: polygon,
                                                            notify_email: email,
                                                            notify_push: push,
                                                            user_id: user.user_id,
                                                            send_now: 1
                                                        }, response => {

                                                        }, error => {
                                                            alert("Error: " + error)
                                                        });

                                                        this.loadListings(() => {
                                                            setTimeout(() => {
                                                                const listings = this.state.listings;

                                                                if (listings.length > 0) {
                                                                    var link = document.getElementById('modal_link');
                                                                    link.click();
                                                                } else {
                                                                    // Quit, toast and go back
                                                                    window.showToastMessage(
                                                                        "Success",
                                                                        "Search was saved!"
                                                                    );

                                                                    this.setState({
                                                                        redir: '/users/' + user.user_id
                                                                    });
                                                                }
                                                            }, 0);
                                                        });
                                                    } else {
                                                        api.saveSearch({
                                                            search_name: name,
                                                            lat: lat,
                                                            lng: lng,
                                                            filters: filters,
                                                            polygon: polygon,
                                                            notify_email: email,
                                                            notify_push: push,
                                                            user_id: user.user_id
                                                        }, response => {
                                                            // success
                                                            window.showToastMessage(
                                                                "Success",
                                                                "Search was saved!"
                                                            );

                                                            this.setState({
                                                                edit: true,
                                                                id: response.id
                                                            });

                                                            this.setState({
                                                                redir: '/users/' + user.user_id
                                                            });
                                                        }, error => {
                                                            alert("Error: " + error)
                                                        });
                                                    }

                                                }
                                            }}
                                        >{this.state.edit && this.state.id ? "Update" : "Save"}</button>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Inner >
        );
    }
}

const styles = {

};

export default AgentSavedSearch;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import Button from "@material-ui/core/Button";
import api from "../../lib/api";

import { Formik } from "formik";
import Dropzone from "react-dropzone";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

@observer
class MortgagePro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agent: null,
            error: false,
            loading: true,
            toAgents: false,
            agent_password: "",
            mpro: null,
            show_form: false,
            agent_toggle: false
        };
    }

    componentDidMount() {
        if (AppStore.app_data.toggles.mortgageProEditingEnabled) {
            this.loadProfile();
        }
    }

    loadProfile() {
        if (UserStore.isAdmin()) {
            api.getAgentMortgageProToggle(
                response => {
                    this.setState({ agent_toggle: response });
                }, error => {
                    // oops
                }
            );
        }

        api.getMpro(
            response => {
                if (!response) {

                    this.setState({ loading: false, mpro: null, show_form: false });
                } else {
                    if (Array.isArray(response) && !response.length) {
                        this.setState({ loading: false, mpro: null, show_form: false });
                    } else {
                        this.setState({ mpro: response, loading: false, show_form: true });
                    }
                }
            },
            error => {

                this.setState({ error: true, loading: false, mpro: null, show_form: false });
            }
        );
    }

    agents() {
        if (this.state.error) {
            return (
                <div className="centerBox">
                    <img src="https://bk.homestack.com/assets/img/not-found.svg" />
                    <h2>Unable to load data</h2>
                    <p>
                        <button
                            onClick={() => {
                                this.setState({ error: false });
                                this.loadProfile();
                            }}
                        >
                            Try again
                        </button>
                    </p>
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div
                    class="d-flex w-100 justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        const item = this.state.mpro;

        const { mpro } = this.state;

        return (
            <section class="admin-content">
                <div class="bg-dark bg-dots m-b-30">
                    <div class="container">
                        <div class="row p-b-60 p-t-60">
                            <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                                <div class="m-b-10">
                                    <div class="avatar avatar-lg ">
                                        <div class="avatar-title bg-info rounded-circle fe fe-briefcase " />
                                    </div>
                                </div>
                                <h3>Mortgage Pro </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container pull-up">
                    <div class="row">



                        <div class="col-lg-6">
                            <div class="card m-b-30">

                                {!mpro && !this.state.show_form ? (
                                    <>
                                        <div class="card-header">
                                            <h5 class="m-b-0">Mortgage Pro</h5>
                                        </div>
                                        <div class="card-body ">
                                            {!UserStore.isAdmin() && (<>
                                                {AppStore.app_data.services.mortgagePro != null ? (
                                                    <p>A default Mortgage Pro is set. If you add one here it will override it.</p>
                                                ) : (
                                                    <p>No default Mortgage Pro is set. You can add your own here.</p>
                                                )}
                                            </>)}

                                            {UserStore.isAdmin() && (<p>There is currently no default Mortgage Pro set.</p>)}

                                            <p>
                                                <button className="btn btn-primary" onClick={() => this.setState({ show_form: true })}>
                                                    Add Mortgage Pro
                                                </button>
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div class="card-body ">
                                        <Formik
                                            initialValues={{
                                                display_name: item?.display_name,
                                                email: item?.email,
                                                phone: item?.phone,
                                                web: item?.web,
                                                company: item?.company,
                                                licenseNumber: item?.license.licenseNumber,
                                                //licensePrefix: item?.license.licensePrefix,
                                            }}
                                            validate={values => {
                                                let errors = {};

                                                if (!values.display_name) {
                                                    errors.display_name = "Required";
                                                }

                                                if (!values.email) {
                                                    errors.email = "Required";
                                                } else if (
                                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                        values.email
                                                    )
                                                ) {
                                                    errors.email = "Invalid email address";
                                                }

                                                if (!values.licenseNumber) {
                                                    errors.licenseNumber = "Required";
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setTimeout(() => {

                                                    api.updateMpro(
                                                        values,
                                                        response => {
                                                            this.forceUpdate();

                                                            window.showToastMessage(
                                                                "Success",
                                                                "Profile has been saved"
                                                            );

                                                            this.loadProfile();
                                                        },
                                                        error => {
                                                            window.showToastMessage(
                                                                "Error",
                                                                "Error saving profile",
                                                                "danger",
                                                                "fe-x"
                                                            );
                                                        }
                                                    );

                                                    setSubmitting(false);
                                                }, 400);
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting
                                                /* and other goodies */
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <p>
                                                        <label for="display_name">Name</label>
                                                        <input
                                                            type="text"
                                                            class={
                                                                errors.display_name
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="display_name"
                                                            id="display_name"
                                                            placeholder="Mortgage Pro name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.display_name}
                                                        />
                                                        <div class="invalid-feedback">
                                                            {errors.display_name &&
                                                                touched.display_name &&
                                                                errors.display_name}
                                                        </div>
                                                    </p>

                                                    <p>
                                                        <label for="email">Email</label>
                                                        <input
                                                            type="email"
                                                            class={
                                                                errors.email
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email address"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                        <div class="invalid-feedback">
                                                            {errors.email && touched.email && errors.email}
                                                        </div>
                                                    </p>

                                                    <p>
                                                        <label for="phone">Phone</label>
                                                        <input
                                                            type="text"
                                                            class={
                                                                errors.phone
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="phone"
                                                            id="phone"
                                                            placeholder="Phone number"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.phone}
                                                        />
                                                        <div class="invalid-feedback">
                                                            {errors.phone && touched.phone && errors.phone}
                                                        </div>
                                                    </p>

                                                    <p>
                                                        <label for="web">Website</label>
                                                        <input
                                                            type="text"
                                                            class={
                                                                errors.web
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="web"
                                                            id="web"
                                                            placeholder="Website"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.web}
                                                        />
                                                        <div class="invalid-feedback">
                                                            {errors.web && touched.web && errors.web}
                                                        </div>
                                                    </p>

                                                    <p>
                                                        <label for="company">Company</label>
                                                        <input
                                                            type="text"
                                                            class={
                                                                errors.company
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="company"
                                                            id="company"
                                                            placeholder="Company name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.company}
                                                            maxLength={200}
                                                        />
                                                        <div class="invalid-feedback">
                                                            {errors.company && touched.company && errors.company}
                                                        </div>
                                                    </p>


                                                    <div>
                                                        {/*<p>
                                                            <label for="licensePrefix">
                                                                License Prefix (if required)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class={
                                                                    errors.licensePrefix
                                                                        ? "form-control is-invalid"
                                                                        : "form-control"
                                                                }
                                                                name="licensePrefix"
                                                                id="licensePrefix"
                                                                placeholder="Agent license prefix"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.licensePrefix}
                                                            />
                                                            <div class="invalid-feedback">
                                                                {errors.licensePrefix &&
                                                                    touched.licensePrefix &&
                                                                    errors.licensePrefix}
                                                            </div>
                                                        </p>*/}

                                                        <p>
                                                            <label for="licenseNumber">License #</label>
                                                            <input
                                                                type="text"
                                                                class={
                                                                    errors.licenseNumber
                                                                        ? "form-control is-invalid"
                                                                        : "form-control"
                                                                }
                                                                name="licenseNumber"
                                                                id="licenseNumber"
                                                                placeholder="License #"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.licenseNumber}
                                                            />
                                                            <div class="invalid-feedback">
                                                                {errors.licenseNumber &&
                                                                    touched.licenseNumber &&
                                                                    errors.licenseNumber}
                                                            </div>
                                                        </p>
                                                    </div>


                                                    <hr />

                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <button
                                                                class="btn btn-primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div class="col-md-6" style={{ textAlign: "right" }}>
                                                            {mpro && (
                                                                <button class="btn btn-danger" onClick={() => {
                                                                    if (window.confirm("Are you sure you want to delete this Mortgage Pro?")) {
                                                                        api.deleteMpro(
                                                                            response => {
                                                                                this.loadProfile();
                                                                            },
                                                                            error => {
                                                                                window.showToastMessage(
                                                                                    "Error",
                                                                                    "An error occurred",
                                                                                    "danger",
                                                                                    "fe-x"
                                                                                );
                                                                            }
                                                                        );
                                                                    }
                                                                }}>Delete Mortgage Pro</button>
                                                            )}
                                                        </div>
                                                    </div>

                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                )}

                            </div>


                            {this.state.show_form && (
                                <div class="card m-b-30">
                                    <div class="card-header">
                                        <h5 class="m-b-0">Photo</h5>
                                    </div>
                                    <div class="card-body ">
                                        <Dropzone
                                            multiple={false}
                                            accept={"image/jpeg, image/png"}
                                            onDrop={acceptedFiles => {
                                                const reader = new FileReader();

                                                reader.onabort = () => alert("file reading was aborted");
                                                reader.onerror = () => alert("file reading has failed");
                                                reader.onload = () => {
                                                    // Do whatever you want with the file contents
                                                    const binaryStr = reader.result;

                                                    api.uploadMproPhoto(
                                                        binaryStr,
                                                        response => {
                                                            //this.setState({ uploading: false, photo: response.photo });
                                                            //alert("ok! " + JSON.stringify(response));
                                                            this.loadProfile();
                                                            //saveUserData(response.user);
                                                        },
                                                        error => {
                                                            //this.setState({ uploading: false });
                                                            // alert("An error occurred");
                                                            window.showToastMessage(
                                                                "Error",
                                                                "An error occurred",
                                                                "danger",
                                                                "fe-x"
                                                            );
                                                        }
                                                    );
                                                };

                                                acceptedFiles.forEach(file =>
                                                    reader.readAsArrayBuffer(file)
                                                );
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {item?.photo ? (
                                                            <div
                                                                style={{
                                                                    border: "1px solid #ddd",
                                                                    borderRadius: 5,
                                                                    padding: 10,
                                                                    cursor: "pointer",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.photo}
                                                                    style={{
                                                                        width: 120,
                                                                        height: 120,
                                                                        borderRadius: 60
                                                                    }}
                                                                />
                                                                <p style={{ marginTop: 10, color: "#aaa" }}>
                                                                    Click to upload or drag new file.
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    border: "1px dotted #ddd",
                                                                    borderRadius: 5,
                                                                    height: 200,
                                                                    padding: 10,
                                                                    cursor: "pointer",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                <p style={{ marginTop: 10, color: "#aaa" }}>
                                                                    Drag &amp; drop a photo here, or click to
                                                                    select.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>

                                        {item?.photo && (
                                            <div>
                                                <p style={{ marginTop: 10 }}>
                                                    <button
                                                        class="btn btn-danger"
                                                        onClick={e => {
                                                            if (
                                                                window.confirm(
                                                                    "Are you sure you want to delete this photo?"
                                                                )
                                                            ) {
                                                                api.deleteMproPhoto(
                                                                    response => {
                                                                        //this.setState({ uploading: false, photo: response.photo });
                                                                        //alert("ok! " + JSON.stringify(response));
                                                                        this.loadProfile();
                                                                        //saveUserData(response.user);
                                                                    },
                                                                    error => {
                                                                        //this.setState({ uploading: false });
                                                                        window.showToastMessage(
                                                                            "Error",
                                                                            "An error occurred",
                                                                            "danger",
                                                                            "fe-x"
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Remove photo
                                                    </button>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}


                        </div>


                        <div class="col-lg-6">

                            {UserStore.isAdmin() && (
                                <div class="card m-b-30">
                                    <div class="card-header">
                                        <h5 class="m-b-0">Mortgage Pro Settings</h5>
                                    </div>
                                    <div class="card-body">
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={this.state.agent_toggle}
                                                        onChange={() => {
                                                            let new_state = !this.state.agent_toggle
                                                            //this.state.agent_toggle = !this.state.agent_toggle;

                                                            this.setState({ agent_toggle: new_state });

                                                            api.updateAgentMortgageProToggle(
                                                                new_state,
                                                                response => {

                                                                }, error => {
                                                                    // oops
                                                                }
                                                            );

                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                }
                                                label="Allow agents to set their own Mortgage Pro"
                                            />
                                        </FormGroup>

                                        <p>
                                            If enabled, agents will be able to set their own Mortgage Pro which will override any set here as the default.
                                        </p>
                                    </div>
                                </div>
                            )}


                        </div>

                    </div>
                </div>
            </section >
        );
    }

    render() {
        if (!AppStore.app_data.toggles.mortgageProEditingEnabled) {
            return <Redirect to="/" />;
        }

        if (!UserStore.isAdmin()) {
            if (!AppStore.app_data.mpro_agent) {
                return <Redirect to="/" />;
            }
        }

        return (
            <Inner>
                <div class="content">{this.agents()}</div>
            </Inner>
        );
    }
}

const styles = {};

export default MortgagePro;

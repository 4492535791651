import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import Help from "../help/Help";
import Select from "react-select";
import { Formik } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
    FiSettings,
} from "react-icons/fi";

const crmFields = [
    { name: null, fields: [], parsing: false },
    {
        name: "fub",
        fields: ['api_key'],
        parsing: false,
        link: {
            url: "https://app.followupboss.com/2/api", //"https://help.followupboss.com/hc/en-us/articles/360014289393-API-Keys",
            label: "Follow Up Boss API Instructions"
        },
        links: [
            {
                url: "https://app.followupboss.com/2/api",
                label: "Step 1: Add Follow Up Boss API key above (get my key)"
            },
            {
                url: "https://homestack.followupboss.com/2/integrations",
                label: "Step 2: Enable HomeStack in your FUB Integrations Dashboard"
            }
        ]
    },
    { name: "idc", fields: ['username', 'password'], parsing: false },
    { name: "propertybase", fields: ['api_key', 'api_url'], parsing: false },
    // Below are for screenshotting, not live atm! Remove being goign live!!
    //{ name: "boomtown", fields: ['api_key'], parsing: false },
    { name: "kvcore", fields: ['api_key'], parsing: false },
    { name: "moxi", fields: [], parsing: false },
    //{ name: "chime", fields: ['api_key'], parsing: false },
    //{ name: "sierra", fields: ['api_key'], parsing: false },
    {
        name: "sierra",
        fields: ['api_key'],
        parsing: false,
        link: {
            url: "https://client.sierrainteractivedev.com/integrations.aspx",
            label: "API key can be found here. Select the 'Sierra Interactive' option and copy provided API key."
        }
    },
    //{ name: "deltanet", fields: [], parsing: true },
    //{ name: "moxiworks", fields: [], parsing: false },
    //{ name: "cinc", fields: ['api_key'], parsing: false },
    { name: "wise", fields: ['api_key'], parsing: false },
    { name: "request", fields: [], parsing: false },
];

/*
Boomtown!, kvCORE, Chime, Sierra Interactive, DeltaNet CRM, MoxiWorks, CINC
Request an integration...
*/

@observer
class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            key: null,
            error: false,
            brokerage_name: "",
            dmca_link: "",
            integration: null,
            agent: null,
            hook_user: "",
            sync_checks: 0,
            integration: null,
            read_integration: null,
            hooks: []
        };
    }

    allowedField = (name, field) => {
        let allowed = false;

        crmFields.map(item => {
            if (item.name == name) {
                const f = item.fields;
                if (f.includes(field)) {
                    allowed = true;
                }
            }
        });

        return allowed;
    }

    crmOptions = () => {
        return [
            { value: null, label: "None" },
            //{ value: "boomtown", label: "Boomtown!" },
            //{ value: "chime", label: "Chime" },
            //{ value: "cinc", label: "CINC" },
            //{ value: "deltanet", label: "DeltaNet CRM" },
            { value: "fub", label: "Follow Up Boss" },
            { value: "kvcore", label: "kvCORE" },
            //{ value: "moxiworks", label: "MoxiWorks" },
            { value: "propertybase", label: "Propertybase" },
            { value: "idc", label: "IDC" },
            { value: "sierra", label: "Sierra Interactive" },
            { value: "wise", label: "Wise Agent" },
            { value: "request", label: "Request an integration..." }
        ];
    };

    componentDidMount() {
        this.load();
        this.loadProfile();

        this.interval = null;
    }

    loadProfile() {
        api.getProfile(
            response => {
                if (!response) {
                    this.setState({ error: true });
                } else {
                    if (Array.isArray(response) && !response.length) {
                        this.setState({ error: true });
                    } else {
                        this.setState({ agent: response });
                    }
                }
            },
            error => {
                this.setState({ error: true });
            }
        );
    }

    getWebHooks() {
        api.getWebhooks(
            response => {
                let hooks = response?.hooks;
                this.setState({ hooks: hooks })
                //for (let x = 0; x < hooks.length; x++) {
                //    this.setState({ ["hook_" + hooks[x].type]: hooks[x].url })
                //}
            },
            error => {
                this.setState({ error: true });
            }
        );
    }

    load() {
        this.setState({
            brokerage_name: AppStore.app_data.brokerage_name,
            dmca_link: AppStore.app_data.links.dmca
        });

        api.getAPIKey(
            response => {
                this.setState({ key: response.key });
            },
            error => {
                this.setState({ error: true });
            }
        );

        if (AppStore.app_data.toggles.integrationsEnabled) {
            this.getWebHooks();

            api.getIntegrationInfo(
                response => {
                    setTimeout(() => {
                        this.setState({ integration: response.info, read_integration: JSON.parse(JSON.stringify(response.info)) })
                    }, 200);
                },
                error => {
                    this.setState({ error: true });
                }
            );
        }
    }

    loadIntegration() {
        const sync_checks = this.state.sync_checks + 1;

        api.getIntegrationInfo(
            response => {
                setTimeout(() => {
                    this.setState({ integration: response.info, read_integration: JSON.parse(JSON.stringify(response.info)), sync_checks: sync_checks })
                }, 200);
            },
            error => {
                this.setState({ error: true });
            }
        );
    }


    copyToClipboard = e => {
        this.copyLink.select();
        document.execCommand("copy");
        window.showToastMessage("Success", "API key copied to clipboard");
        //alert("Copied to clipboard");
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        //e.target.focus();
        //this.setState({ copySuccess: 'Copied!' });
    };

    updateHooks() {
        //let data = { type: "user", url: url, guid: guid };
        let hooks = this.state.hooks;
        let data = hooks[0];

        api.updateWebhook(data, response => {
            window.showToastMessage("Success", "Webhook updated");
            this.getWebHooks();
        }, error => {
            window.showToastMessage("Error", "Webhook update failed");
        });
    }

    updateIntegration() {
        // TODO: Check the required fields to make sure they are present
        let failed = false;
        let int = this.state.integration;
        let name = this.state.integration?.name;
        let required = [];
        crmFields.map(item => {
            if (item.name == name) {
                required = item.fields;
            }
        });

        //alert(JSON.stringify(required))

        let pre_int = {
            name: null,
            api_url: null,
            api_key: null,
            username: null,
            password: null,
            active: false
        };

        if (!name || required.length == 0) {
            int = pre_int;
        } else {
            pre_int.name = int.name;
            required.map(item => {
                if (!int[item]) {
                    failed = true;
                } else {
                    pre_int[item] = int[item];
                }
            });
            int = pre_int;
        }

        if (failed) {
            alert("All fields are required");
            return;
        }

        int.active = this.state.integration.active;

        api.updateIntegration(
            int,
            response => {
                window.showToastMessage("Success", "Integration updated");
                this.loadIntegration();
                this.setState({ sync_checks: 0 })
            },
            error => {
                alert("An error occured");
            }
        );
    }

    updateBrokerageName() {
        api.updateApp(
            {
                brokerage_name: this.state.brokerage_name
            },
            response => {
                AppStore.app_data.brokerage_name = this.state.brokerage_name;
                window.showToastMessage("Success", "Name updated");
            },
            error => {
                alert("An error occured");
            }
        );
    }

    updateDMCA() {
        api.updateApp(
            {
                dmca_link: this.state.dmca_link
            },
            response => {
                AppStore.app_data.links.dmca = this.state.dmca_link;
                window.showToastMessage("Success", "DMCA link updated");
            },
            error => {
                alert("An error occured");
            }
        );
    }

    crmBox() {
        const item = this.state.agent;

        let help_link = crmFields.find((op) => {
            return op.name == this.state.integration?.name;
        })?.link;

        let help_links = crmFields.find((op) => {
            return op.name == this.state.integration?.name;
        })?.links;

        const integration = this.state.integration;
        const read_integration = this.state?.read_integration;

        // If we haven't tested the integration, refresh a few times to see what the status is
        if (AppStore.app_data.toggles.integrationsEnabled) {
            if (this.state.integration) {
                if (integration.active && !integration.sync_checked && !integration.error && this.state.sync_checks < 20) {
                    clearInterval(this.interval);
                    this.interval = setInterval(() => this.loadIntegration(), 5000);
                } else {
                    clearInterval(this.interval);
                }
            }
        }

        if (this.state.integration?.name == "moxi") {
            return (
                <div class="card py-3 m-b-30">
                    <div class="card-body">
                        <h3 class="">CRM Integration</h3>
                        <p>
                            This integration is managed by HomeStack.
                        </p>

                        <div style={{ marginBottom: 12 }}>
                            {read_integration?.active && read_integration?.sync_checked && read_integration?.name && !read_integration.error && (
                                <div style={{
                                    display: "inline-flex",
                                    padding: "3px 6px",
                                    borderRadius: 6,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    backgroundColor: "#00cc99",
                                    color: "#fff"
                                }}>
                                    <FiSettings className="rotator" style={{ marginRight: 6 }} />
                                    Running...
                                </div>
                            )}

                            {read_integration?.active && !read_integration.sync_checked && read_integration?.name && !read_integration.error && (
                                <div style={{
                                    display: "inline-flex",
                                    padding: "3px 6px",
                                    borderRadius: 6,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    backgroundColor: "#f7bc08",
                                    color: "#fff"
                                }}>
                                    <FiSettings className="rotator" style={{ marginRight: 6 }} />
                                    Checking...
                                </div>
                            )}

                            {read_integration?.error && read_integration?.name && (
                                <div style={{
                                    display: "inline-flex",
                                    padding: "3px 6px",
                                    borderRadius: 6,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    backgroundColor: "#f2545b",
                                    color: "#fff"
                                }}>
                                    <FiSettings style={{ marginRight: 6 }} />
                                    Error. Please check credentials.
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            );
        }

        return (
            <div class="card py-3 m-b-30">
                <div class="card-body">
                    <h3 class="">CRM Integration</h3>
                    <p>
                        Select your CRM from the supported list below and enter the required credentials.
                    </p>

                    <div style={{ marginBottom: 12 }}>
                        {read_integration?.active && read_integration?.sync_checked && read_integration?.name && !read_integration.error && (
                            <div style={{
                                display: "inline-flex",
                                padding: "3px 6px",
                                borderRadius: 6,
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: 16,
                                fontWeight: "bold",
                                backgroundColor: "#00cc99",
                                color: "#fff"
                            }}>
                                <FiSettings className="rotator" style={{ marginRight: 6 }} />
                                Running...
                            </div>
                        )}

                        {read_integration?.active && !read_integration.sync_checked && read_integration?.name && !read_integration.error && (
                            <div style={{
                                display: "inline-flex",
                                padding: "3px 6px",
                                borderRadius: 6,
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: 16,
                                fontWeight: "bold",
                                backgroundColor: "#f7bc08",
                                color: "#fff"
                            }}>
                                <FiSettings className="rotator" style={{ marginRight: 6 }} />
                                Checking...
                            </div>
                        )}

                        {read_integration?.error && read_integration?.name && (
                            <div style={{
                                display: "inline-flex",
                                padding: "3px 6px",
                                borderRadius: 6,
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: 16,
                                fontWeight: "bold",
                                backgroundColor: "#f2545b",
                                color: "#fff"
                            }}>
                                <FiSettings style={{ marginRight: 6 }} />
                                Error. Please check credentials.
                            </div>
                        )}
                    </div>


                    {AppStore.app_data.toggles.integrationsEnabled ? (
                        <>
                            <p>
                                <label>
                                    CRM provider
                                </label>
                                <Select
                                    //value={this.state.integration?.name}
                                    options={this.crmOptions()}
                                    value={this.crmOptions().find((op) => {
                                        return op.value == this.state.integration?.name;
                                    })}
                                    onChange={(option) => {
                                        const int = this.state.integration;
                                        int.name = option.value;
                                        this.setState({ integration: int });
                                    }}
                                />
                            </p>

                            {this.state.integration?.name == "request" && (
                                <p>
                                    Please email <a className="text-underline" href="mailto:support@homestack.com">support@homestack.com</a> with the
                                    details of the integration you'd like us to work with. <strong>Note: If your CRM supports email parsing you can fill in the field below to start using this now.</strong>
                                </p>
                            )}

                            {this.state.integration?.name == "moxiworks" && (
                                <div class="card-body">
                                    <div
                                        class="alert alert-border-warning  alert-dismissible fade show"
                                        role="alert"
                                    >
                                        <div class="d-flex">
                                            <div class="icon">
                                                <i class="icon mdi mdi-alert-circle-outline" />
                                            </div>
                                            <div class="content">
                                                <strong>MoxiWorks</strong>
                                                <br />
                                                This integration is managed by HomeStack.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {crmFields.find((op) => {
                                return op.name == this.state.integration?.name;
                            }).parsing && (
                                    <div class="card-body">
                                        <div
                                            class="alert alert-border-warning  alert-dismissible fade show"
                                            role="alert"
                                        >
                                            <div class="d-flex">
                                                <div class="icon">
                                                    <i class="icon mdi mdi-alert-circle-outline" />
                                                </div>
                                                <div class="content">
                                                    <strong>Available through email parsing</strong>
                                                    <br />
                                                    To receive new leads for {this.crmOptions().find((op) => {
                                                        return op.value == this.state.integration?.name;
                                                    }).label}, fill in the CRM email parsing section.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}



                            {this.allowedField(this.state.integration?.name, 'api_url') && (
                                <div class="form-group">
                                    <label for="crm_api_url">API URL</label>
                                    <input
                                        style={{ fontSize: 16 }}
                                        id="crm_api_url"
                                        type="text"
                                        class="form-control"
                                        onChange={e => {
                                            const int = this.state.integration;
                                            int.api_url = e.target.value;
                                            this.setState({ integration: int });
                                        }}
                                        value={this.state.integration?.api_url}
                                    />
                                </div>
                            )}

                            {this.allowedField(this.state.integration?.name, 'api_key') && (
                                <div class="form-group">
                                    <label for="crm_api_key">API Key</label>
                                    <input
                                        style={{ fontSize: 16 }}
                                        id="crm_api_key"
                                        type="text"
                                        class="form-control"
                                        onChange={e => {
                                            const int = this.state.integration;
                                            int.api_key = e.target.value;
                                            this.setState({ integration: int });
                                        }}
                                        value={this.state.integration?.api_key}
                                    />
                                </div>
                            )}

                            {this.allowedField(this.state.integration?.name, 'username') && (
                                <div class="form-group">
                                    <label for="crm_username">Username</label>
                                    <input
                                        style={{ fontSize: 16 }}
                                        id="crm_username"
                                        type="text"
                                        class="form-control"
                                        onChange={e => {
                                            const int = this.state.integration;
                                            int.username = e.target.value;
                                            this.setState({ integration: int });
                                        }}
                                        value={this.state.integration?.username}
                                    />
                                </div>
                            )}

                            {this.allowedField(this.state.integration?.name, 'password') && (
                                <div class="form-group">
                                    <label for="crm_password">Password</label>
                                    <input
                                        style={{ fontSize: 16 }}
                                        id="crm_password"
                                        type="text"
                                        class="form-control"
                                        onChange={e => {
                                            const int = this.state.integration;
                                            int.password = e.target.value;
                                            this.setState({ integration: int });
                                        }}
                                        value={this.state.integration?.password}
                                    />
                                </div>
                            )}

                            {help_links && (
                                <>
                                    {help_links.map(hlink => (
                                        <p><a target="_blank" href={hlink.url} className="text-underline">{hlink.label}</a></p>
                                    ))}
                                </>
                            )}

                            {help_link && !help_links && (
                                <p><a target="_blank" href={help_link.url} className="text-underline">{help_link.label}</a></p>
                            )}

                            {/*<div class="alert alert-warning" role="alert">
                                                    <div class="content">
                                                        Integrations are curently off.
                                                    </div>
                                                </div>*/}

                            {this.state.integration && (
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={this.state.integration?.active}
                                                onChange={() => {
                                                    //this.state.active = !this.state.active;
                                                    const int = this.state.integration;
                                                    int.active = !int.active;
                                                    this.setState({ integration: int });
                                                }}
                                            />
                                        }
                                        label="Integration is active"
                                    />
                                </FormGroup>
                            )}

                            {!crmFields.find((op) => {
                                return op.name == this.state.integration?.name;
                            }).parsing && this.state.integration?.name != "request" && (
                                    <div class="text-right">

                                        <button
                                            onClick={() => {
                                                this.updateIntegration();
                                            }}
                                            class="btn btn-primary btn-cta"
                                        >
                                            Update
                                        </button>

                                    </div>
                                )}
                        </>
                    ) : (
                        <>
                            <div
                                class="alert alert-border-warning  alert-dismissible fade show"
                                role="alert"
                            >
                                <div class="d-flex">
                                    <div class="icon">
                                        <i class="icon mdi mdi-alert-circle-outline" />
                                    </div>
                                    <div class="content">
                                        <strong>Upgrade Required</strong>
                                        <br />
                                        Please contact HomeStack support to enable all
                                        dashboard features.
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    render() {
        if (!UserStore.isAdmin()) {
            return <Redirect to="/user_settings" />;
        }

        if (this.state.error) {
            return (
                <Inner>
                    <div class="content">
                        <div className="centerBox">
                            <img src="https://bk.homestack.com/assets/img/not-found.svg" />
                            <h2>Unable to load data</h2>
                            <p>
                                <button
                                    onClick={() => {
                                        this.setState({ error: false });
                                        this.load();
                                    }}
                                >
                                    Try again
                                </button>
                            </p>
                        </div>
                    </div>
                </Inner>
            );
        }

        if (!this.state.key) {
            return (
                <Inner>
                    <div
                        class="d-flex w-100 justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </Inner>
            );
        }

        if (!this.state.agent) {
            return (
                <div
                    class="d-flex w-100 justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        const item = this.state.agent;

        let help_link = crmFields.find((op) => {
            return op.name == this.state.integration?.name;
        })?.link;

        return (
            <Inner>
                <div class="bg-dark bg-dots m-b-30">
                    <div class="container">
                        {/*<Help section="settings" />*/}
                        <div class="row p-b-60 p-t-60">
                            <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                                <div class="m-b-10">
                                    <div class="avatar avatar-lg ">
                                        <div class="avatar-title bg-info rounded-circle mdi mdi-repeat " />
                                    </div>
                                </div>
                                <h3>Integrations</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="pull-up">
                    <div class="container">
                        <div class="row ">
                            <div class="col-lg-6">

                                {AppStore.app_data.toggles.zapierEnabled && (
                                    <div class="card py-3 m-b-30">
                                        <div class="card-body">
                                            <h3 class="">
                                                Zapier <span class="badge badge-warning">beta</span>
                                            </h3>

                                            {AppStore.app_data.toggles.agentInsightsEnabled ? (
                                                <>
                                                    <div class="card-body">
                                                        <p>
                                                            You can get access to the HomeStack Zapier beta
                                                            from:{" "}
                                                            <a
                                                                href="https://zapier.com/developer/public-invite/28937/ce9d7c0cead1b4ca460d46d7fd1c9b7b/"
                                                                target="_blank"
                                                                class="text-underline"
                                                            >
                                                                https://zapier.com/developer/public-invite/28937/ce9d7c0cead1b4ca460d46d7fd1c9b7b
                                                            </a>
                                                        </p>

                                                        <hr />

                                                        <p>
                                                            Read the{" "}
                                                            <a
                                                                class="text-underline"
                                                                href="/assets/docs/homestack-zapier-beta-guide.pdf"
                                                                target="_blank"
                                                            >
                                                                Zapier integration guide
                                                            </a>
                                                            .
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div
                                                    class="alert alert-border-warning  alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    <div class="d-flex">
                                                        <div class="icon">
                                                            <i class="icon mdi mdi-alert-circle-outline" />
                                                        </div>
                                                        <div class="content">
                                                            <strong>Upgrade Required</strong>
                                                            <br />
                                                            Please contact HomeStack support to enable all
                                                            dashboard features.
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div class="card py-3 m-b-30">
                                    <div class="card-body">
                                        <h3 class="">HomeStack API key</h3>
                                        <p>This key can be used to work with SSO or Zapier.</p>

                                        {AppStore.app_data.toggles.agentInsightsEnabled ? (
                                            <>
                                                {/*}
                                                <div class="card-body">
                                                    <div
                                                        class="alert alert-border-warning  alert-dismissible fade show"
                                                        role="alert"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="icon">
                                                                <i class="icon mdi mdi-alert-circle-outline" />
                                                            </div>
                                                            <div class="content">
                                                                <strong>Keep the API key safe</strong>
                                                                <br />
                                                                This key allows access via SSO.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>*/}

                                                <div class="form-group">
                                                    <label for="api_key">Your HomeStack API key</label>
                                                    <input
                                                        style={{ fontFamily: "monospace", fontSize: 16 }}
                                                        id="api_key"
                                                        type="text"
                                                        class="form-control"
                                                        ref={input => (this.copyLink = input)}
                                                        type="text"
                                                        value={this.state.key}
                                                    />
                                                </div>

                                                <div class="text-center">
                                                    <button
                                                        onClick={this.copyToClipboard}
                                                        class="btn btn-info btn-cta"
                                                    >
                                                        Copy API key
                                                    </button>

                                                    <hr />

                                                    <p>
                                                        Read the{" "}
                                                        <Link to="/settings/sso" class="text-underline">
                                                            SSO guide
                                                        </Link>
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    class="alert alert-border-warning  alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    <div class="d-flex">
                                                        <div class="icon">
                                                            <i class="icon mdi mdi-alert-circle-outline" />
                                                        </div>
                                                        <div class="content">
                                                            <strong>Upgrade Required</strong>
                                                            <br />
                                                            Please contact HomeStack support to enable all
                                                            dashboard features.
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {AppStore.app_data.toggles.integrationsEnabled && (
                                    <div class="card py-3 m-b-30">
                                        <div class="card-body">
                                            <h3 class="">Webhooks <span class="badge badge-warning">beta</span></h3>

                                            {/*this.state.hooks.length == 0 && (
                                                <p>
                                                    <label>User webhook</label>
                                                    <input type="text" placeholder="https://example.com" className="form-control" value={item.url} onChange={e => {
                                                        let hooks = [];
                                                        hooks.push({ type: "user", url: e.target.value });
                                                        this.setState({ hooks: hooks })
                                                    }} />
                                                    <small>This webhook deals with user actions such as new user, change of agent or new chat messages.</small>
                                                </p>
                                            )*/}

                                            {this.state.hooks.length > 1 ? (
                                                <p>There are {this.state.hooks.length} webhooks set, please update through the API</p>
                                            ) : (
                                                <>

                                                    <p>
                                                        <label>User webhook</label>
                                                        <input type="text" placeholder="https://example.com" className="form-control" value={this.state.hooks[0]?.url} onChange={e => {
                                                            let hooks = this.state.hooks;

                                                            if (hooks[0]?.url) {
                                                                hooks[0].url = e.target.value;
                                                                this.setState({ hooks: hooks })
                                                            } else {
                                                                let hooks = [];
                                                                hooks.push({ type: "user", url: e.target.value });
                                                                this.setState({ hooks: hooks })
                                                            }
                                                        }} />
                                                        <small>This webhook deals with user actions such as new user, change of agent or new chat messages.</small>
                                                    </p>

                                                    <p>
                                                        <button
                                                            class="btn btn-primary"
                                                            type="submit"
                                                            onClick={() => {
                                                                this.updateHooks()
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    </p>
                                                </>
                                            )}

                                            <hr />

                                            <p>
                                                More information on webhooks and the <a href="https://homestack.com/apidocs" className="text-underline" target="_blank">HomeStack API can be found here</a>.
                                            </p>

                                        </div>

                                    </div>
                                )}

                                <div class="card py-3 m-b-30">
                                    <div class="card-body">
                                        <h3 class="">
                                            API Nation
                                        </h3>


                                        <>
                                            <div class="card-body">
                                                <p>
                                                    Supercharge your Real Estate business with user friendly automations.
                                                </p><p>
                                                    HomeStack has partnered with API Nation to offer integrations with your favorite CRM's. API Nation offers an affordable monthly subscription that provides access to all your most needed automations.
                                                </p><p>
                                                    API Nation's library also gives access to a wide variety of other platforms. Click below to learn more.
                                                </p><p>
                                                    If you'd like help setting up the automation, please reach out to your account manager for assistance.
                                                </p>

                                                <p>
                                                    <a className="btn btn-primary" href="https://my.apination.com/apps/homestack?utm_source=homestack&came_from=cn_homestack" target="_blank">Learn More</a>
                                                </p>
                                            </div>
                                        </>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-6">


                                {this.crmBox()}


                                {AppStore.app_data.toggles.crmEmailEnabled && AppStore.app_data.toggles.crmEmailProfileEnabled && (
                                    <div class="card m-b-30">
                                        <div class="card-body ">
                                            <h3 class="">CRM Email Parsing</h3>
                                            <Formik
                                                initialValues={{
                                                    crm_email: item.crm_email
                                                }}
                                                validate={values => {
                                                    let errors = {};

                                                    if (values.crm_email && (
                                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                            values.crm_email
                                                        ))
                                                    ) {
                                                        errors.crm_email = "Invalid email address";
                                                    }

                                                    return errors;
                                                }}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setTimeout(() => {
                                                        //alert(JSON.stringify(values, null, 2));

                                                        api.updateProfileCRM(
                                                            values,
                                                            response => {
                                                                this.forceUpdate();

                                                                window.showToastMessage(
                                                                    "Success",
                                                                    "CRM parsing email has been saved"
                                                                );
                                                            },
                                                            error => {
                                                                window.showToastMessage(
                                                                    "Error",
                                                                    "Error saving parsing email",
                                                                    "danger",
                                                                    "fe-x"
                                                                );
                                                            },
                                                            item.id
                                                        );

                                                        setSubmitting(false);
                                                    }, 400);
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting
                                                    /* and other goodies */
                                                }) => (
                                                    <form onSubmit={handleSubmit}>

                                                        <p>
                                                            <label for="crm_email">CRM parsing email</label>
                                                            <input
                                                                type="text"
                                                                class={
                                                                    errors.bio
                                                                        ? "form-control is-invalid"
                                                                        : "form-control"
                                                                }
                                                                name="crm_email"
                                                                id="crm_email"
                                                                placeholder="CRM email..."
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.crm_email}
                                                                maxLength={200}
                                                            />
                                                            <div class="invalid-feedback">
                                                                {errors.crm_email && touched.crm_email && errors.crm_email}
                                                            </div>
                                                        </p>
                                                        <p>This email is used to capture leads in your CRM system if you have email parsing set up. <strong>It is not the contact email</strong>.</p>

                                                        <hr />

                                                        <p>
                                                            <button
                                                                class="btn btn-primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Update
                                                            </button>
                                                        </p>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </section >
            </Inner >
        );

        return (
            <Inner>
                <div class="content">
                    <div class="row">
                        <div class="col-2">
                            {UserStore.isAgent() && (
                                <div class="content-block">
                                    <h2>API key</h2>
                                    <p>Your unique app API key. Keep this safe!</p>
                                    <p>
                                        <input
                                            ref={input => (this.copyLink = input)}
                                            type="text"
                                            value={this.state.key}
                                        />
                                    </p>
                                    <div class="row">
                                        <p>
                                            <button onClick={this.copyToClipboard}>
                                                Copy API key
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/*<div class="col-2">
              <div class="content-block">
                <h2>Regenerate API key</h2>
                <p>
                  You can generate a new API key here, but anything relying on
                  the old one will no longer work.
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <p>
                      <button
                        style={{ backgroundColor: "#a90000" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to generate a new API key? Anything using the old key will no longer work until updated."
                            )
                          ) {
                            alert("rolling....");
                          }
                        }}
                      >
                        Regenerate API key
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}
                    </div>
                </div>
            </Inner>
        );
    }
}



export default Integrations;

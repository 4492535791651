import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import Spinner from "react-spinkit";
import Button from "@material-ui/core/Button";
import api from "../../lib/api";

import { Formik } from "formik";
import Dropzone from "react-dropzone";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

@observer
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { agent: null, error: false, toAgents: false, agent_password: "" };
  }

  componentDidMount() {
    this.loadProfile();
  }

  loadProfile() {
    api.getProfile(
      response => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (Array.isArray(response) && !response.length) {
            this.setState({ error: true });
          } else {
            this.setState({ agent: response });
          }
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  agents() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadProfile();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (!this.state.agent) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const item = this.state.agent;

    return (
      <section class="admin-content">
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle fe fe-user " />
                  </div>
                </div>
                <h3>Profile </h3>
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-body ">
                  <Formik
                    initialValues={{
                      display_name: item.display_name,
                      email: item.email,
                      phone: item.phone,
                      web: item.web,
                      bio: item.bio,
                      social_youtube: item.social_youtube,
                      social_tiktok: item.social_tiktok,
                      social_instagram: item.social_instagram,
                      social_facebook: item.social_facebook,
                      social_twitter: item.social_twitter,
                      social_linkedin: item.social_linkedin,
                      crm_email: item.crm_email,
                      licenseDisplay: item.license.licenseDisplay,
                      licenseNumber: item.license.licenseNumber,
                      licensePrefix: item.license.licensePrefix,
                      active: item.active
                    }}
                    validate={values => {
                      let errors = {};

                      if (!values.display_name) {
                        errors.display_name = "Required";
                      }

                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      /*if (values.crm_email && (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.crm_email
                        ))
                      ) {
                        errors.crm_email = "Invalid email address";
                      }*/

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        //alert(JSON.stringify(values, null, 2));

                        api.updateProfile(
                          values,
                          response => {
                            this.forceUpdate();

                            window.showToastMessage(
                              "Success",
                              "Profile has been saved"
                            );
                          },
                          error => {
                            window.showToastMessage(
                              "Error",
                              "Error saving profile",
                              "danger",
                              "fe-x"
                            );
                          },
                          item.id
                        );

                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <p>
                          <label for="display_name">Name</label>
                          <input
                            type="text"
                            class={
                              errors.display_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="display_name"
                            id="display_name"
                            placeholder="Agent name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.display_name}
                            readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                          />
                          <div class="invalid-feedback">
                            {errors.display_name &&
                              touched.display_name &&
                              errors.display_name}
                          </div>
                        </p>

                        <p>
                          <label for="email">Email</label>
                          <input
                            type="email"
                            class={
                              errors.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="email"
                            id="email"
                            placeholder="Agent email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                          />
                          <div class="invalid-feedback">
                            {errors.email && touched.email && errors.email}
                          </div>
                        </p>

                        <p>
                          <label for="phone">Phone</label>
                          <input
                            type="text"
                            class={
                              errors.phone
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="phone"
                            id="phone"
                            placeholder="Agent phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                          />
                          <div class="invalid-feedback">
                            {errors.phone && touched.phone && errors.phone}
                          </div>
                        </p>

                        <p>
                          <label for="web">Website</label>
                          <input
                            type="text"
                            class={
                              errors.web
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="web"
                            id="web"
                            placeholder="Agent website"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.web}
                            readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                          />
                          <div class="invalid-feedback">
                            {errors.web && touched.web && errors.web}
                          </div>
                        </p>

                        <p>
                          <label for="bio">Biography</label>
                          <textarea
                            value={values.bio}
                            class={
                              errors.bio
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="bio"
                            id="bio"
                            placeholder="Agent bio"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={2000}
                            rows={4}
                          />
                          <div class="invalid-feedback">
                            {errors.bio && touched.bio && errors.bio}
                          </div>
                        </p>

                        <hr />

                        <p><strong>Social links</strong></p>

                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              <label for="social_youtube">Youtube</label>
                              <input
                                type="text"
                                class={
                                  errors.social_youtube
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="social_youtube"
                                id="social_youtube"
                                placeholder="Link here..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.social_youtube}
                                maxLength={255}
                              />
                              <div class="invalid-feedback">
                                {errors.social_youtube && touched.social_youtube && errors.social_youtube}
                              </div>
                            </p>
                          </div>

                          <div class="col-md-6">
                            <p>
                              <label for="social_tiktok">Tiktok</label>
                              <input
                                type="text"
                                class={
                                  errors.social_tiktok
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="social_tiktok"
                                id="social_tiktok"
                                placeholder="Link here..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.social_tiktok}
                                maxLength={255}
                              />
                              <div class="invalid-feedback">
                                {errors.social_tiktok && touched.social_tiktok && errors.social_tiktok}
                              </div>
                            </p>
                          </div>

                          <div class="col-md-6">
                            <p>
                              <label for="social_instagram">Instagram</label>
                              <input
                                type="text"
                                class={
                                  errors.social_instagram
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="social_instagram"
                                id="social_instagram"
                                placeholder="Link here..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.social_instagram}
                                maxLength={255}
                              />
                              <div class="invalid-feedback">
                                {errors.social_instagram && touched.social_instagram && errors.social_instagram}
                              </div>
                            </p>
                          </div>

                          <div class="col-md-6">
                            <p>
                              <label for="social_facebook">Facebook</label>
                              <input
                                type="text"
                                class={
                                  errors.social_facebook
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="social_facebook"
                                id="social_facebook"
                                placeholder="Link here..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.social_facebook}
                                maxLength={255}
                              />
                              <div class="invalid-feedback">
                                {errors.social_facebook && touched.social_facebook && errors.social_facebook}
                              </div>
                            </p>
                          </div>

                          <div class="col-md-6">
                            <p>
                              <label for="social_linkedin">LinkedIn</label>
                              <input
                                type="text"
                                class={
                                  errors.social_linkedin
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="social_linkedin"
                                id="social_linkedin"
                                placeholder="Link here..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.social_linkedin}
                                maxLength={255}
                              />
                              <div class="invalid-feedback">
                                {errors.social_linkedin && touched.social_linkedin && errors.social_linkedin}
                              </div>
                            </p>
                          </div>
                        </div>

                        <hr />


                        <>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={AppStore.app_data.template == "windermere" ? true : false}
                                  color="primary"
                                  checked={values.licenseDisplay}
                                  onChange={() => {
                                    //form.setFieldValue("email", "wwooo");
                                    values.licenseDisplay = !values.licenseDisplay;
                                    this.forceUpdate();
                                  }}
                                //onChange={handleChange}
                                //value="1"
                                />
                              }
                              label="Display license details"
                            />
                          </FormGroup>

                          {values.licenseDisplay && (
                            <div>
                              <p>
                                <label for="licensePrefix">
                                  License Prefix (if required)
                                </label>
                                <input
                                  readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                                  type="text"
                                  class={
                                    errors.licensePrefix
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="licensePrefix"
                                  id="licensePrefix"
                                  placeholder="Agent license prefix"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.licensePrefix}
                                />
                                <div class="invalid-feedback">
                                  {errors.licensePrefix &&
                                    touched.licensePrefix &&
                                    errors.licensePrefix}
                                </div>
                              </p>

                              <p>
                                <label for="licenseNumber">License #</label>
                                <input
                                  readonly={AppStore.app_data.template == "windermere" ? "readonly" : null}
                                  type="text"
                                  class={
                                    errors.licenseNumber
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="licenseNumber"
                                  id="licenseNumber"
                                  placeholder="Agent license #"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.licenseNumber}
                                />
                                <div class="invalid-feedback">
                                  {errors.licenseNumber &&
                                    touched.licenseNumber &&
                                    errors.licenseNumber}
                                </div>
                              </p>
                            </div>
                          )}

                        </>


                        {/*AppStore.app_data.toggles.crmEmailEnabled && AppStore.app_data.toggles.crmEmailProfileEnabled && (
                          <>
                            <hr />
                            <p>
                              <label for="crm_email">CRM email</label>
                              <input
                                type="text"
                                class={
                                  errors.bio
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="crm_email"
                                id="crm_email"
                                placeholder="CRM email..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.crm_email}
                                maxLength={200}
                              />
                              <div class="invalid-feedback">
                                {errors.crm_email && touched.crm_email && errors.crm_email}
                              </div>
                            </p>
                            <p>This email is used to capture leads in your CRM system. <strong>It is not the contact email</strong>.</p>
                          </>
                              )*/}


                        <>
                          <hr />

                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={AppStore.app_data.template == "windermere" ? true : false}
                                  color="primary"
                                  checked={values.active}
                                  onChange={() => {
                                    //form.setFieldValue("email", "wwooo");
                                    values.active = !values.active;
                                    this.forceUpdate();
                                  }}
                                //onChange={handleChange}
                                //value="1"
                                />
                              }
                              label="Active (will show in app if active)"
                            />
                          </FormGroup>

                          <p>
                            <small>
                              If an agent has users tied to them, making them
                              inactive will no longer show them for their users
                              and they will be prompted to choose another agent.
                            </small>
                          </p>
                        </>


                        <hr />

                        <p>
                          <button
                            class="btn btn-primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </p>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>


            </div>

            <div class="col-lg-6">

              {AppStore.app_data.template != "windermere" && (
                <>
                  <div class="card m-b-30">
                    <div class="card-header">
                      <h5 class="m-b-0">Photo</h5>
                    </div>
                    <div class="card-body ">
                      <Dropzone
                        multiple={false}
                        accept={"image/jpeg, image/png"}
                        onDrop={acceptedFiles => {
                          const reader = new FileReader();

                          reader.onabort = () => alert("file reading was aborted");
                          reader.onerror = () => alert("file reading has failed");
                          reader.onload = () => {
                            // Do whatever you want with the file contents
                            const binaryStr = reader.result;

                            api.uploadAvatar(
                              item.id,
                              binaryStr,
                              response => {
                                //this.setState({ uploading: false, photo: response.photo });
                                //alert("ok! " + JSON.stringify(response));
                                this.loadProfile();
                                //saveUserData(response.user);
                              },
                              error => {
                                //this.setState({ uploading: false });
                                // alert("An error occurred");
                                window.showToastMessage(
                                  "Error",
                                  "An error occurred",
                                  "danger",
                                  "fe-x"
                                );
                              }
                            );
                          };

                          acceptedFiles.forEach(file =>
                            reader.readAsArrayBuffer(file)
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {item.photo ? (
                                <div
                                  style={{
                                    border: "1px solid #ddd",
                                    borderRadius: 5,
                                    padding: 10,
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <img
                                    src={item.photo}
                                    style={{
                                      width: 120,
                                      height: 120,
                                      borderRadius: 60
                                    }}
                                  />
                                  <p style={{ marginTop: 10, color: "#aaa" }}>
                                    Click to upload or drag new file.
                                  </p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: "1px dotted #ddd",
                                    borderRadius: 5,
                                    height: 200,
                                    padding: 10,
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <p style={{ marginTop: 10, color: "#aaa" }}>
                                    Drag &amp; drop a photo here, or click to
                                    select.
                                  </p>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      {item.photo && (
                        <div>
                          <p style={{ marginTop: 10 }}>
                            <button
                              class="btn btn-danger"
                              onClick={e => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this photo?"
                                  )
                                ) {
                                  api.deleteAvatar(
                                    item.id,
                                    response => {
                                      //this.setState({ uploading: false, photo: response.photo });
                                      //alert("ok! " + JSON.stringify(response));
                                      this.loadProfile();
                                      //saveUserData(response.user);
                                    },
                                    error => {
                                      //this.setState({ uploading: false });
                                      window.showToastMessage(
                                        "Error",
                                        "An error occurred",
                                        "danger",
                                        "fe-x"
                                      );
                                    }
                                  );
                                }
                              }}
                            >
                              Remove photo
                            </button>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="card m-b-30">
                    <div class="card-header">
                      <h5 class="m-b-0">Change password</h5>
                    </div>
                    <div class="card-body">
                      <p>
                        <label for="agent_password">New password</label>
                        <input type="text" name="agent_password" value={this.state.agent_password} className="form-control" placeholder="Minimum 6 characters" onChange={(e) => {
                          this.setState({ agent_password: e.target.value });
                        }} />
                      </p>
                      <hr />
                      <p>
                        <button
                          class="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            let pwd = this.state.agent_password
                            if (pwd.length < 6) {
                              alert('Passwords must be at least 6 characters');
                            } else {
                              api.updateMyPassword(
                                { agent_password: pwd },
                                (response) => {
                                  this.forceUpdate();
                                  window.showToastMessage(
                                    "Success",
                                    "Password was saved"
                                  );
                                },
                                (error) => {
                                  //alert("Error saving profile");
                                  window.showToastMessage(
                                    "Error",
                                    "Error saving password",
                                    "danger",
                                    "fe-x"
                                  );
                                },
                                item.id
                              );
                            }
                          }}
                        >
                          Update
                        </button>
                      </p>
                    </div>
                  </div>
                </>
              )}

            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    if (AppStore.readOnly) {
      return (
        <Inner>
          <div class="content" style={{ padding: 20 }}><h2>Not available in Read Only mode.</h2></div>
        </Inner>
      );
    }

    return (
      <Inner>
        <div class="content">{this.agents()}</div>
      </Inner>
    );
  }
}

const styles = {};

export default Profile;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { FiX, FiVideo } from "react-icons/fi";
import { Formik } from "formik";
import AppStore from "../../stores/AppStore";
import Help from "../help/Help";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import AgentList from "./components/AgentList";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

@observer
class Agents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      error: false,
      createModal: false,
      importModal: false,
      toAgent: null,
      sendInviteEmail: true,
      resultLimit: 10,
      resultOffset: 0,
      resultsFrom: 0,
      resultsTo: 0,
      resultsTotal: 0,
      resultSort: "",
      officeQ: "",
      resultQ: "",
      selectedFile: null,
      csvAgents: [],
      csvAgentsBad: [],
      loading: true,
      videoModal: false,
      offices: []
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const limitQ = query.get("limit");
    const offsetQ = query.get("offset");
    const sortQ = query.get("sort");
    const queryQ = query.get("q");
    const officeQ = query.get("office");

    if (limitQ && offsetQ) {
      this.setState({
        resultLimit: parseInt(limitQ),
        resultOffset: parseInt(offsetQ),
      });
    }

    if (officeQ) {
      this.setState({
        officeQ: officeQ,
      });
    }

    if (sortQ) {
      this.setState({
        resultSort: sortQ,
      });
    }

    if (queryQ) {
      this.setState({
        resultQ: queryQ,
      });
    }

    setTimeout(() => {
      this.loadAgents();
      this.loadOffices();
    }, 0);
  }

  loadOffices() {
    if (AppStore.app_data.toggles.officesEnabled) {
      api.getOffices(
        (response) => {
          let data = response.offices
          //this.setState({ offices: response.offices });

          function buildTree(items, parentId = "0") {
            return items
              .filter(item => item.parent_id === parentId)
              .map(item => ({ ...item, children: buildTree(items, item.id) }));
          }

          function flattenTree(items, depth = 0) {
            let flat = [];
            items.forEach(item => {
              flat.push({ ...item, depth, padding: `${depth * 10}` }); // Add item with depth and padding
              if (item.children) {
                flat = [...flat, ...flattenTree(item.children, depth + 1)]; // Flatten children with incremented depth
              }
            });
            return flat.map(({ children, ...item }) => item); // Return flat array without children property
          }

          const tree = buildTree(data); // Build hierarchical tree structure
          const orderedFlatArray = flattenTree(tree); // Flatten the tree back into an array with order preserved

          this.setState({
            offices: orderedFlatArray,
          });
        },
        (error) => {
          //
        }
      );
    }
  }

  loadAgents() {
    this.setState({
      resultsTotal: 0,
      loading: true,
    });

    api.getAgents(
      this.state.resultLimit,
      this.state.resultOffset,
      this.state.resultSort,
      (response) => {
        this.setState({
          agents: response.agents,
          resultsTotal: response.total,
          resultsFrom: response.from,
          resultsTo: response.to,
          loading: false,
        });
      },
      (error) => {
        this.setState({ error: true, loading: false });
      },
      null,
      this.state.resultQ,
      this.state.officeQ
    );
  }

  updateHistory() {
    this.props.history.push(
      `${window.location.pathname}?offset=${parseInt(
        this.state.resultOffset
      )}&limit=${parseInt(this.state.resultLimit)}&sort=${this.state.resultSort
      }&q=${this.state.resultQ}&office=${this.state.officeQ}`
    );
  }

  updateQuery(q) {
    setTimeout(() => {
      this.updateHistory();

      this.loadAgents();
    }, 0);
  }

  updateLimit(limit) {
    this.setState({
      resultLimit: limit,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadAgents();
    }, 0);
  }

  updateOffice(office) {
    this.setState({
      officeQ: office,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadAgents();
    }, 0);
  }

  updateSort(sort) {
    this.setState({
      resultSort: sort,
    });

    setTimeout(() => {
      this.updateHistory();

      this.loadAgents();
    }, 0);
  }

  page(type) {
    if (type == "next") {
      this.setState({
        resultOffset: this.state.resultOffset + this.state.resultLimit,
      });
    } else if (type == "prev") {
      this.setState({
        resultOffset: this.state.resultOffset - this.state.resultLimit,
      });
    } else if (type == "last") {
      this.setState({
        resultOffset: this.state.resultsTotal - this.state.resultLimit,
      });
    } else if (type == "first") {
      this.setState({
        resultOffset: 0,
      });
    }

    setTimeout(() => {
      this.updateHistory();

      this.loadAgents();
      window.scrollTo(0, 0);
    }, 0);
  }

  handleClose = () => {
    this.setState({ createModal: false });
  };

  handleCloseImport = () => {
    this.setState({ importModal: false });
  };

  csv() {
    api.getAgentsCSV(
      (response) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob && false) {
          let blob = new Blob([response], { type: "text/csv" });
          window.navigator.msSaveOrOpenBlob(blob, "agents.csv");
        } else {
          // other browsers
          let file = new File([response], "agents.csv", {
            type: "text/csv",
          });

          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari) {
            let blob = new Blob([response], { type: "text/csv" });
            let exportUrl = URL.createObjectURL(blob);
            var e = document.createElement("a");
            e.href = exportUrl;
            e.target = "_self";
            e.setAttribute("download", "download");
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);

            //window.location.assign(exportUrl);
            //window.open(exportUrl, "_self");
            //URL.revokeObjectURL(exportUrl);
          } else {
            let exportUrl = URL.createObjectURL(file);
            window.location.assign(exportUrl);
            URL.revokeObjectURL(exportUrl);
          }
        }
      },
      (error) => {
        //
      }
    );
  }

  planWarning() {
    if (AppStore.app_data.plan.limit <= 0) {
      return null;
    }

    if (this.state.agents.length < AppStore.app_data.plan.limit) {
      return null;
    }

    return (
      <div class="row">
        <div class="col-md-12">
          <div
            class="alert alert-border-warning  alert-dismissible fade show"
            role="alert"
            style={{
              backgroundColor: "#fff",
              /*boxShadow: "1px 2px 10px rgba(0,0,0,0.3)"*/
            }}
          >
            <div class="d-flex">
              <div class="icon">
                <i class="icon mdi mdi-alert-circle-outline" />
              </div>
              <div class="content">
                <strong>You've reached your plan limit for agents</strong>
                <br />
                Your current plan allows a maximum of{" "}
                <strong>{AppStore.app_data.plan.limit}</strong> agent
                {AppStore.app_data.plan.limit == 1 ? null : "s"}. To add more
                please contact{" "}
                <a
                  href="mailto:prosupport@homestack.com"
                  class="text-underline"
                >
                  prosupport@homestack.com
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    if (!this.state.selectedFile) {
      alert("Please select a CSV file.");
      return null;
    }

    // Details of the uploaded file
    //console.log(this.state.selectedFile);

    // axios.post("api/uploadfile", formData);
    api.uploadAgents(
      this.state.selectedFile,
      (response) => {
        //alert(JSON.stringify(response));
        if (response) {
          this.setState({
            csvAgents: response.data.ok_rows,
            selectedFile: null,
          });
        } else {
          alert("Error processing file");
        }
      },
      (error) => {
        alert(error.response.data.message);
      }
    );
  };

  sendAgents() {
    api.sendAgents(
      this.state.csvAgents,
      (response) => {
        if (response) {
          this.setState({
            csvAgents: [],
            csvAgentsBad: [],
            selectedFile: null,
            importModal: false,
          });

          this.loadAgents();
        } else {
          alert("Error processing file");
        }
      },
      (error) => {
        alert(error.response.data.message);
      }
    );
  }

  clearAll() {
    this.setState({
      selectedFile: null,
      csvAgents: [],
      csvAgentsBad: [],
    });
  }

  importModal() {
    // TODO: Show screen for apperoval of agents (just basics of name/email)
    return (
      <Modal open={this.state.importModal} onClose={this.handleCloseImport}>
        <div style={styles.modal}>
          <div style={styles.modalTitle}>
            <h2>Import agents CSV</h2>
            <div
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                this.setState({ importModal: false });
              }}
            >
              <FiX style={styles.menuIcon} />
            </div>
          </div>

          {this.state.csvAgents.length ? (
            <>
              <p>
                <strong>The following agents are ready to import:</strong>
              </p>
              <ul>
                {this.state.csvAgents.map((item) => (
                  <li>
                    {item.name} ({item.email})
                  </li>
                ))}
              </ul>
              <p style={{ paddingTop: 10 }}>
                <button
                  class="btn btn-primary"
                  onClick={() => {
                    this.sendAgents();
                  }}
                >
                  Import agents
                </button>

                <button
                  style={{ marginLeft: 20 }}
                  class="btn btn-danger"
                  onClick={() => {
                    this.clearAll();
                  }}
                >
                  Cancel
                </button>
              </p>
            </>
          ) : (
            <>
              <p>
                You can{" "}
                <a
                  href="https://homestack.com/hs_agent_import.csv"
                  target="_blank"
                  class="text-underline"
                >
                  download the CSV template to fill in here
                </a>
                . Make sure to follow the template otherwise the import will
                fail.
              </p>

              <div>
                <input
                  type="file"
                  name="file"
                  class="form-control-file"
                  onChange={this.onFileChange}
                />
                <p style={{ paddingTop: 10 }}>
                  <button class="btn btn-primary" onClick={this.onFileUpload}>
                    Upload CSV file
                  </button>
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }

  createAgentModal() {
    if (
      AppStore.app_data.plan.limit > 0 &&
      this.state.agents.length >= AppStore.app_data.plan.limit
    ) {
      return (
        <Modal open={this.state.createModal} onClose={this.handleClose}>
          <div style={styles.modal}>
            <div style={styles.modalTitle}>
              <h3>Please upgrade plan</h3>
              <div
                style={{
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.setState({ createModal: false });
                }}
              >
                <FiX style={styles.menuIcon} />
              </div>
            </div>

            <p>
              Your current plan allows a maximum of{" "}
              <strong>{AppStore.app_data.plan.limit}</strong> agent
              {AppStore.app_data.plan.limit == 1 ? null : "s"}. To add more
              please contact{" "}
              <a href="mailto:prosupport@homestack.com" class="text-underline">
                prosupport@homestack.com
              </a>
              .
            </p>
          </div>
        </Modal>
      );
    }

    return (
      <Modal open={this.state.createModal} onClose={this.handleClose}>
        <div style={styles.modal}>
          <div style={styles.modalTitle}>
            <h2>Create new agents</h2>
            <div
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                this.setState({ createModal: false });
              }}
            >
              <FiX style={styles.menuIcon} />
            </div>
          </div>

          <Formik
            initialValues={{
              display_name: null,
              email: null,
              password: null,
            }}
            validate={(values) => {
              let errors = {};

              if (AppStore.app_data.toggles.officesEnabled) {
                if (!values.office_id && !UserStore.isAdmin()) {
                  errors.office_id = "Required";
                  //alert('An office is required')
                }
              }

              if (!values.display_name) {
                errors.display_name = "Required";
              }

              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 6) {
                errors.password = "Password must be at least 6 characters";
              }

              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (AppStore.app_data.toggles.officesEnabled) {
                if (!values.office_id && !UserStore.isAdmin()) {
                  setSubmitting(false);
                  alert("Office is required");
                  return;
                }
              }

              setTimeout(() => {
                values.send_email = this.state.sendInviteEmail;
                //alert(JSON.stringify(values, null, 2));

                api.createAgent(
                  values,
                  (response) => {
                    //alert(JSON.stringify(response));
                    this.setState({ toAgent: response.id });
                  },
                  (error) => {
                    alert(error.response.data.message);
                  }
                );

                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <p>
                  <label for="display_name">Name</label>
                  <input
                    type="text"
                    name="display_name"
                    class="form-control"
                    id="display_name"
                    placeholder="Agent name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.display_name}
                  />
                  <span className="form-error">
                    {errors.display_name &&
                      touched.display_name &&
                      errors.display_name}
                  </span>
                </p>

                <p>
                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    id="email"
                    placeholder="Agent email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="form-error">
                    {errors.email && touched.email && errors.email}
                  </span>
                </p>

                <p>
                  <label for="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    class="form-control"
                    id="password"
                    placeholder="Agent password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span className="form-error">
                    {errors.password && touched.password && errors.password}
                  </span>
                </p>

                {AppStore.app_data.toggles.officesEnabled && (
                  <>
                    <p>
                      <label for="office_id">Office</label>
                      <select
                        value={values.office_id}
                        name="office_id"
                        id="office_id"
                        class="form-control"
                        onChange={e => {
                          values.office_id = e.target.value;
                          //this.updateOffice(e.target.value);
                        }}>
                        <option value="">-- Choose office --</option>
                        {this.state.offices.map(office => {
                          let spacer = "";
                          if (office.padding > 0) {
                            spacer = "-".repeat(parseInt(office.padding) / 10);
                            spacer += " ";
                          }

                          return (
                            <option value={office.id}>
                              {spacer}{office.name}
                            </option>
                          );

                        })}
                      </select>
                      <span className="form-error">
                        {errors.office_id}
                      </span>
                    </p>
                  </>
                )}

                <p>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={this.state.sendInviteEmail}
                          onChange={() => {
                            const tog = !this.state.sendInviteEmail;
                            this.setState({
                              sendInviteEmail: tog,
                            });
                          }}
                          value="1"
                        />
                      }
                      label="Send email notice"
                    />
                  </FormGroup>
                </p>

                <p>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Create agent{" "}
                    {this.state.sendInviteEmail ? "& send email" : null}
                  </button>
                </p>

                <p>
                  <small>You can add more details after creating.</small>
                </p>
              </form>
            )}
          </Formik>

          <div className="alert alert-warning"><strong>Creating a new agent will result in account changes governed by your sales agreement.</strong></div>
        </div>
      </Modal>
    );
  }

  pagination() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 5,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            class="fddorm-group"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              class="form-control"
              name="q"
              value={this.state.resultQ}
              placeholder="Search name, email..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.updateQuery();
                }
              }}
              onChange={(e) => {
                this.setState({ resultQ: e.target.value });
                if (!e.target.value) {
                  this.updateQuery();
                }
              }}
            />
            {this.state.resultQ ? (
              <i
                onClick={() => {
                  this.setState({ resultQ: "" });
                  this.updateQuery();
                }}
                class="fe fe-x"
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  cursor: "pointer",
                  fontSize: 20,
                  color: "red",
                }}
              />
            ) : (
              <></>
            )}
          </div>

          <div class="fddorm-group">
            <select
              value={this.state.resultLimit}
              class="form-control"
              onChange={(e) => {
                this.updateLimit(e.target.value);
              }}
            >
              <option value="0">Show all</option>
              <option value="10">Show 10</option>
              <option value="25">Show 25</option>
              <option value="50">Show 50</option>
            </select>
          </div>
          <div style={{ marginLeft: 10 }}>
            <strong>
              Showing {this.state.resultsFrom} - {this.state.resultsTo} of{" "}
              {this.state.resultsTotal}
            </strong>
          </div>
          <div style={{ marginLeft: 30 }}>
            <strong>Order by</strong>
          </div>
          <div class="fddorm-group" style={{ marginLeft: 10 }}>
            <select
              value={this.state.resultSort}
              class="form-control"
              onChange={(e) => {
                this.updateSort(e.target.value);
              }}
            >
              <option value="">Sorted order</option>
              <option value="a">A-Z</option>
              <option value="z">Z-A</option>
              <option value="n">Newest</option>
              <option value="o">Oldest</option>
              <option value="ai">Inactive first</option>
              <option value="aa">Active first</option>
              <option value="ud">User count (high)</option>
              <option value="ua">User count (low)</option>
            </select>
          </div>
          {AppStore.app_data.toggles.officesEnabled && (
            <>
              <div style={{ marginLeft: 30 }}>
                <strong>Office</strong>
              </div>
              <div class="fddorm-group" style={{ marginLeft: 10 }}>
                <select
                  value={this.state.officeQ}
                  name="office_id"
                  id="office_id"
                  class="form-control"
                  onChange={e => {
                    this.updateOffice(e.target.value);
                  }}>
                  <option value="0">Any</option>
                  {this.state.offices.map(office => {
                    let spacer = "";
                    if (office.padding > 0) {
                      spacer = "-".repeat(parseInt(office.padding) / 10);
                      spacer += " ";
                    }

                    return (
                      <option value={office.id}>
                        {spacer}{office.name}
                      </option>
                    );

                  })}
                </select>
              </div>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {this.state.resultsFrom > 1 && (
            <>
              <button
                onClick={() => {
                  this.page("first");
                }}
                class="btn btn-sm btn-secondary"
              >
                First
              </button>
              <div style={{ width: 10 }} />
              <button
                onClick={() => {
                  this.page("prev");
                }}
                class="btn btn-sm btn-primary"
              >
                &larr; Previous
              </button>
            </>
          )}
          <div style={{ width: 10 }} />
          {this.state.resultsTo < this.state.resultsTotal && (
            <>
              <button
                onClick={() => {
                  this.page("next");
                }}
                class="btn btn-sm btn-primary"
              >
                Next &rarr;
              </button>
              <div style={{ width: 10 }} />
              <button
                onClick={() => {
                  this.page("last");
                }}
                class="btn btn-sm btn-secondary"
              >
                Last
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  agents() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadAgents();
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const { match } = this.props;

    return (
      <section class="admin-content">
        {this.createAgentModal()}
        {this.importModal()}

        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600 }}
          visible={this.state.videoModal}
          onClose={() => { this.setState({ videoModal: false }) }}
          //width={600}
          height={400}
        >
          <p><iframe src="https://player.vimeo.com/video/498099787" width="550" height="350" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
        </Rodal>

        <div class="bg-dark">
          <div class="container  m-b-30">
            <Help section="agents" />
            <div class="row">
              <div class="col-12 text-white p-t-40 p-b-90">
                <div class="float-right">
                  {UserStore.isAdmin() && !AppStore.agentCardsChanged && (
                    <button
                      style={{ marginRight: 20 }}
                      onClick={() => {
                        this.csv();
                      }}
                      class="btn btn-primary"
                    >
                      <i class=" fe fe-download " /> Download CSV
                    </button>
                  )}

                  {UserStore.isAdmin() && !AppStore.agentCardsChanged && !AppStore.readOnly && AppStore.app_data.template != "windermere" && (
                    <button
                      style={{ marginRight: 20 }}
                      onClick={() => {
                        this.setState({ importModal: true });
                      }}
                      class="btn btn-primary"
                    >
                      <i class=" fe fe-upload " /> Upload CSV
                    </button>
                  )}

                  {!AppStore.agentCardsChanged && !AppStore.readOnly && AppStore.app_data.template != "windermere" && (
                    <button
                      onClick={() => {
                        this.setState({ createModal: true });
                      }}
                      class="btn btn-success"
                    >
                      New agent
                    </button>
                  )}

                  {AppStore.agentCardsChanged && !AppStore.readOnly && (
                    <>
                      <button
                        style={{ marginRight: 20 }}
                        onClick={() => {
                          //alert("new order=" + AppStore.newOrder);
                          //return;
                          api.updateAgentOrder(
                            AppStore.newOrder,
                            (response) => {
                              window.showToastMessage(
                                "Success",
                                "New agent order saved"
                              );
                              AppStore.agentCardsChanged = false;
                            },
                            (error) => {
                              //alert("Error saving profile");
                              window.showToastMessage(
                                "Error",
                                "Error saving profile",
                                "danger",
                                "fe-x"
                              );
                            }
                          );
                        }}
                        class="btn btn-warning"
                      >
                        <i class=" fe fe-check " /> Save agent order
                      </button>

                      <button
                        onClick={() => {
                          AppStore.agentCardsChanged = false;
                        }}
                        class="btn btn-danger"
                      >
                        <i class=" fe fe-x " /> Cancel
                      </button>
                    </>
                  )}
                </div>
                <h4 class="">
                  {" "}
                  <span class="btn btn-white-translucent">
                    <i class="icon-placeholder fe fe-briefcase " />
                  </span>{" "}
                  Agents &nbsp;&nbsp;<span style={{ cursor: "pointer", fontSize: 14 }} onClick={() => {
                    this.setState({ videoModal: true })
                  }} class="tag"><FiVideo /> HomeStack Pro Tips</span>
                </h4>
                {/*<p class="opacity-75 ">
                  We have inherited from Bootstrap 4, with some tweaks we have
                  <br /> created table styles that blend in our design.
                </p>*/}
              </div>
            </div>
          </div>
        </div>

        <div class="container pull-up">
          {this.planWarning()}

          <div class="row">
            <div class="col-md-12">
              <div class="card m-b-30">
                <div class="card-body">
                  {this.pagination()}
                  <div class="table-responsive">
                    <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Users</th>
                          <th>Status</th>
                          {AppStore.app_data.toggles.officesEnabled && (
                            <th>Office</th>
                          )}
                          <th>Setup Complete</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <DndProvider backend={HTML5Backend}>
                        {this.state.resultsTotal > 0 && (
                          <AgentList
                            agents={this.state.agents}
                            resultsTotal={this.state.resultsTotal}
                            resultSort={this.state.resultSort}
                          />
                        )}
                      </DndProvider>
                    </table>
                  </div>
                  {this.pagination()}

                  {/*
                  <div class="table-responsive">
                    <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Users</th>
                          <th>Status</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.agents.map(item => (
                          <tr>
                            <td>
                              {item.photo ? (
                                <div class="avatar avatar-sm">
                                  <img
                                    src={item.photo}
                                    class="avatar-img rounded-circle"
                                  />
                                </div>
                              ) : (
                                <div class="avatar avatar-sm">
                                  <span class="avatar-title rounded-circle ">
                                    {item.display_name
                                      .split(" ")
                                      .map((n, i, a) =>
                                        i === 0 || i + 1 === a.length
                                          ? n[0]
                                          : null
                                      )
                                      .join("")}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>{item.display_name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.total_users}</td>
                            <td>{item.active == 1 ? "Active" : "Inactive"}</td>
                            <td>
                              <Link
                                to={`/agents/${item.id}`}
                                class="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
               */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

    return (
      <div class="row">
        <Modal open={this.state.createModal} onClose={this.handleClose}>
          <div style={styles.modal}>
            <div style={styles.modalTitle}>
              <h2>Create new agent</h2>
              <div
                style={{
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.setState({ createModal: false });
                }}
              >
                <FiX style={styles.menuIcon} />
              </div>
            </div>

            <Formik
              initialValues={{
                display_name: null,
                email: null,
                password: null,
              }}
              validate={(values) => {
                let errors = {};

                if (!values.display_name) {
                  errors.display_name = "Required";
                }

                if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length < 6) {
                  errors.password = "Password must be at least 6 characters";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  //alert(JSON.stringify(values, null, 2));

                  api.createAgent(
                    values,
                    (response) => {
                      //alert(JSON.stringify(response));
                      this.setState({ toAgent: response.id });
                    },
                    (error) => {
                      alert(error.response.data.message);
                    }
                  );

                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <p>
                    <label for="display_name">Name</label>
                    <input
                      type="text"
                      name="display_name"
                      id="display_name"
                      placeholder="Agent name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.display_name}
                    />
                    <span className="form-error">
                      {errors.display_name &&
                        touched.display_name &&
                        errors.display_name}
                    </span>
                  </p>

                  <p>
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Agent email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="form-error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </p>

                  <p>
                    <label for="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Agent password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span className="form-error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </p>

                  <p>
                    <button type="submit" disabled={isSubmitting}>
                      Create agemt
                    </button>
                  </p>

                  <p>
                    <small>You can add more details after creating.</small>
                  </p>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        <div class="col">
          <div className="box-title">
            <h2>Agents</h2>
            <div style={{ width: 200 }}>
              <button
                onClick={() => {
                  this.setState({ createModal: true });
                }}
              >
                Create new agent
              </button>
            </div>
          </div>
          <div class="content-block-below">
            <table>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.state.agents.map((item) => (
                  <tr>
                    <td>
                      {item.photo && (
                        <img
                          src={item.photo}
                          style={{ width: 40, height: 40, borderRadius: 20 }}
                        />
                      )}
                    </td>
                    <td>{item.display_name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.total_users}</td>
                    <td>{item.active == 1 ? "Active" : "Inactive"}</td>
                    <td>
                      {!AppStore.readOnly && (
                        <Button
                          color="primary"
                          component={Link}
                          to={`/agents/${item.id}`}
                        >
                          Edit
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!UserStore.isAdmin() && !UserStore.isManager()) {
      return <Redirect to="/" />;
    }

    if (this.state.toAgent) {
      return <Redirect to={"/agents/" + this.state.toAgent} />;
    }

    const user = UserStore.user;

    return (
      <Inner>
        <div class="content">{this.agents()}</div>
      </Inner>
    );
  }
}

const styles = {
  modal: {
    backgroundColor: "#fff",
    padding: 20,
    maxWidth: 500,
    width: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
};

export default Agents;

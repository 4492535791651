// API

// axios
import axios from "axios";
import AppStore from "../stores/AppStore";
import { reaction } from "mobx";

const api_url = "https://bkapi.homestack.com/dash";
const base_api_url = "https://bkapi.homestack.com/";

let api_headers = {
  "X-STACK-ID": null,
};

reaction(
  () => AppStore.api_key,
  () => {
    api_headers = {
      "X-API-KEY": AppStore.api_key,
      //"X-STACK-ID": AppStore.stack_id
    };
  }
);

const Api = {
  // SSO
  accessToken: function (data, _success, _error) {
    axios
      .post(api_url + "/login/sso", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  // cancel sub
  cancelSubscription: function(_success, _error) {
    axios
    .delete(api_url + "/user/cancel_subscription", {
      headers: api_headers,
    })
    .then(function (response) {
      _success(response.data);
    })
    .catch(function (error) {
      if (error.response === undefined) {
        error.response = { data: { message: "Unable to reach server." } };
      }
      _error(error);
    });
  },

  // Log in
  login: function (data, _success, _error) {
    axios
      .post(api_url + "/login", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  // Log in via API key
  key: function (data, _success, _error) {
    axios
      .post(api_url + "/login/token", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  forgot: function (data, _success, _error) {
    axios
      .post(api_url + "/login/forgot", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  getAgentMortgageProToggle: function (_success, _error) {
    axios
      .get(api_url + "/user/mpro_agent", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  updateAgentMortgageProToggle: function (val, _success, _error) {
    let url = api_url + "/user/mpro_agent";

    axios
      .post(url, { toggle: val }, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  getMpro: function (_success, _error) {
    axios
      .get(api_url + "/user/mpro", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deleteMpro: function (_success, _error) {
    let url = api_url + "/user/mpro";

    axios
      .post(url, { delete: 1 }, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateMpro: function (data, _success, _error) {
    let url = api_url + "/user/mpro";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  getProfile: function (_success, _error) {
    axios
      .get(api_url + "/user/profile", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getPrefs: function (_success, _error) {
    axios
      .get(api_url + "/user/prefs", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  savePrefs: function (data, _success, _error) {
    axios
      .post(
        api_url + "/user/prefs",
        { toggles: data },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getStats: function (data, _success, _error) {
    axios
      .post(api_url + "/app/stats/", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getCSV: function (_success, _error) {
    let url = api_url + "/app/csv";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getAgentsCSV: function (_success, _error) {
    let url = api_url + "/app/agents_csv";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getAlerts: function (_success, _error, id) {
    let url = api_url + "/app/alerts";

    if (id) {
      url += "/" + id;
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deleteScheduledAlert: function (id, _success, _error) {
    let url = api_url + "/app/delete_scheduled_alert";

    axios
      .post(
        url,
        { alert_id: id },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getScheduledAlerts: function (_success, _error, id) {
    let url = api_url + "/app/scheduled_alerts";

    if (id) {
      url += "/" + id;
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  saveAgentNotes: function (id, notes, _success, _error) {
    let url = api_url + "/app/agentnotes/" + id;

    axios
      .post(
        url,
        { notes: notes },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getUserData: function (id, type, _success, _error) {
    let url = api_url + "/app/userdata/" + id + "?type=" + type;

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getBotMessage: function (_success, _error) {
    let url = api_url + "/user/bot";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getChatList: function (_success, _error) {
    let url = api_url + "/user/chats";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getChatTranscript: function (data, _success, _error) {
    let url = api_url + "/user/transcript";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getPaymentLinks: function (_success, _error) {
    let url = api_url + "/user/paymentlinks";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  addFeatured: function (data, _success, _error) {
    let url = api_url + "/app/featured_add";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  updateFeatured: function (data, _success, _error) {
    let url = api_url + "/app/featured_edit";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deleteFeatured: function (data, _success, _error) {
    let url = api_url + "/app/featured_remove";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  hideFeatured: function (data, _success, _error) {
    let url = api_url + "/app/featured_hide";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  loadAllUserTags: function (_success, _error, editable) {
    let url = api_url + "/app/user_tags";

    if (editable) {
      url += "?edit=1"
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  createUserTag: function (data, _success, _error) {
    let url = api_url + "/app/user_create_tag";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deleteUserTag: function (data, _success, _error) {
    let url = api_url + "/app/user_delete_tag";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  addUserTag: function (data, _success, _error) {
    let url = api_url + "/app/user_add_tag";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  addUserTags: function (data, _success, _error) {
    let url = api_url + "/app/user_add_tags";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  removeUserTag: function (data, _success, _error) {
    let url = api_url + "/app/user_remove_tag";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getUserTags: function (data, _success, _error, office_id = null) {
    let url = api_url + "/app/alert_tags";

    if (office_id) {
      data.office_id = office_id;
    }

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getUsers: function (limit, offset, sort, _success, _error, id, query, agent_id, tag, office_id) {
    let url = api_url + "/app/users";

    if (id) {
      url += "/" + id;
    }

    url += "?limit=" + limit + "&offset=" + offset + "&sort=" + sort;

    if (query) {
      url += "&q=" + query;
    }

    if (agent_id) {
      url += "&agent=" + agent_id;
    }

    if (tag) {
      url += "&tag=" + tag;
    }

    if (office_id) {
      url += "&office=" + office_id;
    }

    if (limit) {
      //url += "?page=" + page;
    }

    if (offset) {
      //
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  userActive(user_id, status, _success, _error) {
    let url = api_url + "/user/active";

    axios
      .post(
        url,
        { user_id: user_id, status: status },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  promoteManager(user_id, office_id, _success, _error) {
    let url = api_url + "/user/promote_manager";

    axios
      .post(
        url,
        { user_id: user_id, office_id: office_id },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  promoteAgent(user_id, _success, _error) {
    let url = api_url + "/user/promote_agent";

    axios
      .post(
        url,
        { user_id: user_id },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  updateBotMessage: function (msg, _success, _error) {
    let url = api_url + "/user/bot";

    axios
      .post(
        url,
        { message: msg },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  sendAlert(data, _success, _error) {
    let url = api_url + "/app/alerts";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getCustomSections: function (_success, _error, agent_only) {
    let ao = 0;
    if (agent_only) {
      ao = 1;
    }

    axios
      .get(api_url + "/app/section?agent_only=" + ao, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  saveCustomSections: function (data, _success, _error, agent_only) {
    let ao = 0;
    if (agent_only) {
      ao = 1;
    }

    axios
      .post(
        api_url + "/app/section",
        { data: data, agent_only: ao },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  // Single listing
  listing: function (listing_key, _success, _error) {
    axios
      .get('https://bkapi.homestack.com/listings/' + listing_key, {
        headers: {
          "X-STACK-ID": AppStore.app_data.stack_id,
        },
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: 'Unable to reach server.' } };
        }
        _error(error);
      });
  },

  // Search for listings
  listings: function (data, _success, _error) {
    axios
      .post('https://bkapi.homestack.com/listings', data, {
        headers: {
          "X-STACK-ID": AppStore.app_data.stack_id,
        },
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: 'Unable to reach server.' } };
        }
        _error(error);
      });
  },

  getListingSuggestions(q, _success, _error) {
    let url = "https://bkapi.homestack.com/listings/smartsearch";
    let stack_headers = {
      "X-STACK-ID": AppStore.app_data.stack_id,
    };

    axios
      .post(
        url,
        { q: q },
        {
          headers: stack_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getFeatureds: function (_success, _error, id) {
    let url = api_url + "/app/featureds";

    if (id) {
      url += "/" + id;
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getPockets: function (_success, _error, id) {
    let url = api_url + "/app/pockets";

    if (id) {
      url += "/" + id;
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getPocket: function (key, _success, _error, id) {
    let url = api_url + "/app/pocket/" + key;

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getOffices: function (_success, _error) {
    let url = api_url + "/app/offices";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getAgents: function (limit, offset, sort, _success, _error, id, query, office_id) {
    let url = api_url + "/app/agents";

    if (id) {
      url += "/" + id;
    }

    if (limit) {
      url += "?limit=" + limit + "&offset=" + offset + "&sort=" + sort;
    } else if (sort) {
      url += "?sort=" + sort;
    }

    if (query) {
      if (!id && !limit && !sort) {
        url += "?q=" + query;
      } else {
        url += "&q=" + query;
      }
    }

    if (office_id) {
      if (!id && !limit && !sort && !query) {
        url += "?office_id=" + office_id;
      } else {
        url += "&office_id=" + office_id;
      }
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getWebhooks: function (_success, _error) {
    axios
      .get(api_url + "/app/webhooks", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  updateWebhook: function (data, _success, _error) {
    axios
      .post(api_url + "/app/webhooks", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getIntegrationInfo: function (_success, _error, agent_only) {
    let extra = "";
    if (agent_only == true) {
      extra = "?agent=1";
    }

    axios
      .get(api_url + "/app/integration" + extra, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  updateIntegration: function (int, _success, _error, agent_only) {
    let extra = "";
    if (agent_only == true) {
      int.agent = 1;
    }

    axios
      .post(api_url + "/app/integration" + extra, int, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getAPIKey: function (_success, _error) {
    axios
      .get(api_url + "/app/api_key", {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getHelp: function (_success, _error, section) {
    let extra = "";

    if (section) {
      extra = "?section=" + section;
    }

    axios
      .get(api_url + "/user/help" + extra, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  savePocket: function (data, _success, _error) {
    axios
      .post(api_url + "/app/pocket", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deletePocket: function (key, _success, _error) {
    axios
      .post(
        api_url + "/app/pocket_delete",
        { listing_key: key },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  changeAgent: function (userId, agentId, _success, _error) {
    axios
      .post(
        api_url + "/user/change_agent",
        {
          user_id: userId,
          agent_id: agentId,
        },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  changeAgents: function (userIds, agentId, _success, _error) {
    axios
      .post(
        api_url + "/user/change_agents",
        {
          user_ids: userIds,
          agent_id: agentId,
        },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  deleteAgent: function (id, _success, _error) {
    axios
      .post(
        api_url + "/user/delete_agent",
        {
          id: id,
        },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getProvApp: function (uri, provision_id, _success, _error) {
    axios
      .get(api_url + "/app/prov/" + uri + "?provision_id=" + provision_id, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  agentProv: function (uri, data, _success, _error) {
    axios
      .post(
        api_url + "/app/prov_agent/" + uri,
        data,
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  getApp: function (stack_id, _success, _error) {
    axios
      .get(base_api_url + "/app/" + stack_id, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  uploadPocketPhoto: function (image, _success, _error) {
    const formData = new FormData();
    formData.append("file", new Blob([image]));

    axios
      .post(api_url + "/app/pocket_photo/", formData, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  uploadMproPhoto: function (image, _success, _error) {
    const formData = new FormData();

    formData.append("file", new Blob([image]));

    axios
      .post(api_url + "/user/mpro_avatar/", formData, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  deleteMproPhoto: function (_success, _error) {
    axios
      .post(
        api_url + "/user/mpro_avatar/",
        { delete: true },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  uploadAvatar: function (agentId, image, _success, _error) {
    const formData = new FormData();
    /*formData.append("file", {
      uri: image,
      type: "image/jpeg",
      name: "avatar"
    });*/

    formData.append("file", new Blob([image]));

    axios
      .post(api_url + "/user/avatar/" + agentId, formData, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  // Delete avatar
  deleteAvatar: function (agentId, _success, _error) {
    axios
      .post(
        api_url + "/user/avatar/" + agentId,
        { delete: true },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateAgentOrder: function (data, _success, _error) {
    let url = api_url + "/user/agent_order";

    axios
      .post(
        url,
        { order: data },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updatePassword: function (data, _success, _error, id) {
    let url = api_url + "/user/password";

    if (!id) {
      return;
    }

    url += "/" + id;

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateMyPassword: function (data, _success, _error) {
    let url = api_url + "/user/mypassword";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  resendAgentEmail: function (_success, _error, id) {
    let url = api_url + "/user/resend_agent_email";

    if (!id) {
      return;
    }

    url += "/" + id;

    axios
      .post(url, {}, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  loadSearchLinks: function (_success, _error) {
    let url = api_url + "/user/searchlink";

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  loadSaveSearch: function (user_id, _success, _error) {
    let url = api_url + "/user/savesearch";

    if (user_id) {
      url += "/" + user_id;
    }

    axios
      .get(url, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        _error(error);
      });
  },

  saveSearch: function (data, _success, _error) {
    let url = api_url + "/user/savesearch";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  saveSearchLink: function (data, _success, _error) {
    let url = api_url + "/user/searchlink";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateProfileCRM: function (data, _success, _error, id) {
    let url = api_url + "/user/profile_crm_email";

    if (id) {
      url += "/" + id;
    }

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  fubAuth: function (data, _success, _error) {
    let url = api_url + "/app/fubauth";

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateProfile: function (data, _success, _error, id) {
    let url = api_url + "/user/profile";

    if (id) {
      url += "/" + id;
    }

    axios
      .post(url, data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  uploadAgents: function (csv, _success, _error) {
    const formData = new FormData();
    formData.append("file", new Blob([csv]));

    axios
      .post(api_url + "/user/agent_csv", formData, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  sendAgents: function (csv, _success, _error) {
    axios
      .post(
        api_url + "/user/agent_publish",
        { agents: csv },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  uploadUsers: function (csv, _success, _error) {
    const formData = new FormData();
    formData.append("file", new Blob([csv]));

    axios
      .post(api_url + "/user/user_csv", formData, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  sendUsers: function (csv, _success, _error) {
    axios
      .post(
        api_url + "/user/user_publish",
        { agents: csv },
        {
          headers: api_headers,
        }
      )
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  createAgent: function (data, _success, _error) {
    axios
      .post(api_url + "/user/create_agent", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  createUser: function (data, _success, _error) {
    axios
      .post(api_url + "/user/create_user", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },

  updateApp: function (data, _success, _error) {
    axios
      .post(api_url + "/app/update", data, {
        headers: api_headers,
      })
      .then(function (response) {
        _success(response.data);
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = { data: { message: "Unable to reach server." } };
        }
        _error(error);
      });
  },
};

export default Api;

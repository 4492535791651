import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "./ItemTypes";
import { Route, Redirect, Link } from "react-router-dom";
import AppStore from "../../../stores/AppStore";
const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move"
};
const AgentCard = ({ id, text, index, moveCard, dropCard, disabled }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!disabled) {
        AppStore.agentCardsChanged = true;
      }

      dropCard(dragIndex, hoverIndex);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  if (!disabled) {
    drag(drop(ref));
  }

  const item = JSON.parse(text);

  return (
    <tr ref={ref} style={{ opacity }}>
      {disabled ? (
        <td>&nbsp;</td>
      ) : (
        <td>
          <span class="menu-icon">
            <i
              class="icon-placeholder fe fe-menu "
              style={{ fontSize: 20, color: "#bbb", cursor: "move" }}
            />
          </span>
        </td>
      )}
      <td>
        {item.photo ? (
          <div class="avatar avatar-sm">
            <img src={item.photo} class="avatar-img rounded-circle" />
          </div>
        ) : (
          <div class="avatar avatar-sm">
            <span class="avatar-title rounded-circle ">
              {item.display_name
                .split(" ")
                .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
                .join("")}
            </span>
          </div>
        )}
      </td>
      <td>{item.display_name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.total_users}</td>
      <td>{item.active == 1 ? (
        <span style={{
          backgroundColor: "#ddefad",
          color: "#4b5c1e",
          padding: "4px 8px",
          fontWeight: "bold",
          borderRadius: 6
        }}>Active</span>
      ) : (
        <span style={{
          backgroundColor: "#eee",
          color: "#777",
          padding: "4px 8px",
          borderRadius: 6
        }}>Inactive</span>
      )}</td>
      {AppStore.app_data.toggles.officesEnabled && (
        <td>
          {item.office?.name && (
            <span style={{ whiteSpace: "nowrap", padding: "4px 8px", border: "1px solid #aaa", color: "#999", borderRadius: 12 }}>{item.office?.name}</span>
          )}
        </td>
      )}
      <td>{item.login_count > 2 ? (
        <i
          class="fe fe-check text-success"
          style={{ fontWeight: "bold" }}
        ></i>
      ) : (
        <i
          class="fe fe-x text-danger"
          style={{ fontWeight: "bold" }}
        ></i>
      )}</td>
      <td>
        {!AppStore.readOnly && AppStore.app_data.template != "windermere" && (
          <Link to={`/agents/${item.id}`} class="btn btn-primary">
            Edit
          </Link>
        )}
      </td>
    </tr>
  );
};
export default AgentCard;

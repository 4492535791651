import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import DashStat from "./components/DashStat";
import DashMostViewed from "./components/DashMostViewed";
import Chart from "react-apexcharts";
import MostViewed from "../stats/MostViewed";
import UserRegistrations from "../stats/UserRegistrations";
import AppHistory from "../stats/AppHistory";
import moment from "moment";

const latestAlert = "ccwebinar";

@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { logout: false, showFeatureAlert: true }; // TODO: back to TRUE for alert when ready
  }

  componentDidMount() {
    const cookies = new Cookies();
    const feature_alert = cookies.get("feature_alert");
    if (feature_alert == latestAlert) {
      this.setState({ showFeatureAlert: false });
    } else {
      this.featureAlert.click();

      cookies.set("feature_alert", latestAlert, {
        path: "/",
        maxAge: 60 * 60 * 24,
      });
    }
  }

  planBox() {
    if (!UserStore.isAdmin()) {
      return null;
    }

    if (AppStore.app_data.plan.limit == 0) {
      return null;
    }

    const totalAgents = AppStore.app_data.plan.agents;
    const planLimit = AppStore.app_data.plan.limit;
    const percentage = Math.round((totalAgents / planLimit) * 100);
    let percentageClass = "bg-success";
    let warningMessage = null;

    if (percentage >= 100) {
      percentageClass = "bg-danger";
      warningMessage = "Contact support to add more";
    } else if (percentage >= 50) {
      percentageClass = "bg-warning";
      //warningMessage = "Contact support to add more";
    }

    return (
      <div class="col-md-3">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="m-b-10">
              <div class="row">
                <div class="col">
                  <p class="m-b-5">
                    <Link to="/agents">Agents</Link>
                  </p>
                </div>
                <div class="col-auto text-right">
                  <div class="text-muted">
                    {totalAgents} of {planLimit}
                  </div>
                </div>
              </div>
              <div class="">
                <div class="progress">
                  <div
                    class={"progress-bar " + percentageClass}
                    role="progressbar"
                    style={{ width: percentage + "%" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                {warningMessage && (
                  <p style={{ marginTop: 10, marginBottom: 0 }}>
                    <small>
                      <a
                        href="mailto:prosupport@homestack.com"
                        class="text-underline"
                      >
                        {warningMessage}.
                      </a>
                    </small>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.logout) {
      const cookies = new Cookies();
      cookies.remove("bk_api_key");
      cookies.remove('bk_api_key', { path: '/' });
      cookies.remove('bk_ro');
      return <Redirect to="/login" />;
    }

    //if (AppStore.isEmbedded) {
    //  return <Redirect to={"/users/" + AppStore.embedUser} />;
    //}

    const user = UserStore.user;

    let options = {
      chart: {
        id: "apexchart-example",
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
        marker: {
          show: false,
        },
      },
    };
    let series = [
      {
        data: [0, 0, 74, 84, 156, 174, 214, 211, 207, 239, 382],
      },
    ];

    return (
      <Inner>
        <div class="jumbotron">
          <div class="row">
            <div class="col-lg-12 col-xlg-12">
              <h3 class="">Welcome to your dashboard, {user.display_name}</h3>
              <p class="text-muted">
                Choose options from the menu on the left...
              </p>
            </div>
          </div>

          {UserStore?.user?.agent_data?.self_provisioned &&
            UserStore?.user?.agent_data?.subscription_status == "canceled" &&
            (
              <div class="row">
                <div class="col-sm-12">
                  <div
                    class="alert alert-border-danger  alert-dismissible fade show"
                    role="alert"
                    style={{
                      backgroundColor: "#fff",
                      /*boxShadow: "1px 2px 10px rgba(0,0,0,0.3)"*/
                    }}
                  >
                    <h4 className="text-danger">Subscription canceled</h4>
                    <p>Your subsription was cancelled and you have until
                      {" "}<strong>{moment.unix(UserStore?.user?.agent_data?.subscription_cancel_at).format("MMMM D, YYYY")}</strong>{" "}
                      after which your account will be deactivated. <a href="/users" className="text-underline">Download your users now</a> if you need to access this information later.
                    </p>
                  </div>
                </div>
              </div>
            )}

          {!UserStore.isManager() && (
            <>
              <div class="row">
                <div class="col-xlg-6 m-b-3 col-lg-12">
                  <div class="row">
                    <div class=" col-md-3">
                      <DashStat
                        type="app_total_users"
                        label="Total users"
                        icon="users"
                      />
                    </div>
                    <div class=" col-md-3">
                      <DashStat
                        color="warning"
                        type="app_logins"
                        label="Logins this month"
                        icon="users"
                      />
                    </div>
                    <div class=" col-md-3">
                      <DashStat
                        color="success"
                        type="app_listings"
                        label="Listings viewed past 6 months"
                        icon="home"
                      />
                    </div>

                    {this.planBox()}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <MostViewed />
                </div>

                <div class="col-lg-6">
                  <UserRegistrations />
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <AppHistory />
                </div>
              </div>
            </>
          )}

          <button
            type="button"
            class="btn btn-primary m-b-10"
            data-toggle="modal"
            data-target="#example_05"
            ref={(input) => (this.featureAlert = input)}
            style={{ display: "none" }}
          >
            Alert
          </button>

          {this.state.showFeatureAlert && 1 == 9 && (
            <div
              class="modal fade modal-bottom-right show"
              id="example_05"
              tabindex="-1"
              role="dialog"
              aria-labelledby="example_04"
              //style={styles}
              aria-modal="true"
            >
              <div class="modal-dialog modal-sm " role="document">
                <div class="modal-content  ">
                  <div class="modal-body">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div class="text-center p-t-20">
                      <h1>🚀</h1>
                    </div>
                    <div style={{ height: 30 }} />

                    <h3 class="text-left">
                      <small>
                        <span class="badge badge-info">New Webinar</span>
                      </small>{" "}
                      Level Up to Luxury (in any market) with Christophe Choo
                    </h3>
                    <p class="text-left text-muted">
                      <a href="https://us02web.zoom.us/webinar/register/6016856458658/WN_pFBlLTybTzOO2JJ2Y-4_5g" class="text-underline">https://us02web.zoom.us/webinar/register/6016856458658/WN_pFBlLTybTzOO2JJ2Y-4_5g</a>
                    </p>

                    <hr />

                    <div>
                      <button
                        data-dismiss="modal"
                        class="btn btn-primary btn-block"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*} <div class="row">
            <div class="col-lg-4">
              <div class="card m-b-30">
                <div class="card-body">
                  <div class="row p-t-10 ">
                    <div class="col-sm-6 my-auto ">
                      <h5 class="m-0">Total Sales </h5>
                    </div>
                  </div>

                  <div class="row p-t-10">
                    <div class="col-sm-6 my-auto ">
                      <h3 class="">$82.99</h3>
                    </div>
                    <div class="col-sm-6 my-auto  text-right ">
                      <h3 class="text-success">
                        <i class="mdi mdi-arrow-up" /> 2.6%
                      </h3>
                    </div>
                  </div>

                  <Chart
                    options={options}
                    series={series}
                    type="line"
                    //width={500}
                    height={135}
                  />

                  <div class="row text-center">
                    <div class="col-6">
                      <h6 class="text-truncate d-block">Last Month</h6>
                      <p class="m-0">358</p>
                    </div>
                    <div class="col-6">
                      <h6 class="text-truncate d-block">Current Month</h6>
                      <p class="m-0">194</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </Inner>
    );

    return (
      <Inner>
        <div class="content">
          <div class="row">
            <div class="col">
              <div class="content-block-top">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h1 style={{ margin: 0 }}>
                    Welcome to your dashboard, {user.display_name}
                  </h1>
                  {AppStore.logo() && (
                    <img src={AppStore.logo()} style={{ height: 30 }} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <div class="content-block">
                <DashStat type="app_total_users" label="total users" />
              </div>
            </div>
            <div class="col-3">
              <div class="content-block">
                <DashStat type="app_logins" label="logins this month" />
              </div>
            </div>
            <div class="col-3">
              <div class="content-block">
                <DashStat
                  type="app_listings"
                  label="listings viewed past 6 months"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="content-block">
                <DashMostViewed />
              </div>
            </div>
            {/*}
            <div class="col-3">
              <div class="content-block">
                <h3>
                  Logins
                  <br />
                  <small>Over past 7 days</small>
                </h3>
              </div>
            </div>
            <div class="col-3">
              <div class="content-block">
                <h3>
                  User sign ups
                  <br />
                  <small>Recent sign ups</small>
                </h3>
              </div>
                </div>*/}
          </div>

          {/*<div class="row">
            <div class="col-2">
              <div class="content-block">
                <h2>Coming soon</h2>
                <p>
                  More content coming soon. In the meantime use the links on the
                  left.
                </p>
              </div>
            </div>
          </div>*/}
        </div>
      </Inner>
    );
  }
}

export default Dashboard;

import React from "react";

const helpers = {
  propertyType(type) {
    switch (type) {
      case "sfamily":
        return "House";
        break;
      case "condo":
      case "townhouse":
      case "studio":
        return "Condo / Townhouse";
        break;
      case "mfr":
      case "mfr433":
      case "mobileland":
        return "Mobile / Manufactured";
        break;
      case "land":
        return "Land";
        break;
      case "mfamily":
        return "Multi-Family";
        break;
      case "resinc":
        return "Income Property";
        break;
      default:
        return "Other";
        break;
    }
  },

  propertyStatus(status) {
    switch (status) {
      case "active":
        return <span class="badge badge-success">Active</span>;
        break;
      case "pending":
        return <span class="badge badge-warning">Pending</span>;
        break;
      case "closed":
        return <span class="badge badge-danger">Sold</span>;
        break;
      default:
        return status;
        break;
    }
  }
};

export default helpers;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import Select from "react-select";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";
import helpers from "../../lib/helpers";
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const DateInput = ({ value, onClick }) => (
  <input type="text" className="form-control" onClick={onClick} value={value} />
);

let filenum = 1;
let drawingManager = null;

@observer
class PocketEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_edit: false,
      street_number: null,
      active: { value: 0, label: "Inactive" },
      listing_date: new Date(),
      lat: 0,
      lng: 0,
      editType: "New",
      submitType: "Create listing",
      photos: [],
      redirect_added: false
    };
  }

  activeOptions = () => {
    return [{ value: 1, label: "Active" }, { value: 0, label: "Inactive" }];
  };

  typeOptions = () => {
    return [
      { value: "sfamily", label: "House" },
      {
        value: "condo",
        label: "Condo / Townhouse",
      },
      {
        value: "mfamily",
        label: "Multi-family",
      },
      {
        value: "mobileland",
        label: "Mobile / Manfactured",
      },
      {
        value: "land",
        label: "Land",
      },
      {
        value: "other",
        label: "Other",
      },
    ];
  };

  statusOptions = () => {
    return [
      { value: "active", label: "Active" },
      {
        value: "pending",
        label: "Pending",
      },
      {
        value: "closed",
        label: "Sold",
      },
    ];
  };

  stateOptions = () => {
    let states = [];
    if (!AppStore.app_data?.countries || AppStore.app_data?.countries.length == 0 || AppStore.app_data?.countries.includes('us')) {
      states = [
        { value: "na", label: "N/A" },
        { value: "Alabama", label: "Alabama" },
        { value: "Alaska", label: "Alaska" },
        { value: "Arizona", label: "Arizona" },
        { value: "Arkansas", label: "Arkansas" },
        {
          value: "California",
          label: "California",
        },
        { value: "Colorado", label: "Colorado" },
        {
          value: "Connecticut",
          label: "Connecticut",
        },
        { value: "Delaware", label: "Delaware" },
        { value: "Florida", label: "Florida" },
        { value: "Georgia", label: "Georgia" },
        { value: "Hawaii", label: "Hawaii" },
        { value: "Idaho", label: "Idaho" },
        { value: "Illinois", label: "Illinois" },
        { value: "Indiana", label: "Indiana" },
        { value: "Iowa", label: "Iowa" },
        { value: "Kansas", label: "Kansas" },
        { value: "Kentucky", label: "Kentucky" },
        { value: "Louisiana", label: "Louisiana" },
        { value: "Maine", label: "Maine" },
        { value: "Maryland", label: "Maryland" },
        {
          value: "Massachusetts",
          label: "Massachusetts",
        },
        { value: "Michigan", label: "Michigan" },
        { value: "Minnesota", label: "Minnesota" },
        {
          value: "Mississippi",
          label: "Mississippi",
        },
        { value: "Missouri", label: "Missouri" },
        { value: "Montana", label: "Montana" },
        { value: "Nebraska", label: "Nebraska" },
        { value: "Nevada", label: "Nevada" },
        {
          value: "New Hampshire",
          label: "New Hampshire",
        },
        {
          value: "New Jersey",
          label: "New Jersey",
        },
        {
          value: "New Mexico",
          label: "New Mexico",
        },
        { value: "New York", label: "New York" },
        {
          value: "North Carolina",
          label: "North Carolina",
        },
        {
          value: "North Dakota",
          label: "North Dakota",
        },
        { value: "Ohio", label: "Ohio" },
        { value: "Oklahoma", label: "Oklahoma" },
        { value: "Oregon", label: "Oregon" },
        {
          value: "Pennsylvania",
          label: "Pennsylvania",
        },
        {
          value: "Rhode Island",
          label: "Rhode Island",
        },
        {
          value: "South Carolina",
          label: "South Carolina",
        },
        {
          value: "South Dakota",
          label: "South Dakota",
        },
        { value: "Tennessee", label: "Tennessee" },
        { value: "Texas", label: "Texas" },
        { value: "Utah", label: "Utah" },
        { value: "Vermont", label: "Vermont" },
        { value: "Virginia", label: "Virginia" },
        {
          value: "Washington",
          label: "Washington",
        },
        {
          value: "West Virginia",
          label: "West Virginia",
        },
        { value: "Wisconsin", label: "Wisconsin" },
        { value: "Wyoming", label: "Wyoming" },
      ];
    }

    // Add Canadian provinces
    if (AppStore.app_data.countries.includes("ca")) {
      states.push({ value: "Alberta", label: "Alberta" });
      states.push({ value: "British Columbia", label: "British Columbia" });
      states.push({ value: "Manitoba", label: "Manitoba" });
      states.push({ value: "New Brunswick", label: "New Brunswick" });
      states.push({ value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" });
      states.push({ value: "Nova Scotia", label: "Nova Scotia" });
      states.push({ value: "Ontario", label: "Ontario" });
      states.push({ value: "Prince Edward Island", label: "Prince Edward Island" });
      states.push({ value: "Quebec", label: "Quebec" });
      states.push({ value: "Saskatchewan", label: "Saskatchewan" });
      states.push({ value: "Northwest Territories", label: "Northwest Territories" });
      states.push({ value: "Nunavut", label: "Nunavut" });
      states.push({ value: "Yukon", label: "Yukon" });
    }


    return states;
  };

  componentDidMount() {
    // Is this a new or an edit?
    if (this.props.match.params.listingId) {
      this.setState({
        editType: "Edit",
        submitType: "Save changes",
        loading_edit: true,
      });

      // Editing
      api.getPocket(
        this.props.match.params.listingId,
        (response) => {
          //alert(moment.unix(response.listing_date).format("YYYY-MM-DD"));

          response.listing_date = moment.unix(response.listing_date).toDate();

          response.loading_edit = false;

          if (response.hide_address == 1) {
            response.hide_address = true;
          } else {
            response.hide_address = false;
          }

          this.setState(response);

          if (response.status == "active") {
            this.setState({ active: { value: 1, label: "Active" } });
          }

          //setTimeout(() => {
          //console.log(this.state);
          //}, 2000);
        },
        (error) => {
          alert("An error occurred");
        }
      );
    }
  }

  geocode() {
    this.setState({
      lat: 0,
      lng: 0,
    });

    let addr1 = this.state.full_address;
    let addr2 = this.state.city;
    let addr3 = this.state.state;
    let addr4 = this.state.zip;

    if (!addr1 || !addr2 || !addr4) {
      // || !addr3) {
      alert("Please enter all required address fields before geocoding.");
      return;
    }

    let address = encodeURIComponent(`${addr1}, ${addr2}, ${addr3}, ${addr4}`);

    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=AIzaSyCKUKIYX3gchVw_hWarG8X4Mb9IdaLrclo"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length == 0) {
            alert("Unable to geocode based off this address.");
          } else {
            let lat = result.results[0].geometry.location.lat;
            let lng = result.results[0].geometry.location.lng;

            if (lat && lng) {
              this.setState({
                lat: lat,
                lng: lng,
              });
            }
          }
        },

        (error) => {
          alert("Unable to geocode. Please try again later.");
        }
      );
  }

  handleGoogleMapApi = (google) => {
    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      //drawingMode: null,
      drawingControl: false,
      /*polygonOptions: {
          draggable: true,
          editable: true,
          strokeColor: "#FD7E02",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "rgba(253,126,2,0.5)",
          fillOpacity: 0.35
      },*/
      markerOptions: {
        draggable: true,
        editable: true,
      },
    });

    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(this.state.lat),
        lng: parseFloat(this.state.lng),
      },
      map: google.map,
      draggable: true,
      title: "Property location",
    });

    let self = this;
    google.maps.event.addListener(marker, "dragend", function (event) {
      const lat = this.position.lat();
      const lng = this.position.lng();
      self.setState({
        lat: lat,
        lng: lng,
      });
    });
  };

  handleDateChange = (date) => {
    this.setState({
      listing_date: date,
    });
    //moment(date).unix()
  };

  // TODO:
  // When loading an existing, do a stack ID check too to make sure
  // they are allowed to edit that one!

  onSortEnd = ({ oldIndex, newIndex, collection }) => {
    this.setState(({ photos }) => ({
      photos: arrayMove(photos, oldIndex, newIndex),
    }));
  };

  render() {
    if (
      !UserStore.isAdmin() &&
      !AppStore.app_data.toggles.pocketListingsAllowAgents
    ) {
      return <Redirect to="/" />;
    }

    if (
      !AppStore.app_data.toggles.pocketListingsEnabled ||
      AppStore.app_data.toggles.pocketListingsAutomated
    ) {
      return <Redirect to="/" />;
    }

    if (this.state.redirect_added === true) {
      return <Redirect to="/pocket" />;
    }

    if (this.state.loading_edit) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <Inner>
        <section class="admin-content">
          <div class="bg-dark">
            <div class="container  m-b-30">
              <div class="row">
                <div class="col-12 text-white p-t-40 p-b-90">
                  <h4 class="">
                    {" "}
                    <span class="btn btn-white-translucent">
                      <i class="icon-placeholder fe fe-home " />
                    </span>{" "}
                    {this.state.editType} listing
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="container pull-up">
            <div class="row">
              <div class="col-lg-6">
                <Formik
                  //enableReinitialize={true}
                  initialValues={{
                    id: this.state.id,
                    full_address: this.state.full_address,
                    hide_address: this.state.hide_address,
                    city: this.state.city,
                    zip: this.state.zip,
                    state: this.state.state,
                    beds: this.state.beds,
                    baths: this.state.baths,
                    building_size: this.state.building_size,
                    lotsize_sqft: this.state.lotsize_sqft,
                    garages: this.state.garages,
                    year_built: this.state.year_built,
                    price: this.state.price,
                    listing_id: this.state.listing_id,
                    list_agent_name: this.state.list_agent_name,
                    list_agent_license: this.state.list_agent_license,
                    list_agent_company: this.state.list_agent_company,
                    public_remarks: this.state.public_remarks,
                    additional_remarks: this.state.additional_remarks,
                    disclaimer: this.state.disclaimer,
                    listing_date: this.state.listing_date,
                    lat: this.state.lat,
                    lng: this.state.lng,
                    photos: this.state.photos,
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.full_address) {
                      errors.full_address = "Required";
                    }

                    if (!values.city) {
                      errors.city = "Required";
                    }

                    if (!values.zip) {
                      errors.zip = "Required";
                    }

                    if (!values.price) {
                      errors.price = "Required";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      if (this.state.state) {
                        if (this.state.state.value) {
                          if (this.state.state.value == "na") {
                            values.state = "";
                          } else {
                            values.state = this.state.state.value;
                          }
                        } else {
                          values.state = this.state.state;
                        }
                      } else {
                        values.state = "";
                        //alert("Please choose a state");
                        //setSubmitting(false);
                        //return;
                      }

                      if (this.state.property_type) {
                        if (this.state.property_type.value) {
                          values.property_type = this.state.property_type.value;
                        } else {
                          values.property_type = this.state.property_type;
                        }
                      } else {
                        alert("Please choose a property type");
                        setSubmitting(false);
                        return;
                      }

                      if (this.state.hide_address) {
                        values.hide_address = 1;
                      } else {
                        values.hide_address = 0;
                      }

                      if (this.state.status) {
                        if (this.state.status.value) {
                          values.status = this.state.status.value;
                        } else {
                          values.status = this.state.status;
                        }
                      } else {
                        alert("Please choose a status");
                        setSubmitting(false);
                        return;
                      }

                      if (this.state.active) {
                        if (this.state.active.value) {
                          values.active = this.state.active.value;
                        } else {
                          values.active = 0; //this.state.active;
                        }
                      } else {
                        alert("Please choose an active status");
                        setSubmitting(false);
                        return;
                      }

                      if (this.state.listing_date) {
                        values.listing_date = this.state.listing_date;
                      } else {
                        alert("Please choose a listing date");
                        setSubmitting(false);
                        return;
                      }

                      values.listing_key = this.state.listing_key;
                      values.lat = this.state.lat;
                      values.lng = this.state.lng;
                      values.photos = this.state.photos;

                      if (values.lat == 0 || values.lng == 0) {
                        alert("A location/geocoding is required");
                        setSubmitting(false);
                        return;
                      }

                      api.savePocket(
                        values,
                        (response) => {
                          window.showToastMessage("Success", "Listing saved");

                          //if (!this.state.listing_key) {
                          setTimeout(() => {
                            this.setState({ redirect_added: true });
                          }, 1000);
                          //}
                        },
                        (error) => {
                          alert("An error occurred. Please try again later.");
                        }
                      );

                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div class="card m-b-30">
                        <div class="card-body">
                          <h5>Address</h5>

                          <div class="row">
                            <div class="col-md-12">
                              <p>
                                <label for="full_address">
                                  Address
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="full_address"
                                  class="form-control"
                                  id="full_address"
                                  placeholder="1422 Main St #33"
                                  //onChange={handleChange}
                                  onChange={(e) => {
                                    this.setState({
                                      full_address: e.target.value,
                                    });
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.full_address}
                                />
                                <span className="form-error">
                                  {errors.full_address &&
                                    touched.full_address &&
                                    errors.full_address}
                                </span>
                              </p>

                              <p>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        color="primary"
                                        checked={this.state.hide_address}
                                        onChange={() => {
                                          this.setState({
                                            hide_address: !this.state.hide_address,
                                          });
                                        }}
                                        value="1"
                                      />
                                    }
                                    label="Hide address"
                                  />
                                  <span class="text-muted">If selected, this listing with show as "Address Withheld" in the app.</span>
                                </FormGroup>
                              </p>
                            </div>
                          </div>

                          <hr />

                          <div class="row">
                            <div class="col-md-6">
                              <p>
                                <p>
                                  <label for="city">
                                    City<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="city"
                                    class="form-control"
                                    id="city"
                                    placeholder="Westlake Village"
                                    //onChange={handleChange}
                                    onChange={(e) => {
                                      this.setState({ city: e.target.value });
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.city}
                                  />
                                  <span className="form-error">
                                    {errors.city && touched.city && errors.city}
                                  </span>
                                </p>
                              </p>
                            </div>
                            <div class="col-md-6">
                              <p>
                                <label>
                                  State / Province<span className="text-danger">*</span>
                                </label>
                                <Select
                                  value={this.state.state}
                                  options={this.stateOptions()}
                                  value={this.stateOptions().find((op) => {
                                    return op.value == this.state.state;
                                  })}
                                  onChange={(option) => {
                                    this.setState({ state: option });
                                  }}
                                />
                              </p>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <p>
                                <p>
                                  <label for="zip">
                                    Zip / Postal Code<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="zip"
                                    class="form-control"
                                    id="zip"
                                    placeholder="90210"
                                    //onChange={handleChange}
                                    onChange={(e) => {
                                      this.setState({ zip: e.target.value });
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.zip}
                                  />
                                  <span className="form-error">
                                    {errors.zip && touched.zip && errors.zip}
                                  </span>
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p style={{ marginBottom: 30 }}>
                        <button
                          class="btn btn-primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {this.state.submitType}
                        </button>
                      </p>

                      <div class="card m-b-30">
                        <div class="card-body">
                          <h5>Details</h5>

                          <div class="row">
                            <div class="col-md-6">
                              <p>
                                <label for="price">
                                  List price
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="price"
                                  class="form-control"
                                  id="price"
                                  placeholder="780500"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.price}
                                />
                                <span className="form-error">
                                  {errors.price &&
                                    touched.price &&
                                    errors.price}
                                </span>
                              </p>
                            </div>

                            <div class="col-md-6">
                              <p>
                                <label>
                                  Listing date
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  customInput={<DateInput />}
                                  selected={this.state.listing_date}
                                  onChange={this.handleDateChange}
                                />
                              </p>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <p>
                                <label>
                                  Property type
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  value={this.state.property_type}
                                  options={this.typeOptions()}
                                  value={this.typeOptions().find((op) => {
                                    return op.value == this.state.property_type;
                                  })}
                                  onChange={(option) => {
                                    this.setState({ property_type: option });
                                  }}
                                />
                              </p>
                            </div>
                            <div class="col-md-6">
                              <p>
                                <label>
                                  Status
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  value={this.state.status}
                                  options={this.statusOptions()}
                                  value={this.statusOptions().find((op) => {
                                    return op.value == this.state.status;
                                  })}
                                  onChange={(option) => {
                                    this.setState({ status: option });
                                  }}
                                />
                              </p>
                            </div>
                          </div>

                          <hr />

                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <label for="beds">Beds</label>
                                <input
                                  type="text"
                                  name="beds"
                                  class="form-control"
                                  id="beds"
                                  placeholder="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.beds}
                                />
                                <span className="form-error">
                                  {errors.beds && touched.beds && errors.beds}
                                </span>
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p>
                                <label for="baths">Baths</label>
                                <input
                                  type="text"
                                  name="baths"
                                  class="form-control"
                                  id="baths"
                                  placeholder="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.baths}
                                />
                                <span className="form-error">
                                  {errors.baths &&
                                    touched.baths &&
                                    errors.baths}
                                </span>
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p>
                                <label for="building_size">Sqft</label>
                                <input
                                  type="text"
                                  name="building_size"
                                  class="form-control"
                                  id="building_size"
                                  placeholder="1250"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.building_size}
                                />
                                <span className="form-error">
                                  {errors.building_size &&
                                    touched.building_size &&
                                    errors.building_size}
                                </span>
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p>
                                <label for="lotsize_sqft">Lot size</label>
                                <input
                                  type="text"
                                  name="lotsize_sqft"
                                  class="form-control"
                                  id="lotsize_sqft"
                                  placeholder="2600"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lotsize_sqft}
                                />
                                <span className="form-error">
                                  {errors.lotsize_sqft &&
                                    touched.lotsize_sqft &&
                                    errors.lotsize_sqft}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-4">
                              <p>
                                <label for="beds">Garage spaces</label>
                                <input
                                  type="text"
                                  name="garages"
                                  class="form-control"
                                  id="garages"
                                  placeholder="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.garages}
                                />
                                <span className="form-error">
                                  {errors.garages &&
                                    touched.garages &&
                                    errors.garages}
                                </span>
                              </p>
                            </div>

                            <div class="col-md-4">
                              <p>
                                <label for="year_built">Year built</label>
                                <input
                                  type="text"
                                  name="year_built"
                                  class="form-control"
                                  id="year_built"
                                  placeholder="1984"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.year_built}
                                />
                                <span className="form-error">
                                  {errors.year_built &&
                                    touched.year_built &&
                                    errors.year_built}
                                </span>
                              </p>
                            </div>
                          </div>

                          <hr />
                          <p>
                            <label for="public_remarks">Public remarks</label>
                            <textarea
                              id="public_remarks"
                              className="form-control"
                              rows={5}
                              placeholder="Beautiful 2 bedroom house in..."
                              value={values.public_remarks}
                              onChange={handleChange}
                            />
                          </p>

                          <hr />
                          <p>
                            <label for="additional_remarks">Additional remarks</label>
                            <textarea
                              id="additional_remarks"
                              className="form-control"
                              rows={5}
                              placeholder=""
                              value={values.additional_remarks}
                              onChange={handleChange}
                            />
                          </p>
                        </div>
                      </div>

                      <p style={{ marginBottom: 30 }}>
                        <button
                          class="btn btn-primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {this.state.submitType}
                        </button>
                      </p>

                      <div class="card m-b-30">
                        <div class="card-body">
                          <h5>Misc</h5>

                          <p>
                            <label for="listing_id">MLS # or ID</label>
                            <input
                              type="text"
                              name="listing_id"
                              class="form-control"
                              id="listing_id"
                              placeholder="2939393"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.listing_id}
                            />
                            <span className="form-error">
                              {errors.listing_id &&
                                touched.listing_id &&
                                errors.listing_id}
                            </span>
                          </p>

                          <p>
                            <label for="list_agent_name">List agent</label>
                            <input
                              type="text"
                              name="list_agent_name"
                              class="form-control"
                              id="list_agent_name"
                              placeholder="B Smith"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.list_agent_name}
                            />
                            <span className="form-error">
                              {errors.list_agent_name &&
                                touched.list_agent_name &&
                                errors.list_agent_name}
                            </span>
                          </p>

                          <p>
                            <label for="list_agent_license">
                              List agent license #
                            </label>
                            <input
                              type="text"
                              name="list_agent_license"
                              class="form-control"
                              id="list_agent_license"
                              placeholder="CalBRE 344493"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.list_agent_license}
                            />
                            <span className="form-error">
                              {errors.list_agent_license &&
                                touched.list_agent_license &&
                                errors.list_agent_license}
                            </span>
                          </p>

                          <p>
                            <label for="list_agent_company">
                              List agent company
                            </label>
                            <input
                              type="text"
                              name="list_agent_company"
                              class="form-control"
                              id="list_agent_company"
                              placeholder="Smith Realty"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.list_agent_company}
                            />
                            <span className="form-error">
                              {errors.list_agent_company &&
                                touched.list_agent_company &&
                                errors.list_agent_company}
                            </span>
                          </p>

                          <p>
                            <label for="disclaimer">Disclaimer</label>
                            <textarea
                              id="disclaimer"
                              className="form-control"
                              rows={5}
                              placeholder="Enter your disclaimer text here..."
                              value={values.disclaimer}
                              onChange={handleChange}
                            />
                          </p>
                        </div>
                      </div>

                      <p style={{ marginBottom: 30 }}>
                        <button
                          class="btn btn-primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {this.state.submitType}
                        </button>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>

              <div class="col-lg-6">
                <div class="card m-b-30">
                  <div class="card-body">
                    <p>
                      <label>Active on app</label>
                      <Select
                        value={this.state.active}
                        options={this.activeOptions()}
                        value={this.activeOptions().find((op) => {
                          console.log("op", op);
                          return op.value == this.state.active;
                        })}
                        onChange={(option) => {
                          this.setState({ active: option });
                        }}
                      />
                    </p>
                  </div>
                </div>

                <div class="card m-b-30">
                  <div class="card-body">
                    <h5>Location</h5>
                    <p>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.geocode();
                        }}
                      >
                        Geocode address
                      </button>
                    </p>
                    {this.state.lat != 0 && this.state.lng != 0 && (
                      <div
                        style={{
                          height: 300,
                          marginBottom: 10,
                          position: "relative",
                        }}
                      >
                        <GoogleMapReact
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={(google) =>
                            this.handleGoogleMapApi(google)
                          }
                          options={(maps) => {
                            return {
                              zoomControl: true,
                              fullscreenControl: false,
                              mapTypeId: "roadmap", //this.state.mapType
                            };
                          }}
                          // onChange={_debounce(this.mapMoved, 1500)}
                          bootstrapURLKeys={{
                            libraries: "drawing",
                            key: "AIzaSyCKUKIYX3gchVw_hWarG8X4Mb9IdaLrclo",
                          }}
                          center={{
                            lat: parseFloat(this.state.lat),
                            lng: parseFloat(this.state.lng),
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          defaultZoom={14}
                        />
                      </div>
                    )}
                    <div class="row">
                      <div class="col-md-6">
                        <p>
                          <label for="lat">
                            Latitude<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="lat"
                            class="form-control"
                            id="lat"
                            placeholder="34.651"
                            onChange={(e) => {
                              this.setState({ lat: e.target.value });
                            }}
                            value={this.state.lat}
                          />
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <label for="lng">
                            Longitude<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="lng"
                            class="form-control"
                            id="lng"
                            placeholder="-118.82"
                            onChange={(e) => {
                              this.setState({ lng: e.target.value });
                            }}
                            value={this.state.lng}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card m-b-30">
                  <div class="card-body">
                    <h5>Photos</h5>
                    <p>
                      <small>
                        Try to keep photos to a maximum of around 300-400KB
                        where possible and resolution up to a maximum 1600px in
                        any dimension. JPEG / PNG only.
                      </small>
                    </p>
                    <Dropzone
                      multiple={true}
                      accept={"image/jpeg, image/png"}
                      onDrop={(acceptedFiles) => {
                        acceptedFiles.forEach((file) => {
                          const reader = new FileReader();

                          reader.onabort = () =>
                            alert("file reading was aborted");
                          reader.onerror = () =>
                            alert("file reading has failed");
                          reader.onload = () => {
                            // Do whatever you want with the file contents
                            const binaryStr = reader.result;

                            console.log("on file " + filenum);
                            filenum++;

                            api.uploadPocketPhoto(
                              binaryStr,
                              (response) => {
                                window.showToastMessage(
                                  "Success",
                                  "Photo uploaded"
                                );

                                let phot = this.state.photos;
                                phot.push(response.photo);
                                this.setState({ photos: phot });
                              },
                              (error) => {
                                window.showToastMessage(
                                  "Error",
                                  "An error occurred",
                                  "danger",
                                  "fe-x"
                                );
                              }
                            );
                          };

                          reader.readAsArrayBuffer(file);
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div
                              style={{
                                border: "1px dotted #ddd",
                                borderRadius: 5,
                                height: 200,
                                padding: 10,
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ marginTop: 10, color: "#aaa" }}>
                                Drag &amp; drop photos here, or click to select.
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    {this.state.photos.length > 0 && (
                      <h6 style={{ marginTop: 10 }}>
                        Existing photos
                        <br />
                        <span class="text-muted">Drag to reorder</span>
                      </h6>
                    )}

                    {this.state.photos.length > 0 && (
                      <SortableList
                        onClick={(item) => {
                          let new_ph = [];
                          let ph = this.state.photos;
                          for (let x = 0; x < ph.length; x++) {
                            if (ph[x] !== item) {
                              new_ph.push(ph[x]);
                            }
                          }
                          this.setState({ photos: new_ph });
                        }}
                        distance={1}
                        items={this.state.photos}
                        onSortEnd={this.onSortEnd}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Inner>
    );
  }
}

const SortableItem = SortableElement(({ value, onClick }) => (
  <div
    style={{
      cursor: "move",
      overflow: "hidden",
      borderRadius: 6,
      border: "1px solid #eee",
      height: 200,
      width: "100%",
      maxWidth: 300,
      position: "relative",
    }}
  >
    <div
      style={{
        cursor: "pointer",
        backgroundColor: "#fff",
        position: "absolute",
        top: 10,
        right: 10,
        width: 30,
        borderRadius: 6,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => {
        if (window.confirm("Remove this photo?")) {
          onClick(value);
        }
      }}
    >
      <i
        className=" fe fe-x "
        style={{ color: "red", fontSize: 18, fontWeight: "bold" }}
      />
    </div>
    <img
      src={value}
      objectFit="cover"
      style={{ width: "100%", maxWidth: 300, height: 200 }}
    />
  </div>
));

const SortableList = SortableContainer(({ items, onClick }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={index}
          index={index}
          value={value}
          onClick={onClick}
        />
      ))}
    </div>
  );
});

export default PocketEdit;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import AppStore from "../../stores/AppStore";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import moment from 'moment-timezone';
import UserHistory from "../stats/UserHistory";
import Select from "react-select";
import UserMostViewed from "../stats/UserMostViewed";
import { Tooltip } from "react-tippy";
import _debounce from "lodash/debounce";
import Tag from "./components/Tag";
import Rodal from "rodal";
//import "rodal/lib/rodal.css";
import ManageUserTags from "./components/ManageUserTags";

import { Formik } from "formik";
import Autosuggest from "react-autosuggest";
import DatePicker from "react-datepicker";

let alertTypes = [
  { value: "url", label: "URL" },
  { value: "listing", label: "Listing" },
  { value: "text", label: "Text" }
];

let scheduleTypes = [
  { value: "now", label: "Now" },
  { value: "schedule", label: "Schedule a time" }
];

const renderSuggestion = (suggestion) => (
  <span>
    <b>{suggestion.text}</b>
  </span>
);

@observer
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      error: false,
      subtype: "all",
      agents: [],
      offices: [],
      promote_office_id: null,
      selectedAgent: null,
      userLoading: true,
      userData: null,
      userType: null,
      agentNotes: "",
      tagsModal: false,
      allTags: [],
      searches: [],
      pushModal: false,

      // alert stuff
      alerts: [],
      scheduled_alerts: [],
      scheduleType: { value: "now", label: "Now" },
      alertType: null, // listing
      alertRecipient: null,
      alertRecipientTag: false,
      alertListingKey: null,
      alertListingAddress: null,
      alertUsers: [{ value: "all", label: "All users" }],
      value: "",
      suggestions: [],
      currentTags: [],
      allTags: [],
      tagTotalUsers: 0,
      loadingTags: false,
      users: [],
      userModal: false,
      videoModal: false,
      showSchedule: false,
      schedule_date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)),
      schedule_hour: 9,
      schedule_minute: 0
    };

    this.handleAgentInputChangeDelayed = _debounce(this.handleAgentInputChange, 500);
  }

  componentDidMount() {
    this.loadUser(this.props.match.params.userId);
    this.loadSearches(this.props.match.params.userId);
    this.loadAgentNotes(this.props.match.params.userId);
    this.loadAllTags();
    this.loadOffices();
    if (UserStore.isAdmin()) {
      //this.loadAgents();
    }
  }

  loadOffices() {
    if (AppStore.app_data.toggles.officesEnabled) {
      api.getOffices(
        (response) => {
          let data = response.offices
          //this.setState({ offices: response.offices });

          function buildTree(items, parentId = "0") {
            return items
              .filter(item => item.parent_id === parentId)
              .map(item => ({ ...item, children: buildTree(items, item.id) }));
          }

          function flattenTree(items, depth = 0) {
            let flat = [];
            items.forEach(item => {
              flat.push({ ...item, depth, padding: `${depth * 10}` }); // Add item with depth and padding
              if (item.children) {
                flat = [...flat, ...flattenTree(item.children, depth + 1)]; // Flatten children with incremented depth
              }
            });
            return flat.map(({ children, ...item }) => item); // Return flat array without children property
          }

          const tree = buildTree(data); // Build hierarchical tree structure
          const orderedFlatArray = flattenTree(tree); // Flatten the tree back into an array with order preserved

          this.setState({
            offices: orderedFlatArray,
          });
        },
        (error) => {
          //
        }
      );
    }
  }

  getSuggestionValue(suggestion) {
    this.setState({
      alertListingKey: suggestion.key,
      alertListingAddress: suggestion.text,
    });
    return suggestion.text;
  }

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    api.getListingSuggestions(
      value,
      (response) => {
        //
        console.log(response);

        let sug = [];
        const mls = response.mls;
        const address = response.address;

        address.map((item) => {
          sug.push({
            key: item.ListingKey,
            text:
              item.FullStreetAddress +
              ", " +
              item.City +
              ", " +
              item.State +
              ", " +
              item.ZipCode,
          });
        });

        this.setState({
          suggestions: sug,
          alertListingKey: null,
          alertListingAddress: null,
        });
      },
      (error) => {
        this.setState({
          suggestions: [],
          alertListingKey: null,
          alertListingAddress: null,
        });
      }
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  loadSearches(user_id) {
    api.loadSaveSearch(user_id, response => {
      this.setState({
        searches: response.searches
      })
    }, error => {
      // do nothing
    });
  }

  loadAllTags() {
    api.loadAllUserTags(
      (response) => {
        this.setState({ allTags: response.tags })
        //this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  createTag(data) {
    let col = null;
    if (data.color && data.color.value) {
      col = data.color.value;
    }

    api.createUserTag(
      {
        user_id: this.props.match.params.userId,
        name: data.name,
        color: col,
        super_tag: data.super_tag
      },
      (response) => {
        //this.setState({ allTags: response.tags })
        this.loadUser(this.props.match.params.userId);
        this.loadAllTags();
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  addTag(tag) {
    // user_id
    api.addUserTag(
      {
        user_id: this.props.match.params.userId,
        tag_id: tag.id
      },
      (response) => {
        //this.setState({ allTags: response.tags })
        this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  removeTag(tag) {
    //user_id
    api.removeUserTag(
      {
        user_id: this.props.match.params.userId,
        tag_id: tag.id
      },
      (response) => {
        //this.setState({ allTags: response.tags })
        this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        //alert("Error loading tags");
      }
    );
  }

  changeAgent(userId, agentId) {
    //alert("changin to " + agentId + " for user " + userId);
    api.changeAgent(
      userId,
      agentId,
      (response) => {
        this.loadUser(this.props.match.params.userId);
      },
      (error) => {
        alert("Error changing agent");
      }
    );
  }

  loadAgents(q, callback) {
    api.getAgents(
      null,
      null,
      null,
      (response) => {
        let options = [];

        if (response.agents.length > 0) {
          options.push({ value: null, label: "No agent" });
        }

        response.agents.map((item) => {
          options.push({ value: item.id, label: item.display_name });
        });

        this.setState({ agents: options });
      },
      (error) => {
        this.setState({ agents: [] });
      },
      null,
      q
    );
  }

  handleAgentInputChange = value => {
    let q = value;

    if (q.length < 2) {
      this.setState({ agents: [] });
    } else {
      this.loadAgents(q);
    }
  };

  saveAgentNotes() {
    //  this.props.match.params.userId,
    api.saveAgentNotes(
      this.props.match.params.userId,
      this.state.agentNotes,
      (response) => {
        window.showToastMessage("Success", "Agent notes saved");
      },
      (error) => {
        alert("Error saving");
      }
    );
  }

  loadAgentNotes(id) {
    api.getUserData(
      id,
      "agentnotes",
      (response) => {
        if (!response) {
          this.setState({ agentNotes: "" });
        } else {
          if (response.length == 0) {
            this.setState({ agentNotes: "" });
          } else {
            this.setState({
              agentNotes: response.data.notes,
            });
          }
        }
      },
      (error) => {
        this.setState({ error: true });
      }
    );
  }

  loadUser(userId) {
    api.getUsers(
      null,
      null,
      null,
      (response) => {
        if (!response) {
          this.setState({ error: true });
        } else {
          if (response.length == 0) {
            this.setState({ error: true });
          } else {
            this.setState({ user: response.users });
          }
        }
      },
      (error) => {
        this.setState({ error: true });
      },
      userId
    );
  }

  promoteManager() {
    let office_id = this.state.promote_office_id;
    if (!office_id) {
      alert("Choose an office for the manager");
      return;
    }

    const user = this.state.user;

    if (window.confirm("Promote this user to become a manager?")) {
      api.promoteManager(
        user.user_id,
        office_id,
        (response) => {
          window.showToastMessage("Success", "User was promoted to manager");
          //this.loadUser(this.props.match.params.userId);
          // Refrsesh the page
          window.location.reload();
        },
        (error) => {
          window.showToastMessage(
            "Error",
            "An error occurred",
            "danger",
            "fe-x"
          );
        }
      );
    }
  }

  promote() {
    const user = this.state.user;

    if (window.confirm("Promote this user to become an agent?")) {
      api.promoteAgent(
        user.user_id,
        "agentnotes",
        (response) => {
          window.showToastMessage("Success", "User was promoted to agent");
          this.loadUser(this.props.match.params.userId);
        },
        (error) => {
          window.showToastMessage(
            "Error",
            "An error occurred",
            "danger",
            "fe-x"
          );
        }
      );
    }
  }

  userActive(status) {
    let status_changed = "activated";

    if (status == "inactive") {
      status_changed = "deactivated";
    }

    api.userActive(
      this.props.match.params.userId,
      status,
      response => {
        window.showToastMessage("Success", "User was " + status_changed);
        this.loadUser(this.props.match.params.userId);
      }, error => {
        window.showToastMessage(
          "Error",
          "An error occurred",
          "danger",
          "fe-x"
        );
      }
    );
  }

  loadUserdata(type) {
    this.setState({
      userLoading: true,
      userError: false,
      userData: null,
      userType: null,
    });

    // Get the data from API and show success or error

    api.getUserData(
      this.props.match.params.userId,
      type,
      (response) => {
        if (!response) {
          this.setState({ userError: true, userLoading: false });
        } else {
          if (response.length == 0) {
            this.setState({ userError: true, userLoading: false });
          } else {
            this.setState({
              userData: response.data,
              userType: response.type,
              userLoading: false,
            });
          }
        }
      },
      (error) => {
        this.setState({ error: true });
      }
    );

    return null;
  }

  usermodalcontent() {
    if (this.state.userLoading) {
      return <p>Loading...</p>;
    }

    if (this.state.userError) {
      return <p>An error occurred</p>;
    }

    if (this.state.userType == "fav") {
      return this.userFav();
    }

    if (this.state.userType == "notes") {
      return this.userNotes();
    }

    if (this.state.userType == "saved") {
      return this.userSaved();
    }

    return <p>Content</p>;
  }

  userSaved() {
    return (
      <>
        <div class="timeline">
          {this.state.userData.map((item) => (
            <div class="timeline-item">
              <div class="timeline-wrapper">
                <div class="col-auto">
                  <p>
                    <strong>{item.name}</strong>
                    <br />
                    {item.filters}
                  </p>
                </div>
                <div class="ml-auto col-auto text-muted">
                  {moment.unix(item.when).fromNow()}
                  <br />
                  {moment.unix(item.when).format("MMM D YYYY")}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  userNotes() {
    return (
      <>
        <div class="timeline">
          {this.state.userData.map((item) => (
            <div class="timeline-item" style={{ cursor: "pointer" }} onClick={(e) => {
              e.preventDefault();
              //const closing = document.getElementById('close_this_modal');
              //closing.click();
              AppStore.showListingModal(item.listing_key);
            }}>
              <div class="timeline-wrapper">
                <div class="">
                  <div class="avatar avatar-sm">
                    <img class="avatar-img rounded" src={item.photo} alt="" />
                  </div>
                </div>
                <div class="col-auto">
                  <p>{item.address}</p>
                </div>
                <div class="ml-auto col-auto text-muted">
                  {moment.unix(item.when).fromNow()}
                  <br />
                  {moment.unix(item.when).format("MMM D YYYY")}
                </div>
              </div>
              <p class="text-muted">{item.note}</p>
            </div>
          ))}
        </div>
      </>
    );
  }

  userFav() {
    return (
      <>
        <div class="timeline">
          {this.state.userData.map((item) => (
            <div class="timeline-item" style={{ cursor: "pointer" }} onClick={(e) => {
              e.preventDefault();
              //const closing = document.getElementById('close_this_modal');
              //closing.click();
              AppStore.showListingModal(item.listing_key);
            }}>
              <div class="timeline-wrapper">
                <div class="">
                  <div class="avatar avatar-sm">
                    <img class="avatar-img rounded" src={item.photo} alt="" />
                  </div>
                </div>
                <div class="col-auto">
                  <p>
                    <small>{item.list_name}</small><br />
                    {item.address}
                  </p>
                </div>
                <div class="ml-auto col-auto text-muted">
                  {moment.unix(item.when).fromNow()}
                  <br />
                  {moment.unix(item.when).format("MMM D YYYY")}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  userTitle() {
    if (this.state.userLoading) {
      return "Loading...";
    }

    if (this.state.userError) {
      return "Error";
    }

    if (this.state.userType == "fav") {
      return "Favorites";
    }

    if (this.state.userType == "notes") {
      return "User notes";
    }

    if (this.state.userType == "agentnotes") {
      return "Agent notes";
    }

    if (this.state.userType == "saved") {
      return "Saved searches";
    }

    return "Data";
  }

  usermodal() {
    return (
      <div
        class="modal fade modal-slide-right"
        id="slideRightModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="slideRightModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="slideRightModalLabel">
                {this.userTitle()}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              style={{ maxHeight: "100vh", overflow: "scroll" }}
            >
              {this.usermodalcontent()}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="close_this_modal"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  tags(tags) {
    if (tags.length == 0) {
      return (
        <p>No tags set</p>
      );
    }

    return (
      <>
        <p>
          {tags.map(tag => (
            <Tag
              displayOnly={true}
              tag={tag}
              onRemove={(selected) => {
                this.removeTag(selected);
              }}
            />
          ))}
        </p>
      </>
    );
  }

  showUser() {
    if (this.state.error) {
      return (
        <div className="centerBox">
          <img src="https://bk.homestack.com/assets/img/not-found.svg" />
          <h2>Unable to load data</h2>
          <p>
            <button
              onClick={() => {
                this.setState({ error: false });
                this.loadUser(this.props.match.params.userId);
              }}
            >
              Try again
            </button>
          </p>
        </div>
      );
    }

    if (!this.state.user) {
      return (
        <div
          class="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    const item = this.state.user;
    const saveds = this.state.searches ?? [];

    return (
      <section class="admin-content">
        <div class="bg-dark">
          <div class="container  m-b-30">
            <div class="row">

              <div class="col-12 text-white p-t-40 p-b-90">

                {AppStore.isEmbedded ? (
                  <div style={{ textAlign: "center" }}>
                    <a style={{ fontWeight: "bold", padding: "8px 12px", borderRadius: 8, backgroundColor: "#ffffff30" }} href="https://dashboard.homestack.com" target="_blank">Open HomeStack Dashboard</a>
                  </div>
                ) : (

                  <h4 class="">
                    {" "}
                    <span class="btn btn-white-translucent">
                      <i class="icon-placeholder fe fe-user " />
                    </span>{" "}
                    {item.display_name}
                  </h4>

                )}
              </div>





            </div>
          </div>
        </div>

        <div class="container pull-up">
          <div class="row">
            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-body">
                  <div class="text-center ">
                    {item.photo ? (
                      <img src={item.photo} class="rounded-circle" width="80" />
                    ) : (
                      <div class="avatar avatar-lg">
                        <span class="avatar-title rounded-circle ">
                          {item.display_name
                            .split(" ")
                            .map((n, i, a) =>
                              i === 0 || i + 1 === a.length ? n[0] : null
                            )
                            .join("")
                            .toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>

                  <h4 class="text-center m-t-20" style={{ marginBottom: 5 }}>
                    {item.display_name}
                  </h4>
                  {item.type == "agent" && (
                    <h5 class="text-center m-t-20" style={{ marginTop: 0 }}>
                      <span class="badge badge-warning">Agent</span>
                    </h5>
                  )}

                  {item.active == false && (
                    <div class="text-muted text-center m-b-20">
                      <p><span class="badge badge-danger"><strong>Inactive</strong></span></p>
                    </div>
                  )}

                  <div class="text-muted text-center m-b-20">
                    Joined {moment.unix(item.created_epoch).fromNow()}
                  </div>

                  <ul className="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between flex-column flex-sm-row">
                      <div>Email</div>
                      <span>
                        <a
                          className="text-underline"
                          href={"mailto:" + item.email}
                        >
                          {item.email}
                        </a>
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between flex-column flex-sm-row">
                      <div>Phone</div>
                      <div>
                        <a
                          className="text-underline"
                          href={"tel:" + item.phone}
                        >
                          {item.phone}
                        </a>
                        {item.phone_verified && (
                          <Tooltip title="Phone number verified">
                            <span
                              style={{
                                marginLeft: 5,
                                display: "inline-block",
                                width: 20,
                                alignSelf: "center",
                                height: 20,
                                //lineHeight: 20,
                                verticalAlign: "middle",
                                borderRadius: 10,
                                //display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              class="bg-success text-center"
                            >
                              <i
                                class="fe fe-check"
                                style={{ fontWeight: "bold", color: "#fff" }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                      <div>Total logins</div>
                      <span>{item.login_count}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between flex-column flex-sm-row">
                      <div>Last login</div>
                      <span>
                        {moment.unix(item.last_login_epoch).fromNow()}
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                      <div>Completed app setup</div>
                      {item.completed_setup ? (
                        <>
                          {item.completed_setup_epoch > 0 && (
                            <div>{moment.unix(item.completed_setup_epoch).format("MMM D, YYYY")}</div>
                          )}
                          <span
                            style={{
                              marginLeft: 5,
                              display: "inline-block",
                              width: 20,
                              alignSelf: "center",
                              height: 20,
                              //lineHeight: 20,
                              verticalAlign: "middle",
                              borderRadius: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            class="bg-success text-center"
                          >
                            <i
                              class="fe fe-check"
                              style={{ fontWeight: "bold", color: "#fff" }}
                            />
                          </span>
                        </>
                      ) : (
                        <span
                          style={{
                            marginLeft: 5,
                            display: "inline-block",
                            width: 20,
                            alignSelf: "center",
                            height: 20,
                            //lineHeight: 20,
                            verticalAlign: "middle",
                            borderRadius: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          class="bg-danger text-center"
                        >
                          <i
                            class="fe fe-x"
                            style={{ fontWeight: "bold", color: "#fff" }}
                          />
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>


              {!AppStore.isEmbedded && !UserStore.isManager() && (
                <div class="card m-b-30">
                  <div class="card-header" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h5 class="m-b-0">Tags</h5>
                    {!AppStore.readOnly && (
                      <button className="btn btn-sm btn-primary" onClick={() => {
                        this.setState({ tagsModal: true })
                      }}>Manage tags</button>
                    )}
                  </div>
                  <div class="card-body">
                    {this.tags(item.tags)}
                  </div>
                </div>
              )}

              {AppStore.app_data.toggles.officesEnabled && (UserStore.isAdmin() || UserStore.isManager()) && item.type != 'admin' && item.subType != 'agentManager' && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Promote to manager</h5>
                  </div>
                  <div class="card-body">
                    <p>
                      Choose office to make this user a manager of.
                    </p>
                    <p>
                      <select
                        value={this.state.promote_office_id}
                        name="office_id"
                        id="office_id"
                        class="form-control"
                        onChange={e => {
                          this.setState({ promote_office_id: e.target.value });
                        }}>
                        <option value="">-- Choose office --</option>
                        {this.state.offices.map(office => {
                          let spacer = "";
                          if (office.padding > 0) {
                            spacer = "-".repeat(parseInt(office.padding) / 10);
                            spacer += " ";
                          }

                          return (
                            <option value={office.id}>
                              {spacer}{office.name}
                            </option>
                          );

                        })}
                      </select>
                    </p>
                    {!AppStore.readOnly && (
                      <p>
                        <button
                          class="btn btn-primary"
                          onClick={() => {
                            this.promoteManager();
                          }}
                        >
                          Promote to manager
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {UserStore.isAdmin() && item.type != "agent" && !UserStore.isManager() && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Promote to agent</h5>
                  </div>
                  <div class="card-body">
                    <p>
                      You can create an agent from this user and they will then
                      join the agent roster.
                    </p>
                    {!AppStore.readOnly && (
                      <p>
                        <button
                          class="btn btn-primary"
                          onClick={() => {
                            this.promote();
                          }}
                        >
                          Promote to agent
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div class="col-lg-6">
              <div class="card m-b-30">
                <div class="card-body">
                  {item.agent ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div class="avatar avatar-sm">
                        {item.agent.photo ? (
                          <img
                            src={item.agent.photo}
                            class="avatar-img rounded-circle"
                            alt=""
                          />
                        ) : (
                          <div class="avatar-title rounded-circle">
                            {item.agent.display_name
                              .split(" ")
                              .map((n, i, a) =>
                                i === 0 || i + 1 === a.length ? n[0] : null
                              )
                              .join("")
                              .toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <span class="text-muted text-left">Agent</span>
                        <br />
                        {!AppStore.isEmbedded ? (
                          <Link
                            to={`/agents/${item.agent.id}`}
                            className="text-underline"
                          >
                            {item.agent.display_name}
                          </Link>
                        ) : (
                          <>
                            {item.agent.display_name}
                          </>
                        )}

                      </div>
                    </div>
                  ) : (
                    <p class="text-muted">No agent set</p>
                  )}

                  {UserStore.isAdmin() && (
                    <>
                      <hr />
                      {!AppStore.readOnly && (
                        <div class="form-group ">
                          <label class="font-secondary">Change agent</label>
                          <Select
                            value={this.state.selectedAgent}
                            options={this.state.agents}
                            onInputChange={(val) => { this.handleAgentInputChangeDelayed(val) }}
                            onChange={(option) => {
                              this.setState({ selectedAgent: option });
                            }}
                            noOptionsMessage={() => { return <p>Start typing to list agents</p> }}
                            placeholder="Search by agent name..."
                          />
                        </div>
                      )}

                      {this.state.selectedAgent != null && (
                        <div class="form-group text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.changeAgent(
                                item.user_id,
                                this.state.selectedAgent.value
                              );
                              this.setState({ selectedAgent: null });
                            }}
                          >
                            Change
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              this.setState({ selectedAgent: null });
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/*AppStore.app_data.toggles.savedSearchesEnabled && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Actions</h5>
                  </div>
                  <div class="card-body">
                    <ul className="list-group list-group-flush">
                      <li class="list-group-item d-flex justify-content-between">
                        <span>
                          <a className="text-underline" href="#">
                            Create saved search for user
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body"></div>
                </div>
              )*/}


              {!UserStore.isManager() && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Push Notifications</h5>
                  </div>
                  <div class="card-body">


                    <button className="btn btn-primary" onClick={(e) => {
                      e.preventDefault();
                      this.setState({ pushModal: true })
                    }}>Send push notification</button>
                  </div>
                </div>
              )}


              {AppStore.app_data.toggles.agentSavedSearchEnabled && !UserStore.isManager() && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Agent Saved Searches</h5>
                  </div>
                  <div class="card-body">



                    <ul className="list-group list-group-flush mb-4">
                      {saveds.map(item2 => (
                        <li class="list-group-item d-flex justify-content-between">
                          <div>
                            <Link className="text-underline" to={{
                              pathname: "/users/" + this.props.match.params.userId + "/agentsavedsearch/" + item2.id,
                              state: { user: item, edit: item2 }
                            }}>{item2.search_name}</Link>
                          </div>
                          <span className="text-muted"><small>{item2.desc}</small></span>
                        </li>
                      ))}

                      {saveds.length == 0 && (
                        <li class="list-group-item d-flex justify-content-between">
                          <div>
                            <span className="text-muted">No agent saved searches</span>
                          </div>
                        </li>
                      )}
                    </ul>

                    <Link className="btn btn-primary" to={{
                      pathname: "/users/" + this.props.match.params.userId + "/agentsavedsearch",
                      state: { user: item }
                    }}>Create Agent Saved Search</Link>
                  </div>
                </div>
              )}

              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Overview</h5>
                </div>
                <div class="card-body">
                  {this.usermodal()}

                  <ul className="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between">
                      <div>
                        <i class="mdi mdi-circle text-warning fw-600" />{" "}
                        {item.meta.favorites > 0 ? (
                          <a
                            href="#"
                            onClick={() => this.loadUserdata("fav")}
                            data-toggle="modal"
                            data-target="#slideRightModal"
                            className="text-underline"
                          >
                            Favorites
                          </a>
                        ) : (
                          <>Favorites</>
                        )}
                      </div>
                      <span>{item.meta.favorites}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                      <div>
                        <i class="mdi mdi-circle text-info fw-600" />{" "}
                        {item.meta.notes > 0 ? (
                          <a
                            href="#"
                            onClick={() => this.loadUserdata("notes")}
                            data-toggle="modal"
                            data-target="#slideRightModal"
                            className="text-underline"
                          >
                            Notes
                          </a>
                        ) : (
                          <>Notes</>
                        )}
                      </div>
                      <span>{item.meta.notes}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                      <div>
                        <i class="mdi mdi-circle text-success fw-600" />{" "}
                        {item.meta.saved_searches > 0 ? (
                          <a
                            href="#"
                            onClick={() => this.loadUserdata("saved")}
                            data-toggle="modal"
                            data-target="#slideRightModal"
                            className="text-underline"
                          >
                            Saved searches
                          </a>
                        ) : (
                          <> Saved searches</>
                        )}
                      </div>
                      <span>{item.meta.saved_searches}</span>
                    </li>
                  </ul>
                </div>
              </div>

              {!AppStore.isEmbedded && !UserStore.isManager() && (
                <div class="card m-b-30">
                  <div class="card-header">
                    <h5 class="m-b-0">Agent notes</h5>
                  </div>
                  <div class="card-body">
                    <textarea
                      className="form-control"
                      rows="5"
                      value={this.state.agentNotes}
                      onChange={(e) => {
                        this.setState({ agentNotes: e.target.value });
                      }}
                    />
                    <hr />
                    {!AppStore.readOnly && (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.saveAgentNotes();
                        }}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              )}

              {UserStore.isAdmin() && !AppStore.isEmbedded && item.email != "apple@homestack.com" && (
                <>
                  {item.active == true ? (
                    <div class="card m-b-30">
                      <div class="card-header">
                        <h5 class="m-b-0">Make inactive</h5>
                      </div>
                      <div class="card-body">
                        <p>Making a user inactive will prevent them from logging into the app and remove them from the dashboard and Insights.</p>
                        <hr />
                        {!AppStore.readOnly && (
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              if (window.confirm("Make this user inactive?")) {
                                this.userActive("inactive");
                              }
                            }}
                          >
                            Make user inactive
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div class="card m-b-30">
                      <div class="card-header">
                        <h5 class="m-b-0">Make active</h5>
                      </div>
                      <div class="card-body">
                        {!AppStore.readOnly && (
                          <button
                            className="btn btn-success"
                            onClick={() => {

                              this.userActive("active");

                            }}
                          >
                            Make user active
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {/*<div class="card m-b-30">
                <div class="card-header">
                  <h5 class="m-b-0">Photo</h5>
                </div>
                <div class="card-body">
                  <p>Photo</p>
                  {JSON.stringify(item)}
                </div>
              </div>
              */}
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="card m-b-30">
                <div class="card-header">
                  <h5 class="card-title m-b-0">Recent activity</h5>

                  <div class="card-controls">
                    <select
                      class="custom-select"
                      value={this.state.subtype}
                      onChange={(e) => {
                        this.setState({ subtype: e.target.value });
                      }}
                    >
                      <option value="all">All</option>
                      <option value="view">Views</option>
                      <option value="fav">Favorites</option>
                      <option value="note">Notes</option>
                      <option value="share">Shares</option>
                    </select>
                  </div>

                  {/*}  <div class="card-controls">
                    <a href="#" class="js-card-fullscreen icon"></a>
                    <div class="dropdown">
                      <a
                        href="#"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        <i class="icon mdi  mdi-dots-vertical"></i>{" "}
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          backgroundColor: "red",
                          zIndex: 9000000,
                          position: "absolute",
                          willCchange: "transform",
                          top: 0,
                          left: 0,
                          transform: "translate3d(18px, 24px, 0px)"
                        }}
                      >
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            this.setState({ subtype: "all" });
                          }}
                        >
                          All activity
                        </button>
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            this.setState({ subtype: "view" });
                          }}
                        >
                          Views only
                        </button>
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            this.setState({ subtype: "fav" });
                          }}
                        >
                          Favorites only
                        </button>
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            this.setState({ subtype: "note" });
                          }}
                        >
                          Notes only
                        </button>
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            this.setState({ subtype: "share" });
                          }}
                        >
                          Shares only
                        </button>
                      </div>
                    </div>
                  </div>
               */}
                </div>
                <div class="card-body">
                  <UserHistory
                    userId={item.user_id}
                    subtype={this.state.subtype}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <UserMostViewed userId={item.user_id} />{" "}
            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    //const all_tags = this.state.allTags;
    //const user = this.state.user;

    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search listings by address...",
      value,
      onChange: this.onChange,
      className: "form-control",
    };

    return (
      <Inner>
        {this.showUser()}

        <Rodal
          width={"90%"}
          customStyles={{ maxWidth: 600, maxHeight: 600, overflowY: "scroll", zsIndex: 2000 }}
          visible={this.state.pushModal}
          onClose={() => { this.setState({ pushModal: false }) }}
          //width={500}
          height={"90%"}
        >
          <h2>Send push {AppStore.isEmbedded && (<br />)} notification</h2>

          <Formik
            initialValues={{
              title: null,
              subtitle: null,
              //password: null,
              //phone: null,
            }}
            validate={(values) => {
              let errors = {};

              if (!values.title) {
                errors.title = "Required";
              }

              if (values.title && values.title.length > 140) {
                errors.title = "Max length is 140 characters";
              }

              if (values.title && values.title.length < 10) {
                errors.title = "Min length is 10 characters";
              }

              if (!values.subtitle) {
                errors.subtitle = "Required";
              }

              if (values.subtitle && values.subtitle.length > 140) {
                errors.title = "Max length is 140 characters";
              }

              if (values.subtitle && values.subtitle.length < 10) {
                errors.subtitle = "Min length is 10 characters";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (!this.state.alertType) {
                alert("Alert type is required");
                setSubmitting(false);
                return;
              }

              if (
                this.state.alertType &&
                this.state.alertType.value == "text"
              ) {
                if (values.text == "") {
                  alert("Please enter some text");
                  return;
                }
              }

              if (
                this.state.alertType &&
                this.state.alertType.value == "url"
              ) {
                //
              }

              if (
                this.state.alertType &&
                this.state.alertType.value == "listing"
              ) {
                let key = this.state.alertListingKey;
                let addr = this.state.alertListingAddress;

                values.key = key;
                values.address = addr;
              }


              values.recipient = this.props.match.params.userId;

              values.type = this.state.alertType.value;

              // Scheduling stuff
              let d = this.state.schedule_date;
              let new_date = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2)
              ].join('-');

              let sh = this.state.schedule_hour;
              let sm = this.state.schedule_minute;

              String(sh).padStart(2, '0');
              String(sm).padStart(2, '0');

              let stime = sh + ':' + sm + ':00';

              new_date += ' ' + stime;

              if (this.state.showSchedule) {
                values.timing = "schedule";
                values.send_date_time = new_date;
              }
              // End of scheduling

              setTimeout(() => {
                //alert(JSON.stringify(values));
                api.sendAlert(
                  values,
                  (response) => {
                    this.setState({
                      pushModal: false,
                    });
                    window.showToastMessage("Success", "Alert sent");
                  },
                  (error) => {
                    alert(error.response.data.message);
                  }
                );

                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>

                <p>
                  <label for="title">Title* (max 140 characters)</label>
                  <input
                    type="text"
                    name="title"
                    class="form-control"
                    id="title"
                    placeholder="Enter your title..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  <span className="form-error" style={{ color: "red", fontWeight: "bold" }}>
                    {errors.title && touched.title && errors.title}
                  </span>
                </p>

                <p>
                  <label for="subtitle">Subtitle* (max 140 characters)</label>
                  <input
                    type="text"
                    name="subtitle"
                    class="form-control"
                    id="subtitle"
                    placeholder="Enter your subtitle..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subtitle}
                  />
                  <span className="form-error" style={{ color: "red", fontWeight: "bold" }}>
                    {errors.subtitle && touched.subtitle && errors.subtitle}
                  </span>
                </p>

                <p>
                  <label>Alert type</label>
                  <Select
                    value={this.state.alertType}
                    options={alertTypes}
                    onChange={(option) => {
                      this.setState({
                        alertType: option,
                        alertListingKey: null,
                        alertListingAddress: null,
                        value: "",
                      });
                    }}
                  />
                </p>

                {this.state.alertType && this.state.alertType.value == "text" && (
                  <p>
                    <label for="text">Text</label>
                    <textarea
                      rows="4"
                      type="text"
                      name="text"
                      class="form-control"
                      id="text"
                      placeholder="Enter text..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.text}
                    />
                    <span className="form-error">
                      {errors.text && touched.text && errors.text}
                    </span>
                  </p>
                )}

                {this.state.alertType && this.state.alertType.value == "url" && (
                  <p>
                    <label for="url">URL</label>
                    <input
                      type="text"
                      name="url"
                      class="form-control"
                      id="url"
                      placeholder="Enter URL..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                    />
                    <span className="form-error">
                      {errors.url && touched.url && errors.url}
                    </span>
                  </p>
                )}

                {this.state.alertType &&
                  this.state.alertType.value == "listing" && (
                    <p>
                      <label for="listing">Choose listing</label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={(val) =>
                          this.getSuggestionValue(val)
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                    </p>
                  )}

                <p>
                  <label>Send</label>
                  <Select
                    value={this.state.scheduleType}
                    options={scheduleTypes}
                    onChange={(option) => {
                      let showSchedule = false;
                      if (option.value == "schedule") {
                        showSchedule = true;
                      }

                      this.setState({
                        scheduleType: option,
                        showSchedule: showSchedule
                      });
                    }}
                  />
                </p>

                {this.state.showSchedule && (
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <label>Date</label>
                        <DatePicker
                          className="form-control"
                          showIcon
                          minDate={new Date()}
                          selected={this.state.schedule_date}
                          onChange={(date) => {
                            this.setState({ schedule_date: date });
                          }}
                        />
                      </p>
                    </div>

                    <div class="col-md-6">
                      <p>
                        <label>Time</label>

                        <div class="row">
                          <div class="col-md-6">
                            <select className="form-control" value={this.state.schedule_hour} onChange={(e) => {
                              this.setState({ schedule_hour: e.target.value });
                            }}>
                              <option value="0">00</option>
                              <option value="1">01</option>
                              <option value="2">02</option>
                              <option value="3">03</option>
                              <option value="4">04</option>
                              <option value="5">05</option>
                              <option value="6">06</option>
                              <option value="7">07</option>
                              <option value="8">08</option>
                              <option value="9">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <select className="form-control" value={this.state.schedule_minute} onChange={(e) => {
                              this.setState({ schedule_minute: e.target.value });
                            }}>
                              <option value="0">00</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                              <option value="45">45</option>
                            </select>
                          </div>
                        </div>
                      </p>
                    </div>

                    <div class="col-md-12">
                      <p><small>Time now is {moment().tz(AppStore.app_data.tz).format("HH:mm z")}</small></p>
                    </div>
                  </div>
                )}

                <p>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {this.state.showSchedule ? "Schedule notification" : "Send notification"}
                  </button>
                </p>
              </form>
            )}
          </Formik>
        </Rodal>

        <Rodal
          visible={this.state.tagsModal}
          onClose={() => { this.setState({ tagsModal: false }) }}
          width={"90%"}
          customStyles={{ maxWidth: 600, overflow: "auto" }}
          height={400}
        >
          <ManageUserTags
            allTags={this.state.allTags}
            user={this.state.user}
            onAdd={(tag) => {
              //alert('add! ' + JSON.stringify(tag))
              this.addTag(tag);
            }}
            onRemove={(tag) => {
              this.removeTag(tag);
              //alert('remove! ' + JSON.stringify(tag))
            }}
            onCreate={(data) => {
              this.createTag(data);
            }}
          />
        </Rodal>
      </Inner>
    );
  }
}

export default withRouter(User);

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Route, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import Inner from "../template/Inner";
import Spinner from "react-spinkit";
import api from "../../lib/api";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { FiX, FiVideo } from "react-icons/fi";
import { Formik } from "formik";
import Select from "react-select";
//import moment from "moment";
import Autosuggest from "react-autosuggest";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ManageUserTags from "./components/ManageUserTags";
import Rodal from "rodal";
import _debounce from "lodash/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
//import "rodal/lib/rodal.css";

@observer
class SearchLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            scheduled_alerts: [],
            loading: true,
            error: false,
            createModal: false,
        };
    }

    componentDidMount() {
        this.loadSearchLinks();
    }

    loadSearchLinks() {
        this.setState({ loading: false });
        api.loadSearchLinks(
            (response) => {
                this.setState({
                    alerts: response.links,
                    loading: false,
                });
            },
            (error) => {
                this.setState({ error: true, loading: false });
            }
        );
    }

    photo(photo) {
        return (

            <img src={photo} width={30} height={30} style={{ borderRadius: 15 }} />

        );
    }


    alerts() {
        if (this.state.error) {
            return (
                <div className="centerBox">
                    <img src="https://bk.homestack.com/assets/img/not-found.svg" />
                    <h2>Unable to load data</h2>
                    <p>
                        <button
                            onClick={() => {
                                this.setState({ error: false });
                                this.loadUsers();
                            }}
                        >
                            Try again
                        </button>
                    </p>
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div
                    class="d-flex w-100 justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <section class="admin-content">

                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <div class="float-right">
                                    {!AppStore.readOnly && (
                                        <Link className="btn btn-success" to={{
                                            pathname: `searchlinks/new`
                                        }}>Create Search Template</Link>


                                    )}
                                </div>
                                <h4 class="">
                                    {" "}
                                    <span class="btn btn-white-translucent">
                                        <i class="icon-placeholder fe fe-search " />
                                    </span>{" "}
                                    Search Templates
                                </h4>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="container pull-up">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="card m-b-30">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover ">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Created</th>
                                                    <th>Link (copy)</th>
                                                    {UserStore.isAdmin() && <th>Agent</th>}
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.alerts.map((item) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{moment.unix(item.created).format("MMM D YYYY")}</td>
                                                        <td><a href="#" class="text-underline" onClick={(e) => {
                                                            e.preventDefault();

                                                            let aikLink = `https://bk.homestack.com/${AppStore.app_data.short_name
                                                                }/search/${item.link}?aik=${UserStore.user.agent_data.invite_key}`;

                                                            if (AppStore.app_data.toggles.websiteEnabled) {
                                                                aikLink = `https://${AppStore.app_data.short_name}.hsidx.com/@${UserStore.user.agent_data.invite_key}/search/${item.link}`;
                                                            }

                                                            navigator.clipboard.writeText(aikLink);
                                                            window.showToastMessage("Success", "Link copied to clipboard");
                                                        }}>Copy Link</a>
                                                        </td>
                                                        {UserStore.isAdmin() && <td>
                                                            {item.agent_name && (
                                                                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                                                    {item.agent_photo && (
                                                                        <div style={{ marginRight: 5 }}>
                                                                            {this.photo(item.agent_photo)}
                                                                        </div>
                                                                    )}
                                                                    {item.agent_name}
                                                                </div>
                                                            )}
                                                        </td>}
                                                        <td>
                                                            <Link className="btn btn-primary" to={{
                                                                pathname: `searchlinks/${item.id}`,
                                                                state: { edit: item }
                                                            }}>Edit</Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }

    render() {
        return (
            <Inner>
                <div class="content">
                    {this.alerts()}
                </div>
            </Inner>
        );
    }
}

const styles = {
    modal: {
        backgroundColor: "#fff",
        padding: 20,
        maxWidth: 500,
        width: "90%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
    },
    modalTitle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
};

export default SearchLinks;

import React, { Component } from "react";
//import "../login/style.scss";
import { Cookies } from "react-cookie";
import { Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import Inner from "../template/Inner";
import AppStore from "../../stores/AppStore";
import api from "../../lib/api";
import Spinner from "react-spinkit";

@observer
class SettingsSSO extends Component {
  constructor(props) {
    super(props);
    this.state = { logout: false, key: null, error: false };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    api.getAPIKey(
      response => {
        this.setState({ key: response.key });
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  copyToClipboard = e => {
    this.copyLink.select();
    document.execCommand("copy");
    alert("Copied to clipboard");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    //e.target.focus();
    //this.setState({ copySuccess: 'Copied!' });
  };

  render() {
    if (!UserStore.isAdmin()) {
      return <Redirect to="/" />;
    }

    if (this.state.error) {
      return (
        <Inner>
          <div class="content">
            <div className="centerBox">
              <img src="https://bk.homestack.com/assets/img/not-found.svg" />
              <h2>Unable to load data</h2>
              <p>
                <button
                  onClick={() => {
                    this.setState({ error: false });
                    this.load();
                  }}
                >
                  Try again
                </button>
              </p>
            </div>
          </div>
        </Inner>
      );
    }

    if (!this.state.key) {
      return (
        <Inner>
          <div
            class="d-flex w-100 justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </Inner>
      );
    }

    return (
      <Inner>
        <div class="bg-dark bg-dots m-b-30">
          <div class="container">
            <div class="row p-b-60 p-t-60">
              <div class="col-lg-8 text-center mx-auto text-white p-b-30">
                <div class="m-b-10">
                  <div class="avatar avatar-lg ">
                    <div class="avatar-title bg-info rounded-circle mdi mdi-settings " />
                  </div>
                </div>
                <h3>Single Sign On</h3>
              </div>
            </div>
          </div>
        </div>

        <section class="pull-up">
          <div class="container">
            <div class="row ">
              <div class="col-lg-12">
                <div class="card py-3 m-b-30">
                  <div class="card-body">
                    <h3 class="">SSO guide</h3>

                    <p>
                      To login via SSO, get the{" "}
                      <Link to="/settings" class="text-underline">
                        API key
                      </Link>{" "}
                      from the broker dashboard and call the access token
                      endpoint at{" "}
                      <code>https://bkapi.homestack.com/getAccessToken</code>.
                    </p>
                    <p>
                      Pass the API key in an <code>X-API-KEY</code> header.
                    </p>
                    <p>
                      With the result access token, open the URL:{" "}
                      <code>
                        https://broker.homestack.com/sso?e=EMAIL&a=ACCESS_TOKEN
                      </code>
                    </p>

                    <h4>Sample code</h4>

                    <pre
                      style={{ backgroundColor: "#eee", padding: 10 }}
                    >{`var form = new FormData();
form.append('email', user.email);

const options = {
    method: "POST",
    headers: {
        "X-API-KEY": "${this.state.key}"
    },
    body: form
}

fetch("https://bkapi.homestack.com/getAccessToken", options)
.then(function(res)) {
    return res.json();
})
.then(function(json) {
    if (json.accessToken) {
        res.redirect(\`https://broker.homestack.com/sso?e=\${email}&a=\${json.accessToken}\`)
    } else {
        // some exception handling
    }
})
.catch( (err) => {
    console.log(err);
})`}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Inner>
    );
  }
}

export default SettingsSSO;
